import { Course, CourseLevel, CourseStatus } from 'feature/course/course.type';
import { identity, pickBy } from 'lodash';

import queryString from 'query-string';
import useAxios from 'axios-hooks';

type QueryCourses = {
  keyword: string;
  subCategory: string;
  subjectGroup: string;
  gradeLevel: string;
  courseLevel: CourseLevel;
  status: CourseStatus;
  pageNumber: number;
  pageSize: number;
  fromDate: string;
  toDate: string;
  teacherAcademyCourseType: string;
};

export const useCourses = (params?: Partial<QueryCourses>) => {
  const [{ data: response, loading, error }, refetch] = useAxios({
    url: `/courses?${queryString.stringify({
      pageNumber: 1,
      pageSize: 10,
      ...pickBy(params, identity)
    })}`,
    method: 'GET'
  });

  return {
    data: response?.data as Course[],
    loading,
    error,
    refetch,
    item: response?.meta?.pagination?.total
  };
};
