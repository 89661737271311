import { Course } from 'feature/course/course.type';
import useAxios from 'axios-hooks';

export const useCourse = (courseId: string | null = null, manual = false) => {
  const [{ data, loading, error }, refetch] = useAxios('/courses/' + courseId, {
    manual
  });

  return {
    data: loading || !courseId ? null : (data?.data as Course),
    loading: courseId ? loading : false,
    error,
    execute: refetch
  };
};
