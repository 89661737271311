/** @jsx jsx */
import { Button, Row, Table } from 'antd';
import { css, jsx } from '@emotion/react';

import React from 'react';
import Modal from 'antd/lib/modal/Modal';
import { ExclamationCircleTwoTone } from '@ant-design/icons';
import { SchoolRequestRequestorDto } from '@aksorn/backoffice-sdk';
import { ReactComponent as AddIcon } from 'assets/add.svg';
import { ReactComponent as DeleteIcon } from 'assets/delete.svg';

interface AdditionalRequestorsModalProps {
  visible: boolean;
  onClose: () => void;
  onConfirm: () => void;
  loading: boolean;
  newAdditionalRequestors: SchoolRequestRequestorDto[];
  deleteAdditionalRequestors: SchoolRequestRequestorDto[];
}

export const AdditionalRequestorsModal: React.FC<AdditionalRequestorsModalProps> = ({
  visible,
  onClose,
  onConfirm,
  loading,
  newAdditionalRequestors,
  deleteAdditionalRequestors
}) => {

  const columns = [
    {
      title: 'ชื่อ-สกุล',
      dataIndex: '',
      key: 'name',
      render: (record: any) => {
        return (<span className="flex">{record?.firstName} {record?.lastName}</span>)
      }
    },
    {
      title: 'อีเมล',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'เบอร์โทร',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      render: (phoneNumber: any) => {
        return (<span className="flex">{phoneNumber ?? '-'}</span>)
      }
    },
    {
      title: 'ตำแหน่ง',
      dataIndex: 'position',
      key: 'position',
    },
  ];

  return (
    <Modal
      title={null}
      visible={visible}
      style={{ borderRadius: 16 }}
      width={900}
      centered
      closable={false}
      footer={null}
    >
      <div
        className="w-full flex flex-col items-center overflow-y-auto px-4"
        css={css`max-height: 85vh;`}
      >
        <ExclamationCircleTwoTone
          className="mt-8"
          twoToneColor="#FAAD14"
          style={{ fontSize: 100 }}
        />
        <h1 className="text-2xl mt-6 text-center">
          คุณต้องการที่จะแก้ไขข้อมูล <br />
          ผู้มีอำนาจกระทำการแทนโรงเรียน ดังต่อไปนี้ หรือไม่ ?
        </h1>
        {deleteAdditionalRequestors.length ? (
          <div className="w-full flex flex-col justify-start mb-4">
            <div className="flex items-center">
              <DeleteIcon className="w-4 h-4 mr-2" />
              <div className="my-4 flex items-center text-base font-bold text-danger">
                ข้อมูลผู้มีอำนาจกระทำการแทนโรงเรียนที่ต้องการลบ
              </div>
            </div>
            <Table
              dataSource={deleteAdditionalRequestors}
              columns={columns}
              pagination={false}
              scroll={{ x: 750, y: 330 }}
            />
          </div>
        ) : (<></>)}
        {newAdditionalRequestors.length ? (
          <div className="w-full flex flex-col justify-start mb-4">
            <div className="flex items-center">
              <AddIcon
                className="w-4 h-4 mr-2"
                css={css`
                  path {
                    stroke: #48bb78 !important;
                  }
                `}
              />
              <div className="my-4 flex items-center text-base font-bold text-green-500">
                ข้อมูลผู้มีอำนาจกระทำการแทนโรงเรียนที่ต้องการเพิ่ม
              </div>
            </div>
            <Table
              dataSource={newAdditionalRequestors}
              columns={columns}
              pagination={false}
              scroll={{ x: 700, y: 330 }}
            />
          </div>
        ) : (<></>)}
        <Row className="mt-6 mb-8 relative">
          <Button
            className="mr-2 w-32"
            onClick={onClose}
            disabled={loading}
          >
            ยกเลิก
          </Button>
          <Button
            type="primary"
            className="ml-2 w-32"
            onClick={onConfirm}
            loading={loading}
            disabled={loading}
          >
            ยืนยัน
          </Button>
        </Row>
      </div>
    </Modal>
  );
};
