import _ from 'lodash';
import useAxios from 'axios-hooks';
type CategoriesResponse = {
  categoryId?: string;
  name?: {
    th?: string;
    en?: string;
  };
  subCategories?: any[];
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  deletedBy?: string;
  deletedAt?: string;
};

export const useCategories = () => {
  const [{ data, loading, error }, refetch] = useAxios(
    '/categories?pageNumber=1&pageSize=100'
  );

  const res = loading ? [] : (data?.data as CategoriesResponse[]) || [];
  const categoriesOption = _.flatten(
    (res as CategoriesResponse[]).map((s) => {
      return s.subCategories?.map((sc) => ({
        value: sc.subCategoryId as string,
        label: `${s.name?.th} → ${sc.name.th}` as string
      }));
    })
  );

  return {
    data: categoriesOption as { value: string; label: string }[],
    loading,
    error,
    execute: refetch
  };
};
