/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import { CertificateRender } from 'feature/course/certificate/CertificateRender';
import _ from 'lodash';

/* Note
    const factor = 1.35;
    const A4width = 842 * factor + 'px';
    const A4height = 595 * factor + 'px';
*/
type CertificateData = {
  courseNameTh: string;
  sessionNameTh: string;
  candidateNameTh: string;
  passedDateTh: string;
  sessionStartDateTh: string;
  sessionEndDateTh: string;
  courseNameEn: string;
  sessionNameEn: string;
  candidateNameEn: string;
  passedDateEn: string;
  sessionStartDateEn: string;
  sessionEndDateEn: string;
  lang: string;
  certificateImageUrl: string;
  certificateTemplate: string;
  companyName: string;
  signatures: Array<{
    signatureImageUrl: string;
    name: string;
    position: string;
    companyName: string;
  }>;
};

const getCertificateData = () => {
  try {
    return JSON.parse(localStorage.getItem('certificate_data') || '{}');
  } catch (error) {
    return {};
  }
};

const CertificateRenderPage = () => {
  const data: CertificateData = getCertificateData();
  const { certificateImageUrl, certificateTemplate, signatures } = data;

  let templateString = (certificateTemplate as string) || '';
  Object.keys(data).forEach((key) => {
    templateString = templateString.replace(
      '${' + key + '}',
      _.get(data, key, '')
    );
  });
  return (
    <div
      className="certificate"
      css={css`
        position: fixed;
        top: 0;
        left: 0;
        transform-origin: top left;
      `}
    >
      <CertificateRender
        imageUrl={certificateImageUrl}
        templateString={templateString}
        signatures={signatures}
      />
    </div>
  );
};

export default CertificateRenderPage;
