import useAxios from 'axios-hooks';

export const useUpdateCouponSchoolEmail = (schoolCouponId: string = '') => {
  const [_response, execute] = useAxios(
    {
      url: `/coupon-schools/${schoolCouponId}/emails`,
      method: 'PUT'
    },
    { manual: true }
  );

  return {
    execute
  };
};
