import { Col, Modal, Row, Select, Spin, Switch, message } from 'antd';
import { PrimaryButton, TextButton } from 'feature/common/Button';
import React, { useEffect, useState } from 'react';

import Axios from 'axios';
import { CourseLecturer } from 'feature/course/course.type';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Lecturer } from 'feature/lecturer/lecturer';
import _ from 'lodash';
import { useLecturers } from 'hooks/lecturer/useLecturers';

type Props = {
  visible: boolean;
  disableMainLecturer: boolean;
  courseLecturers: CourseLecturer[];
  onClose: () => void;
  onAdd: (lecturerId: any, isMainLecturer: boolean) => Promise<any>;
};

const fetchLecturers = _.debounce(
  async ({
    keyword = '',
    pageNumber = 1,
    isAppendLecturers,
    filteredLecturers,
    courseLecturers,
    setFilteredLecturers
  }) => {
    const {
      data: { data: lecturers }
    } = await Axios.get(`/lecturers`, {
      params: {
        keyword,
        pageNumber,
        pageSize: 20
      }
    });
    let newLecturers = lecturers;
    if (isAppendLecturers) {
      newLecturers = [...filteredLecturers, ...lecturers];
    }
    const courseLecturerIds = courseLecturers.map(
      (cl: CourseLecturer) => cl.lecturerId
    );
    const newFilteredLecturers = newLecturers.filter(
      (l: CourseLecturer) => !courseLecturerIds.includes(l.lecturerId as string)
    );
    setFilteredLecturers(newFilteredLecturers);
  },
  100,
  { leading: false, trailing: true }
);

const AddCourseLecturerModal = (props: Props) => {
  const {
    visible,
    onClose,
    onAdd,
    disableMainLecturer,
    courseLecturers = []
  } = props;

  const [creating, setCreating] = useState(false);
  const [selectedLecturerId, setSelectedLecturerId] = useState<string | null>(
    null
  );
  const [isMainLecturer, setIsMainLecturer] = useState(false);
  const [lecturerPageNumber, setLecturerPageNumber] = useState<number>(1);
  const [lecturerListOptions, setLecturerListOptions] = useState<any>([]);
  const [isLoadingLecturers, setIsLoadingLecturers] = useState<boolean>(true);
  const [filteredLecturers, setFilteredLecturers] = useState<Lecturer[]>([]);
  const [isAppendLecturers, setIsAppendLecturers] = useState(false);
  const [lecturerSearchText, setLecturerSearchText] = useState<string>('');

  const { data, loading } = useLecturers({
    pageNumber: 1,
    pageSize: 20
  });

  useEffect(() => {
    if (!loading) {
      const courseLecturerIds = courseLecturers.map((cl) => cl.lecturerId);
      const filteredLecturers = data.filter(
        (l) => !courseLecturerIds.includes(l.lecturerId as string)
      );
      setFilteredLecturers(filteredLecturers);
    }
  }, [courseLecturers, data, loading]);

  useEffect(() => {
    const lecturerListOptions = filteredLecturers?.map(
      (lecturer: Lecturer) => ({
        key: lecturer?.lecturerId,
        label: lecturer?.name?.th,
        value: lecturer?.lecturerId
      })
    ) as any;
    setLecturerListOptions(lecturerListOptions);
  }, [filteredLecturers]);

  useEffect(() => {
    setIsLoadingLecturers(false);
  }, [lecturerListOptions]);

  useEffect(() => {
    getLecturers({
      keyword: lecturerSearchText,
      pageNumber: lecturerPageNumber
    });
  }, [courseLecturers, lecturerPageNumber, lecturerSearchText]);

  const getLecturers = async ({
    keyword,
    pageNumber
  }: {
    keyword: string;
    pageNumber: number;
  }) => {
    setIsLoadingLecturers(true);
    try {
      await fetchLecturers({
        keyword,
        pageNumber,
        pageSize: 10,
        isAppendLecturers,
        filteredLecturers,
        setFilteredLecturers,
        courseLecturers
      });
    } catch (error) {
      setIsLoadingLecturers(false);
      console.error(error);
    }
  };

  const handleLoadMoreLecturers = async (event: any) => {
    const target = event.target;
    if (target.scrollTop + target.offsetHeight > 0.99 * target.scrollHeight) {
      target.scrollTo(0, target.scrollHeight);
      setIsAppendLecturers(true);
      setLecturerPageNumber(lecturerPageNumber + 1);
    }
  };

  const handleResetLoadMoreState = (value: string) => {
    setLecturerSearchText(value);
    setLecturerPageNumber(1);
    setIsAppendLecturers(false);
  };

  return (
    <Modal
      visible={visible}
      title="เพิ่มวิทยากร"
      footer={null}
      width={640}
      onCancel={onClose}
      afterClose={() => {
        handleResetLoadMoreState('');
        setSelectedLecturerId(null);
      }}
    >
      <div className="flex  flex-col w-full font-sarabun">
        <Row>
          <Col span={6}>
            <div className="w-32">ชื่อวิทยากร</div>
          </Col>
          <Col span={18}>
            <Select
              showSearch
              loading={loading}
              style={{ width: '100%' }}
              placeholder="ค้นหาวิทยากร"
              optionFilterProp="children"
              value={selectedLecturerId as any}
              onSearch={handleResetLoadMoreState}
              notFoundContent={(() => {
                if (isLoadingLecturers)
                  return (
                    <div className="flex justify-center items-center">
                      <Spin size="small" />
                    </div>
                  );
                return (
                  <div className="ant-select-item-empty">
                    <div className="ant-empty ant-empty-normal ant-empty-small">
                      <div className="ant-empty-description">
                        ไม่พบโรงเรียนที่ค้นหา
                      </div>
                    </div>
                  </div>
                );
              })()}
              filterOption={(input, option) => {
                return (option?.label as string)?.includes(input);
              }}
              onPopupScroll={handleLoadMoreLecturers}
              onSelect={(value) => {
                setSelectedLecturerId(value as string);
                handleResetLoadMoreState('');
              }}
              options={[...lecturerListOptions]}
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col span={6}>
            <div className="w-32">วิทยากรหลัก</div>
          </Col>
          <Col span={18}>
            <Switch
              checked={isMainLecturer}
              disabled={disableMainLecturer}
              onChange={setIsMainLecturer}
            />
            <span className="ml-2">เป็นวิทยากรหลัก</span>
          </Col>
        </Row>
        <Row className="mt-4 mb-32">
          <Col span={6}></Col>
          {disableMainLecturer && (
            <Col span={18} className="flex items-center">
              <ExclamationCircleOutlined className="mr-4" />
              วิทยากรหลักมีได้คนเดียว คอร์สนี้มีวิทยากรหลักแล้ว
            </Col>
          )}
        </Row>
      </div>

      <Row>
        <Col span={24}>
          <div className="flex justify-end items-center">
            <TextButton className="mr-6" onClick={onClose}>
              ยกเลิก
            </TextButton>
            <PrimaryButton
              loading={creating}
              htmlType="submit"
              onClick={async () => {
                setCreating(true);
                try {
                  await onAdd(selectedLecturerId, isMainLecturer);
                  setIsMainLecturer(false);
                  setSelectedLecturerId(null);
                } catch (error) {
                  message.error('สร้างวิทยากรในคอร์สไม่สำเร็จ');
                } finally {
                  setCreating(false);
                }
              }}
            >
              บันทึก
            </PrimaryButton>
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

export default AddCourseLecturerModal;
