import useAxios from 'axios-hooks';

export const useRejectSchoolRequest = () => {
  const [{ data: response, loading, error }, fire] = useAxios(
    {
      method: 'POST'
    },
    { manual: true }
  );

  const execute = (schoolRequestId: string, reason: string = '') => {
    return fire({
      url: `/school-requests/${schoolRequestId}/reject`,
      data: {
        rejectReason: reason
      }
    });
  };

  return {
    data: response?.data,
    loading,
    error,
    execute
  };
};
