import Axios from 'axios';

export const getSubjects = async () => {
  const { data: response } = await Axios.get(`/subjects`, {
    params: {
      pageNumber: 1,
      pageSize: 100
    }
  });
  return response;
};
