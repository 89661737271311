import useAxios from 'axios-hooks';

export const useSession = (sessionId: string) => {
  const [{ data, loading, error }, refetch] = useAxios(
    `/course-sessions/${sessionId}`
  );

  return {
    data: data?.data || null,
    loading: loading,
    error,
    execute: refetch
  };
};
