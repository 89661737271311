import { CourseSignatureListResponse } from '@aksorn/backoffice-sdk';
import useAxios from 'axios-hooks';

export const useCourseSignatures = (courseId: string) => {
  const [
    { data, loading, error },
    refetch
  ] = useAxios<CourseSignatureListResponse>(
    `/course-signatures?courseId=${courseId}&pageNumber=1&pageSize=100
    `
  );

  return {
    data: data?.data,
    loading: loading,
    error,
    execute: refetch
  };
};
