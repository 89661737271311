import { CouponReport } from './coupon-report.type';
import { DateFormat } from 'feature/common/global.type';
import { Link } from 'react-router-dom';
import React from 'react';
import { Table } from 'antd';
import moment from 'moment';

interface Props {
  couponReports: CouponReport[];
  loading?: boolean;
}
export const CouponReportTable = ({
  couponReports = [],
  loading = false
}: Props) => {
  const columns = [
    {
      title: 'รหัสคูปอง',
      key: 'couponCode',
      width: 100,
      render: (record: CouponReport) => (
        <Link
          to={`/coupon/report/detail?couponId=${record.couponId}`}
          className="text-blue font-medium"
        >
          {record.couponCode}
        </Link>
      )
    },
    {
      title: 'ชื่อคูปอง',
      dataIndex: 'name',
      key: 'name',
      render: (name: CouponReport['name']) => <div>{name.th}</div>
    },
    {
      title: 'จำนวนรอบอบรม',
      dataIndex: 'numberOfSessions',
      key: 'numberOfSessions',
      width: 150
    },
    {
      title: 'โควต้า',
      dataIndex: 'quotaLimit',
      key: 'quotaLimit',
      width: 80
    },
    {
      title: 'เหลือ',
      dataIndex: 'quotaLeft',
      key: 'quotaLeft',
      width: 80
    },
    {
      title: 'จำนวนรร.ทั้งหมด',
      dataIndex: 'numberOfSchools',
      key: 'numberOfSchools',
      width: 150
    },
    {
      title: 'จำนวนรร.ที่ใช้คูปอง',
      dataIndex: 'numberOfSchoolsUsedCoupon',
      key: 'numberOfSchoolsUsedCoupon',
      width: 150
    },
    {
      title: 'เริ่มใช้งาน',
      dataIndex: 'couponStartDate',
      key: 'couponStartDate',
      width: 100,
      render: (timestamp: string) =>
        timestamp && moment(timestamp).format(DateFormat.DEFAULT)
    },
    {
      title: 'หมดอายุ',
      dataIndex: 'couponExpiryDate',
      key: 'couponExpiryDate',
      width: 100,
      render: (timestamp: string) =>
        timestamp && moment(timestamp).format(DateFormat.DEFAULT)
    }
  ];
  return (
    <>
      <Table
        loading={loading}
        pagination={false}
        dataSource={couponReports}
        columns={columns as any}
        rowKey="couponId"
      />
    </>
  );
};
