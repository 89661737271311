import { Button, Divider, Drawer, Spin } from 'antd';

import Axios from 'axios';
import { CouponReportResendEmailCard } from 'feature/coupon-report/CouponReportResendEmailCard';
import { CourseReportAutomationSchoolEmail } from './coupon-report.type';
import React from 'react';
import { useCourseReportAutomationSchoolEmails } from 'hooks/course-report-automation/useCourseReportAutomationSchoolEmails';
import { useState } from 'react';

interface Props {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  couponId: string;
  couponCourseSession: {
    courseName: string;
    courseSessionId: string;
    courseSessionName: string;
    couponCourseSessionId: string;
  };
}

export const CouponReportResendEmailDrawer = (props: Props) => {
  const { visible, setVisible, couponCourseSession } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    data: courseReportSchoolEmails,
    loading,
    refetch
  } = useCourseReportAutomationSchoolEmails(
    couponCourseSession?.couponCourseSessionId
  );

  const resendAll = async (
    couponCourseSessionId: string,
    courseReportSchoolEmails: CourseReportAutomationSchoolEmail[]
  ) => {
    const resendEmails = courseReportSchoolEmails?.map(
      (courseReportSchoolEmail) =>
        courseReportSchoolEmail?.emails?.map((email) => ({
          couponCourseSessionId: couponCourseSessionId,
          couponSchoolEmailId: email?.couponSchoolEmailId
        }))
    );

    const resendEmailList = resendEmails?.flat();

    try {
      setIsLoading(true);

      await Promise.all(
        resendEmailList?.map((resendEmail) =>
          Axios.post('/course-report-automation/resend', resendEmail)
        )
      );

      refetch();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Drawer
      visible={visible}
      width={640}
      placement="right"
      onClose={() => {
        setVisible(false);
      }}
      title={<h3>เลิอกอีเมลที่จะส่งซ้ำ</h3>}
    >
      <div className="flex justify-between w-full items-center">
        <div>
          <div className="text-2xl font-extrabold">
            คอร์สที่ผู้ใช้ได้รับ: {couponCourseSession?.courseName}
          </div>
          <div className="text-lg font-bold text-mono-gray-100">
            รอบอบรม: {couponCourseSession?.courseSessionName}
          </div>
        </div>
        <Button
          className="w-24 rounded-lg"
          type="primary"
          onClick={() => {
            resendAll(
              couponCourseSession?.couponCourseSessionId,
              courseReportSchoolEmails
            );
          }}
        >
          Resend All
        </Button>
      </div>
      <Spin spinning={isLoading || loading}>
        {courseReportSchoolEmails?.map(
          (courseReportSchoolEmail: CourseReportAutomationSchoolEmail) => {
            return (
              <>
                <div className="text-xl mt-8">
                  โรงเรียน: {courseReportSchoolEmail?.school?.schoolName?.th}
                </div>
                {courseReportSchoolEmail?.emails?.length !== 0 ? (
                  courseReportSchoolEmail?.emails?.map((email, index) => (
                    <CouponReportResendEmailCard
                      couponCourseSessionId={
                        couponCourseSession?.couponCourseSessionId
                      }
                      index={index}
                      email={email}
                      refetch={refetch}
                    />
                  ))
                ) : (
                  <div className="text-gray-700 text-lg">ไม่มีอีเมล</div>
                )}
                <Divider />
              </>
            );
          }
        )}
      </Spin>
    </Drawer>
  );
};
