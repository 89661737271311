import {
  CourseLevel,
  CourseStatus,
  CourseVisibility,
  SessionCondition,
  SessionStatus,
  SessionType
} from './course.type';

export const GRADES = [
  'ป.1',
  'ป.2',
  'ป.3',
  'ป.4',
  'ป.5',
  'ป.6',
  'ม.1',
  'ม.2',
  'ม.3',
  'ม.4',
  'ม.5',
  'ม.6',
  'ปริญญาตรี',
  'ปริญญาโท',
  'ปริญญาเอก',
  'ปวช',
  'ปวส'
];

export const COURSE_LEVEL_TEXT = {
  [CourseLevel.STUDENT]: 'นักเรียน',
  [CourseLevel.TEACHER]: 'ครู'
};

export const COURSE_STATUS_TEXT = {
  [CourseStatus.ACTIVE]: 'Active',
  [CourseStatus.INACTIVE]: 'Inactive'
};
export const COURSE_VISIBILITY_TEXT = {
  [CourseVisibility.PUBLIC]: 'Public',
  [CourseVisibility.SPECIAL]: 'Special'
};

export const SESSION_TYPE_TEXT = {
  [SessionType.PUBLIC]: 'Public',
  [SessionType.SPECIAL]: 'Special'
};

export const courseSessionVideoPayload = {
  name: {
    th: 'อบรมฟรี',
    en: ''
  },

  registrationStartDate: new Date(),
  registrationEndDate: '3000-12-31T04:47:45.051Z',
  sessionStartDate: new Date(),
  sessionEndDate: '3000-12-31T04:47:45.051Z',
  visibility: SessionType.SPECIAL,
  status: SessionStatus.ACTIVE,
  learningType: SessionCondition.PARALLEL,

  videoViewingPassPercentage: 0,
  courseSessionSections: [],
  courseSessionLessons: [],
  courseSessionLecturers: [],

  participatedCertificate: {
    enabled: false,
    videoViewingPassPercentage: 0,
    quizScorePassPercentage: 0,
    signatures: [] as any
  },
  passedCertificate: {
    enabled: false,
    videoViewingPassPercentage: 0,
    quizScorePassPercentage: 0,
    signatures: [] as any
  },
  seatLimit: 9999999
};
