import { identity, pickBy } from 'lodash';

import { Lecturer } from 'feature/lecturer/lecturer';
import queryString from 'query-string';
import useAxios from 'axios-hooks';

type QueryParams = {
  pageNumber: number;
  pageSize: number;
  keyword: string;
};

export const useLecturers = (params: Partial<QueryParams>) => {
  const [{ data: response, loading, error }, refetch] = useAxios(
    `/lecturers?${queryString.stringify({
      pageNumber: 1,
      pageSize: 10,
      ...pickBy(params, identity)
    })}`
  );

  return {
    data: loading ? [] : (response?.data as Lecturer[]) || [],
    loading,
    error,
    execute: refetch,
    item: response && response?.meta?.pagination?.total
  };
};
