import { Button, Col, Form, Input, Modal, Row, Upload } from 'antd';
import {
  CourseSignature,
  CourseSignatureFormData
} from 'feature/course/signature/signature';
import { FormRule, getRule } from 'feature/common/Form/utils';
import { PrimaryButton, TextButton } from 'feature/common/Button';
import React, { useEffect } from 'react';
import { UploadStatus, useUploadImage } from 'hooks/useUploadImage';

import { CloudUploadOutlined } from '@ant-design/icons';
import { mapCourseSignatureDTOToFormData } from 'feature/course/signature/utils';

type Props = {
  visible: boolean;
  selectedCourseSignature: CourseSignature | null;
  onClose: () => void;
  onEdit: (data: CourseSignatureFormData, signatureFiles: any) => Promise<any>;
};

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
};

export const EditCourseSignatureModal = (props: Props) => {
  const { selectedCourseSignature, visible, onClose, onEdit } = props;
  const [form] = Form.useForm();

  const {
    fileList: signatureTHFileList,
    status: signatureTHStatus,
    beforeUpload: signatureTHBeforeUpload,
    onRemove: signatureTHRemove,
    setDefaultFile: setDefaultFileTH
  } = useUploadImage();
  const {
    fileList: signatureENFileList,
    status: signatureENStatus,
    beforeUpload: signatureENBeforeUpload,
    onRemove: signatureENRemove,
    setDefaultFile: setDefaultFileEN
  } = useUploadImage();

  useEffect(() => {
    if (form && selectedCourseSignature) {
      const signatureFormData = mapCourseSignatureDTOToFormData(
        selectedCourseSignature
      );
      if (signatureFormData.signatureImageUrlTH)
        setDefaultFileTH(signatureFormData.signatureImageUrlTH);
      if (signatureFormData.signatureImageUrlEN)
        setDefaultFileEN(signatureFormData.signatureImageUrlEN);
      form.setFieldsValue({
        ...signatureFormData,
        signatureImageUrlTH: signatureTHFileList,
        signatureImageUrlEN: signatureENFileList
      });
    }
  }, [selectedCourseSignature, form]);

  useEffect(() => {
    form.setFieldsValue({
      signatureImageUrlTH: { fileList: signatureTHFileList },
      signatureImageUrlEN: { fileList: signatureENFileList }
    });
  }, [signatureTHFileList]);

  const onFinish = (values: any) => {
    const signatureFiles = {
      signatureTHFileList,
      signatureENFileList
    };
    onEdit(values, signatureFiles);
  };

  const handleOnCancel = () => {
    onClose();
  };

  return (
    <Modal
      visible={visible}
      title="แก้ไขลายเซ็น"
      footer={null}
      onCancel={handleOnCancel}
      width={800}
    >
      <Form
        name="course-signature-create"
        {...layout}
        form={form}
        onFinish={onFinish}
      >
        <Form.Item
          label="ชื่อ - นามสกุล (TH)"
          name="nameTH"
          rules={[getRule(FormRule.REQUIRE)]}
        >
          <Input placeholder="ชื่อ - นามสกุลภาษาไทย" />
        </Form.Item>
        <Form.Item label="ชื่อ - นามสกุล (EN)" name="nameEN">
          <Input placeholder="ชื่อ - นามสกุลภาษาอังกฤษ" />
        </Form.Item>
        <Form.Item label="ตำแหน่ง(TH)" name="positionTH">
          <Input placeholder="ตำแหน่งภาษาไทย" />
        </Form.Item>
        <Form.Item label="ตำแหน่ง(EN)" name="positionEN">
          <Input placeholder="ตำแหน่งภาษาอังกฤษ" />
        </Form.Item>
        <Form.Item label="บริษัท(TH)" name="companyNameTH">
          <Input placeholder="บริษัทภาษาไทย" />
        </Form.Item>
        <Form.Item label="บริษัท(EN)" name="companyNameEN">
          <Input placeholder="บริษัทภาษาอังกฤษ" />
        </Form.Item>
        <Form.Item
          label="รูปลายเซ็น (TH)"
          name="signatureImageUrlTH"
          wrapperCol={{ span: 12 }}
          rules={[
            getRule(FormRule.REQUIRE, ' '),
            getRule(FormRule.UPLOAD_REQUIRE, 'กรุณาอัพโหลดรูปภาพ')
          ]}
        >
          <Upload
            onRemove={signatureTHRemove}
            beforeUpload={signatureTHBeforeUpload}
            fileList={signatureTHFileList}
            listType="picture"
          >
            {signatureTHStatus === UploadStatus.EMPTY && (
              <Button>
                <CloudUploadOutlined /> Upload
              </Button>
            )}
          </Upload>
        </Form.Item>
        <Form.Item
          label="รูปลายเซ็น (EN)"
          name="signatureImageUrlEN"
          wrapperCol={{ span: 12 }}
        >
          <Upload
            onRemove={signatureENRemove}
            beforeUpload={signatureENBeforeUpload}
            fileList={signatureENFileList}
            listType="picture"
          >
            {signatureENStatus === UploadStatus.EMPTY && (
              <Button>
                <CloudUploadOutlined /> Upload
              </Button>
            )}
          </Upload>
        </Form.Item>
        <Row className="mt-10">
          <Col span={24}>
            <div className="flex justify-end items-center">
              <TextButton className="mr-6" onClick={handleOnCancel}>
                ยกเลิก
              </TextButton>
              <PrimaryButton htmlType="submit">บันทึก</PrimaryButton>
            </div>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
