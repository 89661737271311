import { Col, Row, Tabs } from 'antd';

import { CourseDetailForm } from 'feature/course/detail/CourseDetailForm';
import { PageContainer } from 'feature/common/PageContainer';
import React from 'react';
import { RightOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

const { TabPane } = Tabs;

export const CourseCreatePage = () => {
  const history = useHistory();

  return (
    <PageContainer className="p-8">
      <Row justify="space-between" align="middle" className="mb-4">
        <Col>
          <div className="flex items-center">
            <h1
              className="text-xl font-bold text-mono-gray-300 cursor-pointer"
              onClick={() => history.push('/course/manage')}
            >
              จัดการคอร์ส
            </h1>
            <RightOutlined
              className="mx-2 text-mono-gray-300"
              style={{ fontSize: 12 }}
            />
            <h1 className="text-xl font-bold ">เพิ่มคอร์ส</h1>
          </div>
        </Col>
      </Row>
      <Tabs defaultActiveKey="1">
        <TabPane
          tab="ข้อมูลคอร์ส"
          key="1"
          style={{ height: 'calc(100vh - 180px)', overflow: 'auto' }}
        >
          <CourseDetailForm />
        </TabPane>
        <TabPane disabled tab="วิทยากร" key="2"></TabPane>
        <TabPane disabled tab="บทเรียน" key="3"></TabPane>
        <TabPane disabled tab="จัดการรอบ" key="4"></TabPane>
        <TabPane disabled tab="ประกาศนียบัตร" key="5"></TabPane>
        <TabPane disabled tab="ลายเซ็น" key="6"></TabPane>
      </Tabs>
    </PageContainer>
  );
};
