/** @jsx jsx */
import { Button, Input, Pagination, Select, Spin } from 'antd';
import { css, jsx } from '@emotion/react';
import { useEffect, useState } from 'react';

import { Province } from 'feature/coupon/province.type';
import { SchoolRequestStatus } from 'feature/school-request/school-request.type';
import SchoolRequestTable from './component/SchoolRequestTable';
import { ReactComponent as Search } from 'assets/search.svg';
import { schoolRequestState } from 'recoilModule/schoolRequest/atom';
import { useProvinces } from 'hooks/province/useProvinces';
import { useRecoilState } from 'recoil';
import { useSchoolRequests } from 'hooks/school-request/useSchoolRequests';

const { Option } = Select;

const statusOptions = [
  {
    label: 'ทั้งหมด',
    value: 'all'
  },
  {
    label: 'รอเอกสาร',
    value: SchoolRequestStatus.CREATED
  },
  {
    label: 'รออนุมัติ',
    value: SchoolRequestStatus.PENDING
  },
  {
    label: 'อนุมัติ',
    value: SchoolRequestStatus.ACCEPTED
  },
  {
    label: 'ไม่อนุมัติ',
    value: SchoolRequestStatus.REJECTED
  }
];

export const SchoolRequestList = () => {
  const [filter, setFilter] = useRecoilState(schoolRequestState);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { data: schoolRequests, totalItem } = useSchoolRequests(
    filter?.status,
    filter?.province,
    filter?.schoolId,
    filter?.schoolName,
    filter?.schoolRequestId,
    currentPage
  );
  const { data: provinces, loading: provinceLoading } = useProvinces();
  const [searchRequestId, setSearchRequestId] = useState('');
  const [searchedSchoolName, setSearchedSchoolName] = useState('');
  const data = schoolRequests;

  useEffect(() => {
    return () => {
      setFilter({
        status: SchoolRequestStatus.PENDING
      });
    };
  }, [setFilter]);
  return (
    <div className="pt-2">
      <div className="flex justify-between items-center mb-2">
        <div className="text-base font-bold text-mono-gray-500">
          รายการคำขออนุมัติ
        </div>
        <div className="flex justify-end items-center">
          <Select
            placeholder="สถานะ"
            css={css`
              width: 160px;
              margin-right: 10px;
            `}
            value={filter.status}
            onSelect={(value) => setFilter({ ...filter, status: value })}
          >
            {statusOptions.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
          <Select
            placeholder="จังหวัด"
            showSearch
            disabled={provinceLoading}
            css={css`
              width: 200px;
              margin-right: 10px;
            `}
            value={filter.province}
            onChange={(value) => setFilter({ ...filter, province: value })}
            filterOption={(input: string, option?: any) =>
              (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
            }
            allowClear
          >
            {provinces?.map((province: Province) => (
              <Option key={province?.provinceId} value={province?.provinceId}>
                {province?.provinceName?.th}
              </Option>
            ))}
          </Select>
          <Input
            placeholder="โรงเรียน"
            suffix={<Search />}
            onChange={(e: any) => {
              setSearchedSchoolName(e.target.value.trim());
            }}
            onPressEnter={() => {
              setFilter({ ...filter, schoolName: searchedSchoolName });
            }}
            css={css`
              width: 240px;
              margin-right: 10px;
            `}
          />
          <Input
            placeholder="หมายเลขคำขอ"
            suffix={<Search />}
            css={css`
              width: 240px;
            `}
            onChange={(e: any) => {
              setSearchRequestId(e.target.value.trim());
            }}
            onPressEnter={() => {
              setFilter({ ...filter, schoolRequestId: searchRequestId });
            }}
          />
          <Button
            type="primary"
            css={css`
              width: 50px;
              margin-left: 0.5rem;
            `}
            block
            onClick={() => {
              setFilter({
                ...filter,
                schoolRequestId: searchRequestId,
                schoolName: searchedSchoolName
              });
            }}
          >
            ค้นหา
          </Button>
        </div>
      </div>
      <Spin spinning={provinceLoading}>
        <SchoolRequestTable dataSource={data} pagination={false} />
        <Pagination
          total={totalItem}
          current={currentPage}
          onChange={(page) => {
            setCurrentPage(page);
          }}
          className="flex justify-end mt-4"
        />
      </Spin>
    </div>
  );
};
