import { EditorState, Modifier } from 'draft-js';

import React from 'react';

const ToolbarButton = ({
  onClick,
  text
}: {
  text: string;
  onClick: () => void;
}) => {
  return (
    <div
      onClick={onClick}
      className="ml-4 border border-black hover:border-gray-800 cursor-pointer text-black hover:text-gray-700 text-sm px-4 py-1"
    >
      <span className="whitespace-no-wrap">{text}</span>
    </div>
  );
};

export const CustomVariables = (props: any) => {
  const editText = (template: string) => {
    const { editorState, onChange } = props;
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      template,
      editorState.getCurrentInlineStyle()
    );
    onChange(EditorState.push(editorState, contentState, 'insert-characters'));
  };
  const addCourseNameTH = (): void => {
    editText('${courseNameTh}');
  };
  const addEditionTH = (): void => {
    editText('${sessionNameTh}');
  };
  const addCandidateNameTH = (): void => {
    editText('${candidateNameTh}');
  };
  const addPassedDateTH = (): void => {
    editText('${passedDateTh}');
  };
  const addSessionStartDateTH = (): void => {
    editText('${sessionStartDateTh}');
  };
  const addSessionEndDateTH = (): void => {
    editText('${sessionEndDateTh}');
  };
  const addCourseNameEN = (): void => {
    editText('${courseNameEn}');
  };
  const addEditionEN = (): void => {
    editText('${sessionNameEn}');
  };
  const addCandidateNameEN = (): void => {
    editText('${candidateNameEn}');
  };
  const addPassedDateEN = (): void => {
    editText('${passedDateEn}');
  };
  const addSessionStartDateEN = (): void => {
    editText('${sessionStartDateEn}');
  };
  const addSessionEndDateEN = (): void => {
    editText('${sessionEndDateEn}');
  };

  return (
    <div className="w-full my-4">
      <div className="">
        <div className="flex">
          <ToolbarButton onClick={addCourseNameTH} text="ชื่อคอร์ส (TH)" />
          <ToolbarButton onClick={addEditionTH} text="ชื่อรอบอบรม (TH)" />
          <ToolbarButton
            onClick={addCandidateNameTH}
            text="ชื่อผู้เรียน (TH)"
          />
          <ToolbarButton onClick={addPassedDateTH} text="วันที่เรียนจบ (TH)" />
          <ToolbarButton
            onClick={addSessionStartDateTH}
            text=" วันที่เริ่มต้นรอบอบรม (TH)"
          />
        </div>
        <div className="flex mt-4">
          <ToolbarButton
            onClick={addSessionEndDateTH}
            text="วันที่สิ้นสุดรอบอบรม (TH)"
          />
          <ToolbarButton onClick={addCourseNameEN} text="ชื่อคอร์ส (EN)" />
          <ToolbarButton onClick={addEditionEN} text="ชื่อรอบอบรม (EN)" />
          <ToolbarButton
            onClick={addCandidateNameEN}
            text="ชื่อผู้เรียน (EN)"
          />
        </div>
      </div>
      <div className="flex mt-4 ">
        <ToolbarButton onClick={addPassedDateEN} text="วันที่เรียนจบ (EN)" />
        <ToolbarButton
          onClick={addSessionStartDateEN}
          text="วันที่เริ่มต้นรอบอบรม (EN)"
        />
        <ToolbarButton
          onClick={addSessionEndDateEN}
          text="วันที่สิ้นสุดรอบอบรม (EN)"
        />
      </div>
    </div>
  );
};
