import { CouponSchoolEmail } from 'feature/coupon/coupon.type';
import useAxios from 'axios-hooks';

export const useCouponSchoolEmails = (schoolCouponId: string = '') => {
  const [{ data: response, loading, error }, refetch] = useAxios(
    `/coupon-schools/${schoolCouponId}/emails`
  );

  return {
    data: (response?.data as CouponSchoolEmail[]) || [],
    loading,
    error,
    refetch
  };
};
