import useAxios from 'axios-hooks';

export const useUpdateCourseSection = (courseSectionId: string) => {
  const [_response, execute] = useAxios(
    {
      url: `/course-sections/${courseSectionId}`,
      method: 'PUT'
    },
    { manual: true }
  );

  return {
    execute
  };
};
