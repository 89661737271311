import {
  UserCourseSessionCertificateGetResponse,
  UserCourseSessionCertificateUpdateBody
} from '@aksorn/backoffice-sdk';
import { identity, pickBy } from 'lodash';

import useAxios from 'axios-hooks';

export const useUpdateUserCourseSessionCertificate = () => {
  const [
    { data: response, loading },
    fire
  ] = useAxios<UserCourseSessionCertificateGetResponse>(
    { method: 'PUT' },
    { manual: true }
  );

  const execute = ({
    userCourseSessionId,
    data
  }: {
    userCourseSessionId: string;
    data: UserCourseSessionCertificateUpdateBody;
  }) => {
    return fire({
      url: `user-course-session/${userCourseSessionId}/certificate`,
      data: { ...pickBy(data, identity) }
    });
  };

  return {
    data: response?.data,
    loading,
    execute
  };
};
