import { Col, Row } from 'antd';

import React from 'react';

export const DetailListItem = (props: {
  label: string;
  value: string | number | undefined;
  small?: boolean;
  spanLeft?: number;
  spanRight?: number;
}) => {
  const { spanLeft = 8, spanRight = 12 } = props;

  return (
    <Col span={Boolean(props.small) ? 6 : 12}>
      <Row>
        <Col span={spanLeft}>{props.label}</Col>
        <Col span={spanRight} className="font-sarabun">
          {props.value ?? '-'}
        </Col>
      </Row>
    </Col>
  );
};
