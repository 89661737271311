import { Button, Col, Form, Input, Modal, Row, Upload } from 'antd';
import { FormRule, getRule } from 'feature/common/Form/utils';
import { PrimaryButton, TextButton } from 'feature/common/Button';
import { UploadStatus, useUploadImage } from 'hooks/useUploadImage';

import { CloudUploadOutlined } from '@ant-design/icons';
import { CourseSignature } from 'feature/course/signature/signature';
import React from 'react';
import { mapCourseSignatureFormDataToDTO } from 'feature/course/signature/utils';

type Props = {
  visible: boolean;
  onClose: () => void;
  onAdd: (
    data: CourseSignature,
    signatureFiles: any,
    resetFields: () => void
  ) => Promise<any>;
};

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
};

export const AddCourseSignatureModal = (props: Props) => {
  const { visible, onClose, onAdd } = props;

  const [form] = Form.useForm();
  const {
    fileList: signatureTHFileList,
    status: signatureTHStatus,
    beforeUpload: signatureTHBeforeUpload,
    onRemove: signatureTHRemove
  } = useUploadImage();
  const {
    fileList: signatureENFileList,
    status: signatureENStatus,
    beforeUpload: signatureENBeforeUpload,
    onRemove: signatureENRemove
  } = useUploadImage();

  const resetFields = () => {
    form.resetFields();
    signatureTHRemove();
    signatureENRemove();
  };

  const onFinish = (values: any) => {
    const data = mapCourseSignatureFormDataToDTO(values);
    const signatureFiles = {
      signatureTHFileList,
      signatureENFileList
    };
    onAdd(data, signatureFiles, resetFields);
  };

  const handleOnCancel = () => {
    resetFields();
    onClose();
  };

  return (
    <Modal
      visible={visible}
      title="เพิ่มลายเซ็น"
      footer={null}
      onCancel={handleOnCancel}
      width={800}
    >
      <Form
        name="course-signature-create"
        {...layout}
        form={form}
        onFinish={onFinish}
        onValuesChange={(c, ac) => {
          console.log(':p', ac);
        }}
      >
        <Form.Item
          label="ชื่อ - นามสกุล (TH)"
          name="nameTH"
          rules={[getRule(FormRule.REQUIRE)]}
        >
          <Input placeholder="ชื่อ - นามสกุลภาษาไทย" />
        </Form.Item>
        <Form.Item label="ชื่อ - นามสกุล (EN)" name="nameEN">
          <Input placeholder="ชื่อ - นามสกุลภาษาอังกฤษ" />
        </Form.Item>
        <Form.Item label="ตำแหน่ง(TH)" name="positionTH">
          <Input placeholder="ตำแหน่งภาษาไทย" />
        </Form.Item>
        <Form.Item label="ตำแหน่ง(EN)" name="positionEN">
          <Input placeholder="ตำแหน่งภาษาอังกฤษ" />
        </Form.Item>
        <Form.Item label="บริษัท(TH)" name="companyNameTH">
          <Input placeholder="บริษัทภาษาไทย" />
        </Form.Item>
        <Form.Item label="บริษัท(EN)" name="companyNameEN">
          <Input placeholder="บริษัทภาษาอังกฤษ" />
        </Form.Item>
        <Form.Item
          label="รูปลายเซ็น (TH)"
          name="signatureImageUrlTH"
          wrapperCol={{ span: 12 }}
          rules={[
            getRule(FormRule.REQUIRE, ' '),
            getRule(FormRule.UPLOAD_REQUIRE, 'กรุณาอัพโหลดรูปภาพ')
          ]}
        >
          <Upload
            onRemove={signatureTHRemove}
            beforeUpload={signatureTHBeforeUpload}
            fileList={signatureTHFileList}
            listType="picture"
          >
            {signatureTHStatus === UploadStatus.EMPTY && (
              <Button>
                <CloudUploadOutlined /> Upload
              </Button>
            )}
          </Upload>
        </Form.Item>
        <Form.Item
          label="รูปลายเซ็น (EN)"
          name="signatureImageUrlEN"
          wrapperCol={{ span: 12 }}
        >
          <Upload
            onRemove={() => {
              console.log('onremove called');
              signatureENRemove();
            }}
            beforeUpload={signatureENBeforeUpload}
            fileList={signatureENFileList}
            listType="picture"
          >
            {signatureENStatus === UploadStatus.EMPTY && (
              <Button>
                <CloudUploadOutlined /> Upload
              </Button>
            )}
          </Upload>
        </Form.Item>
        <Row className="mt-10">
          <Col span={24}>
            <div className="flex justify-end items-center">
              <TextButton className="mr-6" onClick={handleOnCancel}>
                ยกเลิก
              </TextButton>
              <PrimaryButton htmlType="submit">บันทึก</PrimaryButton>
            </div>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
