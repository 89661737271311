/** @jsx jsx */
import { Col, Row } from 'antd';

import { jsx } from '@emotion/react';

interface PerSonalDetailProps {
  firstName?: string;
  lastName?: string;
  email?: string;
  tel?: string;
  position?: string;
}

export const PersonalDetail: React.FC<PerSonalDetailProps> = ({
  firstName,
  lastName,
  email,
  tel,
  position
}) => (
  <Row className="mt-4 text-mono-gray-400">
    <Col span={24}>
      <Row gutter={[8, 8]}>
        <Col span={9} className="text-base font-medium">
          ชื่อ
        </Col>
        <Col span={15} className="font-sarabun">
          {firstName}
        </Col>
      </Row>
      <Row gutter={[8, 8]} className="mt-2">
        <Col span={9} className="text-base font-medium">
          สกุล
        </Col>
        <Col span={15} className="font-sarabun">
          {lastName}
        </Col>
      </Row>
      <Row gutter={[8, 8]} className="mt-2">
        <Col span={9} className="text-base font-medium">
          อีเมล
        </Col>
        <Col span={15} className="font-sarabun">
          {email}
        </Col>
      </Row>
      <Row gutter={[8, 8]} className="mt-2">
        <Col span={9} className="text-base font-medium">
          เบอร์โทร
        </Col>
        <Col span={15} className="font-sarabun">
          {tel}
        </Col>
      </Row>
      {position && (
        <Row gutter={[8, 8]} className="mt-2">
          <Col span={9} className="text-base font-medium">
            ตำแหน่ง
          </Col>
          <Col span={15} className="font-sarabun">
            {position}
          </Col>
        </Row>
      )}
    </Col>
  </Row>
);
