import { Col, Row, Tabs } from 'antd';
import { CourseLevel, TeacherAcademyType } from 'feature/course/course.type';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { CourseCertificateForm } from 'feature/course/certificate/CourseCertificateForm';
import { CourseDetailForm } from 'feature/course/detail/CourseDetailForm';
import { CourseLectureForm } from 'feature/course/lecture/CourseLectureForm';
import { CourseLecturerForm } from 'feature/course/lecturer/CourseLectureForm';
import { CourseSessionForm } from 'feature/course/CourseSessionForm';
import { CourseSignatureForm } from 'feature/course/signature/CourseSignatureForm';
import { CourseTabAlertModal } from 'feature/course/CourseTabAlertModal';
import { PageContainer } from 'feature/common/PageContainer';
import { RightOutlined } from '@ant-design/icons';
import queryString from 'query-string';
import { useCourse } from 'hooks/course/useCourse';

const { TabPane } = Tabs;

enum TabKey {
  DETAIL = '1',
  LECTURER = '2',
  SESSION = '3',
  LECTURE = '4',
  CERTIFICATE = '5',
  SIGNATURE = '6'
}

export const CourseEditPage = () => {
  const history = useHistory();
  const location = useLocation();
  const qs = queryString.parse(location.search) as {
    courseId: string;
    tab: string;
  };
  const detailRef = useRef();
  const [activeKey, setActiveKey] = useState('1');
  const [isFormChange, setIsFormChange] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);
  const [nextKey, setNextKey] = useState('1');
  const [courseLevel, setCourseLevel] = useState<CourseLevel | undefined>();
  const [teacherAcademyCourseType, setTeacherAcademyCourseType] = useState<
    string | undefined
  >();
  const { data: courseData, execute: fetchCourse } = useCourse(qs?.courseId);
  useEffect(() => {
    if (qs?.tab) {
      console.log(qs.tab);
      if (Object.values(TabKey).includes(qs.tab as TabKey)) {
        setActiveKey(qs?.tab as string);
      } else {
        setActiveKey(TabKey.DETAIL);
      }
    }
  }, []);

  useEffect(() => {
    fetchCourse();
  }, [qs?.courseId]);

  useEffect(() => {
    if (courseData) {
      setCourseLevel(courseData.courseLevel);
      setTeacherAcademyCourseType(courseData.teacherAcademyCourseType?.nameTh);
    }
  }, [courseData]);

  const disabledTab =
    courseLevel === CourseLevel.TEACHER &&
    teacherAcademyCourseType === TeacherAcademyType.VIDEO;

  return (
    <PageContainer className="p-8">
      <Row justify="space-between" align="middle" className="mb-4">
        <Col>
          <div className="flex items-center">
            <h1
              className="text-xl font-bold text-mono-gray-300 cursor-pointer"
              onClick={() => history.push('/course/manage')}
            >
              จัดการคอร์ส
            </h1>
            <RightOutlined
              className="mx-2 text-mono-gray-300"
              style={{ fontSize: 12 }}
            />
            <h1 className="text-xl font-bold ">แก้ไขคอร์ส</h1>
          </div>
        </Col>
      </Row>
      <Tabs
        activeKey={activeKey}
        onChange={(key) => {
          if (isFormChange) {
            setNextKey(key);
            setAlertVisible(true);
          } else {
            history.replace(
              '/course/manage/edit?tab=' +
                key +
                (qs.courseId ? '&courseId=' + qs.courseId : '')
            );
            setActiveKey(key);
            history.push(
              '/course/manage/edit?courseId=' + qs.courseId + '&tab=' + key
            );
          }
        }}
      >
        <TabPane
          tab="ข้อมูลคอร์ส"
          key={TabKey.DETAIL}
          style={{ height: 'calc(100vh - 180px)', overflow: 'auto' }}
        >
          <CourseDetailForm
            courseId={qs.courseId as string}
            ref={detailRef}
            setIsFormChange={setIsFormChange}
            refetch={fetchCourse}
          />
        </TabPane>
        <TabPane tab="วิทยากร" key={TabKey.LECTURER}>
          <CourseLecturerForm courseId={qs.courseId} />
        </TabPane>
        <TabPane tab="บทเรียน" key={TabKey.LECTURE}>
          <CourseLectureForm courseId={qs.courseId} />
        </TabPane>
        <TabPane tab="จัดการรอบ" key={TabKey.SESSION} disabled={disabledTab}>
          <CourseSessionForm courseId={qs.courseId as string} />
        </TabPane>
        <TabPane
          tab="ประกาศนียบัตร"
          key={TabKey.CERTIFICATE}
          disabled={disabledTab}
        >
          <CourseCertificateForm courseId={qs.courseId} />
        </TabPane>
        <TabPane tab="ลายเซ็น" key={TabKey.SIGNATURE} disabled={disabledTab}>
          <CourseSignatureForm courseId={qs.courseId as string} />
        </TabPane>
      </Tabs>
      <CourseTabAlertModal
        visible={alertVisible}
        onOK={async () => {
          let currentRef;
          if (activeKey === TabKey.DETAIL) {
            currentRef = detailRef.current;
          }
          try {
            if (currentRef) {
              await (currentRef as any).submitForm();
              setActiveKey(nextKey);
              history.push(
                '/course/manage/edit?courseId=' +
                  qs.courseId +
                  '&tab=' +
                  nextKey
              );
              setIsFormChange(false);
            }
          } catch (error) {
          } finally {
            setAlertVisible(false);
          }
        }}
        onCancel={() => setAlertVisible(false)}
      />
    </PageContainer>
  );
};
