import { Editor, EditorProps } from 'react-draft-wysiwyg';

import React from 'react';

export const WysiwygEditor = (props: EditorProps) => {
  return (
    <div>
      <span className="text-warning-shift text-red text-2xl">
        *ใช้ Shift + Enter เพื่อขึ้นบรรทัดใหม่*
      </span>
      <Editor
        {...props}
        toolbar={{
          options: [
            'inline',
            'blockType',
            'fontSize',
            'fontFamily',
            'list',
            'textAlign',
            'colorPicker',
            'history'
          ],
          fontFamily: {
            options: [
              'Arial',
              'Georgia',
              'Impact',
              'Tahoma',
              'Times New Roman',
              'Verdana',
              'Sarabun',
              'Noto Sans'
            ]
          }
        }}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
      />
    </div>
  );
};
