import {
  ProductPrivilegedType,
  SchoolRequestStatus
} from 'feature/school-request/school-request.type';

import { SchoolRequestApproveBody } from '@aksorn/backoffice-sdk';
import { atom } from 'recoil';

interface SchoolRequestFilter {
  status: SchoolRequestStatus;
  province?: string;
  schoolId?: string;
  schoolName?: string;
  schoolRequestId?: string;
  lastedUpdateId?: string;
}

export const schoolRequestState = atom({
  key: 'schoolRequestState',
  default: {
    province: undefined,
    schoolId: undefined,
    schoolName: undefined,
    schoolRequestId: undefined,
    lastedUpdateId: undefined,
    status: SchoolRequestStatus.PENDING
  } as SchoolRequestFilter
});

export const schoolEditOrdersState = atom({
  key: 'schoolEditOrdersState',
  default: {
    productPrivilegeType: ProductPrivilegedType.BASIC as any,
    orderItems: []
  } as SchoolRequestApproveBody
});
