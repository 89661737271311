import { Button, Modal, Row } from 'antd';

import { ExclamationCircleTwoTone } from '@ant-design/icons';
import React from 'react';

export const CourseTabAlertModal = (props: any) => {
  const { visible, onOK, onCancel } = props;
  return (
    <Modal
      title={null}
      visible={visible}
      style={{ borderRadius: 16 }}
      width={800}
      closable={false}
      footer={null}
    >
      <div className="w-full flex flex-col  items-center">
        <ExclamationCircleTwoTone
          className="mt-12"
          twoToneColor="#1890FF"
          style={{ fontSize: 100 }}
        />
        <h1 className="text-3xl mt-6">คุณยังไม่ได้บันทึกการแก้ไขล่าสุด</h1>
        <Row className="mt-8 mb-12 relative">
          <Button className="mr-2" onClick={onCancel}>
            ยกเลิก
          </Button>
          <Button type="primary" className="ml-2" onClick={onOK}>
            บันทึก
          </Button>
        </Row>
      </div>
    </Modal>
  );
};
