/** @jsx jsx */
import { Form, Input, Modal, Spin, message } from 'antd';
import { Fragment, useEffect, useState } from 'react';
import { Global, css, jsx } from '@emotion/react';
import { PrimaryButton, TextButton } from 'feature/common/Button';

import _ from 'lodash';
import { useCouponSchoolEmails } from 'hooks/couponSchool/useCouponSchoolEmails';
import { useUpdateCouponSchoolEmail } from 'hooks/couponSchool/useUpdateCouponSchoolEmail';

interface Props {
  schoolCouponId?: string;
  visible: boolean;
  setIsVisible: (visible: false) => void;
}

export const EditSchoolEmailModal = (props: Props) => {
  const { schoolCouponId, visible, setIsVisible } = props;
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm();

  const { execute: updateSchoolCouponEmail } = useUpdateCouponSchoolEmail(
    schoolCouponId
  );

  const { data: couponSchoolEmails, loading, refetch } = useCouponSchoolEmails(
    schoolCouponId
  );

  const onFinish = async (value: any) => {
    const emails = _.valuesIn(value)?.filter((email) => !_.isEmpty(email));

    const loading = message.loading('กำลังบันทึก...');
    try {
      loading();
      await updateSchoolCouponEmail({ data: { emails: emails } });
      message.success('บันทึกสำเร็จ', 2);
    } catch (error) {
      console.error(error);
      message.error('บันทึกไม่สำเร็จ', 2);
    } finally {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    if (visible) {
      refetch();
    }
  }, [visible]);

  useEffect(() => {
    const schoolEmails = couponSchoolEmails?.map(
      (couponSchool) => couponSchool?.email
    );
    form.setFieldsValue({
      schoolEmail1: schoolEmails[0] || null,
      schoolEmail2: schoolEmails[1] || null,
      schoolEmail3: schoolEmails[2] || null,
      schoolEmail4: schoolEmails[3] || null,
      schoolEmail5: schoolEmails[4] || null
    });
  }, [couponSchoolEmails]);

  return (
    <Modal
      visible={visible}
      afterClose={() => {
        form.resetFields();
      }}
      onCancel={() => {
        setIsVisible(false);
      }}
      bodyStyle={{ height: '350px' }}
      width="784px"
      title="แก้ไขอีเมล"
      footer={null}
    >
      <Form
        form={form}
        onFinish={onFinish}
        colon={false}
        labelCol={{ span: 6 }}
        onFinishFailed={() => {
          if (!validateTrigger.includes('onChange')) {
            setValidateTrigger([...validateTrigger, 'onChange']);
          }
        }}
      >
        <Global
          styles={css`
            .ant-modal-content {
              height: 100%;
            }
          `}
        />

        <div
          css={css`
            width: 555px;
          `}
        >
          {loading ? (
            <Spin spinning={loading} />
          ) : (
            <Fragment>
              <Form.Item
                name="schoolEmail1"
                label="อีเมลคุณครู 1"
                validateTrigger={validateTrigger}
                rules={[{ type: 'email', message: 'รูปแบบอีเมลไม่ถูกต้อง' }]}
              >
                <Input placeholder="อีเมล" autoComplete="newpassword" />
              </Form.Item>
              <Form.Item
                name="schoolEmail2"
                label="อีเมลคุณครู 2"
                validateTrigger={validateTrigger}
                rules={[{ type: 'email', message: 'รูปแบบอีเมลไม่ถูกต้อง' }]}
              >
                <Input placeholder="อีเมล" autoComplete="newpassword" />
              </Form.Item>
              <Form.Item
                name="schoolEmail3"
                label="อีเมลคุณครู 3"
                validateTrigger={validateTrigger}
                rules={[{ type: 'email', message: 'รูปแบบอีเมลไม่ถูกต้อง' }]}
              >
                <Input placeholder="อีเมล" autoComplete="newpassword" />
              </Form.Item>
              <Form.Item
                name="schoolEmail4"
                label="อีเมลคุณครู 4"
                validateTrigger={validateTrigger}
                rules={[{ type: 'email', message: 'รูปแบบอีเมลไม่ถูกต้อง' }]}
              >
                <Input placeholder="อีเมล" autoComplete="newpassword" />
              </Form.Item>
              <Form.Item
                name="schoolEmail5"
                label="อีเมลคุณครู 5"
                validateTrigger={validateTrigger}
                rules={[{ type: 'email', message: 'รูปแบบอีเมลไม่ถูกต้อง' }]}
              >
                <Input placeholder="อีเมล" autoComplete="newpassword" />
              </Form.Item>
            </Fragment>
          )}
        </div>
        <div
          className="flex justify-end items-center py-4 px-8"
          css={css`
            background-color: #eceff3;
            position: absolute;
            bottom: 0;
            left: 0;
            height: 80px;
            width: 100%;
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;
          `}
        >
          <TextButton
            onClick={() => {
              setIsVisible(false);
            }}
          >
            ยกเลิก
          </TextButton>
          <PrimaryButton>บันทึก</PrimaryButton>
        </div>
      </Form>
    </Modal>
  );
};
