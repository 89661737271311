import { Button, Form, Input } from 'antd';

import React from 'react';
import { authState } from 'recoilModule/auth/atom';
import { useHistory } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

const LoginPage = () => {
  const setAuthState = useSetRecoilState(authState);
  const history = useHistory();
  const onFinish = (value: any) => {
    if (
      value?.username === 'backoffice@admin.com' &&
      value?.password === 'admin1234'
    ) {
      setAuthState({
        isLogin: true,
        userId: value?.username
      });
      localStorage.setItem('userToken', value?.username);
      history.push('/course/manage');
    }
  };
  return (
    <div className="p-20">
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        layout="vertical"
      >
        <Form.Item
          label="Username"
          name="username"
          rules={[{ required: true, message: 'Please input your username!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default LoginPage;
