/** @jsx jsx */
import { Col, Row, message } from 'antd';
import { RequestUploadUrl, UploadStatus } from 'hooks/useUploadImage';

import Axios from 'axios';
import { BannerForm } from 'feature/banner/BannerForm';
import { ReactComponent as BreadcrumbsIcon } from 'assets/breadcrumbs.svg';
import { PageContainer } from 'feature/common/PageContainer';
import { jsx } from '@emotion/react';
import { useAddBanner } from 'hooks/banner/useAddBanner';
// import { useBanners } from 'hooks/banner/useBanners';
import { useEditBanner } from 'hooks/banner/useEditBanner';
import { useHistory } from 'react-router-dom';

export const AddBannerPage = () => {
  const history = useHistory();

  // const { refetch: getActivateBannerList } = useBanners({ isVisible: true });
  const { execute: addBanner } = useAddBanner();
  const { execute: editBanner } = useEditBanner();

  const handleUploadImage = async (bannerId: string, file: any) => {
    // console.log('file', file);
    if (!file) return null;
    if (file.status === UploadStatus.EMPTY) return null;
    try {
      const requestCoverImageResponse: {
        data: RequestUploadUrl;
      } = await Axios.post('/assets', {
        refOwnerType: 'banner-thumbnail',
        refOwnerId: bannerId,
        filename: file.name,
        contentType: file.type
      });
      // console.log('requestCoverImageResponse', requestCoverImageResponse);
      await Axios({
        url: requestCoverImageResponse.data.data.upload.url,
        params: requestCoverImageResponse.data.data.upload.params,
        method: 'PUT',
        data: file,
        headers: { 'content-type': file.type }
      });
      return requestCoverImageResponse.data.data.url;
    } catch (error) {
      console.log(error);
    }
  };

  const onFinish = async (data: any) => {
    // console.log('onFinish', data);
    const payload = {
      bannerName: data.bannerName,
      imgUrl: 'imgUrl',
      startDate: data.dateRange[0].toISOString(),
      endDate: data.dateRange[1].toISOString(),
      isVisible: true
    };
    try {
      /*
      if (data.isVisible) {
        const res = await getActivateBannerList();
        // console.log('res', res);
        const activateBannerList = res.data.data;
        // console.log('activateBannerList', activateBannerList);
        for (var activateBanner of activateBannerList) {
          // console.log('activateBanner', activateBanner);
          var newPayload = {
            bannerName: activateBanner.bannerName,
            imgUrl: activateBanner.imgUrl,
            startDate: activateBanner.startDate,
            endDate: activateBanner.endDate,
            isVisible: false
          };
          // console.log('newPayload', newPayload);
          await editBanner(newPayload, activateBanner.bannerId);
        }
      }
      */
      const res = await addBanner(payload);
      const bannerId = res.data.data.bannerId;
      let urlBannerImage = null;
      if (data.bannerImage) {
        urlBannerImage = await handleUploadImage(
          bannerId as string,
          data.bannerImage?.file
        );
      }
      if (urlBannerImage) {
        await editBanner({ ...payload, imgUrl: urlBannerImage }, bannerId);
      }
      message.success('เพิ่มแบนเนอร์สำเร็จ');
      history.replace('/banner');
    } catch (error) {
      message.error('เพิ่มแบนเนอร์ไม่สำเร็จ');
      console.log('error', error);
    }
  };

  return (
    <PageContainer className="p-8">
      <Row justify="space-between" align="middle" className="mb-8">
        <Col>
          <div className="flex items-center">
            <h1
              className="text-xl font-bold text-mono-gray-300 cursor-pointer"
              onClick={() => history.push('/banner')}
            >
              จัดการแบนเนอร์
            </h1>
            <BreadcrumbsIcon />
            <h1 className="text-xl font-bold">เพิ่มแบนเนอร์</h1>
          </div>
        </Col>
      </Row>
      <BannerForm
        onFinish={(data: any) => onFinish(data)}
        handleUploadImage={(courseId: string, file: any) =>
          handleUploadImage(courseId, file)
        }
        mode="add"
      />
    </PageContainer>
  );
};
