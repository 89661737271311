import {
  CourseSignature,
  CourseSignatureFormData
} from 'feature/course/signature/signature';

import _ from 'lodash';

export const mapCourseSignatureFormDataToDTO = (
  data: CourseSignatureFormData
): CourseSignature => {
  return {
    name: {
      th: data.nameTH,
      en: data.nameEN || ''
    },
    position: {
      th: data.positionTH || '',
      en: data.positionEN || ''
    },
    companyName: {
      th: data.companyNameTH || '',
      en: data.companyNameEN || ''
    },
    signatureImageUrl: {
      th: data.signatureImageUrlTH,
      en: data.signatureImageUrlEN || (null as any)
    }
  };
};

export const mapCourseSignatureDTOToFormData = (
  data: CourseSignature
): CourseSignatureFormData => {
  return {
    nameTH: data?.name?.th,
    nameEN: data?.name?.en,
    positionTH: data?.position?.th,
    positionEN: data?.position?.en,
    companyNameTH: data?.companyName?.th,
    companyNameEN: data?.companyName?.en,
    signatureImageUrlTH: data?.signatureImageUrl?.th,
    signatureImageUrlEN: data?.signatureImageUrl?.en
  };
};
