import { Button, Modal, Row } from 'antd';

import { ExclamationCircleTwoTone } from '@ant-design/icons';
import React from 'react';

type Props = {
  visible: boolean;
  onOk: () => void;
  onCancel: () => void;
  subTitle?: string;
};

export const AlertModal = (props: Props) => {
  const { visible, onOk, onCancel, subTitle = '' } = props;

  return (
    <Modal
      title={null}
      visible={visible}
      style={{ borderRadius: 16 }}
      width={800}
      closable={false}
      footer={null}
    >
      <div className="w-full flex flex-col  items-center">
        <ExclamationCircleTwoTone
          className="mt-12"
          twoToneColor="#FAAD14"
          style={{ fontSize: 100 }}
        />
        <h1 className="text-3xl mt-6 text-center">
          รายการนี้อาจมีการเชื่อมโยงกับข้อมูลอื่น <br />
          คุณแน่ใจหรือว่าต้องการลบ ?
        </h1>
        <h2 className="text-base mt-4 font-sarabun text-gray-700">
          {subTitle}
        </h2>
        <Row className="mt-8 mb-12 relative">
          <Button className="mr-2 w-32" onClick={onCancel}>
            ยกเลิก
          </Button>
          <Button
            type="primary"
            className="ml-2 border-red bg-red w-32"
            onClick={onOk}
          >
            ลบ
          </Button>
        </Row>
      </div>
    </Modal>
  );
};
