import useAxios from 'axios-hooks';

export const useCreateLecturer = () => {
  const [_response, execute] = useAxios(
    {
      url: '/lecturers',
      method: 'POST'
    },
    { manual: true }
  );

  return {
    execute
  };
};
