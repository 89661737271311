import { Col, Form, Row, Spin, Switch, Tabs, message } from 'antd';
import { ContentState, EditorState } from 'draft-js';
import { Course, CourseCertificate } from 'feature/course/course.type';
import { HTMLtoState, parseStateToHTML } from 'feature/common/Wysiwyg/utils';
import { PrimaryButton, TextButton } from 'feature/common/Button';
import React, { Fragment, useEffect, useRef, useState } from 'react';

import Axios from 'axios';
import { CertificateEditor } from './CertificateEditor';
import { RequestUploadUrl } from 'hooks/useUploadImage';
import htmlToDraft from 'html-to-draftjs';
import { useCourse } from 'hooks/course/useCourse';
import { useHistory } from 'react-router-dom';

const certHTML =
  '<p style="text-align: center"><span style="color: rgb(226, 80, 65); font-size: 24px; font-family: sarabun"><strong><br />ประกาศนียบัตรฉบับนี้ให้ไว้เพื่อแสดงว่า<br /><br /><br /></strong></span><span style="color: rgb(251, 160, 38); font-size: 18px; font-family: sarabun"><strong>${candidateNameTh}</strong></span><span style="color: rgb(226, 80, 65); font-size: 24px; font-family: sarabun"><strong><br /><br /><br /></strong></span><span style="color: rgb(0, 0, 0); font-size: 18px; font-family: sarabun">ได้เข้าร่วมโครงการอบรมเชิงปฏิบัติการ<br />หลักสูตร<strong>"การประยุกต์ใช้นวัตกรรมในการจัดกิจกรรมการเรียนรู้<br />เพื่อเสริมสร้างสรรถนะด้านการสื่อสารภาษาอังกฤษในชั้นเรียน"</strong>ระดับประถม<br /></span><span style="color: rgb(0, 0, 0); font-size: 14px; font-family: sarabun">วันที่ 1 มีนาคม พ.ศ. 2563<br />ณ Clerverse Co. Ltd.</span></p>';

type Props = {
  courseId: string;
};

const uploadImage = async (courseId: any = '', file: any) => {
  try {
    if (!file) return null;
    if (file.url && file.url.startsWith('http')) return file.url;
    const requestThumbnailUrlResponse: {
      data: RequestUploadUrl;
    } = await Axios.post('/assets', {
      refOwnerType: 'course-certificate',
      refOwnerId: courseId,
      filename: file.name,
      contentType: file.type
    });
    try {
      await Axios({
        method: 'PUT',
        url: requestThumbnailUrlResponse.data.data.upload.url,
        params: requestThumbnailUrlResponse.data.data.upload.params,
        data: file.file,
        headers: { 'content-type': file.type }
      });
    } catch (error) {}
    return requestThumbnailUrlResponse.data.data.url;
  } catch (error) {
    console.log(error);
  }
};

export const CourseCertificateForm = (props: Props) => {
  const { courseId = null } = props;
  const [form] = Form.useForm();
  const history = useHistory();
  const { data: course, loading } = useCourse(courseId);
  const contentBlock = htmlToDraft(certHTML);
  const contentState = ContentState.createFromBlockArray(
    contentBlock.contentBlocks
  );
  const [participateTHState, setNormalTHState] = useState(
    EditorState.createEmpty()
  );
  const [participateENState, setNormalENState] = useState(
    EditorState.createEmpty()
  );
  const [passTHState, setPassTHState] = useState(EditorState.createEmpty());
  const [passENState, setPassENState] = useState(EditorState.createEmpty());
  const [participateTHImg, setNormalTHImg] = useState(null);
  const [participateENImg, _setNormalENImg] = useState(null);
  const [passTHImg, setPassTHImg] = useState(null);
  const [passENImg, _setPassENImg] = useState(null);

  const [isParticipateError, setIsParticipateError] = useState(false);
  const [isPassError, setIsPassError] = useState(false);
  const errorRef = useRef(null);

  const showError = () => {
    if (errorRef) {
      const errorContent = errorRef?.current as any;
      errorContent.scrollIntoView();
    }
  };

  const handleError = (value: any) => {
    let isError = false;

    if (
      isParticipatedCert &&
      (!value.companyNameParticipateTH || !participateTHImg)
    ) {
      setIsParticipateError(true);
      isError = true;
    }
    if (isPassCert && (!value.companyNamePassTH || !passTHImg)) {
      setIsPassError(true);
      isError = true;
    }
    if (isError) {
      showError();
      throw new Error('Form is invalid');
    }
  };

  const onFinish = async (value: any) => {
    try {
      handleError(value);
    } catch (error) {
      return;
    }
    setIsParticipateError(false);
    setIsPassError(false);
    const mock: Partial<CourseCertificate> = {
      signatureOffset: {
        th: 0,
        en: 0
      },
      templateOffset: {
        th: 0,
        en: 0
      }
    };

    const updateParticipatedCert: CourseCertificate = {
      enabled: isParticipatedCert,
      template: {
        th: parseStateToHTML(participateTHState),
        en: parseStateToHTML(participateENState)
      },
      companyName: {
        th: value.companyNameParticipateTH || null,
        en: value.companyNameParticipateEN || null
      },
      imageUrl: {
        th: await uploadImage(courseId, participateTHImg),
        en: await uploadImage(courseId, participateENImg)
      },
      ...mock
    };
    const updatePassCert: CourseCertificate = {
      enabled: isPassCert,
      template: {
        th: parseStateToHTML(passTHState),
        en: parseStateToHTML(passENState)
      },
      companyName: {
        th: value.companyNamePassTH || null,
        en: value.companyNamePassEN || null
      },
      imageUrl: {
        th: await uploadImage(courseId, passTHImg),
        en: await uploadImage(courseId, passENImg)
      },
      ...mock
    };

    const updateCourse: Course = {
      passedCertificate: updatePassCert,
      participatedCertificate: updateParticipatedCert
    };

    const hide = message.loading('กำลังอัพเดต ประกาศนียบัตร');

    Axios.put('/courses/' + course?.courseId, updateCourse)
      .then(() => {
        message.success('อัพเดต ประกาศนียบัตร สำเร็จ');
      })
      .catch((err) => {
        const { code, message: errMessage } = err.response.data;
        message.error(`Error ${code}: ${errMessage}`);
      })
      .finally(() => {
        hide();
      });
  };
  const onFinishFailed = ({ values }: any) => {
    try {
      handleError(values);
    } catch (error) {}
  };
  const [isPassCert, setIsPassCert] = useState(false);
  const [isParticipatedCert, setIsParticipatedCert] = useState(false);
  const setExampleState = (setFunction: any) =>
    setFunction(EditorState.createWithContent(contentState));
  useEffect(() => {
    if (course) {
      setIsParticipatedCert(!!course.participatedCertificate.enabled);
      setIsPassCert(!!course.passedCertificate.enabled);
      if (!!course.participatedCertificate.enabled) {
        setNormalTHState(
          EditorState.createWithContent(
            HTMLtoState(course.participatedCertificate.template?.th)
          )
        );
        setNormalENState(
          EditorState.createWithContent(
            HTMLtoState(course.participatedCertificate.template?.en)
          )
        );
      }
      if (!!course.passedCertificate.enabled) {
        setPassTHState(
          EditorState.createWithContent(
            HTMLtoState(course.passedCertificate.template?.th)
          )
        );
        setPassENState(
          EditorState.createWithContent(
            HTMLtoState(course.passedCertificate.template?.en)
          )
        );
      }
      form.setFieldsValue({
        companyNameParticipateTH:
          course.participatedCertificate.companyName?.th,
        companyNameParticipateEN:
          course.participatedCertificate.companyName?.en,
        companyNamePassTH: course.passedCertificate.companyName?.th,
        companyNamePassEN: course.passedCertificate.companyName?.en,
        companyNamePassENImage: course.passedCertificate.imageUrl?.en,
        companyNamePassTHImage: course.passedCertificate.imageUrl?.th,
        companyNameParticipateENImage:
          course.participatedCertificate.imageUrl?.en,
        companyNameParticipateTHImage:
          course.participatedCertificate.imageUrl?.th
      });
    }
  }, [course, form]);
  return (
    <Spin spinning={loading}>
      <Form
        name="lecturer-detail"
        onValuesChange={(c, a) => {
          if (!!a?.companyNameParticipateTH && participateTHImg) {
            setIsParticipateError(false);
          }
          if (!!a?.companyNamePassTH && passTHImg) {
            setIsPassError(false);
          }
        }}
        onFinish={onFinish}
        form={form}
        onFinishFailed={onFinishFailed}
        className="custom-form font-sa"
      >
        <Row className="mb-4">
          <h1 className="text-base font-semibold">
            เลือกใบประกาศนียบัตรในคอร์สนี้
          </h1>
        </Row>
        <Row>
          <Col span={24}>
            <Row className="mb-4">
              <Col>
                <Switch
                  checked={isParticipatedCert}
                  onChange={(value) => setIsParticipatedCert(value)}
                />
                <span className="ml-4 font-sarabun">
                  ประกาศนียบัตรสำหรับผู้เข้าร่วมการอบรม
                </span>
              </Col>
            </Row>
            <Row>
              <Col>
                <Switch
                  checked={isPassCert}
                  onChange={(value) => setIsPassCert(value)}
                />
                <span className="ml-4 font-sarabun">
                  ประกาศนียบัตรสำหรับผู้ผ่านการอบรม{' '}
                </span>
              </Col>
            </Row>
          </Col>
        </Row>
        {(isPassCert || isParticipatedCert) && (
          <Fragment>
            <Row className="mt-8 mb-4">
              <Col span={24}>
                <h1 className="text-base font-semibold">จัดการประกาศนียบัตร</h1>
              </Col>
            </Row>
            <div ref={errorRef} className="w-1"></div>
            <Tabs type="card">
              {isParticipatedCert && (
                <Fragment>
                  <Tabs.TabPane
                    tab={
                      <p className={isParticipateError ? 'text-red' : ''}>
                        เข้าร่วมการอบรม (ภาษาไทย)*
                      </p>
                    }
                    key="1"
                  >
                    <CertificateEditor
                      defaultFileUrl={
                        course?.participatedCertificate.imageUrl?.th
                      }
                      setImage={setNormalTHImg}
                      editorState={participateTHState}
                      setEditorState={setNormalTHState}
                      setExampleState={() => setExampleState(setNormalTHState)}
                      name="companyNameParticipateTH"
                      label="ชื่อหน่วยงาน (TH)"
                    />
                  </Tabs.TabPane>
                  {/* <Tabs.TabPane tab="เข้าร่วมการอบรม (ภาษาอังกฤษ)" key="2">
                    <CertificateEditor
                      defaultFileUrl={
                        course?.participatedCertificate.imageUrl?.en
                      }
                      setImage={setNormalENImg}
                      editorState={participateENState}
                      setEditorState={setNormalENState}
                      setExampleState={() => setExampleState(setNormalENState)}
                      name="companyNameParticipateEN"
                      label="ชื่อหน่วยงาน (EN)"
                    />
                  </Tabs.TabPane> */}
                </Fragment>
              )}
              {isPassCert && (
                <Fragment>
                  <Tabs.TabPane
                    tab={
                      <p className={isPassError ? 'text-red' : ''}>
                        ผ่านการอบรม (ภาษาไทย)*
                      </p>
                    }
                    key="3"
                  >
                    <CertificateEditor
                      defaultFileUrl={course?.passedCertificate.imageUrl?.th}
                      setImage={setPassTHImg}
                      editorState={passTHState}
                      setEditorState={setPassTHState}
                      setExampleState={() => setExampleState(setPassTHState)}
                      name="companyNamePassTH"
                      label="ชื่อหน่วยงาน (TH)"
                    />
                  </Tabs.TabPane>
                  {/* <Tabs.TabPane tab="ผ่านการอบรม (ภาษาอังกฤษ)" key="4">
                    <CertificateEditor
                      defaultFileUrl={course?.passedCertificate.imageUrl?.en}
                      setImage={setPassENImg}
                      editorState={passENState}
                      setEditorState={setPassENState}
                      setExampleState={() => setExampleState(setPassENState)}
                      name="companyNamePassEN"
                      label="ชื่อหน่วยงาน (EN)"
                    />
                  </Tabs.TabPane> */}
                </Fragment>
              )}
            </Tabs>
          </Fragment>
        )}
        <Row className="mt-4">
          <Col span={24}>
            <div className="flex justify-end items-center">
              <TextButton
                className="mr-6"
                onClick={() => history.push('/course/manage')}
              >
                ยกเลิก
              </TextButton>
              <PrimaryButton htmlType="submit">บันทึก</PrimaryButton>
            </div>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};
