import { useEffect, useState } from 'react';

import _ from 'lodash';
import { getBase64 } from 'feature/common/Form/utils';
import { message } from 'antd';

export enum UploadStatus {
  EMPTY = 'empty',
  DONE = 'done',
  ERROR = 'error'
}

export enum RefOwnerType {
  COURSE_THUMBNAIL = 'course-thumbnail',
  LECTURER_CV = 'lecturer-cv',
  LECTURER_AVATAR = 'lecturer-avatar',
  COURSE_SIGNATURE = 'course-signature',
  LESSON_FILE = 'lesson-file'
}

export type RequestUploadUrl = {
  data: {
    url: string;
    upload: {
      url: string;
      params: {
        'X-Goog-Algorithm': string;
        'X-Goog-Credential': string;
        'X-Goog-Date': string;
        'X-Goog-Expires': string;
        'X-Goog-SignedHeaders': string;
        'X-Goog-Signature': string;
      };
    };
  };
};

export const useUploadImage = (allowFile = false) => {
  const [fileList, setFileList] = useState<any>([]);
  const [imageUrl, setImageUrl] = useState('');
  const [fileName, setFileName] = useState('');
  const [fileType, setFileType] = useState('');
  const [file, setFile] = useState(null);
  const [status, setStatus] = useState(UploadStatus.EMPTY);
  const setDefaultFile = (initFileUrl: string) => {
    let fileName: any = '';
    try {
      fileName = initFileUrl.split('/').pop();
    } catch (error) {
      fileName = 'preview.png';
    }
    setFileList([{ uid: initFileUrl, name: fileName, url: initFileUrl }]);
    setImageUrl(initFileUrl);
    setStatus(UploadStatus.DONE);
  };
  const onRemove = () => {
    setFileList([]);
    setFileName('');
    setImageUrl('');
    setStatus(UploadStatus.EMPTY);
  };
  const beforeUpload = (file: any) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (isJpgOrPng || allowFile) {
      setStatus(UploadStatus.DONE);
      setFileList([file]);
    } else {
      message.error('Not support this file');
      setFileList([
        {
          uid: '-1',
          name: file.name,
          status: 'error'
        }
      ]);
      setStatus(UploadStatus.ERROR);
    }
    setFileType(file.type);
    setFileName(file.name);
    setFile(file);

    return false;
  };

  useEffect(() => {
    const getBase64Img = async () => {
      if (!_.isEmpty(fileList)) {
        try {
          const res: any = await getBase64(fileList[0]);
          setImageUrl(res);
          if (!fileList[0].url) {
            setFileList(() => [
              { uid: fileName, name: fileName, url: res, type: fileType, file }
            ]);
          }
        } catch (error) {}
      }
    };
    getBase64Img();
  }, [fileList, status]);
  return {
    fileList,
    status,
    imageUrl,
    beforeUpload,
    onRemove,
    setDefaultFile
  };
};
