/** @jsx jsx */
import { Button, Table, message } from 'antd';
import {
  CourseSignature,
  CourseSignatureFormData
} from 'feature/course/signature/signature';
import { RefOwnerType, RequestUploadUrl } from 'hooks/useUploadImage';
import { css, jsx } from '@emotion/react';
import { useEffect, useState } from 'react';

import { AddCourseSignatureModal } from 'feature/course/signature/AddCourseSignatureModal';
import { AlertModal } from 'feature/common/AlertModal';
import Axios from 'axios';
import { ReactComponent as DeleteIcon } from 'assets/delete.svg';
import { EditCourseSignatureModal } from 'feature/course/signature/EditCourseSignatureModal';
import { ReactComponent as FormEditIcon } from 'assets/form-edit.svg';
import { PlusOutlined } from '@ant-design/icons';
import { mapCourseSignatureFormDataToDTO } from 'feature/course/signature/utils';
import { useCourseSignature } from 'hooks/courseSignature/useCourseSignature';
import { useHistory } from 'react-router-dom';

type Props = {
  courseId?: string;
};

export const CourseSignatureForm = (props: Props) => {
  const { courseId } = props;
  const [deletingItemId, setDeletingItemId] = useState<any>(null);
  const { data, execute: refetchCourseSignature } = useCourseSignature(
    courseId
  );
  const [addSignatureModalVisible, setAddSignatureModalVisible] = useState(
    false
  );
  const [editSignatureModalVisible, setEditSignatureModalVisible] = useState(
    false
  );
  const [
    selectedCourseSignature,
    setSelectedCourseSignature
  ] = useState<CourseSignature | null>(null);
  const history = useHistory();
  useEffect(() => {
    const refetch = async () => await refetchCourseSignature();
    refetch();
  }, [history, refetchCourseSignature]);

  const handleUploadImage = async (courseId: string, file: any) => {
    if (file && (file.url as string).startsWith('http')) return file.url;
    try {
      const requestThumbnailUrlResponse: {
        data: RequestUploadUrl;
      } = await Axios.post('/assets', {
        refOwnerType: RefOwnerType.COURSE_SIGNATURE,
        refOwnerId: courseId,
        filename: file.name,
        contentType: file.type
      });
      await Axios({
        url: requestThumbnailUrlResponse.data.data.upload.url,
        params: requestThumbnailUrlResponse.data.data.upload.params,
        method: 'PUT',
        data: file.file,
        headers: { 'content-type': file.type }
      });
      return requestThumbnailUrlResponse.data.data.url;
    } catch (error) {
      console.error(error);
    }
  };

  const handleOnAdd = async (
    data: CourseSignature,
    signatureFiles: any,
    resetField: () => void
  ) => {
    const hide = message.loading('กำลังสร้าง ลายเซ็นคอร์ส');
    try {
      const { signatureTHFileList, signatureENFileList } = signatureFiles;
      const signatureTHFile = signatureTHFileList[0];
      const signatureENFile = signatureENFileList[0];
      let signatureUrlTH = null;
      let signatureUrlEN = null;

      if (signatureTHFile) {
        signatureUrlTH = await handleUploadImage(
          courseId as string,
          signatureTHFile
        );
      }
      if (signatureENFile) {
        signatureUrlEN = await handleUploadImage(
          courseId as string,
          signatureENFile
        );
      }
      if (signatureUrlTH || signatureUrlEN) {
        await Axios.post('/course-signatures', {
          course: { courseId },
          ...data,
          signatureImageUrl: {
            en: signatureUrlEN,
            th: signatureUrlTH
          }
        });
        setAddSignatureModalVisible(false);
        await refetchCourseSignature();
        hide();
        message.success('สร้างลายเซ็นในคอร์สสำเร็จ');
        resetField();
      } else {
        hide();
        message.error(
          'สร้างลายเซ็นในคอร์สไม่สำเร็จกรุณาใส่ภาพลายเซ็นใหม่อีกครั้ง'
        );
      }
    } catch (e) {
      console.error(e);
      hide();
      message.error(
        'สร้างลายเซ็นในคอร์สไม่สำเร็จ ' +
          e.response.data.code +
          ': ' +
          e.response.data.message
      );
    }
  };
  const handleOnEdit = async (
    data: CourseSignatureFormData,
    signatureFiles: any
  ) => {
    const { signatureTHFileList, signatureENFileList } = signatureFiles;
    const signatureTHFile = signatureTHFileList[0];
    const signatureENFile = signatureENFileList[0];
    let signatureImageUrlTH;
    let signatureImageUrlEN;

    const hide = message.loading('กำลังอัพเดต ลายเซ็นคอร์ส');

    if (signatureTHFile) {
      signatureImageUrlTH = await handleUploadImage(
        courseId as string,
        signatureTHFile
      );
    }
    if (signatureENFile) {
      signatureImageUrlEN = await handleUploadImage(
        courseId as string,
        signatureENFile
      );
    }

    try {
      const courseSignatureDTO = mapCourseSignatureFormDataToDTO({
        ...data,
        signatureImageUrlTH,
        signatureImageUrlEN
      });
      await Axios.put(
        '/course-signatures/' + selectedCourseSignature?.courseSignatureId,
        courseSignatureDTO
      );
      setEditSignatureModalVisible(false);
      await refetchCourseSignature();
      hide();
      message.success('อัพเดตลายเซ็นในคอร์สสำเร็จ');
    } catch (e) {
      console.error(e);
      hide();
      message.error('อัพเดตเซ็นต์ในคอร์สไม่สำเร็จ');
    }
  };

  const handleDelete = async (courseSignatureId: string) => {
    const hide = message.loading('กำลังลบ ลายเซ็นคอร์ส');
    try {
      await Axios.delete('course-signatures/' + courseSignatureId);
      await refetchCourseSignature();
      hide();
      message.success('ลบลายเซ็นสำเร็จ');
    } catch (e) {
      console.error(e);
      hide();
      message.error('ลบลายเซ็นผิดผลาด');
    }
  };

  const columns = [
    {
      title: 'รายชื่อ',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      render: (idx: string, record: CourseSignature) => (
        <span>{record.name?.th}</span>
      )
    },
    {
      title: 'ตำแหน่ง',
      dataIndex: 'position',
      key: 'position',
      render: (idx: string, record: CourseSignature) => (
        <span>{record.position?.th}</span>
      )
    },
    {
      title: 'บริษัท',
      dataIndex: 'company',
      key: 'company',
      render: (idx: string, record: CourseSignature) => (
        <span>{record.companyName?.th}</span>
      )
    },
    {
      title: 'ลายเซ็น',
      dataIndex: 'signature',
      key: 'signature',
      render: (idx: string, record: CourseSignature) => (
        <div className="flex justify-start">
          {record.signatureImageUrl?.th && (
            <img
              alt="signature_th"
              className="h-6 px-2"
              src={record.signatureImageUrl?.th}
            />
          )}
          {record.signatureImageUrl?.en && (
            <img
              alt="signature_en"
              className="h-6 px-2"
              src={record.signatureImageUrl?.en}
            />
          )}
        </div>
      )
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      align: 'right',
      render: (idx: string, record: CourseSignature) => {
        return (
          <div className="flex justify-end">
            <div
              className="mr-4"
              onClick={() => {
                setSelectedCourseSignature(record);
                setEditSignatureModalVisible(true);
              }}
            >
              <FormEditIcon
                style={{ color: '#1890FF' }}
                className="cursor-pointer"
              />
            </div>

            <DeleteIcon
              style={{ color: '#F03D3E' }}
              className="cursor-pointer"
              onClick={() => setDeletingItemId(record.courseSignatureId)}
            />
          </div>
        );
      }
    }
  ];

  return (
    <div>
      <div className="flex justify-between font-bold text-base text-mono-gray-400 mb-6">
        ลายเซ็น
        <Button
          className="flex items-center"
          onClick={() => setAddSignatureModalVisible(true)}
        >
          <PlusOutlined
            className="relative w-4 h-4"
            css={css`
              margin-right: 0.5rem;
            `}
          />
          เพิ่มลายเซ็น
        </Button>
      </div>
      <Table dataSource={data} columns={columns as any} />
      <AddCourseSignatureModal
        visible={addSignatureModalVisible}
        onAdd={handleOnAdd}
        onClose={() => setAddSignatureModalVisible(false)}
      />
      <EditCourseSignatureModal
        selectedCourseSignature={selectedCourseSignature}
        visible={editSignatureModalVisible}
        onEdit={handleOnEdit}
        onClose={() => setEditSignatureModalVisible(false)}
      />
      <AlertModal
        subTitle="ลายเซ็น มีการเชื่อมกับรอบการเรียนและใบประกาศ"
        visible={!!deletingItemId}
        onOk={async () => {
          try {
            await handleDelete(deletingItemId);
            setDeletingItemId(null);
          } catch (error) {}
        }}
        onCancel={() => {
          setDeletingItemId(null);
        }}
      />
    </div>
  );
};
