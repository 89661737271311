/** @jsx jsx */
import { CertificateStatus, CertificateType } from './course-report.type';
import { Col, Form, Input, Modal, Radio, Row, message } from 'antd';
import {
  CourseSessionReportUserDto,
  UserCourseSessionCertificateGetStatusDto,
  UserCourseSessionCertificateUpdateBody
} from '@aksorn/backoffice-sdk';
import { FormRule, getRule } from 'feature/common/Form/utils';
import { Fragment, useEffect } from 'react';
import { PrimaryButton, TextButton } from 'feature/common/Button';
import { css, jsx } from '@emotion/react';

import { ENABLE_CERTIFICATE_TYPE_TEXT } from './const';
import { useGetUserCourseSessionCertificate } from 'hooks/user-course-session-certificate/useGetUserCourseSessionCertificate';
import { useUpdateUserCourseSessionCertificate } from 'hooks/user-course-session-certificate/useUpdateUserCourseSessionCertificate';

interface ModalProps {
  visible: boolean;
  user: CourseSessionReportUserDto;
  onClose: () => void;
  refetch: () => void;
}

interface CertificateSectionProps {
  name: string;
  title: string;
  certificate?: UserCourseSessionCertificateGetStatusDto;
}

const CertificateSection = ({
  name,
  title,
  certificate
}: CertificateSectionProps) => {
  return (
    <Fragment>
      <Row gutter={[0, 18]}>
        <div className="text-base font-bold my-3">{title}</div>
      </Row>
      <Row gutter={[0, 18]}>
        <Col span={6} className="self-center">
          อนุมัติใบประกาศ
        </Col>
        <Col span={12}>
          <Form.Item name={name + 'Status'}>
            <Radio.Group>
              <Radio.Button
                value={CertificateStatus.DEFAULT}
                disabled={certificate?.isQualify}
              >
                {ENABLE_CERTIFICATE_TYPE_TEXT[CertificateStatus.DEFAULT]}
              </Radio.Button>
              <Radio.Button value={CertificateStatus.QUALIFY}>
                {ENABLE_CERTIFICATE_TYPE_TEXT[CertificateStatus.QUALIFY]}
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Fragment>
        <Row gutter={[0, 18]}>
          <div className="text-mono-gray-300 font-bold my-3">
            แก้ไขชื่อในใบประกาศ
          </div>
        </Row>
        {certificate?.wasGenerated ? (
          <Fragment>
            <Row gutter={[0, 18]}>
              <Col span={6} className="self-center">
                ชื่อ - นามสกุล (TH)
              </Col>
              <Col span={12}>
                <Form.Item
                  name={name + 'NameTH'}
                  rules={[
                    getRule(FormRule.REQUIRE, 'กรุณากรอกชื่อ - นามสกุลภาษาไทย')
                  ]}
                >
                  <Input placeholder="ชื่อ - นามสกุล (TH)" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[0, 18]}>
              <Col span={6} className="self-center">
                ชื่อ - นามสกุล (EN)
              </Col>
              <Col span={12}>
                <Form.Item
                  name={name + 'NameEN'}
                  rules={[
                    getRule(
                      FormRule.REQUIRE,
                      'กรุณากรอกชื่อ - นามสกุลภาษาอังกฤษ'
                    )
                  ]}
                >
                  <Input placeholder="ชื่อ - นามสกุล (EN)" />
                </Form.Item>
              </Col>
            </Row>
          </Fragment>
        ) : (
          <div className="mb-6">User ยังไม่ได้ดาวน์โหลด{title}</div>
        )}
      </Fragment>
    </Fragment>
  );
};

export const CourseReportManageUserDetailModal = ({
  visible,
  user,
  onClose,
  refetch
}: ModalProps) => {
  const {
    data: userCourseSessionCertificate,
    loading,
    execute: getUserCourseSessionCertificate
  } = useGetUserCourseSessionCertificate();

  const {
    loading: updating,
    execute: updateUserCourseSessionCertificate
  } = useUpdateUserCourseSessionCertificate();

  const [form] = Form.useForm();

  useEffect(() => {
    if (user)
      getUserCourseSessionCertificate({
        userCourseSessionId: user.userCourseSessionId
      });
  }, [user]);

  useEffect(() => {
    if (visible && !loading && userCourseSessionCertificate) {
      const {
        participatedCertificate,
        passedCertificate
      } = userCourseSessionCertificate;
      form.setFieldsValue({
        participatedCertificateStatus: participatedCertificate.isQualify
          ? CertificateStatus.QUALIFY
          : CertificateStatus.DEFAULT,
        participatedCertificateNameTH: participatedCertificate.nameTh,
        participatedCertificateNameEN: participatedCertificate.nameEn,
        passedCertificateStatus: passedCertificate.isQualify
          ? CertificateStatus.QUALIFY
          : CertificateStatus.DEFAULT,
        passedCertificateNameTH: passedCertificate.nameTh,
        passedCertificateNameEN: passedCertificate.nameEn
      });
    }
  }, [visible, loading, userCourseSessionCertificate]);

  const handleSubmit = async (value: any) => {
    const loading = message.loading('กำลังบันทึก...');
    try {
      if (userCourseSessionCertificate?.participatedCertificate?.isEnabled) {
        const participatedCertificateData = {
          certificateType: CertificateType.PARTICIPATED_CERTIFICATE as any,
          status:
            value.participatedCertificateStatus === CertificateStatus.DEFAULT
              ? undefined
              : value.participatedCertificateStatus,
          nameTh: value.participatedCertificateNameTH,
          nameEn: value.participatedCertificateNameEN
        } as UserCourseSessionCertificateUpdateBody;

        await updateUserCourseSessionCertificate({
          userCourseSessionId: user.userCourseSessionId,
          data: participatedCertificateData
        });
      }
      if (userCourseSessionCertificate?.passedCertificate?.isEnabled) {
        const passedCertificateData = {
          certificateType: CertificateType.PASSED_CERTIFICATE as any,
          status:
            value.passedCertificateStatus === CertificateStatus.DEFAULT
              ? undefined
              : value.passedCertificateStatus,
          nameTh: value.passedCertificateNameTH,
          nameEn: value.passedCertificateNameEN
        } as UserCourseSessionCertificateUpdateBody;

        await updateUserCourseSessionCertificate({
          userCourseSessionId: user.userCourseSessionId,
          data: passedCertificateData
        });
      }
      loading();
      message.success('บันทึกสำเร็จ');
      refetch();
      onClose();
    } catch (error) {
      loading();
      message.error('บันทึกไม่สำเร็จ');
      console.error(error);
    }
  };

  return (
    <Modal
      visible={visible}
      footer={null}
      width={784}
      title="ข้อมูลผู้เรียน"
      afterClose={() => {
        form.resetFields();
        onClose();
      }}
      onCancel={onClose}
      css={css`
        .ant-form-item {
          margin-bottom: 0 !important;
        }
        .ant-modal-body {
          min-height: 700px;
        }
      `}
    >
      <Form form={form} colon={false} onFinish={handleSubmit} className="mb-24">
        <Row gutter={[0, 18]}>
          <Col span={6}>ชื่อ - นามสกุล</Col>
          <Col span={12}>{user?.userName?.th}</Col>
        </Row>
        <Row gutter={[0, 18]}>
          <Col span={6}>โรงเรียน</Col>
          <Col span={12}>{user?.schoolName?.th}</Col>
        </Row>
        <Row gutter={[0, 18]}>
          <Col span={6}>คูปองที่ใช้</Col>
          <Col span={12}>{user?.couponCode}</Col>
        </Row>
        {userCourseSessionCertificate?.participatedCertificate?.isEnabled && (
          <CertificateSection
            name="participatedCertificate"
            title="ใบประกาศเข้าร่วมการอบรม"
            certificate={userCourseSessionCertificate?.participatedCertificate}
          />
        )}
        {userCourseSessionCertificate?.passedCertificate?.isEnabled && (
          <CertificateSection
            name="passedCertificate"
            title="ใบประกาศผ่านการอบรม"
            certificate={userCourseSessionCertificate?.passedCertificate}
          />
        )}
        <div
          className="flex justify-end items-center py-4 px-8"
          css={css`
            background-color: #fcfcfd;
            position: absolute;
            bottom: 0;
            left: 0;
            height: 80px;
            width: 100%;
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;
          `}
        >
          <TextButton onClick={onClose}>ยกเลิก</TextButton>
          <PrimaryButton htmlType="submit" loading={updating}>
            บันทึก
          </PrimaryButton>
        </div>
      </Form>
    </Modal>
  );
};
