/** @jsx jsx */
import { Col, Row } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';

import { PageContainer } from 'feature/common/PageContainer';
import { RightOutlined } from '@ant-design/icons';
import { SchoolRequest } from 'feature/school-request/request-list/SchoolRequest';
import { jsx } from '@emotion/react';
import queryString from 'query-string';

export const SchoolManageRequestPage = () => {
  const history = useHistory();
  const location = useLocation();
  const qs = queryString.parse(location?.search);

  return (
    <PageContainer className="p-8 relative">
      <Row justify="space-between" align="middle" className="mb-4">
        <Col>
          <div className="flex items-center">
            <h1
              className="text-xl font-bold text-mono-gray-300 cursor-pointer"
              onClick={() => history.goBack()}
            >
              จัดการบัญชีโรงเรียน
            </h1>
            <RightOutlined className="mx-2 text-mono-gray-300 text-xs" />
            <h1 className="text-xl font-bold ">แก้ไขคำขอเปิดบัญชีโรงเรียน</h1>
          </div>
        </Col>
      </Row>
      <SchoolRequest schoolRequestId={qs?.schoolRequestId as string} />
    </PageContainer>
  );
};
