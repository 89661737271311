import {
  SchoolRequest,
  SchoolRequestStatus
} from 'feature/school-request/school-request.type';

import useAxios from 'axios-hooks';

export const useSchoolRequests = (
  status: SchoolRequestStatus = SchoolRequestStatus.PENDING,
  provinceId?: string,
  schoolId?: string,
  schoolName?: string,
  schoolRequestId?: string,
  page: number = 1
) => {
  const [{ data: response, loading, error }, refetch] = useAxios({
    url: `/school-requests`,
    params: {
      pageNumber: page,
      pageSize: 10,
      provinceId,
      schoolId,
      schoolName,
      schoolRequestId,
      status: status === SchoolRequestStatus.ALL ? undefined : status
    }
  });

  return {
    data: (response?.data as SchoolRequest[]) || [],
    loading,
    error,
    execute: refetch,
    totalItem: response?.meta?.pagination?.total
  };
};
