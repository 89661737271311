/** @jsx jsx */
import { Button, Collapse, Spin, Table, Typography, message } from 'antd';
import { CourseLessonType, CourseSection } from 'feature/course/course.type';
import { DownOutlined, PlusOutlined } from '@ant-design/icons';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Fragment, useEffect, useState } from 'react';
import { css, jsx } from '@emotion/react';

import { AlertModal } from 'feature/common/AlertModal';
import { ReactComponent as DeleteIcon } from 'assets/delete.svg';
import { ReactComponent as DragIcon } from 'assets/drag.svg';
import { ReactComponent as EditIcon } from 'assets/edit.svg';
import { ReactComponent as FlagIcon } from 'assets/flag.svg';
import { ReactComponent as FormEditIcon } from 'assets/form-edit.svg';
import { ManageLessonListModal } from './ManageLessonListModal';
import { ManageSectionModal } from 'feature/course/lecture/ManageSectionModal';
import { ReactComponent as PreviewIcon } from 'assets/preview.svg';
import _ from 'lodash';
import axios from 'axios';
import { useCourseSections } from 'hooks/course/useCourseSections';
import { useReorderCourses } from 'hooks/course/useReorderCourses';

type CourseLectureFormProps = {
  courseId: string;
};

const { Panel } = Collapse;

const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  // some basic styles to make the items look a bit nicer
  display: 'flex',
  alignItems: 'center',
  userSelect: 'none',
  background: 'transparent',

  // styles we need to apply on draggables
  ...draggableStyle
});

export const CourseLectureForm = ({ courseId }: CourseLectureFormProps) => {
  const { data, loading, execute: refetch } = useCourseSections(courseId);
  const { execute: reorderCourses } = useReorderCourses();
  const [deletingItemId, setDeletingItemId] = useState<any>(null);

  const [sections, setSections] = useState<CourseSection[]>(data || []);
  const [
    visibleManageSectionModal,
    setVisibleManageSectionModal
  ] = useState<boolean>(false);
  const [defaultSections, setDefaultSections] = useState<any>([]);
  const [
    visibleManageLessonListModal,
    setVisibleManageLessonListModal
  ] = useState<boolean>(false);
  const [isDraggable, setIsDraggable] = useState<boolean>(false);
  const [selectedSection, setSelectedSection] = useState<
    CourseSection | undefined
  >();
  const [activeKeys, setActiveKeys] = useState([]);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [updatedSectionId, setUpdatedSectionId] = useState<string>();

  useEffect(() => {
    if (!loading) {
      setSections(data);
      const section = _.find(data, {
        courseSectionId: selectedSection?.courseSectionId
      });
      setSelectedSection(section);
    }
  }, [data, loading, selectedSection]);

  const handleDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newItems = reorder(
      sections,
      result.source.index,
      result.destination.index
    );

    setSections(newItems);
  };

  const handleReorder = async () => {
    const loading = message.loading('กำลังดำเนินการ', 0);
    try {
      const data = sections.map((section: CourseSection, index: number) => ({
        courseSectionId: section?.courseSectionId,
        position: index
      }));

      await reorderCourses({ data });
      await refetch();
      loading();
      message.success('บันทึกสำเร็จ');
      setIsDraggable(!isDraggable);
    } catch (error) {
      loading();
      message.error('บันทึกไม่สำเร็จ');
      console.error(error);
    }
  };

  const handleDeleteCourseSection = async (courseSectionId: string) => {
    const loading = message.loading('กำลังดำเนินการ...', 0);
    try {
      await axios.delete(`/course-sections/${courseSectionId}`);
      await refetch();
      loading();
      message.success('ลบบทเรียนสำเร็จ');
    } catch (error) {
      loading();
      message.error('ลบบทเรียนไม่สำเร็จ');
      console.error(error);
    }
  };

  const renderToolbarButton = () => {
    if (isDraggable) {
      return (
        <Fragment>
          <Button
            className="px-4 mr-2"
            onClick={() => {
              setIsDraggable(!isDraggable);
              setSections(defaultSections);
            }}
          >
            ยกเลิก
          </Button>
          <Button type="primary" className="px-5" onClick={handleReorder}>
            เสร็จสิ้น
          </Button>
        </Fragment>
      );
    }
    return (
      <Fragment>
        <Button
          className="flex items-center mr-2"
          disabled={sections?.length === 0}
          css={css`
            svg {
              path {
                transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
              }
            }
            &:focus,
            &:hover {
              svg {
                path {
                  fill: #1890ff;
                }
              }
            }
          `}
          onClick={() => {
            setIsDraggable(!isDraggable);
            setDefaultSections(sections);
          }}
        >
          <EditIcon
            className="relative w-4 h-4"
            css={css`
              margin-right: 0.5rem;
              bottom: 1px;
            `}
          />
          จัดเรียง
        </Button>
        <Button
          className="flex items-center"
          onClick={() => {
            setVisibleManageSectionModal(true);
          }}
        >
          <PlusOutlined className="relative w-4 h-4" />
          เพิ่มบทเรียน
        </Button>
      </Fragment>
    );
  };

  const columns = [
    { title: 'เนื้อหา', dataIndex: ['name', 'th'], key: 'nameTH' },
    {
      title: 'ประเภท',
      dataIndex: 'lessonType',
      key: 'lessonType',
      width: 120,
      render: (lessonType: CourseLessonType) => {
        switch (lessonType) {
          case CourseLessonType.BYTEARK:
            return <Typography.Text>ByteArk</Typography.Text>;
          case CourseLessonType.YOUTUBE:
            return <Typography.Text>Youtube</Typography.Text>;
          case CourseLessonType.FILE:
            return <Typography.Text>ไฟล์</Typography.Text>;
          case CourseLessonType.QUIZ:
            return <Typography.Text>แบบฝึกหัด</Typography.Text>;
        }
      }
    },
    // {
    //   title: 'ขนาด',
    //   dataIndex: 'size',
    //   key: 'size',
    //   width: 130,
    //   render: (text: string) => (
    //     <Typography.Text className="flex w-full">{text || '-'}</Typography.Text>
    //   )
    // },
    {
      title: 'Preview',
      dataIndex: 'isPreview',
      key: 'isPreview',
      align: 'center' as any,
      width: 150,
      render: (isPreview: boolean) => (
        <Typography.Text className="flex justify-center w-full">
          {isPreview ? (
            <PreviewIcon
              css={css`
                color: #f2994a;
              `}
            />
          ) : (
            '-'
          )}
        </Typography.Text>
      )
    },
    {
      title: 'สำคัญ',
      dataIndex: 'isImportant',
      key: 'isImportant',
      align: 'center' as any,
      width: 150,
      render: (isImportant: string) => (
        <Typography.Text className="flex justify-center w-full">
          {isImportant ? (
            <FlagIcon
              css={css`
                color: #f2994a;
              `}
            />
          ) : (
            '-'
          )}
        </Typography.Text>
      )
    },
    // {
    //   title: 'สิทธิการเข้าถึง',
    //   dataIndex: 'canDownload',
    //   key: 'canDownload',
    //   align: 'center' as any,
    //   width: 120,
    //   render: (canDownload: string) => (
    //     <Typography.Text>{canDownload ? 'Download' : '-'}</Typography.Text>
    //   )
    // },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 130
    }
  ];

  return (
    <Spin spinning={loading}>
      <div className="my-3">
        <div className="flex justify-between font-bold text-base text-mono-gray-400 mb-6">
          บทเรียนทั้งหมด ({sections?.length})
          <div className="flex">{renderToolbarButton()}</div>
        </div>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable
            droppableId="droppable"
            css={css`
              width: 100%;
            `}
          >
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {sections.map((section, index) => (
                  <Draggable
                    key={section?.courseSectionId}
                    draggableId={section?.courseSectionId}
                    index={index}
                    isDragDisabled={!isDraggable}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                        css={css`
                          box-shadow: ${snapshot.isDragging
                            ? '0 10px 30px -5px rgba(0,0,0,.3)'
                            : ''};
                        `}
                      >
                        <Collapse
                          className="w-full border-none"
                          defaultActiveKey={
                            index === 0 ? section?.courseSectionId : undefined
                          }
                          activeKey={isDraggable ? [] : activeKeys}
                          onChange={(activeKeys: any) => {
                            setActiveKeys(activeKeys);
                          }}
                          expandIcon={({ isActive }) => {
                            if (isDraggable) return <DragIcon />;
                            return <DownOutlined rotate={isActive ? 180 : 0} />;
                          }}
                          css={css`
                            .ant-collapse-item
                              > .ant-collapse-header
                              .ant-collapse-arrow {
                              left: ${isDraggable ? '10px' : '16px'};
                              top: ${isDraggable ? '10px' : '12px'};
                            }
                            .ant-collapse-item-disabled > .ant-collapse-header {
                              cursor: move;
                            }
                            .ant-collapse-header {
                              font-weight: ${updatedSectionId ===
                              section?.courseSectionId
                                ? 'bold'
                                : 'normal'};
                            }
                          `}
                        >
                          <Panel
                            key={section?.courseSectionId}
                            header={section.name?.th}
                            disabled={isDraggable}
                            extra={
                              <div className="w-full flex justify-between pl-4">
                                <div className="text-mono-gray-400 text-sm">
                                  มีทั้งหมด{' '}
                                  {section?.courseLessons?.length || 0} เนื้อหา
                                </div>
                                {!isDraggable && (
                                  <div
                                    className="flex justify-center items-center"
                                    css={css`
                                      margin-right: 1.75rem;
                                    `}
                                  >
                                    <FormEditIcon
                                      className="mr-3 cursor-pointer"
                                      onClick={(event: any) => {
                                        setIsEdit(true);
                                        setSelectedSection(section);
                                        setVisibleManageSectionModal(true);
                                        event.stopPropagation();
                                      }}
                                      css={css`
                                        color: #1890ff;
                                        :hover {
                                          color: #1c64ff;
                                        }
                                      `}
                                    />

                                    <DeleteIcon
                                      className=""
                                      css={css`
                                        color: #f03d3e;
                                      `}
                                      onClick={() => {
                                        setDeletingItemId(
                                          section.courseSectionId
                                        );
                                      }}
                                    />
                                  </div>
                                )}
                              </div>
                            }
                            className="border-none relative"
                            css={css`
                              .ant-collapse-header {
                                display: flex;
                                white-space: nowrap;
                                color: #1890ff !important;
                                padding-top: 1.25rem !important;
                                padding-bottom: 1.25rem !important;
                              }
                              .ant-collapse-content-active {
                                border: none;
                              }
                              .ant-collapse-content-box {
                                position: relative;
                                padding: 0;
                              }
                              .ant-collapse-extra {
                                width: 100%;
                              }
                            `}
                          >
                            <Table
                              className="pb-4 text-mono-gray-400"
                              dataSource={section?.courseLessons}
                              columns={columns}
                              rowKey="key"
                              size="small"
                              css={css`
                                .ant-table-thead > tr > th {
                                  font-size: 0.875rem;
                                  font-weight: normal;
                                  color: #999999;
                                  background-color: #ffffff;
                                }
                                .ant-table-tbody .ant-table-cell {
                                  font-family: Sarabun;
                                  color: #495057;
                                }
                                .ant-table-tbody > tr > td {
                                  border: none;
                                }
                              `}
                            />
                            <Button
                              className="absolute flex items-center"
                              onClick={() => {
                                setSelectedSection(section);
                                setVisibleManageLessonListModal(true);
                              }}
                              css={css`
                                bottom: 15px;
                              `}
                            >
                              <PlusOutlined className="relative w-4 h-4" />
                              จัดการเนื้อหา
                            </Button>
                          </Panel>
                        </Collapse>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <ManageSectionModal
          isEdit={isEdit}
          section={selectedSection}
          courseId={courseId}
          visible={visibleManageSectionModal}
          setUpdatedSectionId={setUpdatedSectionId}
          refetch={refetch}
          onClose={() => {
            setIsEdit(false);
            setVisibleManageSectionModal(false);
          }}
        />
        <ManageLessonListModal
          courseSectionId={selectedSection?.courseSectionId || ''}
          visible={visibleManageLessonListModal}
          lessons={selectedSection?.courseLessons || []}
          setUpdatedSectionId={setUpdatedSectionId}
          refetch={refetch}
          onClose={() => {
            setVisibleManageLessonListModal(false);
          }}
        />
      </div>
      <AlertModal
        subTitle="บทเรียน มีการเชื่อมกับเนื้อหา, รอบการเรียนและประวัติการเรียน"
        visible={!!deletingItemId}
        onOk={async () => {
          try {
            await handleDeleteCourseSection(deletingItemId);
            setUpdatedSectionId('');
            setDeletingItemId(null);
          } catch (error) {}
        }}
        onCancel={() => {
          setDeletingItemId(null);
        }}
      />
    </Spin>
  );
};
