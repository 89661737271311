import {
  LANG,
  Localization,
  LocalizationType
} from 'feature/common/global.type';

import { Lecturer } from 'feature/lecturer/lecturer';

export enum CourseVisibility {
  PUBLIC = 'public',
  SPECIAL = 'special'
}
export enum CourseStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive'
}
export enum CourseLevel {
  TEACHER = 'teacher',
  STUDENT = 'student'
}

export type FK = {
  id?: string;
  name?: Localization;
};
export type SubjectGroupDto = {
  subjectGroupId?: string;
  name?: {
    th?: string;
    en?: string;
  };
  slug?: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  deletedBy?: string;
  deletedAt?: string;
};

type SubjectGroupId = {
  subjectGroupId: string;
};

export type CourseCreateDTO = {
  courseId?: string;
  name?: Localization;
  slug?: string;
  visibility?: CourseVisibility;
  status?: CourseStatus;
  courseLevel?: CourseLevel;
  description?: Localization;
  benefit?: Localization;
  fullPrice?: number;
  salePrice?: number;
  language?: LANG;
  previewVideoUrl?: string;
  thumbnailUrl?: LocalizationType<string | null>;
  gradeLevels?: any[];
  courseCollections?: any[];
  subjectGroups?: SubjectGroupId[];
  subCategories?: any[];
  rating?: number;
  isPopular?: boolean;
  isRecommend?: boolean;
  isNew?: boolean;
  isNewEndAt?: Date;
  createdAt?: string;
  createdBy?: string;
  updatedAt?: string;
  updatedBy?: string;
  duration?: number;
  teacherAcademyCourse?: TeacherAcademyCourseType | null;
};

export type CourseUpdateDTO = {
  name: Localization;
};

export type CourseCertificateDTO = {
  enabled?: boolean;
  companyNameTh?: string;
  companyNameEn?: string;
  imageUrlTh?: string;
  imageUrlEn?: string;
  templateTh?: string;
  templateEn?: string;
  templateOffsetTh?: number;
  templateOffsetEn?: number;
  signatureOffsetTh?: number;
  signatureOffsetEn?: number;
};
export type CourseCertificate = {
  enabled?: boolean;
  companyName?: Localization;
  imageUrl?: Localization;
  template?: Localization;
  templateOffset?: LocalizationType<number>;
  signatureOffset?: LocalizationType<number>;
};

export type Course = {
  courseId?: string;
  name?: Localization;
  slug?: string;
  visibility?: CourseVisibility;
  status?: CourseStatus;
  courseLevel?: CourseLevel;
  description?: Localization;
  benefit?: Localization;
  fullPrice?: number;
  salePrice?: number;
  language?: LANG;
  previewVideoUrl?: string;
  thumbnailUrl?: Localization;
  gradeLevels?: any[];
  courseCollections?: any[];
  subjectGroups?: SubjectGroupId[];
  subCategories?: any[];
  passedCertificate: CourseCertificate;
  participatedCertificate: CourseCertificate;
  rating?: number;
  isPopular?: boolean;
  isRecommend?: boolean;
  isNew?: boolean;
  isNewEndAt?: Date;
  createdAt?: string;
  createdBy?: string;
  updatedAt?: string;
  updatedBy?: string;
  duration?: number;
  teacherAcademyCourseType?: TeacherAcademyCourseType | null;
};

export type CourseDetailFormData = {
  nameTH?: string;
  nameEN?: string;
  slug?: string;
  visibility?: string;
  status?: string;
  courseLevel?: string;
  descriptionTH?: string;
  descriptionEN?: string;
  benefitTH?: string;
  benefitEN?: string;
  fullPrice?: number;
  salePrice?: number;
  language?: string;
  previewVideoUrl?: string;
  thumbnailUrlTH?: string;
  thumbnailUrlEN?: string;
  gradeLevels?: any[];
  courseCollections?: any[];
  subjectGroups?: SubjectGroupId[];
  subCategories?: any[];
  rating?: number;
  isPopular?: boolean;
  isRecommend?: boolean;
  isNew?: boolean;
  isNewEndAt?: Date;
  duration?: number;
  teacherAcademyCourseType?: string | null;
};

export type CourseSectionCreateDTO = {
  course: {
    courseId: string;
  };
  name: Localization;
};

export type CourseSection = {
  courseSectionId: string;
  name: Localization;
  position: number;
  courseLessons: CourseLesson[];
  createdBy: string;
  updatedBy: string;
  deletedBy: string;
};

export enum CourseLessonType {
  YOUTUBE = 'youtube',
  FILE = 'file',
  QUIZ = 'quiz',
  BYTEARK = 'byteark'
}

export enum QuizLessonType {
  PRETEST = 'preTest',
  POSTTEST = 'postTest'
}

export type CourseLessonCreateDto = {
  courseSection: {
    courseSectionId: string;
  };
  name: Localization;
  description: Localization;
  lessonType: CourseLessonType;
  isImportant: boolean;
  isPreview?: boolean;
  fileUrl?: string;
  fileDuration?: number;
  quizRefCode?: string;
  quizQuestionAmount?: number;
  quizMaxScore?: number;
  quizDuration?: number;
  quizLessonType?: QuizLessonType;
  videoUrl?: string;
  videoDuration?: number;
  previewVideoUrl?: string;
  previewVideoDuration?: number;
};

export type CourseLesson = {
  courseLessonId: string;
  name: Localization;
  position: number;
  description: Localization;
  lessonType: CourseLessonType;
  isImportant: boolean;
  isPreview: boolean;
  fileUrl: string;
  fileDuration: number;
  quizRefCode: string;
  quizMaxScore: number;
  quizDuration: number;
  quizLessonType: QuizLessonType;
  videoUrl: string;
  videoDuration: number;
  previewVideoUrl: string;
  previewVideoDuration: number;
  createdBy: string;
  createdAt: string;
  updatedBy: string;
  updatedAt: string;
  deletedBy: string;
  deletedAt: string;
};

export enum SessionSequential {
  Sequential = 'Sequential',
  Shuffle = 'Shuffle'
}
export enum SessionLevel {
  STUDENT = 'student',
  TEACHER = 'teacher'
}

export enum SessionType {
  PUBLIC = 'public',
  SPECIAL = 'special'
}
export enum SessionCondition {
  PARALLEL = 'parallel',
  SEQUENTIAL = 'sequential'
}
export enum SessionStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive'
}
export type Session = {
  registerStartDate?: Date | string;
  registerEndDate?: Date | string;
  sessionStartDate?: Date | string;
  sessionEndDate?: Date | string;
  name?: string;
  category?: CourseVisibility;
  sequential?: SessionSequential;
  showOnWeb?: boolean;
};

export type CourseLecturer = {
  courseLecturerId: string;
  order: number;
  courseId: string;
  lecturerId: string;
  isMainLecturer: boolean;
  lecturer: Lecturer;
  course: Course;
  createdBy: string;
  createdAt: Date;
  updatedBy: string;
  updatedAt: Date;
  deletedBy: string;
  deletedAt: Date;
};

export type TeacherAcademyCourseType = {
  teacherAcademyCourseTypeId: string;
  nameTh: string;
  nameEn: string | null;
};

export enum TeacherAcademyType {
  INTERACTIVE = 'interactive',
  VIDEO = 'video'
}
