import { Button, Card, Spin } from 'antd';
import React, { useState } from 'react';

import Axios from 'axios';
import { CourseReportEmailDTO } from 'feature/coupon-report/coupon-report.type';

interface Props {
  email: CourseReportEmailDTO;
  couponCourseSessionId: string;
  index: number;
  refetch: () => void;
}
export const CouponReportResendEmailCard = ({
  email,
  couponCourseSessionId,
  index,
  refetch
}: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const resendEmail = async (emailId: string) => {
    try {
      setIsLoading(true);
      await Axios.post('/course-report-automation/resend', {
        couponCourseSessionId: couponCourseSessionId,
        couponSchoolEmailId: emailId
      });
      refetch();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Spin spinning={isLoading} key={email?.couponSchoolEmailId}>
        <Card
          title={`อีเมลที่ ${index + 1}: ${email?.email}`}
          className="mt-4"
          bodyStyle={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}
          extra={
            <Button
              className="w-12 rounded-lg text-xs"
              type="primary"
              onClick={() => resendEmail(email?.couponSchoolEmailId)}
            >
              resend
            </Button>
          }
        >
          <div className="flex justify-start items-start">
            <div className="flex-none mr-3 text-xs font-bold">Link : </div>
            <a target="_blank" rel="noopener noreferrer" href={email?.link}>
              {email?.link || '-'}
            </a>
          </div>
          <div className="flex justify-start items-center">
            <div className="mr-3 font-bold text-xs ">Password :</div>
            <div>{email?.password || '-'}</div>
          </div>
        </Card>
      </Spin>
    </>
  );
};
