/** @jsx jsx */
import { Button, Col, Form, Input, Row, Upload } from 'antd';
import { FormRule, getRule } from 'feature/common/Form/utils';
import { Fragment, useEffect } from 'react';
import { css, jsx } from '@emotion/react';

import { CertificateRender } from './CertificateRender';
import { CloudUploadOutlined } from '@ant-design/icons';
import { CustomVariables } from 'feature/common/Wysiwyg/CustomVariables';
import { WysiwygEditor } from 'feature/common/Wysiwyg/Editor';
import mockSignatureUrl from 'assets/testSig-ink.png';
import { parseStateToHTML } from 'feature/common/Wysiwyg/utils';
import { useUploadImage } from 'hooks/useUploadImage';

const layout = {
  labelCol: { span: 12 },
  wrapperCol: { span: 12 }
};

const mockSignature = {
  signatureImageUrl: mockSignatureUrl,
  name: 'นายตัวอย่าง ตัวอย่างลายเซ็น',
  position: 'ผู้อํานวยการโรงเรียนตัวอย่างใบประกาศ',
  companyName: 'อักษรเจริญทัศน์'
} as {
  signatureImageUrl: string;
  name: string;
  position: string;
  companyName: string;
};

export const CertificateEditor = ({
  editorState,
  setEditorState,
  setExampleState,
  setImage,
  defaultFileUrl = '',
  name,
  label
}: any) => {
  const {
    beforeUpload,
    imageUrl,
    setDefaultFile,
    onRemove,
    fileList
  } = useUploadImage();
  useEffect(() => {
    if (fileList[0]) {
      setImage(fileList[0]);
    }
  }, [fileList]);
  useEffect(() => {
    if (defaultFileUrl) {
      setDefaultFile(defaultFileUrl);
    }
  }, [defaultFileUrl]);
  return (
    <Fragment>
      <Row>
        <div
          css={css`
            margin-left: 2rem;
            box-shadow: 5px 10px 25px rgba(59, 43, 91, 0.7);
          `}
        >
          {imageUrl && (
            <CertificateRender
              imageUrl={imageUrl}
              templateString={parseStateToHTML(editorState)}
              signatures={[mockSignature, mockSignature, mockSignature]}
            />
          )}
        </div>
      </Row>

      <Row className="mt-8">
        <Col span={12} className="pl-8">
          <div
            css={css`
              width: calc(2970px * 0.3);
              .ant-form-item {
                margin: 0 !important;
              }
            `}
          >
            <div className="w-full border ">
              <div className="h-16 border-b flex items-center p-4">
                ส่วนที่ 1 ข้อมูลใบประกาศ
              </div>
              <div className="h-16 flex items-center p-4">
                <Form.Item
                  name={name}
                  label={label}
                  rules={name.includes('TH') && [getRule(FormRule.REQUIRE)]}
                  {...layout}
                >
                  <Input />
                </Form.Item>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="mt-8">
        <Col span={12} className="pl-8">
          <div
            css={css`
              width: calc(2970px * 0.3);
            `}
          >
            <div className="flex mt-4 w-full">
              <div className="flex w-full">
                <Form.Item
                  className="w-full"
                  name={name + 'Image'}
                  label="อัปโหลดรูปภาพพื้นหลังใบประกาศ"
                  rules={
                    name.includes('TH') && [
                      getRule(FormRule.REQUIRE, 'กรุณาอัปโหลดรูป')
                    ]
                  }
                  labelCol={{ span: 6 }}
                  wrapperCol={{ span: 18 }}
                >
                  <Upload
                    onRemove={onRemove}
                    beforeUpload={beforeUpload}
                    showUploadList={false}
                  >
                    <Button>
                      <CloudUploadOutlined /> Upload
                    </Button>
                  </Upload>
                </Form.Item>
              </div>
              {imageUrl && (
                <Button onClick={() => setExampleState()} className="ml-4">
                  Example
                </Button>
              )}
            </div>
            {imageUrl && (
              <WysiwygEditor
                toolbarCustomButtons={[<CustomVariables />]}
                editorState={editorState}
                onEditorStateChange={setEditorState}
              />
            )}
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};
