import { identity, pickBy } from 'lodash';

import { CouponReportCourseSession } from 'feature/coupon-report/coupon-report.type';
import useAxios from 'axios-hooks';

type CouponReportCourseSessionRowItem = CouponReportCourseSession & {
  index: number;
};

type QueryCouponReportCourseSessions = {
  couponId: string;
  pageNumber?: number;
  pageSize?: number;
};

export const useCouponReportCourseSessions = (
  params: QueryCouponReportCourseSessions
) => {
  const { couponId = '' } = params;
  const [{ data, loading, error }, refetch] = useAxios({
    url: `/coupon-reports/${couponId}/course-sessions`,
    params: {
      pageNumber: 1,
      pageSize: 10,
      ...pickBy(params, identity)
    }
  });

  return {
    data: data?.data.map((d: CouponReportCourseSession, idx: number) => ({
      ...d,
      index: ((params.pageNumber || 1) - 1) * 10 + idx + 1
    })) as CouponReportCourseSessionRowItem[],
    loading: loading,
    error,
    refetch,
    item: data && data?.meta?.pagination?.total
  };
};
