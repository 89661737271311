import { SubjectGroupDto } from 'feature/course/course.type';
import useAxios from 'axios-hooks';

export const useSubjectGroups = () => {
  const [{ data, loading, error }, refetch] = useAxios(
    '/subject-groups?pageNumber=1&pageSize=100'
  );

  const res = loading ? [] : (data?.data as SubjectGroupDto[]) || [];
  const subjectGroupOption = (res as SubjectGroupDto[]).map((s) => ({
    value: s.subjectGroupId as string,
    label: `${s.name?.th}` as string
  }));
  return {
    data: subjectGroupOption,
    loading,
    error,
    execute: refetch
  };
};
