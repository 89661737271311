import {
  Localization,
  ProductGradeLevel,
  ProductListDto,
  ProductSubject
} from '@aksorn/backoffice-sdk';

import { ReactComponent as FormEditIcon } from 'assets/form-edit.svg';
import { ProductBookType } from './product';
import React from 'react';
import { Table } from 'antd';
import { useHistory } from 'react-router-dom';

interface ProductTableManageProps {
  loading: boolean;
  productsListData: any;
  setQueryBeforeFetch: Function;
  setPageSize: Function;
}

export const ProductTableManage = ({
  loading,
  productsListData,
  setQueryBeforeFetch,
  setPageSize
}: ProductTableManageProps) => {
  // console.log('ProductTableManage', productsListData);
  const history = useHistory();

  const handleTableChange = (value: any) => {
    // console.log('value', value);
    if (productsListData?.meta?.pagination?.pageSize !== value.pageSize) {
      setPageSize(value.pageSize);
    } else {
      setQueryBeforeFetch(value.current);
    }
  };

  const columns: any = [
    {
      title: 'รหัสสินค้า',
      dataIndex: 'productId',
      key: 'productId',
      children: [
        {
          title: 'Basic',
          dataIndex: 'basicSku',
          key: 'basicSku',
          align: 'center',
          width: 150,
          render: (text: string) => <span className="text-blue">{text}</span>
        },
        {
          title: 'Premium',
          dataIndex: 'premiumSku',
          key: 'premiumSku',
          align: 'center',
          width: 150,
          render: (text: string) => (
            <span className="text-blue">{text ? text : '-'}</span>
          )
        }
      ]
    },
    {
      title: 'ชื่อสินค้า',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      width: 135,
      render: (name: Localization) => name?.th
    },
    {
      title: 'สาระวิชา',
      dataIndex: 'subject',
      key: (subject: any) => subject.subjectId,
      align: 'center',
      width: 135,
      render: (subject: ProductSubject) => subject?.name?.th
    },
    {
      title: 'ระดับชั้น',
      dataIndex: 'gradeLevel',
      key: 'gradeLevel',
      align: 'center',
      width: 135,
      render: (gradeLevel: ProductGradeLevel) => gradeLevel?.name?.th
    },
    {
      title: 'ประเภท',
      dataIndex: 'bookType',
      key: 'bookType',
      align: 'center',
      width: 135,
      render: (bookType: ProductBookType) =>
        bookType === ProductBookType.EBOOK ? 'แบบเรียน' : 'แบบฝึกหัด'
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      width: 100,
      render: (text: string, record: ProductListDto) => {
        return (
          <div className="flex justify-center">
            <FormEditIcon
              className="mr-2 cursor-pointer"
              onClick={() => {
                history.push({
                  pathname: `/product/manage-list/edit/${record.productId}`,
                  state: {
                    queryString: document.location.search
                  }
                });
              }}
            />{' '}
          </div>
        );
      }
    }
  ];

  return (
    <Table
      bordered
      loading={loading}
      dataSource={productsListData?.products}
      columns={columns}
      pagination={productsListData?.meta?.pagination}
      onChange={(value) => handleTableChange(value)}
      rowKey="productId"
      scroll={{ x: 1000 }}
    />
  );
};
