import { Col, Row } from 'antd';

import { LecturerForm } from 'feature/lecturer/LecturerForm';
import { PageContainer } from 'feature/common/PageContainer';
import React from 'react';
import { RightOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

export const LecturerCreatePage = () => {
  const history = useHistory();
  return (
    <PageContainer className="p-8">
      <Row justify="space-between" align="middle" className="mb-8">
        <Col>
          <div className="flex items-center">
            <h1
              className="text-xl font-bold text-mono-gray-300 cursor-pointer"
              onClick={() => history.push('/lecturer')}
            >
              จัดการวิทยากร
            </h1>
            <RightOutlined
              className="mx-2 text-mono-gray-300"
              style={{ fontSize: 12 }}
            />
            <h1 className="text-xl font-bold ">เพิ่มวิทยากร</h1>
          </div>
        </Col>
      </Row>
      <LecturerForm />
    </PageContainer>
  );
};
