import { identity, pickBy } from 'lodash';

import { CourseSessionExportExcelReportQuery } from '@aksorn/backoffice-sdk';
import { downloadFile } from 'utils/downloadFile';
import useAxios from 'axios-hooks';

export const useCourseSessionReportExportExcel = () => {
  const [{ loading }, fire] = useAxios(
    {
      method: 'POST',
      responseType: 'blob'
    },
    { manual: true }
  );

  const execute = async ({
    courseSessionId,
    params
  }: {
    courseSessionId: string;
    params?: CourseSessionExportExcelReportQuery;
  }) => {
    return fire({
      url: `/course-session-reports/${courseSessionId}/export-excel`,
      params: {
        ...pickBy(params, identity)
      }
    }).then((response) => {
      const disposition = response.headers['content-disposition'];

      if (disposition && disposition.indexOf('attachment') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);
        let fileName = '';
        if (matches != null && matches[1]) {
          fileName = matches[1].replace(/['"]/g, '');
        }

        if (fileName) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          downloadFile({ url, fileName: decodeURIComponent(fileName) });
        }
      }
    });
  };

  return {
    loading: loading,
    execute
  };
};
