import { identity, pickBy } from 'lodash';

import { CouponCourseSession } from 'feature/coupon/coupon.type';
import useAxios from 'axios-hooks';

type QueryCouponCourseSessions = {
  couponId: string;
  pageNumber?: number;
  pageSize?: number;
};

export const useCouponCourseSessions = (params: QueryCouponCourseSessions) => {
  const { couponId } = params;
  const [{ data, loading, error }, refetch] = useAxios({
    url: couponId && `/coupon-course-sessions`,
    params: {
      pageNumber: 1,
      pageSize: 10,
      ...pickBy(params, identity)
    },
    method: 'GET'
  });

  return {
    data: data && (data?.data as CouponCourseSession[]),
    meta: data && data.meta,
    loading: loading,
    error,
    refetch
  };
};
