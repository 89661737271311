import { Button, Col, Empty, Form, Modal, Row, Select, Table } from 'antd';
import { Course, CourseLevel } from 'feature/course/course.type';
import { Global, css } from '@emotion/react';
import { PrimaryButton, TextButton } from 'feature/common/Button';
import React, { useEffect, useState } from 'react';

import Axios from 'axios';
import { CourseSession } from 'feature/course/session/CourseSessionType';
import { CourseVisibility } from 'feature/course/course.type';
import { Option } from 'antd/lib/mentions';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import queryString from 'query-string';
import { useCategories } from 'hooks/course/useCategories';

type AddCouponReceiveCourseModalProps = {
  visible: boolean;
  courseLevel: CourseLevel;
  onClose: () => void;
  onSubmit: (id: string) => Promise<any>;
  disabledCourseIds: string[];
};

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
};
interface CourseSessionExtended {
  courseName: string;
  courseId: string;
}
const formatName = (course: Course) => {
  let name = course?.name?.th;
  if (course?.name?.en) {
    name += '/' + course?.name?.en;
  }
  return name;
};

const columns = [
  {
    title: 'รายชื่อคอร์ส',
    dataIndex: 'courseName',
    key: 'courseName',
    width: 250
  },
  {
    title: 'รอบอบรม',
    dataIndex: 'name',
    key: 'name',
    render: (name: CourseSession['name']) => name?.th
  },
  {
    title: 'วันที่อบรม',
    dataIndex: 'sessionStartDate',
    key: 'sessionStartDate',
    render: (text: string, record: CourseSession & CourseSessionExtended) => {
      const sessionStartDate = moment(record?.sessionStartDate).format('D MMM');
      const sessionEndDate = moment(record?.sessionEndDate).format('D MMM YY');
      return <span>{`${sessionStartDate} - ${sessionEndDate}`}</span>;
    }
  },
  {
    title: 'ประเภทอบรม',
    dataIndex: 'visibility',
    key: 'visibility',
    render: (v: string) => v.charAt(0).toUpperCase() + v.slice(1)
  }
];
export const AddCouponReceiveCourseModal = ({
  visible,
  onClose,
  onSubmit,
  courseLevel,
  disabledCourseIds
}: AddCouponReceiveCourseModalProps) => {
  const [selectedCourse, setSelectedCourse] = useState<Course>();
  const [courses, setCourses] = useState<Course[]>([]);
  const [sessions, setSessions] = useState<
    (CourseSession & CourseSessionExtended)[]
  >();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const { data: subCategories } = useCategories();
  const [form] = Form.useForm();

  const reset = () => {
    setSessions(undefined);
    setCourses([]);
    setSelectedCourse(undefined);
    setSelectedRowKeys([]);
    form.resetFields();
  };

  useEffect(() => {
    if (visible) {
      const qs = queryString.stringify({
        courseLevel:
          courseLevel === CourseLevel.STUDENT ? courseLevel : undefined,
        pageSize: 100,
        pageNumber: 1
      });
      Axios.get(`/courses?${qs}`).then((response) => {
        setCourses(response.data.data);
      });
    }
  }, [visible, courseLevel]);

  const onValuesChange = (changedValue: any, allValues: any) => {
    const fieldName = Object.keys(changedValue)[0];

    if (['visibility', 'subCategory'].includes(fieldName)) {
      const qs = queryString.stringify({
        courseLevel:
          courseLevel === CourseLevel.STUDENT ? courseLevel : undefined,
        visibility: allValues['visibility'],
        subCategory: allValues['subCategory'],
        pageSize: 100,
        pageNumber: 1
      });
      Axios.get(`/courses?${qs}`).then((response) => {
        setCourses(response.data.data);
      });
    } else if (fieldName === 'courseId') {
      const courseId = allValues['courseId'];
      const course = courses.find((course) => course.courseId === courseId);
      setSelectedCourse(course);
    }
  };

  const searchSession = () => {
    if (!selectedCourse) return;
    const qs = queryString.stringify({
      courseId: selectedCourse.courseId,
      pageSize: 100,
      pageNumber: 1
    });
    Axios.get(`/course-sessions?${qs}`).then((response) => {
      const courseName = selectedCourse?.name?.th;
      setSessions(
        response.data.data.map((courseSession: CourseSession) => ({
          ...courseSession,
          courseName,
          courseId: selectedCourse.courseId
        }))
      );
    });
  };

  const onSelectChange = (selectedRowKeys: any) => {
    setSelectedRowKeys(selectedRowKeys);
  };
  const rowSelection = {
    type: 'radio' as 'radio',
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record: CourseSession & CourseSessionExtended) => ({
      disabled: disabledCourseIds.includes(record?.courseId)
    })
  };
  return (
    <Modal
      title="เพิ่มคอร์ส"
      width={980}
      visible={visible}
      onCancel={onClose}
      afterClose={reset}
      bodyStyle={{ padding: '0' }}
      footer={
        <Row>
          <Col span={24}>
            <div className="flex justify-end items-center">
              <TextButton
                className="mr-6"
                onClick={() => {
                  onClose();
                }}
              >
                ยกเลิก
              </TextButton>
              <PrimaryButton
                onClick={() => {
                  onSubmit(selectedRowKeys[0]);
                }}
              >
                บันทึก
              </PrimaryButton>
            </div>
          </Col>
        </Row>
      }
    >
      <Global
        styles={css`
          .ant-table-pagination.ant-pagination {
            margin-right: 1rem;
          }
        `}
      />
      <Form
        form={form}
        onFinish={searchSession}
        onValuesChange={onValuesChange}
      >
        <Row className="p-6">
          <Col flex={1} className="pr-2">
            <Form.Item name="visibility">
              <Select className="w-full" placeholder="ประเภทอบรม" allowClear>
                <Option
                  key={CourseVisibility.PUBLIC}
                  value={CourseVisibility.PUBLIC}
                >
                  Public
                </Option>
                <Option
                  key={CourseVisibility.SPECIAL}
                  value={CourseVisibility.SPECIAL}
                >
                  Special
                </Option>
              </Select>
            </Form.Item>
          </Col>
          <Col flex={4} className="pr-2">
            <Form.Item name="subCategory">
              <Select className="w-full" placeholder="หมวดหมู่" allowClear>
                {subCategories?.map((s) => (
                  <Option key={s?.value} value={s?.value}>
                    {s.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col flex={4} className="pr-2">
            <Form.Item name="courseId">
              <Select
                allowClear
                showSearch
                className="w-full"
                placeholder="ชื่อคอร์ส"
                optionFilterProp="children"
              >
                {courses?.map((course) => (
                  <Option
                    key={course?.courseId}
                    value={course?.courseId}
                    disabled={disabledCourseIds.includes(
                      course?.courseId as string
                    )}
                  >
                    {formatName(course)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col flex={1}>
            <Button
              type="primary"
              className="w-full"
              htmlType="submit"
              disabled={!selectedCourse}
            >
              ค้นหา
            </Button>
          </Col>
        </Row>
      </Form>
      {sessions ? (
        <>
          <Row className="p-4">
            <div className="text-base text-gray-900">
              จำนวน {sessions.length} รอบอบรม
            </div>
          </Row>
          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={sessions}
            rowKey="courseSessionId"
            {...layout}
          />
        </>
      ) : (
        <Empty
          className="my-16"
          css={css`
            padding: 2em;
            display: flex;
            flex-direction: column;
            align-items: center;
          `}
          imageStyle={{ display: 'none' }}
          description={
            <div className="flex flex-col">
              <SearchOutlined style={{ fontSize: '65px', color: '#495057' }} />
              <div className="text-xl text-black my-4 font-sans">
                กรุณาค้นหาคอร์ส
              </div>
              <div className="text-gray-900">
                กรุณาเลือกคอร์สที่ต้องการเพิ่มเข้าไปในคูปอง
              </div>
            </div>
          }
        ></Empty>
      )}
    </Modal>
  );
};
