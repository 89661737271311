/** @jsx jsx */
import {
  Alert,
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Spin,
  Switch,
  Table,
  Tabs,
  Upload,
  message
} from 'antd';
import Axios, { AxiosError } from 'axios';
import { CloudUploadOutlined, LoadingOutlined } from '@ant-design/icons';
import {
  CourseCreateDTO,
  CourseLevel,
  CourseStatus,
  CourseVisibility
} from 'feature/course/course.type';
import { FormRule, getRule } from 'feature/common/Form/utils';
import { HTMLtoState, parseStateToHTML } from 'feature/common/Wysiwyg/utils';
import { PrimaryButton, TextButton } from 'feature/common/Button';
import {
  RequestUploadUrl,
  UploadStatus,
  useUploadImage
} from 'hooks/useUploadImage';
import { css, jsx } from '@emotion/react';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react';
import { mapCourseToFormData, mapFormDataToCourse } from 'feature/course/utils';

import { COURSE_STATUS_TEXT } from '../const';
import { CourseSuccessModal } from 'feature/course/detail/CourseSuccessModal';
import { EditorState } from 'draft-js';
import { LANG } from 'feature/common/global.type';
import { WysiwygEditor } from 'feature/common/Wysiwyg/Editor';
import _ from 'lodash';
import moment from 'moment';
import { toEndOfDay } from 'utils/normalizeForm';
import { useCategories } from 'hooks/course/useCategories';
import { useCourse } from 'hooks/course/useCourse';
import { useCourseCollections } from 'hooks/course/useCourseCollections';
import { useCreateCourse } from 'hooks/course/useCreateCourse';
import { useGradeLVs } from 'hooks/course/useGradeLVs';
import { useHistory } from 'react-router-dom';
import { useSubjectGroups } from 'hooks/course/useSubjectGroups';
import { useTeacherAcademyCourseTypes } from 'hooks/course/useTeacherAcademyCourseTypes';

const { TabPane } = Tabs;
const { Option } = Select;

const columns = [
  {
    title: 'วิดีโอ',
    dataIndex: 'name'
  },
  {
    title: 'ความยาว',
    dataIndex: 'duration',
    render: (time: number) => <span>{time} นาที</span>
  }
];

// MOCK PREVIEW
const data = [
  {
    key: '1',
    name: 'สมบัติของจำนวนนับ',
    duration: 5,
    url: 'https://www.youtube.com/watch?v=mpMWpBIqVDk'
  },
  {
    key: '2',
    name: 'สมบัติของจำนวนเศษส่วน',
    duration: 5,
    url: 'https://www.youtube.com/watch?v=mpMWpBIqVDk'
  },
  {
    key: '3',
    name: 'สมบัติของจำนวนเต็ม',
    duration: 5,
    url: 'https://www.youtube.com/watch?v=mpMWpBIqVDk'
  }
];

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
};

type Props = {
  courseId?: string;
  setIsFormChange?: any;
  refetch?: any;
};

enum Mode {
  CREATE,
  EDIT
}

export const CourseDetailForm = forwardRef((props: Props, ref: any) => {
  const {
    courseId = null,
    setIsFormChange = () => {},
    refetch = () => {}
  } = props;

  useImperativeHandle(ref, () => ({
    async submitForm() {
      form.submit();
    }
  }));

  const [form] = Form.useForm();
  const [videoModalVisible, setVideoModalVisible] = useState(false);
  const mode: Mode = courseId ? Mode.EDIT : Mode.CREATE;
  const errorRef = useRef(null);
  const [editorDesTHState, setEditorDesTHState] = useState(
    EditorState.createEmpty()
  );
  const [editorDesENState, setEditorDesENState] = useState(
    EditorState.createEmpty()
  );
  const [editorBenefitTHState, setEditorBenefitTHState] = useState(
    EditorState.createEmpty()
  );
  const [editorBenefitENState, setEditorBenefitENState] = useState(
    EditorState.createEmpty()
  );
  const [redirectCourseId, setRedirectCourseId] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [selectedPreview, setSelectedPreview] = useState(null);
  const [isError, setIsError] = useState(false);
  const [isPopular, setIsPopular] = useState<boolean>(false);
  const [isRecommend, setIsRecommend] = useState<boolean>(false);
  const [isNew, setIsNew] = useState<boolean>(false);
  const [courseLevel, setCourseLevel] = useState<string | undefined>(
    CourseLevel.STUDENT
  );

  const {
    fileList: thumbnailTHFileList,
    status: thumbnailTHStatus,
    beforeUpload: thumbnailTHBeforeUpload,
    onRemove: thumbnailTHRemove,
    setDefaultFile: setDefaultFileTH
  } = useUploadImage();
  const {
    fileList: thumbnailENFileList,
    status: thumbnailENStatus,
    beforeUpload: thumbnailENBeforeUpload,
    onRemove: thumbnailENRemove,
    setDefaultFile: setDefaultFileEN
  } = useUploadImage();
  const history = useHistory();
  const {
    data: courseData,
    loading: courseLoading,
    execute: fetchCourse
  } = useCourse(courseId);
  useEffect(() => {
    const fetch = async () => await fetchCourse();
    fetch();
  }, [history]);
  const { execute: createCourse } = useCreateCourse();
  const {
    data: subjectGroupOptions,
    loading: subjectGroupsLoading
  } = useSubjectGroups();
  const { data: gradeLVOptions, loading: gradeLVsLoading } = useGradeLVs();
  const {
    data: categoriesOptions,
    loading: categoriesLoading
  } = useCategories();
  const {
    data: courseCollectionOptions,
    loading: courseCollectionsLoading
  } = useCourseCollections();
  const {
    data: teacherAcademyCourseTypeOptions,
    loading: teacherAcademyCourseTypeLoading
  } = useTeacherAcademyCourseTypes();

  const [creatingCourse, setCreatingCourse] = useState(false);
  const loading =
    courseLoading ||
    subjectGroupsLoading ||
    gradeLVsLoading ||
    categoriesLoading ||
    creatingCourse ||
    courseCollectionsLoading ||
    teacherAcademyCourseTypeLoading;
  const showError = () => {
    setIsError(true);
    if (errorRef) {
      const errorContent = errorRef?.current as any;
      errorContent.scrollIntoView();
    }
  };

  const onCreateCourse = async (data: CourseCreateDTO, extraData: any = {}) => {
    setCreatingCourse(true);
    const hide = message.loading('กำลังสร้าง course');

    try {
      const res = await createCourse({
        data
      });
      const courseId = res.data.data.courseId;
      let thumbnailUrlTH = null;
      let thumbnailUrlEN = null;
      if (extraData.thumbnailTH) {
        thumbnailUrlTH = await handleUploadImage(
          courseId as string,
          extraData.thumbnailTH
        );
      }
      if (extraData.thumbnailEN) {
        thumbnailUrlEN = await handleUploadImage(
          courseId as string,
          extraData.thumbnailEN
        );
      }
      if (thumbnailUrlTH || thumbnailUrlEN) {
        await updateCourse(courseId, {
          ...data,
          thumbnailUrl: {
            en: thumbnailUrlEN,
            th: thumbnailUrlTH
          }
        } as CourseCreateDTO);
      }

      hide();
      setIsSuccess(true);
      setRedirectCourseId(res.data.data.courseId);
      form.resetFields();
    } catch (error) {
      hide();
      if (Axios.isAxiosError(error)) {
        const { response } = error as AxiosError;
        if (response) {
          const { code, message: errMessage } = response.data;
          message.error(`Error ${code}: ${errMessage}`);
        }
      }
    } finally {
      setCreatingCourse(false);
    }
  };

  const updateCourse = async (id: any, data: any) => {
    return await Axios.put('/courses/' + id, {
      ...data
    });
  };
  const onUpdateCourse = async (id: any, data: any) => {
    setCreatingCourse(true);

    const hide = message.loading('กำลังอัพเดต course');
    await updateCourse(id, data)
      .then(() => {
        hide();
        message.success('อัพเดต course สำเร็จ');
        refetch();
      })
      .catch((err) => {
        hide();
        const { code, message: errMessage } = err.response.data;
        message.error(`Error ${code}: ${errMessage}`);
      })
      .finally(() => {
        setCreatingCourse(false);
      });
  };

  const handleUploadImage = async (courseId: string, file: any) => {
    if (!file) return null;
    if ((file.url as string).startsWith('http')) return file.url;
    if (file.status === UploadStatus.EMPTY) return null;
    try {
      const requestThumbnailUrlResponse: {
        data: RequestUploadUrl;
      } = await Axios.post('/assets', {
        refOwnerType: 'course-thumbnail',
        refOwnerId: courseId,
        filename: file.name,
        contentType: file.type
      });
      await Axios({
        url: requestThumbnailUrlResponse.data.data.upload.url,
        params: requestThumbnailUrlResponse.data.data.upload.params,
        method: 'PUT',
        data: file.file,
        headers: { 'content-type': file.type }
      });
      return requestThumbnailUrlResponse.data.data.url;
    } catch (error) {
      console.log(error);
    }
  };

  const onFinish = async (value: any) => {
    setIsError(false);
    const course = {
      ...value,
      descriptionTH: parseStateToHTML(editorDesTHState),
      descriptionEN: parseStateToHTML(editorDesENState),
      benefitTH: parseStateToHTML(editorBenefitTHState),
      benefitEN: parseStateToHTML(editorBenefitENState),
      teacherAcademyCourseType:
        courseLevel === CourseLevel.TEACHER
          ? { teacherAcademyCourseTypeId: value.teacherAcademyCourseType }
          : null
    };

    if (mode === Mode.CREATE) {
      const courseCreateDTO = mapFormDataToCourse({
        ...course,
        isNewEndAt: course.isNewEndAt ? course.isNewEndAt.toDate() : undefined
      });

      await onCreateCourse(courseCreateDTO, {
        thumbnailTH: thumbnailTHFileList[0],
        thumbnailEN: thumbnailENFileList[0]
      });
    } else {
      let thumbnailUrlTH = null;
      let thumbnailUrlEN = null;
      try {
        thumbnailUrlTH = await handleUploadImage(
          courseId as string,
          thumbnailTHFileList[0]
        );

        thumbnailUrlEN = await handleUploadImage(
          courseId as string,
          thumbnailENFileList[0]
        );
      } catch (error) {}

      let courseCreateDTO = mapFormDataToCourse({
        ...course,
        isNewEndAt: course.isNewEndAt ? course.isNewEndAt.toDate() : undefined,
        thumbnailUrlEN,
        thumbnailUrlTH
      });
      if (
        !courseCreateDTO?.thumbnailUrl?.th ||
        !courseCreateDTO?.thumbnailUrl?.en
      ) {
        courseCreateDTO = {
          ...courseCreateDTO,
          thumbnailUrl: {
            th: courseCreateDTO?.thumbnailUrl?.th || null,
            en: courseCreateDTO?.thumbnailUrl?.en || null
          }
        };
      }

      await onUpdateCourse(courseId, courseCreateDTO);
      await fetchCourse();
      setIsFormChange(false);
    }
  };
  const onFinishFailed = () => {
    showError();
  };

  useEffect(() => {
    if (form && courseData) {
      const formData = mapCourseToFormData(courseData);
      form.setFieldsValue({
        ...formData,
        isNewEndAt: formData.isNewEndAt
          ? moment(formData.isNewEndAt)
          : undefined
      });
      setIsPopular(!!formData.isPopular);
      setIsRecommend(!!formData.isRecommend);
      setIsNew(!!formData.isNew);
      setCourseLevel(formData.courseLevel);
      const desTH = EditorState.createWithContent(
        HTMLtoState(formData.descriptionTH)
      );
      const desEN = EditorState.createWithContent(
        HTMLtoState(formData.descriptionEN)
      );
      const benefitTH = EditorState.createWithContent(
        HTMLtoState(formData.benefitTH)
      );
      const benefitEH = EditorState.createWithContent(
        HTMLtoState(formData.benefitEN)
      );
      setEditorBenefitENState(benefitEH);
      setEditorBenefitTHState(benefitTH);
      setEditorDesENState(desEN);
      setEditorDesTHState(desTH);
      if (courseData.thumbnailUrl?.th)
        setDefaultFileTH(courseData.thumbnailUrl?.th as any);
      if (courseData.thumbnailUrl?.en)
        setDefaultFileEN(courseData.thumbnailUrl?.en as any);
    }
  }, [courseData]);

  return (
    <Spin
      spinning={loading}
      size="large"
      indicator={<LoadingOutlined style={{ fontSize: 50 }} spin />}
    >
      <Form
        name="course-detail"
        initialValues={{
          courseLevel: CourseLevel.STUDENT,
          language: LANG.TH,
          visibility: CourseVisibility.PUBLIC,
          status: CourseStatus.INACTIVE
        }}
        onValuesChange={() => {
          setIsFormChange(true);
        }}
        onFinish={onFinish}
        form={form}
        onFinishFailed={onFinishFailed}
        className=" custom-form font-sa"
        colon={false}
        {...layout}
      >
        <div ref={errorRef}>
          {isError && (
            <Alert
              className="mb-2"
              message="คุณยังกรอกข้อมูลไม่ครบ กรุณาตรวจสอบอีกครั้งค่ะ"
              type="error"
              showIcon
            />
          )}
        </div>
        <Row className="mb-4">
          <h1 className="text-base font-semibold">ข้อมูลคอร์ส</h1>
        </Row>
        {courseData && (
          <Row className="mb-4">
            <Col span={12} className="pr-8">
              <Row>
                <Col className="text-mono-gray-400" span={8}>
                  Id
                </Col>
                <Col span={16}>{courseData.courseId}</Col>
              </Row>
            </Col>
            <Col span={12} />
          </Row>
        )}
        <Row>
          <Col span={12} className="pr-8">
            <Form.Item
              label="ชื่อคอร์ส (TH)"
              name="nameTH"
              rules={[getRule(FormRule.REQUIRE)]}
            >
              <Input placeholder="ชื่อคอร์สภาษาไทย" />
            </Form.Item>
            <Form.Item label="ชื่อคอร์ส (EN)" name="nameEN">
              <Input placeholder="ชื่อคอร์สภาษาอังกฤษ" />
            </Form.Item>

            <Form.Item label="ระดับชั้น" name="gradeLevels">
              <Select placeholder="เลือกระดับชั้น" mode="multiple">
                {gradeLVOptions.map((g) => (
                  <Option key={g.value} value={g.value}>
                    {g.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="วิชา" name="subjectGroups">
              <Select placeholder="เลือกวิชา" mode="multiple">
                {subjectGroupOptions.map((s) => (
                  <Option key={s.value} value={s.value}>
                    {s.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="SubCategories" name="subCategories">
              <Select
                placeholder="เลือกหมวดหมู่ - หมวดหมู่ย่อย"
                mode="multiple"
              >
                {categoriesOptions?.map((s) => (
                  <Option key={s?.value} value={s?.value}>
                    {s.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="เรื่องที่สนใจ" name="courseCollections">
              <Select placeholder="เลือกเรื่องที่สนใจ" mode="multiple">
                {courseCollectionOptions?.map((s) => (
                  <Option key={s?.value} value={s?.value}>
                    {s.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Rating" name="rating">
              <Input
                type="number"
                placeholder="0.0 - 5.0"
                min="0"
                max="5"
                step="0.1"
                addonAfter="คะแนน"
                onChange={(event: any) => {
                  const value = event.target.value;
                  if (value >= 5.0) form.setFieldsValue({ rating: 5.0 });
                  else form.setFieldsValue({ rating: +value });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="คลิปตัวอย่างคอร์ส" name="previewVideoUrl">
              <Input placeholder="กรอก Url Intro Video" />
            </Form.Item>
            {/* <Row className="mb-8">
              <Col span={6}></Col>
              <Col span={12}>
                <Button
                  className="w-full  max-w-xs"
                  onClick={() => setVideoModalVisible(true)}
                >
                  <PlusOutlined /> เลือกจาก Preview Video
                </Button>
              </Col>
            </Row> */}

            <Form.Item
              label="รูปภาพ Thumbnail (TH)"
              name="thumbnailTH"
              wrapperCol={{ span: 12 }}
            >
              <Upload
                onRemove={thumbnailTHRemove}
                beforeUpload={thumbnailTHBeforeUpload}
                fileList={thumbnailTHFileList}
                listType="picture"
              >
                {thumbnailTHStatus === UploadStatus.EMPTY && (
                  <Button>
                    <CloudUploadOutlined /> Upload
                  </Button>
                )}
              </Upload>
            </Form.Item>
            <Form.Item
              label="รูปภาพ Thumbnail (EN)"
              name="thumbnailEN"
              wrapperCol={{ span: 12 }}
            >
              <Upload
                onRemove={thumbnailENRemove}
                beforeUpload={thumbnailENBeforeUpload}
                fileList={thumbnailENFileList}
                listType="picture"
              >
                {thumbnailENStatus === UploadStatus.EMPTY && (
                  <Button>
                    <CloudUploadOutlined /> Upload
                  </Button>
                )}
              </Upload>
            </Form.Item>
            <Form.Item
              label="Slug"
              name="slug"
              rules={[
                {
                  required: true,
                  pattern: new RegExp(/^[-a-zA-Z0-9ก-ฮะ-เ่้๊๋]+$/i),
                  message: 'กรุณากรอกโดยห้ามใส่อักขระพิเศษ'
                }
              ]}
            >
              <Input placeholder="slug" />
            </Form.Item>
            <Form.Item
              label="ระดับคอร์สเรียน"
              name="courseLevel"
              rules={[getRule(FormRule.REQUIRE)]}
            >
              <Radio.Group
                onChange={(e: any) => {
                  if (e.target.value === CourseLevel.STUDENT)
                    form.setFieldsValue({
                      teacherAcademyCourseType: null
                    });

                  setCourseLevel(e.target.value);
                  form.setFieldsValue({
                    courseLevel: e.target.value
                  });
                }}
              >
                <Radio.Button value={CourseLevel.STUDENT}>
                  นักเรียน
                </Radio.Button>
                <Radio.Button value={CourseLevel.TEACHER}>คุณครู</Radio.Button>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="ประเภทคอร์ส"
              name="teacherAcademyCourseType"
              rules={
                courseLevel === CourseLevel.TEACHER
                  ? [getRule(FormRule.REQUIRE)]
                  : []
              }
            >
              <Select
                placeholder="เลือกประเภทคอร์ส"
                disabled={courseLevel === CourseLevel.STUDENT}
                // onChange={(_, option: any) => {
                //   setTeacherAcademyCourseType(option?.title);
                // }}
              >
                {teacherAcademyCourseTypeOptions?.map((s) => (
                  <Option key={s?.value} value={s?.value} title={s.label}>
                    {s.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="ภาษาที่ใช้"
              name="language"
              rules={[getRule(FormRule.REQUIRE)]}
            >
              <Radio.Group>
                <Radio.Button value="th">ไทย</Radio.Button>
                <Radio.Button value="en">English</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row className="mt-10">
          <Col span={12}>
            <Row className="mb-4">
              <h1 className="text-base font-semibold">สถานะ</h1>
            </Row>
            <Form.Item
              label="สถานะการเผยแพร่"
              name="visibility"
              rules={[getRule(FormRule.REQUIRE)]}
            >
              <Radio.Group>
                <Radio.Button value={CourseVisibility.PUBLIC}>
                  Public
                </Radio.Button>
                <Radio.Button value={CourseVisibility.SPECIAL}>
                  Special
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="สถานะของคอร์ส"
              name="status"
              rules={[getRule(FormRule.REQUIRE)]}
            >
              <Radio.Group>
                <Radio.Button value={CourseStatus.ACTIVE}>
                  {COURSE_STATUS_TEXT[CourseStatus.ACTIVE]}
                </Radio.Button>
                <Radio.Button value={CourseStatus.INACTIVE}>
                  {COURSE_STATUS_TEXT[CourseStatus.INACTIVE]}
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
            <Form.Item label="คอร์สยอดนิยม" name="isPopular">
              <Switch
                checked={isPopular}
                onChange={(value: boolean) => {
                  setIsPopular(value);
                  form.setFieldsValue({ isPopular: value });
                }}
              />
              <span className="ml-2">เปิด</span>
            </Form.Item>
            <Form.Item label="คอร์สแนะนำ" name="isRecommend">
              <Switch
                checked={isRecommend}
                onChange={(value: boolean) => {
                  setIsRecommend(value);
                  form.setFieldsValue({ isRecommend: value });
                }}
              />
              <span className="ml-2">เปิด</span>
            </Form.Item>
            <Form.Item label="คอร์สใหม่" name="isNew">
              <Switch
                checked={isNew}
                onChange={(value: boolean) => {
                  setIsNew(value);
                  form.setFieldsValue({ isNew: value });
                }}
              />
              <span className="ml-2">เปิด</span>
            </Form.Item>
            <Form.Item
              label="วันสิ้นสุดความใหม่"
              name="isNewEndAt"
              rules={[
                () => ({
                  validator(rule, value) {
                    if (isNew && !value) {
                      return Promise.reject('กรุณากรอกวันสิ้นสุดความใหม่');
                    }
                    return Promise.resolve();
                  }
                })
              ]}
              normalize={(value) => {
                return toEndOfDay(value);
              }}
            >
              <DatePicker disabled={!isNew} />
            </Form.Item>
          </Col>
          <Col span={12} className="pr-8">
            <Row className="mb-4">
              <h1 className="text-base font-semibold">ราคา</h1>
            </Row>
            <Form.Item
              label="ราคาเต็ม"
              name="fullPrice"
              rules={[getRule(FormRule.REQUIRE)]}
            >
              <Input
                min={0}
                type="number"
                className="max-w-xs"
                placeholder="12XX"
                addonAfter="บาท"
              />
            </Form.Item>
            <Form.Item
              label="ราคาขาย"
              name="salePrice"
              rules={[getRule(FormRule.REQUIRE)]}
            >
              <Input
                min={0}
                type="number"
                className="max-w-xs"
                placeholder="2XX"
                addonAfter="บาท"
              />
            </Form.Item>
            <Row className="mb-4 mt-4">
              <h1 className="text-base font-semibold">ชั่วโมงการเรียน</h1>
            </Row>
            <Form.Item
              label="จำนวนชั่วโมง"
              name="duration"
              rules={[getRule(FormRule.REQUIRE)]}
            >
              <Input
                min={1}
                type="number"
                className="max-w-xs"
                placeholder="1X"
                addonAfter="ชั่วโมง"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className="mt-10">
          <Col span={24}>
            <Row
              css={css`
                label {
                  font-size: 1rem;
                  font-weight: 600;
                }
                .ant-form-item-explain {
                  position: absolute;
                  top: 5px;
                  left: 14px;
                }
              `}
            >
              <Col span={24}>
                <Form.Item
                  name="descriptionTH"
                  label="ข้อมูลทั่วไป"
                  rules={[getRule(FormRule.REQUIRE)]}
                  labelCol={{ span: 0 }}
                  className="w-full"
                ></Form.Item>
              </Col>
            </Row>
            <Row className="w-full">
              <Tabs defaultActiveKey="1" type="card">
                <TabPane
                  tab={
                    <span>
                      ภาษาไทย <span className="text-red mb-2">*</span>
                    </span>
                  }
                  key="1"
                >
                  <Form.Item
                    name=""
                    className="w-full"
                    wrapperCol={{ span: 24 }}
                  >
                    <WysiwygEditor
                      editorState={editorDesTHState}
                      onEditorStateChange={(state) => {
                        form.setFieldsValue({
                          descriptionTH: parseStateToHTML(state)
                        });
                        setEditorDesTHState(state);
                      }}
                    />
                  </Form.Item>
                </TabPane>
                <TabPane tab="English" key="2">
                  <Form.Item
                    name=""
                    className="w-full"
                    wrapperCol={{ span: 24 }}
                  >
                    <WysiwygEditor
                      editorState={editorDesENState}
                      onEditorStateChange={setEditorDesENState}
                    />
                  </Form.Item>
                </TabPane>
              </Tabs>
            </Row>
          </Col>
        </Row>
        <Row className="mt-10">
          <Col span={24}>
            <Row
              css={css`
                label {
                  font-size: 1rem;
                  font-weight: 600;
                }
                .ant-form-item-explain {
                  position: absolute;
                  top: 5px;
                  left: 14px;
                }
              `}
            >
              <Col span={24}>
                <Form.Item
                  name="benefitTH"
                  label="ประโยชน์ที่ได้รับ"
                  rules={[getRule(FormRule.REQUIRE)]}
                  labelCol={{ span: 0 }}
                  className="w-full"
                ></Form.Item>
              </Col>
            </Row>
            <Row className="w-full">
              <Tabs defaultActiveKey="1" type="card">
                <TabPane
                  tab={
                    <span>
                      ภาษาไทย <span className="text-red mb-2">*</span>
                    </span>
                  }
                  key="1"
                >
                  <Form.Item
                    name=""
                    className="w-full"
                    wrapperCol={{ span: 24 }}
                  >
                    <WysiwygEditor
                      editorState={editorBenefitTHState}
                      onEditorStateChange={(state) => {
                        form.setFieldsValue({
                          benefitTH: parseStateToHTML(state)
                        });
                        setEditorBenefitTHState(state);
                      }}
                    />
                  </Form.Item>
                </TabPane>
                <TabPane tab="English" key="2">
                  <Form.Item
                    name=""
                    className="w-full"
                    wrapperCol={{ span: 24 }}
                  >
                    <WysiwygEditor
                      editorState={editorBenefitENState}
                      onEditorStateChange={setEditorBenefitENState}
                    />
                  </Form.Item>
                </TabPane>
              </Tabs>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className="flex justify-end items-center">
              <TextButton
                className="mr-6"
                onClick={() => history.push('/course/manage')}
              >
                ยกเลิก
              </TextButton>
              <PrimaryButton htmlType="submit">บันทึก</PrimaryButton>
            </div>
          </Col>
        </Row>
        <Modal
          css={css`
            .ant-modal-body {
              padding: 0px;
            }
          `}
          onCancel={() => setVideoModalVisible(false)}
          title="เพิ่มคลิปตัวอย่างคอร์ส"
          visible={videoModalVisible}
          footer={
            <Row>
              <Col span={24}>
                <div className="flex justify-end items-center">
                  <TextButton
                    className="mr-6"
                    onClick={() => setVideoModalVisible(false)}
                  >
                    ยกเลิก
                  </TextButton>
                  <PrimaryButton
                    onClick={() => {
                      setVideoModalVisible(false);

                      form.setFieldsValue({ previewVideoUrl: selectedPreview });
                    }}
                  >
                    บันทึก
                  </PrimaryButton>
                </div>
              </Col>
            </Row>
          }
          style={{ borderRadius: 16 }}
          width={800}
        >
          <Table
            pagination={false}
            rowSelection={{
              type: 'radio',
              onChange: (key: any, value: any) => {
                setSelectedPreview(value[0]?.url);
              }
            }}
            columns={columns}
            dataSource={data}
          />
        </Modal>
        <CourseSuccessModal courseId={redirectCourseId} visible={isSuccess} />
      </Form>
    </Spin>
  );
});
