/** @jsx jsx */
import { Col, Form, Input, Modal, Row, message } from 'antd';
import {
  CourseSection,
  CourseSectionCreateDTO,
  CourseUpdateDTO
} from 'feature/course/course.type';
import { FormRule, getRule } from 'feature/common/Form/utils';
import { Fragment, useEffect } from 'react';
import { Global, css, jsx } from '@emotion/react';
import { PrimaryButton, TextButton } from 'feature/common/Button';

import { useCreateCourseSection } from 'hooks/course/useCreateCourseSection';
import { useUpdateCourseSection } from 'hooks/course/useUpdateCourseSection';

type ManageSectionModalProps = {
  isEdit: boolean;
  courseId: string;
  section?: CourseSection;
  visible: boolean;
  setUpdatedSectionId: (sectionId: string) => void;
  refetch: () => {};
  onClose: () => void;
};

export const ManageSectionModal = ({
  isEdit,
  courseId,
  section,
  visible,
  setUpdatedSectionId,
  refetch,
  onClose
}: ManageSectionModalProps) => {
  const { execute: createCourseSection } = useCreateCourseSection();
  const { execute: updateCourseSection } = useUpdateCourseSection(
    section?.courseSectionId || ''
  );

  const [form] = Form.useForm();

  useEffect(() => {
    if (isEdit && section) {
      form.setFieldsValue({
        nameTH: section?.name?.th,
        nameEN: section?.name?.en
      });
    }
  }, [visible, section, form, isEdit]);

  const handleSubmit = async (values: any) => {
    await form.validateFields();

    const { nameTH, nameEN } = values;

    if (isEdit) {
      const data = {
        name: {
          th: nameTH,
          en: nameEN
        }
      } as CourseUpdateDTO;

      const loading = message.loading('กำลังดำเนินการ...', 0);
      try {
        await updateCourseSection({ data });
        await refetch();
        loading();
        message.success('แก้ไขบทเรียนสำเร็จ');
        setUpdatedSectionId(section?.courseSectionId as string);
        onClose();
      } catch (error) {
        loading();
        message.error('แก้ไขบทเรียนไม่สำเร็จ');
        console.error(error);
      }
    } else {
      const data = {
        course: { courseId },
        name: {
          th: nameTH,
          en: nameEN
        }
      } as CourseSectionCreateDTO;

      const loading = message.loading('กำลังดำเนินการ...', 0);
      try {
        const {
          data: { data: createdCourseSection }
        } = await createCourseSection({ data });
        setUpdatedSectionId(createdCourseSection?.courseSectionId);
        await refetch();
        loading();
        message.success(`${isEdit ? 'แก้ไข' : 'เพิ่ม'}บทเรียนสำเร็จ`);
        onClose();
      } catch (error) {
        loading();
        message.error(`${isEdit ? 'แก้ไข' : 'เพิ่ม'}บทเรียนไม่สำเร็จ`);
        console.error(error);
      }
    }
  };

  return (
    <Fragment>
      <Global
        styles={css`
          .ant-modal-body {
            padding: 24px 2.5rem;
          }
          body {
            overflow-y: ${visible ? 'hidden' : 'auto'} !important;
          }
        `}
      />
      <Modal
        title={`${isEdit ? 'แก้ไข' : 'เพิ่ม'}บทเรียน`}
        visible={visible}
        maskClosable={false}
        onCancel={onClose}
        afterClose={() => {
          form.resetFields();
        }}
        footer={
          <Row>
            <Col span={24}>
              <div className="flex justify-end items-center">
                <TextButton className="mr-6" onClick={onClose}>
                  ยกเลิก
                </TextButton>
                <PrimaryButton
                  onClick={() => {
                    handleSubmit(form.getFieldsValue());
                  }}
                >
                  บันทึก
                </PrimaryButton>
              </div>
            </Col>
          </Row>
        }
        css={css`
          width: 640px !important;
          padding-bottom: 0;
          .ant-modal-title {
            font-weight: bold;
          }
          .ant-modal-body {
            min-height: 520px;
          }
        `}
      >
        <Form
          form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          colon={false}
        >
          <Form.Item
            label="ชื่อบทเรียน (TH)"
            name="nameTH"
            rules={[getRule(FormRule.REQUIRE, 'กรุณากรอกชื่อบทเรียนภาษาไทย')]}
          >
            <Input placeholder="ชื่อบทเรียนภาษาไทย..." />
          </Form.Item>
          <Form.Item label="ชื่อบทเรียน (EN)" name="nameEN">
            <Input placeholder="ชื่อบทเรียนภาษาอังกฤษ..." />
          </Form.Item>
        </Form>
      </Modal>
    </Fragment>
  );
};
