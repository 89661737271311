import { CouponReport } from 'feature/coupon-report/coupon-report.type';
import useAxios from 'axios-hooks';

export const useCouponReport = (couponId: string) => {
  const [{ data, loading, error }, refetch] = useAxios(
    couponId && `/coupon-reports/${couponId}`
  );

  return {
    data: data && (data?.data as CouponReport),
    loading: loading,
    error,
    refetch
  };
};
