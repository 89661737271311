import {
  SchoolRequest,
  SchoolRequestStatus
} from 'feature/school-request/school-request.type';

import { DateFormat } from 'feature/common/global.type';
import { ReactComponent as FormEditIcon } from 'assets/form-edit.svg';
import { ReactComponent as PreviewIcon } from 'assets/previewIcon.svg';
import React from 'react';
import { Table } from 'antd';
import { TableProps } from 'antd/lib/table';
import { ReactComponent as TrayIcon } from 'assets/tray.svg';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

const SchoolRequestTable = (props: TableProps<any>) => {
  const history = useHistory();
  const columns = [
    {
      title: 'วันที่ส่งคำขอ',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 150,
      render: (date: string) => {
        const momentDateFormat = moment(date)
          .add(543, 'year')
          .format(DateFormat.DEFAULT);
        return momentDateFormat;
      }
    },
    {
      title: 'หมายเลขคำขอ',
      dataIndex: 'schoolRequestId',
      key: 'schoolRequestId',
      width: 150
    },
    {
      title: 'รหัสโรงเรียน',
      dataIndex: ['school', 'schoolCode'],
      key: ['school', 'schoolCode'],
      width: 150
    },
    {
      title: 'โรงเรียน',
      dataIndex: ['school', 'schoolName', 'th'],
      key: ['school', 'schoolName', 'th']
    },
    {
      title: 'จังหวัด',
      dataIndex: ['school', 'province', 'provinceName', 'th'],
      key: ['school', 'province', 'provinceName', 'th']
    },
    {
      title: 'สถานะ',
      dataIndex: 'status',
      key: 'status',
      render: (requestStatus: SchoolRequestStatus) => {
        switch (requestStatus) {
          case SchoolRequestStatus.PENDING:
            return <>รออนุมัติ</>;
          case SchoolRequestStatus.CREATED:
            return <>รอเอกสาร</>;
          case SchoolRequestStatus.ACCEPTED:
            return <>อนุมัติ</>;
          case SchoolRequestStatus.REJECTED:
            return <>ไม่อนุมัติ</>;
          default:
            return <>-</>;
        }
      }
    },
    {
      dataIndex: 'status',
      key: 'action',
      render: (requestStatus: SchoolRequestStatus, record: SchoolRequest) => {
        switch (requestStatus) {
          case SchoolRequestStatus.PENDING:
            return (
              <div
                className="flex items-center justify-end cursor-pointer"
                onClick={() => {
                  history.push(
                    `/school-manage/detail?schoolRequestId=${record?.schoolRequestId}`
                  );
                }}
              >
                <FormEditIcon />
                <span className="ml-2 text-blue">จัดการอนุมัติ</span>
              </div>
            );
          default:
            return (
              <div
                className="flex items-center justify-end cursor-pointer"
                onClick={() => {
                  history.push(
                    `/school-manage/detail?schoolRequestId=${record?.schoolRequestId}`
                  );
                }}
              >
                <PreviewIcon />
                <span className="ml-2 text-blue">ดูรายละเอียด</span>
              </div>
            );
        }
      }
    }
  ];

  return (
    <Table
      columns={columns as any}
      locale={{
        emptyText: (
          <div className="flex flex-1 flex-col items-center py-8">
            <TrayIcon />
            <span className="font-bold mt-2">ไม่มีคำขอเปิดบัญชีโรงเรียน</span>
          </div>
        )
      }}
      {...props}
    />
  );
};

export default SchoolRequestTable;
