import {
  Course,
  CourseLecturer,
  CourseLesson,
  CourseLevel,
  CourseSection,
  SessionType
} from 'feature/course/course.type';

import { CourseSignatureDto } from '@aksorn/backoffice-sdk';

interface SessionName {
  th: string;
  en: string;
}
export interface Certificate {
  enabled?: boolean;
  videoViewingPassPercentage?: number;
  quizScorePassPercentage?: number;
  signatures?: Partial<CourseSignatureDto>[];
}
export interface CourseSession {
  course?: Partial<Course>;
  name?: SessionName;
  courseSessionId?: string;
  registrationStartDate?: string;
  registrationEndDate?: string;
  sessionStartDate?: string;
  sessionEndDate?: string;
  visibility?: SessionType;
  status?: string;
  seatLimit?: number;
  seatUsage?: number;
  courseLevel?: CourseLevel;
  videoViewingPassPercentage?: number;
  courseSessionSections?: CourseSessionSection[] | null;
  courseSessionLessons?: CourseSessionLesson[] | null;
  courseSessionLecturers?: CourseSessionLecturer[] | null;
  learningType?: string;
  passedCertificate?: Certificate;
  participatedCertificate?: Certificate;
  certificateGenerationStartDate?: string | null;
  certificateGenerationEndDate?: string | null;
}

export enum Mode {
  ADD = 'add',
  Edit = 'edit'
}
export enum CertificateCondition {
  PARTICIPATED = 'participated',
  PASSED = 'passed'
}

export interface CourseSessionLessonIdWithQuiz {
  id?: string;
  quizScorePassPercentage?: number | null;
  quizAnswerSheetSchedule?: string | null;
}

export interface CourseSessionSection {
  courseSection: Partial<CourseSection>;
  learningStartDate: string | null;
}
export interface CourseSessionLesson {
  courseLesson?: Partial<CourseLesson>;
  quizScorePassPercentage?: number | null;
  quizAnswerSheetSchedule?: string | null;
}
export interface CourseSessionLecturer {
  courseLecturer?: Partial<CourseLecturer>;
  position?: number | null;
}
