/** @jsx jsx */
import { Col, Popconfirm, Row, Switch, Table } from 'antd';
import { DeleteTwoTone, FormOutlined } from '@ant-design/icons';

import React from 'react';
// import _ from 'lodash';
import { jsx } from '@emotion/react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

export const BannerTable = ({
  data = [],
  loading = false,
  handleEdit,
  handleDelete,
  pagination,
  handleTableChange
}: {
  data: any[];
  loading: boolean;
  handleEdit: Function;
  handleDelete: Function;
  pagination?: any;
  handleTableChange?: Function;
}) => {
  const history = useHistory();

  const columns = [
    {
      title: 'ชื่อแบนเนอร์',
      dataIndex: '',
      key: 'bannerName',
      render: (record: any) => {
        return (
          <span className="flex">
            <a target="_blank" href={record?.imgUrl}>
              <img src={record?.imgUrl || ''} className="w-6 h-6 mr-2" />
            </a>
            <a target="_blank" href={record?.imgUrl}>
              {record?.bannerName || ''}
            </a>
          </span>
        );
      }
    },
    {
      title: 'วัน-เวลา เริ่มแสดงแบนเนอร์',
      dataIndex: 'startDate',
      key: 'startDate',
      render: (startDate: any) =>
        moment.utc(startDate).utcOffset(7).format('DD/MM/YYYY HH:mm')
    },
    {
      title: 'วัน-เวลา สิ้นสุดการแสดงแบนเนอร์',
      dataIndex: 'endDate',
      key: 'endDate',
      render: (endDate: any) =>
        moment.utc(endDate).utcOffset(7).format('DD/MM/YYYY HH:mm')
    },
    {
      title: 'สถานะ',
      dataIndex: '',
      key: 'status',
      width: 176,
      render: (record: any) => {
        const startDate = moment(record.startDate);
        const endDate = moment(record.endDate);
        const currentDate = moment();
        var statusText = '';
        var textColor = '';
        if (!record.isVisible) {
          statusText = 'ปิดการแสดงแบนเนอร์';
          textColor = 'text-gray-800';
        } else if (currentDate.isBefore(startDate)) {
          statusText = 'รอเวลาแสดงแบนเนอร์';
          textColor = 'text-yellow';
        } else if (currentDate.isAfter(endDate)) {
          statusText = 'หมดเวลาแสดงแบนเนอร์';
          textColor = 'text-red';
        } else {
          statusText = 'กำลังแสดงแบนเนอร์';
          textColor = 'text-green-600';
        }
        return <div className={`font-bold ${textColor}`}>{statusText}</div>;
      }
    },
    {
      title: '',
      dataIndex: '',
      key: 'x',
      width: 128,
      render: (record: any) => {
        return (
          <Row gutter={4}>
            <Col className="flex items-center">
              <Popconfirm
                /*
                title={`คุณต้องการที่จะ${
                  record.isVisible ? 'ปิด' : 'เปิด'
                }การใช้งานแบนเนอร์นี้ ใช่หรือไม่?`}
                */
                title={
                  record.isVisible
                    ? `คุณต้องการที่จะปิดการแสดงแบนเนอร์นี้
                    (แม้ว่าจะอยู่ในช่วงเวลาที่กำลังแสดงแบนเนอร์ก็ตาม) ใช่หรือไม่ ?`
                    : `คุณต้องการที่จะเปิดการแสดงแบนเนอร์นี้
                    ให้กลับมาแสดงในช่วงเวลาที่กำหนด ใช่หรือไม่ ?`
                }
                okText="ตกลง"
                cancelText="ยกเลิก"
                placement="bottom"
                onConfirm={() => {
                  handleEdit(
                    {
                      bannerName: record.bannerName,
                      imgUrl: record.imgUrl,
                      startDate: record.startDate,
                      endDate: record.endDate,
                      isVisible: !record.isVisible
                    },
                    record.bannerId
                  );
                }}
                className={record.isVisible ? 'bg-green-600' : ''}
              >
                <Switch
                  checked={record.isVisible}
                  checkedChildren="เปิด"
                  unCheckedChildren="ปิด"
                  size="small"
                />
              </Popconfirm>
            </Col>
            <Col className="flex items-center">
              <div
                className="flex px-2 text-center cursor-pointer"
                onClick={() => history.push('/banner/edit/' + record.bannerId)}
              >
                <FormOutlined style={{ color: '#1890FF' }} />
              </div>
            </Col>
            <Col className="flex items-center">
              <Popconfirm
                title="คุณต้องการที่จะลบแบนเนอร์นี้ ใช่หรือไม่?"
                okText="ลบ"
                cancelText="ยกเลิก"
                placement="bottom"
                onConfirm={() => {
                  handleDelete(record.bannerId);
                }}
              >
                <DeleteTwoTone twoToneColor="#F03D3E" />
              </Popconfirm>
            </Col>
          </Row>
        );
      }
    }
  ];

  return (
    <React.Fragment>
      <Table
        loading={loading}
        dataSource={data}
        columns={columns}
        rowKey="bannerId"
        pagination={pagination || false}
        onChange={(value) => {
          if (!!handleTableChange) {
            handleTableChange(value);
          }
        }}
      />
    </React.Fragment>
  );
};
