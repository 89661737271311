export enum CertificateStatus {
  DEFAULT = 'default',
  QUALIFY = 'qualify',
  DISQUALIFY = 'disqualify'
}

export enum CourseReportUserSort {
  USER = 'user',
  SCHOOL = 'school'
}

export enum CourseReportProgressStatus {
  PASSED = 'passed',
  IN_PROGRESS = 'inProgress',
  FAILED = 'failed'
}

export enum CertificateType {
  PASSED_CERTIFICATE = 'passedCertificate',
  PARTICIPATED_CERTIFICATE = 'participatedCertificate'
}
