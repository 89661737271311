export enum ProductType {
  ALL = 'all',
  BOOK = 'book',
  QUIZ = 'quiz',
  COURSE = 'course'
}

export enum ProductBookType {
  EBOOK = 'ebook'
}
