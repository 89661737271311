/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import { Avatar } from 'antd';
import { AvatarProps } from 'antd/lib/avatar';
import { UserOutlined } from '@ant-design/icons';

export const StyledAvatar = (props: AvatarProps) => {
  return (
    <div
      css={css`
        .ant-avatar {
          display: flex;
          justify-content: center;
          align-items: center;
          > img {
            object-fit: contain !important;
          }
        }
      `}
    >
      <Avatar icon={<UserOutlined />} {...props} />
    </div>
  );
};
