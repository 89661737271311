import { CouponSchool } from 'feature/coupon/coupon.type';
import useAxios from 'axios-hooks';

export const useCouponSchools = (couponId: string) => {
  const [{ data: response, loading, error }, refetch] = useAxios(
    `/coupon-schools?pageNumber=1&pageSize=100&couponId=${couponId}`
  );

  return {
    data: (response?.data as CouponSchool[]) || [],
    loading,
    error,
    execute: refetch
  };
};
