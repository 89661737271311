/** @jsx jsx */
import {
  Button,
  Col,
  Input,
  Pagination,
  Row,
  Select,
  Spin,
  Table,
  message
} from 'antd';
import {
  CourseLessonUserProgress,
  CourseSessionReportUserDto
} from '@aksorn/backoffice-sdk';
import {
  CourseReportProgressStatus,
  CourseReportUserSort
} from './course-report.type';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Fragment, useEffect, useState } from 'react';
import { Global, css, jsx } from '@emotion/react';

import Axios from 'axios';
import { CourseReportManageUserDetailModal } from './CourseReportManageUserDetailModal';
import { ReactComponent as FormEditIcon } from 'assets/form-edit.svg';
import { School } from 'feature/coupon/school.type';
import { UploadOutlined } from '@ant-design/icons';
import { UserType } from 'feature/common/user.type';
import debounce from 'lodash/debounce';
import { useCourseSessionReportExportExcel } from 'hooks/course-session-report/useCourseSessionReportExportExcel';
import { useCourseSessionReportUsers } from 'hooks/course-session-report/userCourseSessionReportUsers';

const fetchSchools = debounce(
  async ({
    keyword = '',
    pageNumber = 1,
    isAppendSchools,
    filteredSchools = [],
    setFilteredSchools
  }) => {
    const {
      data: { data: schools }
    } = await Axios.get(`/schools`, {
      params: {
        keyword,
        pageNumber,
        pageSize: 20
      }
    });

    let newSchools = schools;
    if (isAppendSchools) {
      newSchools = [...filteredSchools, ...schools];
    }
    setFilteredSchools(newSchools);
  },
  100,
  { leading: false, trailing: true }
);

export const CourseReportDetailNameList = ({
  courseSessionId
}: {
  courseSessionId: string;
}) => {
  const [schoolIdFilter, setSchoolIdFilter] = useState<string>();
  const [isLoadingSchools, setIsLoadingSchools] = useState(false);
  const [isAppendSchools, setIsAppendSchools] = useState(false);
  const [schoolSearchText, setSchoolSearchText] = useState<string>('');
  const [schoolPageNumber, setSchoolPageNumber] = useState<number>(1);
  const [filteredSchools, setFilteredSchools] = useState<School[]>([]);
  const [schoolListOptions, setSchoolListOptions] = useState<any>([]);
  const [keyword, setKeyword] = useState('');
  const [searchFilter, setSearchFilter] = useState<string>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sortBy, _setSortBy] = useState<CourseReportUserSort>();
  const [
    selectedUser,
    setSelectedUser
  ] = useState<CourseSessionReportUserDto>();
  const [visibleUserDetailModal, setVisibleUserDetailModal] = useState(false);
  const [dataSource, setDataSource] = useState<CourseSessionReportUserDto[]>();

  const { data, loading, refetch, item } = useCourseSessionReportUsers({
    courseSessionId,
    schoolId: schoolIdFilter,
    couponCode: keyword,
    pageNumber: currentPage,
    sortBy: sortBy
  });

  const {
    loading: exportExcelLoading,
    execute: exportExcelExecute
  } = useCourseSessionReportExportExcel();

  useEffect(() => {
    if (courseSessionId) {
      refetch();
    } else {
      setDataSource([]);
    }
  }, [courseSessionId, schoolIdFilter, keyword, currentPage, sortBy]);

  useEffect(() => {
    if (!loading) {
      const dataSource = data?.map((dto: CourseSessionReportUserDto) => {
        const courseLessons = dto?.courseLessons;

        const children = courseLessons?.map(
          (courseLesson: CourseLessonUserProgress) => ({
            key: dto?.userId + courseLesson?.courseLessonId,
            name: {
              th: `${courseLesson?.courseLessonName?.th}${
                courseLesson.isImportant ? '*' : ''
              }`,
              en: `${courseLesson?.courseLessonName?.en}${
                courseLesson.isImportant ? '*' : ''
              }`
            },

            schoolAliasName: {
              th:
                (courseLesson?.quizScore ?? '-') +
                '/' +
                courseLesson?.quizMaxScore,
              en:
                (courseLesson?.quizScore ?? '-') +
                '/' +
                courseLesson?.quizMaxScore
            },

            isExpandable: false
          })
        );

        return {
          ...dto,
          key: dto?.userId,
          name: { th: dto?.title + dto?.userName?.th, en: dto?.userName?.en },
          children,
          isExpandable: courseLessons?.length > 0
        };
      });
      setDataSource(dataSource);
    }
  }, [loading]);

  const columns = [
    {
      title: 'รายชื่อ',
      key: 'userId',
      width: '300',
      render: (dto: { title: string; name: { th: string } }) => dto.name.th
    },
    {
      title: 'โรงเรียน',
      dataIndex: ['schoolAliasName', 'th'],
      key: 'schoolId'
    },
    {
      title: 'คูปองที่ใช้',
      dataIndex: 'couponCode',
      key: 'couponCode',
      align: 'center'
    },
    {
      title: 'ระดับผู้ใช้',
      dataIndex: 'userType',
      key: 'userType',
      align: 'center',
      render: (userType: UserType) => {
        if (!userType) return <Fragment></Fragment>;
        switch (userType) {
          case UserType.STUDENT:
            return <Fragment>นักเรียน</Fragment>;
          case UserType.TEACHER:
            return <Fragment>คุณครู</Fragment>;
          default:
            return <Fragment>-</Fragment>;
        }
      }
    },
    {
      title: 'Progress',
      dataIndex: 'progressPercent',
      key: 'progressPercent',
      align: 'center',
      render: (progressPercent: number) => {
        if (progressPercent === undefined) return <Fragment></Fragment>;
        return <div>{progressPercent} %</div>;
      }
    },
    {
      title: 'เข้าร่วมอบรม',
      dataIndex: 'progressStatus',
      key: 'progressStatus',
      align: 'center',
      render: (progressStatus?: CourseReportProgressStatus) => {
        if (!progressStatus) return <Fragment></Fragment>;
        switch (progressStatus) {
          case CourseReportProgressStatus.PASSED:
            return 'ผ่าน';
          case CourseReportProgressStatus.FAILED:
            return 'ไม่ผ่าน';
          case CourseReportProgressStatus.IN_PROGRESS:
            return 'อยู่ในระหว่างการอบรม';
          default:
            return '-';
        }
      }
    },
    {
      title: 'ใบประกาศเข้าร่วมการอบรม',
      dataIndex: 'isReceivedParticipatedCertificate',
      key: 'isReceivedParticipatedCertificate',
      align: 'center',
      render: (isReceivedParticipatedCertificate?: boolean) => {
        if (isReceivedParticipatedCertificate === undefined)
          return <Fragment></Fragment>;
        return (
          <div>
            {isReceivedParticipatedCertificate ? 'ได้รับ' : 'ไม่ได้รับ'}
          </div>
        );
      }
    },
    {
      title: 'ใบประกาศผ่านการอบรม',
      dataIndex: 'isReceivedPassedCertificate',
      key: 'isReceivedPassedCertificate',
      align: 'center',
      render: (isReceivedPassedCertificate?: boolean) => {
        if (isReceivedPassedCertificate === undefined)
          return <Fragment></Fragment>;
        return (
          <div>{isReceivedPassedCertificate ? 'ได้รับ' : 'ไม่ได้รับ'}</div>
        );
      }
    },
    {
      title: '',
      dataIndex: 'progressStatus',
      key: 'userId',
      render: (
        progressStatus: CourseReportProgressStatus,
        record: CourseSessionReportUserDto
      ) => {
        if (!progressStatus) return <Fragment></Fragment>;
        return (
          <div
            className="cursor-pointer"
            onClick={() => {
              setSelectedUser(record);
              setVisibleUserDetailModal(true);
            }}
          >
            <FormEditIcon />
          </div>
        );
      }
    }
  ];

  useEffect(() => {
    const schoolListOptions = filteredSchools?.map((school: School) => ({
      key: school?.schoolId,
      label: school?.schoolAliasName?.th,
      value: school?.schoolId
    })) as any;
    setSchoolListOptions(schoolListOptions);
  }, [filteredSchools]);

  useEffect(() => {
    getSchools({
      schoolSearchText,
      pageNumber: schoolPageNumber,
      isAppendSchools,
      filteredSchools,
      setFilteredSchools
    });
  }, [schoolSearchText, schoolPageNumber]);

  useEffect(() => {
    setIsLoadingSchools(false);
  }, [schoolListOptions]);

  const getSchools = async ({
    schoolSearchText,
    pageNumber,
    isAppendSchools,
    filteredSchools,
    setFilteredSchools
  }: {
    schoolSearchText: string;
    pageNumber: number;
    isAppendSchools: boolean;
    filteredSchools: School[];
    setFilteredSchools: Function;
  }) => {
    try {
      setIsLoadingSchools(true);
      await fetchSchools({
        keyword: schoolSearchText,
        pageNumber,
        isAppendSchools,
        filteredSchools,
        setFilteredSchools
      });
    } catch (error) {
      setIsLoadingSchools(false);
      console.error(error);
    }
  };

  const handleLoadMoreSchools = async (event: any) => {
    const target = event.target;
    if (target.scrollTop + target.offsetHeight > 0.99 * target.scrollHeight) {
      target.scrollTo(0, target.scrollHeight);
      setIsAppendSchools(true);
      setSchoolPageNumber(schoolPageNumber + 1);
    }
  };

  const handleResetLoadMoreState = (value: string) => {
    setSchoolPageNumber(1);
    setSchoolSearchText(value);
    setIsAppendSchools(false);
  };

  const handleSearch = (text: string = '') => {
    setKeyword(text.trim());
    setCurrentPage(1);
  };

  const exportExcel = async () => {
    try {
      await exportExcelExecute({
        courseSessionId,
        params: {
          schoolId: schoolIdFilter,
          couponCode: keyword
        }
      });
    } catch (error) {
      console.error(error);
      openMessageError();
    }
  };

  const openMessageError = () => {
    message.error('ไม่สามารถดาวน์โหลดได้ในขณะนี้ กรุณาลองใหม่อีกครั้ง');
  };

  return (
    <Spin spinning={exportExcelLoading}>
      <Global
        styles={css`
          .ant-table-row.ant-table-row-level-1 > td > span {
            margin-right: 16px;
          }
          .ant-table-row.ant-table-row-level-1 {
            background-color: #f8f9fa;
          }
        `}
      />
      <div className="flex justify-between w-full">
        <div className="w-full">
          <div className="bg-mono-gray-50 p-2 mb-4">
            <Row className="w-full" gutter={[8, 0]}>
              <Col span={13}>
                <Select
                  showSearch
                  className="w-full"
                  placeholder="โรงเรียน"
                  allowClear
                  optionFilterProp="children"
                  value={schoolIdFilter as string}
                  onSearch={handleResetLoadMoreState}
                  notFoundContent={(() => {
                    if (isLoadingSchools)
                      return (
                        <div className="flex justify-center items-center">
                          <Spin size="small" />
                        </div>
                      );
                    return (
                      <div className="ant-select-item-empty">
                        <div className="ant-empty ant-empty-normal ant-empty-small">
                          <div className="ant-empty-description">
                            ไม่พบโรงเรียนที่ค้นหา
                          </div>
                        </div>
                      </div>
                    );
                  })()}
                  filterOption={(input, option) => {
                    return (option?.label as string)?.includes(input);
                  }}
                  onPopupScroll={handleLoadMoreSchools}
                  onSelect={(value) => {
                    setSchoolIdFilter(value as string);
                    handleResetLoadMoreState('');
                  }}
                  onClear={() => {
                    setSchoolIdFilter(undefined);
                    handleResetLoadMoreState('');
                  }}
                  options={schoolListOptions}
                  css={css`
                    .anticon-close-circle {
                      position: relative;
                      bottom: 2px;
                    }
                  `}
                />
              </Col>
              <Col span={8}>
                <Input
                  placeholder="ค้นหาจากชื่อรหัสหรือชื่อคูปอง"
                  onChange={(e: any) => {
                    setSearchFilter(e.target.value);
                  }}
                  onPressEnter={() => handleSearch(searchFilter)}
                />
              </Col>
              <Col span={3}>
                <Button
                  className="w-full"
                  type="primary"
                  onClick={() => handleSearch(searchFilter)}
                >
                  ค้นหา
                </Button>
              </Col>
            </Row>
          </div>
        </div>
        <div className="flex-shrink-0">
          <div className="p-2 mb-4">
            <Button
              className="flex items-center"
              onClick={exportExcel}
              disabled={!courseSessionId}
            >
              <UploadOutlined /> Export Excel
            </Button>
          </div>
        </div>
      </div>
      <Spin spinning={loading}>
        <Table
          dataSource={dataSource}
          columns={columns as any}
          pagination={false}
          expandable={{
            expandIcon: ({ expanded, onExpand, record }: any) => {
              if (record?.isExpandable) {
                return expanded ? (
                  <UpOutlined
                    style={{ marginRight: '1rem' }}
                    onClick={(e) => onExpand(record, e)}
                  />
                ) : (
                  <DownOutlined
                    style={{ marginRight: '1rem' }}
                    onClick={(e) => onExpand(record, e)}
                  />
                );
              } else return <Fragment></Fragment>;
            },
            rowExpandable: (record: any) => record.isExpandable
          }}
        />
        {item > 0 && (
          <Pagination
            current={currentPage}
            showSizeChanger={false}
            onChange={(page) => {
              setCurrentPage(page);
            }}
            total={item}
            className="flex justify-end mt-4"
          />
        )}
      </Spin>
      <CourseReportManageUserDetailModal
        visible={visibleUserDetailModal}
        user={selectedUser as CourseSessionReportUserDto}
        onClose={() => {
          setVisibleUserDetailModal(false);
        }}
        refetch={refetch}
      />
    </Spin>
  );
};
