import {
  COURSE_STATUS_TEXT,
  COURSE_VISIBILITY_TEXT
} from 'feature/course/const';
import React, { useEffect } from 'react';

import { CourseSessionReportDto } from '@aksorn/backoffice-sdk';
import { DetailListItem } from 'feature/common/DetailListItem';
import { Row } from 'antd';
import moment from 'moment';
import { useCourseSessionReports } from 'hooks/course-session-report/useCourseSessionReports';

interface Props {
  courseSessionId: string;
}

export const CourseReportDetailSessionInfo = (props: Props) => {
  const { courseSessionId } = props;

  const { data: session, refetch } = useCourseSessionReports(courseSessionId);

  useEffect(() => {
    if (courseSessionId) refetch();
  }, [courseSessionId]);

  return (
    <>
      <Row className="mt-2">
        <DetailListItem
          label="วันที่อบรม"
          value={
            session
              ? moment(session?.sessionStartDate)?.format('D MMM') +
                ' - ' +
                moment(session?.sessionEndDate)
                  ?.add(543, 'years')
                  ?.format('D MMM YY')
              : '-'
          }
        />
        <DetailListItem
          label="ผู้เรียน"
          value={`${session?.numberOfRegisteredUser ?? '-'} คน`}
          spanLeft={12}
          spanRight={12}
        />
      </Row>
      <Row className="mt-2">
        <DetailListItem
          label="ประเภท"
          value={
            COURSE_VISIBILITY_TEXT[
              session?.visibility as CourseSessionReportDto.visibility
            ]
          }
        />
        <DetailListItem
          label="ผู้ได้รับใบประกาศเข้าร่วมการอบรม"
          value={`${session?.numberOfParticipatedUser ?? '-'} คน`}
          spanLeft={12}
          spanRight={12}
        />
      </Row>
      <Row className="mt-2">
        <DetailListItem
          label="สถานะ"
          value={
            COURSE_STATUS_TEXT[session?.status as CourseSessionReportDto.status]
          }
        />
        <DetailListItem
          label="ผู้ได้รับใบประกาศผ่านการอบรม"
          value={`${session?.numberOfPassedUser ?? '-'} คน`}
          spanLeft={12}
          spanRight={12}
        />
      </Row>
    </>
  );
};
