import Axios from 'axios';

export const getGradeLevels = async () => {
  const { data: response } = await Axios.get(`/grade-levels`, {
    params: {
      pageNumber: 1,
      pageSize: 100
    }
  });
  return response;
};
