import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  Switch,
  Table,
  message
} from 'antd';
import {
  CertificateCondition,
  CourseSession,
  CourseSessionLecturer,
  CourseSessionLesson,
  CourseSessionLessonIdWithQuiz,
  CourseSessionSection,
  Mode
} from 'feature/course/session/CourseSessionType';
import {
  CourseLecturer,
  CourseLesson,
  CourseLessonType,
  CourseSection,
  SessionCondition
} from 'feature/course/course.type';
import { FormRule, getRule } from 'feature/common/Form/utils';
import { Global, css } from '@emotion/react';
import { PrimaryButton, TextButton } from 'feature/common/Button';
import React, { useEffect, useState } from 'react';

import Axios from 'axios';
import { CourseSessionCertificateCondition } from 'feature/course/session/CourseSessionCertificateCondition';
import { CourseSessionGenerateDateCertificate } from 'feature/course/session/CourseSessionGenerateDateCertificate';
import { CourseSessionSolutionReviewDate } from 'feature/course/session/CourseSessionSolutionReviewDate';
import { CourseSignatureDto } from '@aksorn/backoffice-sdk';
import { ReactComponent as DeleteIcon } from 'assets/delete.svg';
import { PlusOutlined } from '@ant-design/icons';
import _ from 'lodash';
import moment from 'moment';
import { toEndOfDay } from '../../../utils/normalizeForm';
import { toStartOfDay } from 'utils/normalizeForm';
import { useCourseLecturers } from 'hooks/courseLecturer/useCourseLecturer';
import { useCourseSection } from 'hooks/session/useCourseSection';
import { useCourseSignatures } from 'hooks/session/useCourseSignatures';
import { useSession } from 'hooks/session/useSession';

const { RangePicker } = DatePicker;

const { Option } = Select;

interface Props {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  courseId: string;
  courseSessionId: string;
  refetch: () => void;
}

interface SelectItem {
  label?: string;
  value: string;
  key?: string;
}

export const CourseSessionEditSession = (props: Props) => {
  const { visible, setVisible, courseId, courseSessionId, refetch } = props;

  const {
    data: sessionData,
    loading: loadingSession,
    execute: refetchSession
  } = useSession(courseSessionId);
  const {
    data: sectionData,
    loading: loadingCourseSection,
    execute: fetchCourseSection
  } = useCourseSection(courseId);

  const {
    data: lecturers,
    loading: loadingCourseLecturers
  } = useCourseLecturers(courseId);
  const {
    data: signatures,
    loading: loadingCourseSignature,
    execute: fetchSignature
  } = useCourseSignatures(courseId);

  const [saving, setSaving] = useState<boolean>(false);

  const [
    importantExerciseIdInTables,
    setImportantExerciseIdInTables
  ] = useState<string[]>([]);

  const [
    lessonWithQuizScheduleOrQuizPercentages,
    setLessonWithQuizScheduleOrQuizPercentages
  ] = useState<CourseSessionLessonIdWithQuiz[]>([]);

  const [sections, setSection] = useState<CourseSection[]>([]);
  const [session, setSession] = useState<CourseSession>();
  const [lecturerOptions, setLecturerOptions] = useState<SelectItem[]>([]);
  const [selectedLecturers, setSelectedLecturers] = useState<SelectItem[]>([]);
  const [
    selectedParticipatedSignatures,
    setSelectedParticipatedSignatures
  ] = useState<SelectItem[]>([]);
  const [selectedPassedSignatures, setSelectedPassedSignatures] = useState<
    SelectItem[]
  >([]);

  const [form] = Form.useForm();

  useEffect(() => {
    if (visible) refetchData();
  }, [visible]);

  useEffect(() => {
    const defaultLecturers = session?.courseSessionLecturers?.map(
      (lecturer: CourseSessionLecturer) => ({
        value: lecturer?.courseLecturer?.courseLecturerId,
        label: lecturer?.courseLecturer?.lecturer?.name?.th,
        key: lecturer?.courseLecturer?.courseLecturerId
      })
    ) as SelectItem[];
    setSelectedLecturers(defaultLecturers || []);

    if (session?.participatedCertificate?.signatures) {
      const defaultParticipatedSignature1 =
        session.participatedCertificate.signatures[0];

      const defaultParticipatedSignature2 =
        session.participatedCertificate.signatures[1];

      const defaultParticipatedSignature3 =
        session.participatedCertificate.signatures[2];

      const defaultParticipatedSignatures = [
        defaultParticipatedSignature1,
        defaultParticipatedSignature2,
        defaultParticipatedSignature3
      ];

      const convertedDefaultSignatures = defaultParticipatedSignatures
        .filter(
          (courseSignature): Partial<CourseSignatureDto> => courseSignature
        )
        .map((signature: Partial<CourseSignatureDto>) => ({
          value: signature?.courseSignatureId,
          label: signature?.name?.th,
          key: signature?.courseSignatureId
        })) as SelectItem[];

      setSelectedParticipatedSignatures(convertedDefaultSignatures);
    }

    if (session?.passedCertificate?.signatures) {
      const defaultPassedSignature1 = session.passedCertificate.signatures[0];

      const defaultPassedSignature2 = session.passedCertificate.signatures[1];

      const defaultPassedSignature3 = session.passedCertificate.signatures[2];

      const defaultPassedSignatures = [
        defaultPassedSignature1,
        defaultPassedSignature2,
        defaultPassedSignature3
      ];

      const convertedDefaultSignatures = defaultPassedSignatures
        .filter(
          (courseSignature): Partial<CourseSignatureDto> => courseSignature
        )
        .map((signature: Partial<CourseSignatureDto>) => ({
          value: signature?.courseSignatureId,
          label: signature?.name?.th,
          key: signature?.courseSignatureId
        })) as SelectItem[];
      setSelectedPassedSignatures(convertedDefaultSignatures);
    }
  }, [session]);

  useEffect(() => {
    const selectedLecturerIds = _.values(
      _.mapValues(selectedLecturers, (l: SelectItem) => l?.value)
    );
    const lecturerOptions = lecturers
      ?.filter((lecturer: CourseLecturer) => {
        return !selectedLecturerIds.includes(lecturer?.courseLecturerId as any);
      })
      .map((courseLecturer) => ({
        label: courseLecturer?.lecturer?.name?.th,
        value: courseLecturer?.courseLecturerId
      }));
    setLecturerOptions(lecturerOptions);
  }, [form, lecturers, selectedLecturers]);

  useEffect(() => {
    if (!loadingCourseSection && !loadingSession) {
      const courseSessionSections =
        sessionData?.courseSessionSections?.filter(
          (section: CourseSessionSection) => section?.courseSection
        ) || [];
      const convertedCourseSections = sectionData?.map(
        ({ courseLessons: _courseLessons, ...restProps }: CourseSection) => ({
          courseSection: { ...restProps },
          learningStartDate: null
        })
      ) as CourseSessionSection[];
      const mergedSections =
        _.compact(
          convertedCourseSections?.map(
            (section: CourseSessionSection, index: number) => {
              if (
                _.find(courseSessionSections, {
                  courseSection: {
                    courseSectionId: section?.courseSection?.courseSectionId
                  }
                })
              ) {
                return courseSessionSections[index];
              }
              return section;
            }
          )
        ) || [];
      setSession({
        ...sessionData,
        courseSessionSections: [...mergedSections]
      });
      setSection(sectionData);

      const newLessonWithQuizScheduleOrQuizPercentages = sessionData?.courseSessionLessons?.map(
        (lesson: any) => ({
          id: lesson?.courseLesson?.courseLessonId,
          quizScorePassPercentage: lesson?.quizScorePassPercentage || null,
          quizAnswerSheetSchedule: lesson?.quizAnswerSheetSchedule || null
        })
      );
      setLessonWithQuizScheduleOrQuizPercentages(
        newLessonWithQuizScheduleOrQuizPercentages
      );
    }
  }, [loadingCourseSection, loadingSession]);

  useEffect(() => {
    form.setFieldsValue({
      nameTH: session?.name?.th || null,
      nameEN: session?.name?.en || null,
      instructors:
        selectedLecturers?.length > 0 ? selectedLecturers : [undefined],
      seatLimit: session?.seatLimit,
      registrationDate: [
        moment(session?.registrationStartDate),
        moment(session?.registrationEndDate)
      ],
      sessionDate: [
        moment(session?.sessionStartDate),
        moment(session?.sessionEndDate)
      ],
      condition: session?.learningType,
      type: session?.visibility,
      passedSignature1: selectedPassedSignatures[0],
      passedSignature2: selectedPassedSignatures[1],
      passedSignature3: selectedPassedSignatures[2],
      participatedSignature1: selectedParticipatedSignatures[0],
      participatedSignature2: selectedParticipatedSignatures[1],
      participatedSignature3: selectedParticipatedSignatures[2]
    });
  }, [
    session,
    selectedLecturers,
    form,
    selectedPassedSignatures,
    selectedParticipatedSignatures
  ]);

  const refetchData = async () => {
    await fetchCourseSection();
    await fetchSignature();
  };

  const loading =
    loadingSession ||
    loadingCourseSection ||
    loadingCourseLecturers ||
    loadingCourseSignature;

  const columns = [
    {
      title: 'บทเรียน',
      dataIndex: 'name',
      key: 'name',
      render: (name: any, record: CourseSessionSection, index: any) => {
        return <div>บทที่ {index + 1}</div>;
      }
    },
    {
      title: 'วันเปิดบทเรียน',
      dataIndex: 'learningStartDate',
      key: 'learningStartDate',
      render: (text: any, record: CourseSessionSection) => {
        const indexOfChangedElement = session?.courseSessionSections?.findIndex(
          (courseSection: CourseSessionSection) =>
            courseSection?.courseSection?.courseSectionId ===
            record?.courseSection?.courseSectionId
        ) as number;
        return (
          <DatePicker
            defaultValue={text === null ? undefined : moment(text)}
            onChange={(date: any) => {
              const newCourseSessionSections = session?.courseSessionSections as CourseSessionSection[];
              newCourseSessionSections[
                indexOfChangedElement
              ].learningStartDate = toStartOfDay(date)?.toISOString(true);
              setSession({
                ...session,
                courseSessionSections: [...newCourseSessionSections]
              });
            }}
          />
        );
      }
    },
    {
      title: 'วีดีโอที่สำคัญ',
      dataIndex: ['courseSection', 'courseSectionId'],
      key: 'courseLessoNWithImportantVideo',
      render: (courseSectionId: string) => {
        const renderedSections = sections?.filter((section: any) => {
          return section?.courseSectionId === courseSectionId;
        });

        let renderedLessons;
        if (renderedSections?.length > 0) {
          renderedLessons = renderedSections[0]?.courseLessons?.reduce(
            (total: any, currentLesson: CourseLesson) => {
              if (
                currentLesson?.isImportant &&
                currentLesson?.lessonType === CourseLessonType.YOUTUBE
              ) {
                total.push(...total, currentLesson);
              }
              return total;
            },
            []
          );
        }
        return renderedLessons?.map((lesson: CourseLesson) => {
          return <span>{lesson?.name?.th}</span>;
        });
      }
    },
    {
      title: 'แบบฝึกหัด',
      dataIndex: ['courseSection', 'courseSectionId'],
      key: 'courseLessonsWithImportantExercise',

      render: (courseSectionId: string) => {
        const lessonIds = sections?.reduce(
          (total: any, currentValue: CourseSection) => {
            if (currentValue?.courseSectionId === courseSectionId) {
              const lessonId = currentValue?.courseLessons?.map(
                (courseLesson: CourseLesson) => courseLesson?.courseLessonId
              );

              total.push(...lessonId);
            }
            return total;
          },
          []
        );

        const courseSessionLessons = session?.courseSessionLessons?.reduce(
          (total: any, currentValue: CourseSessionLesson) => {
            if (
              _.includes(
                lessonIds,
                currentValue?.courseLesson?.courseLessonId
              ) &&
              currentValue?.courseLesson?.isImportant &&
              currentValue?.courseLesson?.lessonType === CourseLessonType?.QUIZ
            ) {
              total?.push(currentValue);
            }

            return total;
          },
          []
        );

        const renderingExerciseLessons = courseSessionLessons?.map(
          (courseLesson: CourseSessionLesson, index: number) => {
            const indexOfChangedElement = lessonWithQuizScheduleOrQuizPercentages?.findIndex(
              (lessonInformation: CourseSessionLessonIdWithQuiz) => {
                return (
                  lessonInformation?.id ===
                  courseLesson?.courseLesson?.courseLessonId
                );
              }
            );
            return (
              <div className="flex justify-start mt-1">
                <Checkbox
                  defaultChecked={importantExerciseIdInTables?.includes(
                    courseLesson?.courseLesson?.courseLessonId as string
                  )}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setImportantExerciseIdInTables([
                        ...importantExerciseIdInTables,
                        courseLesson?.courseLesson?.courseLessonId as string
                      ]);
                    } else {
                      setImportantExerciseIdInTables(
                        importantExerciseIdInTables.filter(
                          (ids: string) =>
                            ids !== courseLesson?.courseLesson?.courseLessonId
                        )
                      );
                      const newLessonWithQuizScheduleOrQuizPercentages = lessonWithQuizScheduleOrQuizPercentages;
                      newLessonWithQuizScheduleOrQuizPercentages[
                        indexOfChangedElement
                      ].quizScorePassPercentage = null;
                      setLessonWithQuizScheduleOrQuizPercentages(
                        newLessonWithQuizScheduleOrQuizPercentages
                      );
                    }
                  }}
                >
                  แบบฝึกหัดที่ {index + 1} ได้คะแนนรวมมากกว่าที่กำหนด
                </Checkbox>

                <Input
                  className="w-12 ml-2"
                  defaultValue={lessonWithQuizScheduleOrQuizPercentages[
                    indexOfChangedElement
                  ].quizScorePassPercentage?.toString()}
                  disabled={
                    !importantExerciseIdInTables?.includes(
                      courseLesson?.courseLesson?.courseLessonId as string
                    )
                  }
                  onChange={(e) => {
                    if (indexOfChangedElement !== -1) {
                      const newLessonWithQuizScheduleOrQuizPercentages = lessonWithQuizScheduleOrQuizPercentages;
                      newLessonWithQuizScheduleOrQuizPercentages[
                        indexOfChangedElement
                      ].quizScorePassPercentage = parseInt(e.target.value);
                      setLessonWithQuizScheduleOrQuizPercentages(
                        newLessonWithQuizScheduleOrQuizPercentages
                      );
                    }
                  }}
                />
                <span className="ml-2">เปอร์เซ็นต์</span>
              </div>
            );
          }
        );
        return renderingExerciseLessons;
      }
    }
  ];

  const renderSectionCondition = () => {
    return (
      <div>
        <div className="font-bold mb-4">เงื่อนไขการผ่านบทเรียน</div>
        <Row>
          <Col span={12} className="pr-16">
            <Form.Item
              label="เปอร์เซ็นต์การดูวีดีโอ"
              name="percentOfWatchingVideo"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 14 }}
            >
              <Input
                defaultValue={session?.videoViewingPassPercentage || undefined}
                onChange={(e) => {
                  setSession({
                    ...session,
                    videoViewingPassPercentage: parseInt(e.target.value)
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Table
          dataSource={session?.courseSessionSections as CourseSessionSection[]}
          columns={columns}
        />
      </div>
    );
  };

  const onFinish = async (formValue: any) => {
    setSaving(true);

    const courseLecturers = session?.courseSessionLecturers?.map(
      (courseLecturer: CourseSessionLecturer, index: number) => ({
        ...courseLecturer,
        position: index + 1
      })
    );

    const courseSessionSectionIds = session?.courseSessionSections?.map(
      (section: CourseSessionSection) => ({
        id: section?.courseSection?.courseSectionId,
        learningStartDate: section?.learningStartDate
      })
    );

    const courseSections = sections?.map((section: CourseSection) => {
      const courseSessionSection = _.find(
        courseSessionSectionIds,
        (o) => o.id === section?.courseSectionId
      );
      if (courseSessionSection) {
        return {
          courseSection: {
            courseSectionId: section?.courseSectionId
          },
          learningStartDate: courseSessionSection?.learningStartDate
        };
      } else
        return {
          courseSection: {
            courseSectionId: section?.courseSectionId
          },
          learningStartDate: null
        };
    });

    const allLessons = sections.map((s) => s.courseLessons).flat();
    const courseLessons = allLessons.map((lesson) => {
      const lessonId = lesson.courseLessonId;
      const lessonWithConfig = lessonWithQuizScheduleOrQuizPercentages.find(
        (lesson) => lesson.id === lessonId
      );
      if (lessonWithConfig) {
        return {
          courseLesson: {
            courseLessonId: lessonWithConfig?.id
          },
          quizAnswerSheetSchedule:
            lessonWithConfig?.quizAnswerSheetSchedule || null,

          quizScorePassPercentage:
            lessonWithConfig?.quizScorePassPercentage || null
        };
      }
      return {
        courseLesson: {
          courseLessonId: lesson.courseLessonId
        },
        quizAnswerSheetSchedule: null,
        quizScorePassPercentage: null
      };
    });

    const participatedCertificateCourseSignatures = session?.participatedCertificate?.signatures?.map(
      (signature: Partial<CourseSignatureDto>) => ({
        courseSignatureId: signature?.courseSignatureId
      })
    );
    const passedCertificateCourseSignatures = session?.passedCertificate?.signatures?.map(
      (signature: Partial<CourseSignatureDto>) => ({
        courseSignatureId: signature?.courseSignatureId
      })
    );

    let sessionData = {
      ...session,
      name: { th: formValue.nameTH, en: formValue?.nameEN || '' },
      seatLimit: Number(formValue?.seatLimit) || 1,
      registrationStartDate: toStartOfDay(
        formValue?.registrationDate[0]
      )?.toISOString(true),
      registrationEndDate: toEndOfDay(
        formValue?.registrationDate[1]
      )?.toISOString(true),
      sessionStartDate: toStartOfDay(formValue?.sessionDate[0])?.toISOString(
        true
      ),
      sessionEndDate: toEndOfDay(formValue?.sessionDate[1])?.toISOString(true),
      visibility: formValue?.type?.toLowerCase(),
      learningType: formValue?.condition?.toLowerCase(),
      courseSessionLecturers: courseLecturers || [],
      courseSessionLessons: courseLessons,
      courseSessionSections: courseSections,
      participatedCertificate: {
        ...session?.participatedCertificate,
        videoViewingPassPercentage:
          session?.participatedCertificate?.videoViewingPassPercentage || 0,
        quizScorePassPercentage:
          session?.participatedCertificate?.quizScorePassPercentage || 0,
        signatures: participatedCertificateCourseSignatures
      },
      passedCertificate: {
        ...session?.passedCertificate,
        videoViewingPassPercentage:
          session?.passedCertificate?.videoViewingPassPercentage || 0,
        quizScorePassPercentage:
          session?.passedCertificate?.quizScorePassPercentage || 0,
        signatures: passedCertificateCourseSignatures
      }
    };

    const loading = message.loading('กำลังดำเนินการ...', 0);
    try {
      await Axios.put(
        '/course-sessions/' + session?.courseSessionId,
        sessionData
      );
      loading();
      message.success('บันทึกสำเร็จ');
      await refetch();
      await refetchSession();
      setVisible(false);
    } catch (error) {
      loading();
      message.error('บันทึกไม่สำเร็จ');
      console.error(error);
    } finally {
      setSaving(false);
    }
  };

  if (loading) return <></>;

  return (
    <Modal
      title="แก้ไขรอบ"
      visible={visible}
      footer={null}
      maskClosable={false}
      className="w-11/12"
      onCancel={() => {
        setVisible(false);
        form.resetFields();
      }}
    >
      <Spin spinning={loading || saving}>
        <Global
          styles={css`
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
            .ant-modal {
              top: 7rem;
            }
            .ant-modal-content {
              padding: 1rem;
            }
          `}
        />
        <Form form={form} onFinish={onFinish}>
          <div className="font-bold mb-4">ข้อมูลพื้นฐาน</div>
          <Row>
            <Col span={12} className="pr-16">
              <Form.Item
                label="ชื่อรอบอบรม(TH)"
                name="nameTH"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 14 }}
                rules={[getRule(FormRule.REQUIRE, 'โปรดกรอกชื่อรอบอบรม')]}
              >
                <Input className="" placeholder="ชื่อรอบอบรมภาษาไทย" />
              </Form.Item>
              <Form.Item
                label="ชื่อรอบอบรม(EN)"
                name="nameEN"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 14 }}
              >
                <Input className="" placeholder="ชื่อรอบอบรมภาษาอังกฤษ" />
              </Form.Item>
              <Form.Item
                label="จำนวนผู้เข้าเรียนต่อรอบ"
                name="seatLimit"
                rules={[getRule(FormRule.REQUIRE)]}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 14 }}
              >
                <Input
                  min={1}
                  type="number"
                  placeholder="100"
                  addonAfter="คน"
                />
              </Form.Item>
              <Form.Item
                label="รอบลงทะเบียน"
                name="registrationDate"
                rules={[getRule(FormRule.REQUIRE, 'กรุณาระบุรอบลงทะเบียน')]}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 14 }}
                normalize={(value) => {
                  if (!value) return [];
                  return [value[0], value[1]];
                }}
              >
                <RangePicker />
              </Form.Item>
              <Form.Item
                label="ระยะเวลาอบรม"
                name="sessionDate"
                rules={[getRule(FormRule.REQUIRE, 'กรุณาระบุระยะเวลาอบรม')]}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 14 }}
                normalize={(value) => {
                  if (!value) return [];
                  return [value[0], value[1]];
                }}
              >
                <RangePicker />
              </Form.Item>
            </Col>
            <Col span={12} className="pr-14">
              <Form.Item
                label="ประเภทอบรม"
                name="type"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 14 }}
              >
                <Select
                  placeholder="Public/Special"
                  defaultValue={session?.visibility}
                >
                  <Select.Option value="public">Public</Select.Option>
                  <Select.Option value="special">Special</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="รูปแบบการดูเนื้อหา"
                name="condition"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 14 }}
              >
                <Select
                  placeholder="เรียงตามลำดับ/เลือกดูเนื้อหาได้ตามอิสระ"
                  onSelect={(value) => {
                    setSession({
                      ...session,
                      learningType: value as SessionCondition
                    });
                  }}
                  defaultValue={session?.learningType}
                >
                  <Select.Option value="sequential">
                    เรียงตามลำดับ
                  </Select.Option>
                  <Select.Option value="parallel">
                    เลือกดูเนื้อหาได้แบบอิสระ
                  </Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="สถานะ"
                name="status"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 14 }}
              >
                <Switch
                  defaultChecked={session?.status === 'active'}
                  onClick={(check) => {
                    let status;
                    if (check) {
                      status = 'active';
                    } else {
                      status = 'inactive';
                    }
                    setSession({ ...session, status: status });
                  }}
                />
                <span className="ml-2">
                  {session?.status === 'active' ? 'Active' : ''}
                </span>
              </Form.Item>
            </Col>
          </Row>
          <div className="font-bold mb-4">ข้อมูลวิทยากร</div>
          <Row>
            <Col span={12} className="pr-16">
              <Form.List name="instructors">
                {(fields, { add, remove }) => {
                  return (
                    <div>
                      {fields.map((field, index) => (
                        <Form.Item
                          {...field}
                          labelCol={{ span: 8 }}
                          wrapperCol={{ span: 14 }}
                          label={`วิทยากรคนที่ ${index + 1}`}
                          key={field.key}
                        >
                          <div className="flex justify-center">
                            <Form.Item
                              {...field}
                              label={`วิทยากรคนที่ ${index}`}
                              validateTrigger={['onChange', 'onBlur']}
                              rules={
                                index > 0
                                  ? [
                                      getRule(
                                        FormRule.REQUIRE,
                                        'โปรดเลือกวิทยากร'
                                      )
                                    ]
                                  : []
                              }
                              noStyle
                            >
                              <Select
                                showSearch
                                labelInValue
                                placeholder="เลือกวิทยากร"
                                style={{ width: '100%' }}
                                onSelect={(obj: any) => {
                                  const convertedObj = {
                                    courseLecturer: {
                                      courseLecturerId: obj.value,
                                      lecturer: {
                                        name: { th: obj.label, en: '' }
                                      }
                                    }
                                  };

                                  const currentLecturers =
                                    session?.courseSessionLecturers || [];
                                  if (index > currentLecturers.length - 1) {
                                    setSession({
                                      ...session,
                                      courseSessionLecturers: [
                                        ...currentLecturers,
                                        convertedObj
                                      ]
                                    });
                                  } else {
                                    currentLecturers[index] = convertedObj;
                                    setSession({
                                      ...session,
                                      courseSessionLecturers: currentLecturers
                                    });
                                  }
                                }}
                              >
                                {lecturerOptions.map((obj: any) => (
                                  <Option value={obj.value}>{obj.label}</Option>
                                ))}
                              </Select>
                            </Form.Item>
                            <div className="flex items-center anticon anticon-minus-circle dynamic-delete-button">
                              {fields.length > 1 ? (
                                <DeleteIcon
                                  className="dynamic-delete-button cursor-pointer"
                                  style={{ margin: '0 8px' }}
                                  onClick={() => {
                                    remove(field.name);
                                    const newLecturers = session?.courseSessionLecturers?.filter(
                                      (
                                        lecturer: Partial<CourseSessionLecturer>,
                                        index: number
                                      ) => index !== field.name
                                    );
                                    setSession({
                                      ...session,
                                      courseSessionLecturers: newLecturers
                                    });
                                  }}
                                />
                              ) : null}
                            </div>
                          </div>
                        </Form.Item>
                      ))}
                      <Row>
                        <Col span={8} />
                        <Col span={14}>
                          <Button
                            onClick={() => {
                              add();
                            }}
                          >
                            <PlusOutlined /> เพิ่มวิทยากร
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  );
                }}
              </Form.List>
            </Col>
          </Row>
          {session?.learningType === 'sequential' && renderSectionCondition()}

          <CourseSessionSolutionReviewDate
            sections={sections}
            lessonWithQuizScheduleOrQuizPercentages={
              lessonWithQuizScheduleOrQuizPercentages
            }
            setLessonWithQuizScheduleOrQuizPercentages={
              setLessonWithQuizScheduleOrQuizPercentages
            }
            mode={Mode.Edit}
          />

          <div className="font-bold mb-4">จัดการใบประกาศสำหรับรอบอบรม</div>
          <Row gutter={[8, 8]}>
            <Col span={12}>
              <Switch
                defaultChecked={session?.participatedCertificate?.enabled}
                size="small"
                onClick={(check) => {
                  setSession({
                    ...session,
                    participatedCertificate: {
                      ...session?.participatedCertificate,
                      enabled: check
                    }
                  });
                }}
              />
              <span className="ml-4  text-mono-gray-400">
                ประกาศนียบัตรสำหรับผู้เข้าร่วมการอบรม
              </span>

              {session?.participatedCertificate?.enabled && (
                <CourseSessionCertificateCondition
                  type="participatedCertificate"
                  certificate={sessionData.participatedCertificate}
                  courseId={sessionData?.course?.courseId}
                  session={session}
                  prefix={CertificateCondition.PARTICIPATED}
                  signatures={signatures}
                  signatureValidator={
                    session.participatedCertificate.signatures &&
                    session.participatedCertificate.signatures[0]
                  }
                  selectedSignatures={selectedParticipatedSignatures}
                  defaultVideoViewingPassPercentage={
                    session?.participatedCertificate?.videoViewingPassPercentage
                  }
                  defaultQuizScorePassPercentage={
                    session?.participatedCertificate?.quizScorePassPercentage
                  }
                  setVideoViewingPassPercentage={(e: any) =>
                    setSession({
                      ...session,
                      participatedCertificate: {
                        ...session.participatedCertificate,
                        videoViewingPassPercentage: parseInt(
                          e.target.value
                        ) as number
                      }
                    })
                  }
                  setQuizScorePassPercentage={(e: any) =>
                    setSession({
                      ...session,
                      participatedCertificate: {
                        ...session.participatedCertificate,
                        quizScorePassPercentage: parseInt(
                          e.target.value
                        ) as number
                      }
                    })
                  }
                  setCourseSignatureId={({ obj, index, isRemove }: any) => {
                    if (isRemove) {
                      const newSignatures = (session?.participatedCertificate
                        ?.signatures as Partial<CourseSignatureDto>[]).filter(
                        (signature: Partial<CourseSignatureDto>, i: number) =>
                          i !== index
                      );
                      setSession({
                        ...session,
                        participatedCertificate: {
                          ...session?.participatedCertificate,
                          signatures: newSignatures
                        }
                      });
                    } else {
                      const convertedObj = {
                        courseSignatureId: obj.value,
                        name: { th: obj.label, en: '' }
                      };

                      const currentSignatures = session?.participatedCertificate
                        ?.signatures as Partial<CourseSignatureDto>[];

                      if (index > currentSignatures.length - 1) {
                        setSession({
                          ...session,
                          participatedCertificate: {
                            ...session?.participatedCertificate,
                            signatures: [
                              ...(session?.participatedCertificate
                                ?.signatures as Partial<CourseSignatureDto>[]),
                              convertedObj
                            ] as Partial<CourseSignatureDto>[]
                          }
                        });
                      } else {
                        const newSignatures = session?.participatedCertificate
                          ?.signatures as Partial<CourseSignatureDto>[];
                        newSignatures[index] = convertedObj;
                        setSession({
                          ...session,
                          participatedCertificate: {
                            ...session?.participatedCertificate,
                            signatures: newSignatures
                          }
                        });
                      }
                    }
                  }}
                />
              )}
              <Col></Col>

              <Switch
                defaultChecked={session?.passedCertificate?.enabled}
                size="small"
                onClick={(check) => {
                  setSession({
                    ...session,
                    passedCertificate: {
                      ...session?.passedCertificate,
                      enabled: check
                    }
                  });
                }}
              />
              <span className="ml-4 text-mono-gray-400">
                ประกาศนียบัตรสำหรับผู้ผ่านการอบรม
              </span>
              {session?.passedCertificate?.enabled && (
                <CourseSessionCertificateCondition
                  type="passedCertificate"
                  courseId={sessionData?.course?.courseId}
                  session={session}
                  prefix={CertificateCondition.PASSED}
                  signatures={signatures}
                  signatureValidator={
                    session.passedCertificate.signatures &&
                    session.passedCertificate.signatures[0]
                  }
                  selectedSignatures={selectedPassedSignatures}
                  defaultVideoViewingPassPercentage={
                    session?.passedCertificate?.videoViewingPassPercentage
                  }
                  defaultQuizScorePassPercentage={
                    session?.passedCertificate?.quizScorePassPercentage
                  }
                  setVideoViewingPassPercentage={(e: any) =>
                    setSession({
                      ...session,
                      passedCertificate: {
                        ...session.passedCertificate,
                        videoViewingPassPercentage: parseInt(
                          e.target.value
                        ) as number
                      }
                    })
                  }
                  setQuizScorePassPercentage={(e: any) =>
                    setSession({
                      ...session,
                      passedCertificate: {
                        ...session.passedCertificate,
                        quizScorePassPercentage: parseInt(
                          e.target.value
                        ) as number
                      }
                    })
                  }
                  setCourseSignatureId={({ obj, index, isRemove }: any) => {
                    if (isRemove) {
                      const newSignatures = (session?.passedCertificate
                        ?.signatures as Partial<CourseSignatureDto>[]).filter(
                        (signature: Partial<CourseSignatureDto>, i: number) =>
                          i !== index
                      );
                      setSession({
                        ...session,
                        passedCertificate: {
                          ...session?.passedCertificate,
                          signatures: newSignatures
                        }
                      });
                    } else {
                      const convertedObj = {
                        courseSignatureId: obj.value,
                        name: { th: obj.label, en: '' }
                      };

                      const currentSignatures = session?.passedCertificate
                        ?.signatures as Partial<CourseSignatureDto>[];

                      if (index > currentSignatures.length - 1) {
                        setSession({
                          ...session,
                          passedCertificate: {
                            ...session?.passedCertificate,
                            signatures: [
                              ...(session?.passedCertificate
                                ?.signatures as Partial<CourseSignatureDto>[]),
                              convertedObj
                            ] as Partial<CourseSignatureDto>[]
                          }
                        });
                      } else {
                        const newSignatures = session?.passedCertificate
                          ?.signatures as Partial<CourseSignatureDto>[];
                        newSignatures[index] = convertedObj;
                        setSession({
                          ...session,
                          passedCertificate: {
                            ...session?.passedCertificate,
                            signatures: newSignatures
                          }
                        });
                      }
                    }
                  }}
                />
              )}
              {(session?.participatedCertificate?.enabled ||
                session?.passedCertificate?.enabled) && (
                <CourseSessionGenerateDateCertificate
                  session={session}
                  setSession={setSession}
                />
              )}
            </Col>
          </Row>
          <div className="flex justify-end items-center">
            <TextButton
              onClick={() => {
                setVisible(false);
              }}
            >
              ยกเลิก
            </TextButton>
            <PrimaryButton htmlType="submit">บันทึก</PrimaryButton>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};
