/** @jsx jsx */
import { Button, Modal, Spin, Table, Typography, message } from 'antd';
import { CourseLesson, CourseLessonType } from 'feature/course/course.type';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Fragment, useEffect, useState } from 'react';
import { Global, css, jsx } from '@emotion/react';

import { AlertModal } from 'feature/common/AlertModal';
import Axios from 'axios';
import { ReactComponent as DeleteIcon } from 'assets/delete.svg';
import { ReactComponent as DragIcon } from 'assets/drag.svg';
import { ReactComponent as EditIcon } from 'assets/edit.svg';
import { ReactComponent as FlagIcon } from 'assets/flag.svg';
import { ReactComponent as FormEditIcon } from 'assets/form-edit.svg';
import { ManageLessonModal } from 'feature/course/lecture/ManageLessonModal';
import { PlusOutlined } from '@ant-design/icons';
import { ReactComponent as PreviewIcon } from 'assets/preview.svg';
import { useReorderLessons } from 'hooks/course/useReorderLessons';

type ManageLessonListModalProps = {
  courseSectionId: string;
  visible: boolean;
  lessons: CourseLesson[];
  setUpdatedSectionId: (section: string) => void;
  refetch: () => {};
  onClose: () => void;
};

const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  // styles we need to apply on draggables
  ...draggableStyle
});

export const ManageLessonListModal = (props: ManageLessonListModalProps) => {
  const {
    courseSectionId,
    visible,
    setUpdatedSectionId,
    refetch,
    onClose
  } = props;

  const { execute: reorderLessons } = useReorderLessons();
  const [deletingItemId, setDeletingItemId] = useState<any>(null);

  const [
    visibleManageLessonModal,
    setVisibleManageLessonModal
  ] = useState<boolean>(false);
  const [lessons, setLessons] = useState<CourseLesson[]>(props.lessons);
  const [isDraggable, setIsDraggable] = useState<boolean>(false);
  const [defaultLessons, setDefaultLessons] = useState<CourseLesson[]>([]);
  const [reordering, setReordering] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [selectedLesson, setSelectedLesson] = useState<CourseLesson>();
  const [deleting, setDeleting] = useState<boolean>(false);
  const [updatedLessonId, setUpdatedLessonId] = useState<string>();
  const columns = [
    {
      title: '',
      dataIndex: 'sort',
      width: 30,
      onCell: () => {
        return { style: { minWidth: 30 } };
      },
      render: () =>
        isDraggable && (
          <DragIcon
            css={css`
              path {
                fill: #acb5bd;
              }
            `}
          />
        )
    },
    {
      title: 'เนื้อหา',
      dataIndex: ['name', 'th'],
      key: 'nameTH',
      width: 600,
      onCell: () => {
        return { style: { minWidth: 600 } };
      }
    },
    {
      title: 'ประเภท',
      dataIndex: 'lessonType',
      key: 'lessonType',
      width: 120,
      onCell: () => {
        return { style: { minWidth: 120 } };
      },
      render: (type: CourseLessonType) => {
        switch (type) {
          case CourseLessonType.BYTEARK:
            return <Typography.Text>ByteArk</Typography.Text>;
          case CourseLessonType.YOUTUBE:
            return <Typography.Text>Youtube</Typography.Text>;
          case CourseLessonType.FILE:
            return <Typography.Text>ไฟล์</Typography.Text>;
          case CourseLessonType.QUIZ:
            return <Typography.Text>แบบฝึกหัด</Typography.Text>;
        }
      }
    },
    // {
    //   title: 'ขนาด',
    //   dataIndex: 'size',
    //   key: 'size',
    //   width: 130,
    //   onCell: () => {
    //     return { style: { minWidth: 130 } };
    //   },
    //   render: (text: string) => (
    //     <Typography.Text className="flex w-full">{text || '-'}</Typography.Text>
    //   )
    // },
    {
      title: 'Preview',
      dataIndex: 'isPreview',
      key: 'isPreview',
      align: 'center' as any,
      width: 150,
      onCell: () => {
        return { style: { minWidth: 150 } };
      },
      render: (isPreview: boolean) => (
        <Typography.Text className="flex justify-center w-full">
          {isPreview ? (
            <PreviewIcon
              css={css`
                color: #f2994a;
              `}
            />
          ) : (
            '-'
          )}
        </Typography.Text>
      )
    },
    {
      title: 'สำคัญ',
      dataIndex: 'isImportant',
      key: 'isImportant',
      align: 'center' as any,
      width: 150,
      onCell: () => {
        return { style: { minWidth: 150 } };
      },
      render: (isImportant: string) => (
        <Typography.Text className="flex justify-center w-full">
          {isImportant ? (
            <FlagIcon
              css={css`
                color: #f2994a;
              `}
            />
          ) : (
            '-'
          )}
        </Typography.Text>
      )
    },
    // {
    //   title: 'สิทธิการเข้าถึง',
    //   dataIndex: 'canDownload',
    //   key: 'canDownload',
    //   align: 'center' as any,
    //   width: 120,
    //   render: (canDownload: string) => (
    //     <Typography.Text>{canDownload ? 'Download' : '-'}</Typography.Text>
    //   )
    // },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 130,
      onCell: () => {
        return { style: { minWidth: 130 } };
      },
      render: (text: string, record: CourseLesson) => {
        return (
          !isDraggable && (
            <div className="flex justify-center items-center">
              <FormEditIcon
                className="mr-3 cursor-pointer"
                css={css`
                  color: #1890ff;
                `}
                onClick={() => {
                  setIsEdit(true);
                  setSelectedLesson(record);
                  setVisibleManageLessonModal(true);
                }}
              />

              <DeleteIcon
                className="cursor-pointer"
                css={css`
                  color: #f03d3e;
                `}
                onClick={() => setDeletingItemId(record.courseLessonId)}
              />
            </div>
          )
        );
      }
    }
  ];

  useEffect(() => {
    if (props.lessons) {
      setLessons(props.lessons);
    }
  }, [props.lessons]);

  const handleDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newItems = reorder(
      lessons,
      result.source.index,
      result.destination.index
    );

    setLessons(newItems);
  };

  const handleDeleteCourseLesson = async (courseLessonId: string) => {
    setDeleting(true);
    const loading = message.loading('กำลังดำเนินการ...', 0);
    try {
      await Axios.delete(`/course-lessons/${courseLessonId}`);
      await refetch();
      loading();
      message.success('ลบเนื้อหาสำเร็จ');
      setUpdatedLessonId('');
    } catch (error) {
      loading();
      message.error('ลบเนื้อหาไม่สำเร็จ');
      console.error(error);
    } finally {
      setDeleting(false);
    }
  };

  const handleReorder = async () => {
    setReordering(true);
    const loading = message.loading('กำลังดำเนินการ', 0);
    try {
      const data = lessons.map((lesson: CourseLesson, index: number) => ({
        courseLessonId: lesson?.courseLessonId,
        position: index
      }));

      await reorderLessons({ data });
      await refetch();
      loading();
      message.success('บันทึกสำเร็จ');
      setIsDraggable(!isDraggable);
    } catch (error) {
      loading();
      message.error('บันทึกไม่สำเร็จ');
      console.error(error);
    } finally {
      setReordering(false);
    }
  };

  const DraggableBodyRow = (props: any) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = lessons.findIndex(
      (x: CourseLesson) => x?.courseLessonId === props['data-row-key']
    );
    const item: CourseLesson = lessons[index];

    return (
      <Draggable
        key={item?.courseLessonId}
        draggableId={item?.courseLessonId}
        index={index}
        isDragDisabled={!isDraggable}
      >
        {(provided, snapshot) => (
          <tr
            {...props}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getItemStyle(
              snapshot.isDragging,
              provided.draggableProps.style
            )}
            css={css`
              background-color: #ffffff;
              box-shadow: ${snapshot.isDragging
                ? '0 10px 30px -5px rgba(0,0,0,.3)'
                : ''};
              font-weight: ${updatedLessonId === item?.courseLessonId
                ? 'bold'
                : 'normal'};
            `}
          />
        )}
      </Draggable>
    );
  };

  const renderToolbarButton = () => {
    if (isDraggable) {
      return (
        <Fragment>
          <Button
            className="px-4 mr-2"
            loading={reordering}
            onClick={() => {
              setIsDraggable(!isDraggable);
              setLessons(defaultLessons);
            }}
          >
            ยกเลิก
          </Button>
          <Button
            className="px-5"
            loading={reordering}
            type="primary"
            onClick={handleReorder}
          >
            เสร็จสิ้น
          </Button>
        </Fragment>
      );
    }
    return (
      <Fragment>
        <Button
          className="flex items-center mr-2"
          disabled={lessons?.length === 0}
          css={css`
            svg {
              path {
                transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
              }
            }
            &:focus,
            &:hover {
              svg {
                path {
                  fill: #1890ff;
                }
              }
            }
          `}
          onClick={() => {
            setIsDraggable(!isDraggable);
            setDefaultLessons(lessons);
          }}
        >
          <EditIcon
            className="relative w-4 h-4"
            css={css`
              margin-right: 0.5rem;
              bottom: 1px;
            `}
          />
          จัดเรียง
        </Button>
        <Button
          className="flex items-center"
          onClick={() => {
            setVisibleManageLessonModal(true);
          }}
        >
          <PlusOutlined className="relative w-4 h-4" />
          เพิ่มเนื้อหา
        </Button>
      </Fragment>
    );
  };

  return (
    <Spin spinning={deleting}>
      <Global
        styles={css`
          .ant-modal-wrap {
            top: -85px;
          }
          .ant-modal-body {
            padding: 24px 2.5rem;
          }
          body {
            overflow-y: ${visible ? 'hidden' : 'auto'} !important;
          }
        `}
      />
      <Modal
        title="จัดการเนื้อหาในบทเรียน"
        visible={visible}
        maskClosable={false}
        onCancel={onClose}
        afterClose={() => {
          setIsDraggable(false);
        }}
        footer={null}
        css={css`
          width: 1390px !important;
          padding-bottom: 0;
          .ant-modal-title {
            font-weight: bold;
          }
          .ant-modal-body {
            min-height: 794px;
          }
        `}
      >
        <div className="flex justify-between">
          <div
            className="text-base font-bold mb-4"
            css={css`
              color: #595959;
            `}
          >
            เนื้อหาทั้งหมด
          </div>
          <div className="flex">{renderToolbarButton()}</div>
        </div>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable
            droppableId="droppable"
            css={css`
              width: 100%;
            `}
          >
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                <Table
                  className="mb-4"
                  loading={reordering}
                  pagination={false}
                  dataSource={lessons}
                  columns={columns}
                  rowKey="courseLessonId"
                  size="small"
                  locale={{ emptyText: 'ไม่มีเนื้อหา กรุณาเพิ่มเนื้อหา' }}
                  components={{
                    body: {
                      row: DraggableBodyRow
                    }
                  }}
                  css={css`
                    .ant-table-thead > tr > th {
                      font-size: 0.875rem;
                      font-weight: normal;
                      color: #999999;
                    }
                    .ant-table-tbody > .ant-table-placeholder > td {
                      border-bottom: 1px solid #f0f0f0;
                      padding: 1rem 0 !important;
                    }
                    .ant-table-tbody > tr > td {
                      border: none;
                    }
                  `}
                />
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Modal>
      <ManageLessonModal
        isEdit={isEdit}
        visible={visibleManageLessonModal}
        lesson={selectedLesson}
        courseSectionId={courseSectionId}
        refetch={refetch}
        setUpdatedLessonId={(lessonId: string) => {
          setUpdatedLessonId(lessonId);
          setUpdatedSectionId('');
        }}
        setSelectedLesson={setSelectedLesson}
        onClose={() => {
          setIsEdit(false);
          setVisibleManageLessonModal(false);
        }}
      />
      <AlertModal
        subTitle="เนื้อหา มีการเชื่อมกับเงื่อนไขการผ่านบทเรียน, และเงื่อนไขการได้รับใบประกาศ"
        visible={!!deletingItemId}
        onOk={async () => {
          try {
            await handleDeleteCourseLesson(deletingItemId);
            setDeletingItemId(null);
          } catch (error) {}
        }}
        onCancel={() => {
          setDeletingItemId(null);
        }}
      />
    </Spin>
  );
};
