import { School } from './../coupon/school.type';

export interface SchoolRequestList {
  schoolRequestId: string;
  school: School;
  productPrivilegedType: ProductPrivilegedType;
  status: SchoolRequestStatus;
}

export enum ProductPrivilegedType {
  BASIC = 'basic',
  PREMIUM = 'premium'
}
export enum SchoolRequestStatus {
  ALL = 'all',
  CREATED = 'created',
  PENDING = 'pending',
  REJECTED = 'rejected',
  ACCEPTED = 'accepted'
}
export enum RequestType {
  SCHOOL_ADMIN = 'school_admin',
  SALE = 'sales'
}

export interface SchoolRequestOrder {
  orderId: string;
}

export interface SchoolRequest extends SchoolRequestList {
  address: string;
  requestType: RequestType;
  requestDocuments: string[];
  salesOrderDocuments: string[];
  identityDocuments: string[];
  signatureDocuments: string[];
  requestor: SchoolRequestRequestor;
  additionalRequestors: SchoolRequestRequestor[] | null;
  superAdmins: SchoolRequestContactPerson[];
  contactPerson: SchoolRequestContactPerson;
  rejectReason: string;
  rejectedAt: Date;
  pendingAt: Date;
  acceptedAt: Date;
  createdAt: Date;
  updatedAt: Date;
  order: SchoolRequestOrder;
}

interface SchoolRequestContactPerson {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
}

interface SchoolRequestRequestor extends SchoolRequestContactPerson {
  position: string;
}
