import { Col, Row, Tabs, message } from 'antd';
import React, { useState } from 'react';

import Axios from 'axios';
import { CouponDetailForm } from 'feature/coupon/CouponDetailForm';
import { CouponSuccessModal } from 'feature/coupon/CouponSuccessModal';
import { PageContainer } from 'feature/common/PageContainer';
import { RightOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';
import { useHistory } from 'react-router-dom';

const { TabPane } = Tabs;
export const CouponCreatePage = () => {
  const history = useHistory();
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [couponId, setCouponId] = useState<string>('');

  const handleSubmit = async (values: any) => {
    try {
      const res = await Axios.post('/coupons', {
        name: {
          th: values['name.th'],
          en: values['name.en']
        },
        couponLevel: values?.couponLevel,
        quotaLimit: +values?.quotaLimit,
        couponStartDate: values?.duration[0],
        couponExpiryDate: values?.duration[1]
      });
      setSuccessModalVisible(true);
      setCouponId(res.data.data.couponId);
    } catch {
      message.error('ทำรายการไม่สำเร็จ');
    }
  };
  return (
    <PageContainer className="p-8">
      <Row justify="space-between" align="middle" className="mb-4">
        <Col>
          <div className="flex items-center">
            <h1
              className="text-xl font-bold text-mono-gray-300 cursor-pointer"
              onClick={() => history.push('/coupon/manage')}
            >
              จัดการคูปอง
            </h1>
            <RightOutlined className="mx-2 text-mono-gray-300 text-xs" />
            <h1 className="text-xl font-bold ">เพิ่มคูปอง</h1>
          </div>
        </Col>
      </Row>
      <Tabs defaultActiveKey="1">
        <TabPane
          tab="รายละเอียด"
          key="1"
          css={css`
            height: calc(100vh - 180px);
            overflow: auto;
          `}
          style={{ height: 'calc(100vh - 180px)', overflow: 'auto' }}
        >
          <CouponDetailForm handleSubmit={handleSubmit} mode="create" />
        </TabPane>
        <TabPane tab="คอร์สที่จะได้รับ" key="2" disabled></TabPane>
        <TabPane tab="โรงเรียน" key="3" disabled></TabPane>
      </Tabs>
      <CouponSuccessModal visible={successModalVisible} couponId={couponId} />
    </PageContainer>
  );
};
