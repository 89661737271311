export enum LANG {
  TH = 'th',
  EN = 'en'
}

export type Localization = {
  th?: string;
  en?: string;
};
export type LocalizationType<T> = {
  th?: T;
  en?: T;
};

export enum DateFormat {
  DEFAULT = 'DD/MM/YY'
}
