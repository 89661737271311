import { Col, Divider, Row, Select, Spin, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { CourseReportDetailCouponList } from 'feature/course-report/CourseReportDetailCouponList';
import { CourseReportDetailNameList } from 'feature/course-report/CourseReportDetailNameList';
import { CourseReportDetailSessionInfo } from 'feature/course-report/CourseReportDetailSessionInfo';
import { CourseSession } from 'feature/course/session/CourseSessionType';
import { DetailListItem } from 'feature/common/DetailListItem';
import { PageContainer } from 'feature/common/PageContainer';
import { RightOutlined } from '@ant-design/icons';
import _ from 'lodash';
import queryString from 'query-string';
import { useCourse } from 'hooks/course/useCourse';
import { useSessions } from 'hooks/session/useSessions';

const { TabPane } = Tabs;
const { Option } = Select;

export const CourseReportDetailPage = () => {
  const history = useHistory();
  const location = useLocation();
  const { courseId, tab } = queryString.parse(location.search);
  const { data: course } = useCourse(courseId as string);
  const { data: sessions, loading } = useSessions(courseId as string);
  const [courseSessionId, setCourseSessionId] = useState<string>('');

  useEffect(() => {
    if (!loading && sessions) {
      setCourseSessionId(sessions[0]?.courseSessionId);
    }
  }, [loading]);

  return (
    <PageContainer className="p-8 text-sm">
      <Row justify="space-between" align="middle" className="mb-4">
        <Col>
          <div className="flex items-center">
            <h1
              className="text-xl font-bold text-mono-gray-300 cursor-pointer"
              onClick={() => history.push('/course/report')}
            >
              รายงานคอร์ส
            </h1>
            <RightOutlined className="mx-2 text-mono-gray-300 text-xs" />
            <h1 className="text-xl font-bold ">รายละเอียดรายงานคอร์ส</h1>
          </div>
        </Col>
      </Row>
      <Spin spinning={loading}>
        <DetailListItem label="คอร์ส" value={course?.name?.th} />
        <Col span={12}>
          <Row className="mt-3">
            <Col span={8} className="self-center">
              รอบอบรม
            </Col>
            <Col span={12} className="font-sarabun">
              {sessions?.length > 0 ? (
                <Select
                  className="w-full"
                  value={courseSessionId}
                  onSelect={(e) => {
                    setCourseSessionId(e as string);
                  }}
                >
                  {sessions.map(({ name, courseSessionId }: CourseSession) => {
                    return (
                      <Option value={courseSessionId as string}>
                        {name?.th}
                      </Option>
                    );
                  })}
                </Select>
              ) : (
                'ไม่มีรอบอบรม'
              )}
            </Col>
          </Row>
        </Col>
        <Divider orientation="left" className="text-xs text-mono-gray-300">
          รายละเอียด
        </Divider>
        <CourseReportDetailSessionInfo courseSessionId={courseSessionId} />
      </Spin>
      <Tabs
        defaultActiveKey={(tab as string) || '1'}
        type="card"
        className="mt-5"
      >
        <TabPane tab="รายชื่อผู้เรียน" key="1">
          <CourseReportDetailNameList courseSessionId={courseSessionId} />
        </TabPane>
        <TabPane tab="รายการคูปอง" key="2">
          <CourseReportDetailCouponList courseSessionId={courseSessionId} />
        </TabPane>
      </Tabs>
    </PageContainer>
  );
};
