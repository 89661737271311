/** @jsx jsx */

import { COURSE_LEVEL_TEXT, COURSE_STATUS_TEXT } from 'feature/course/const';
import { Col, Divider, Row, Table, Typography } from 'antd';
import {
  Course,
  CourseLevel,
  CourseStatus,
  CourseVisibility,
  FK,
  Session,
  SessionSequential,
  TeacherAcademyCourseType
} from 'feature/course/course.type';
import { DeleteTwoTone, FormOutlined, UpOutlined } from '@ant-design/icons';
import { Fragment, useEffect, useState } from 'react';
import { css, jsx } from '@emotion/react';

import { AlertModal } from 'feature/common/AlertModal';
import Axios from 'axios';
import { Localization } from 'feature/common/global.type';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';

const MOCK_SESSIONS: Session[] = [
  {
    name: 'สนุกคิดคณิตคิดเร็ว รุ่นที่ 1',
    category: CourseVisibility.PUBLIC,
    sequential: SessionSequential.Sequential,
    showOnWeb: false,
    registerEndDate: new Date().toISOString(),
    registerStartDate: new Date().toISOString(),
    sessionEndDate: new Date().toISOString(),
    sessionStartDate: new Date().toISOString()
  },
  {
    name: 'สนุกคิดคณิตคิดเร็ว รุ่นที่ 2',
    category: CourseVisibility.PUBLIC,
    sequential: SessionSequential.Shuffle,
    showOnWeb: false,
    registerEndDate: new Date().toISOString(),
    registerStartDate: new Date().toISOString(),
    sessionEndDate: new Date().toISOString(),
    sessionStartDate: new Date().toISOString()
  },
  {
    name: 'สนุกคิดคณิตคิดเร็ว รุ่นที่ 3',
    category: CourseVisibility.SPECIAL,
    sequential: SessionSequential.Sequential,
    showOnWeb: false,
    registerEndDate: new Date().toISOString(),
    registerStartDate: new Date().toISOString(),
    sessionEndDate: new Date().toISOString(),
    sessionStartDate: new Date().toISOString()
  }
];

interface Props {
  courses?: Course[];
  loading: boolean;
  refetch: Function;
}

export const CourseManageTable = ({
  courses = [],
  loading,
  refetch
}: Props) => {
  const history = useHistory();
  const [deletingItemId, setDeletingItemId] = useState<any>(null);
  const [deletingModalVisible, setDeletingModalVisible] = useState(false);
  const renderContent = (content: Localization) => <span>{content?.th}</span>;
  const renderContentFK = (content: FK[]) => (
    <span>{content && content.map((c) => c?.name?.th).join(' ')}</span>
  );
  const columns = [
    {
      title: 'Id',
      dataIndex: 'courseId',
      key: 'id',
      width: 300
    },
    {
      title: 'คอร์ส',
      dataIndex: 'name',
      key: 'name',
      render: renderContent,
      width: 300
    },
    {
      title: 'ระดับคอร์ส',
      dataIndex: 'courseLevel',
      key: 'courseLevel',
      width: 150,
      render: (courseLevel: CourseLevel) => {
        if (!courseLevel) return <Typography.Text>-</Typography.Text>;
        return (
          <Typography.Text>{COURSE_LEVEL_TEXT[courseLevel]}</Typography.Text>
        );
      }
    },
    {
      title: 'ระดับชั้น',
      dataIndex: 'gradeLevels',
      key: 'gradeLevels',
      render: renderContentFK,
      width: 100
    },
    {
      title: 'วิชา',
      dataIndex: 'subjectGroups',
      key: 'subjectGroups',
      render: renderContentFK,
      width: 250
    },
    {
      title: 'หมวดหมู่',
      dataIndex: 'subCategories',
      key: 'subCategories',
      render: renderContentFK,
      width: 100
    },
    {
      title: 'ประเภทคอร์ส',
      dataIndex: 'teacherAcademyCourseType',
      key: 'teacherAcademyCourseType',
      render: (content: TeacherAcademyCourseType) => {
        if (!content) return <Typography.Text>-</Typography.Text>;
        return <Typography.Text>{content?.nameTh}</Typography.Text>;
      },
      width: 100
    },
    {
      title: 'สถานะ',
      dataIndex: 'status',
      key: 'status',
      width: 100,
      render: (status: CourseStatus) => {
        if (!status) return <Typography.Text>-</Typography.Text>;
        return <Typography.Text>{COURSE_STATUS_TEXT[status]}</Typography.Text>;
      }
    },
    {
      title: 'ใหม่',
      dataIndex: 'isNew',
      key: 'isNew',
      width: 80,
      render: (isNew: boolean) => {
        if (!isNew) return <Typography.Text>-</Typography.Text>;
        return (
          <Typography.Text
            css={css`
              color: rgb(220, 15, 15, 0.65);
            `}
          >
            New
          </Typography.Text>
        );
      }
    },
    {
      title: 'ราคา',
      dataIndex: 'fullPrice',
      key: 'fullPrice',
      width: 100,
      render: (text: string) => <span>{(+text || 0).toLocaleString()}</span>
    },
    {
      title: '',
      dataIndex: '',
      key: 'x',
      width: 100,
      render: (text: any, record: Course) => {
        return (
          <Row gutter={4}>
            <Col>
              <a className="block mx-2 text-center">
                <FormOutlined
                  style={{ color: '#1890FF' }}
                  onClick={() =>
                    history.push(
                      '/course/manage/edit?courseId=' + record?.courseId
                    )
                  }
                />
              </a>
            </Col>
            <Col>
              <DeleteTwoTone
                twoToneColor="#F03D3E"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setDeletingItemId(record?.courseId);
                  setDeletingModalVisible(true);
                }}
              />
            </Col>
          </Row>
        );
      }
    }
  ];

  const [expandedRowKeys, setExpandedRowKeys] = useState<any>([]);
  useEffect(() => {
    if (!_.isEmpty(courses)) {
      setExpandedRowKeys([courses[0].courseId]);
    }
  }, [courses]);

  return (
    <Fragment>
      <Table
        loading={loading}
        dataSource={courses}
        columns={columns}
        pagination={false}
        onRow={(record: any) => {
          return {
            onClick: () => {
              history.push('/course/manage/edit?courseId=' + record?.courseId);
              if (!record?.session) return;
              setExpandedRowKeys((prev: any[]) => {
                if (prev.includes(record.courseId))
                  return prev.filter((p) => p !== record.courseId);
                return [...prev, record.courseId];
              });
            }
          };
        }}
        expandable={{
          expandedRowKeys: expandedRowKeys,
          onExpand: (expanded, record: any) =>
            setExpandedRowKeys((prev: any[]) => {
              if (prev.includes(record.courseId))
                return prev.filter((p) => p !== record.courseId);
              return [...prev, record.courseId];
            }),
          expandedRowRender: () => (
            <CourseSessionExpand sessions={MOCK_SESSIONS} />
          ),
          rowExpandable: (record: any) => record?.session,
          expandIcon: ({ expanded, onExpand, record, expandable }) =>
            expandable && (
              <UpOutlined
                rotate={!expanded ? 180 : 0}
                onClick={(e) => onExpand(record, e)}
              />
            )
        }}
        rowKey="courseId"
        css={css`
          .ant-table-row {
            cursor: pointer;
          }
        `}
      />
      <AlertModal
        subTitle="ข้อมูลคอร์ส มีการเชื่อมกับข้อมูลการเรียน, สินค้า, Order และ Subscription"
        visible={deletingModalVisible}
        onOk={async () => {
          try {
            await Axios.delete('/courses/' + deletingItemId);
            setDeletingModalVisible(false);
            refetch();
          } catch (error) {}
        }}
        onCancel={() => {
          setDeletingModalVisible(false);
          setDeletingItemId(null);
        }}
      />
    </Fragment>
  );
};

const CourseSessionExpand = ({ sessions }: { sessions: Session[] }) => {
  return (
    <div className="w-full bg-white">
      <Row className="text-mono-gray-300 pl-16">
        <Col span={5}>ชื่อรอบอบรม</Col>
        <Col span={3}>วันที่อบรม</Col>
        <Col span={3}>ประเภท</Col>
        <Col span={3}>รูปแบบการเรียน</Col>
      </Row>
      <Divider />
      {sessions.map((session) => (
        <Row className=" pl-16 text-mono-gray-400 mt-2">
          <Col span={5}>{session.name}</Col>
          <Col span={3}>1 ก.ค. - 1 ส.ค. 63</Col>
          <Col span={3}>{session.category}</Col>
          <Col span={3}>{session.sequential}</Col>
        </Row>
      ))}
    </div>
  );
};
