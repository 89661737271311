import { CourseLecturer } from 'feature/course/course.type';
import useAxios from 'axios-hooks';

export const useCourseLecturers = (courseId: string = '') => {
  const [{ data: response, loading, error }, refetch] = useAxios(
    '/course-lecturers?pageNumber=1&pageSize=100&courseId=' + courseId
  );

  return {
    data: loading ? [] : (response?.data as CourseLecturer[]) || [],
    loading,
    error,
    execute: refetch
  };
};
