import { ProductListResponseData } from '@aksorn/backoffice-sdk';
import useAxios from 'axios-hooks';

export enum ProductType {
  BOOK = 'book'
}

export const useProducts = () => {
  const [{ data: response, loading, error }, fire] = useAxios(
    {
      method: 'GET'
    },
    { manual: true }
  );

  const execute = (
    productType: string = ProductType.BOOK,
    pageNumber: number = 1,
    pageSize: number = 20
  ) => {
    return fire({
      url: `/products?productType=${productType}&pageNumber=${pageNumber}&pageSize=${pageSize}`
    });
  };

  return {
    data: response?.data as ProductListResponseData,
    loading,
    error,
    execute
  };
};
