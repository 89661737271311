import { identity, pickBy } from 'lodash';

import Axios from 'axios';

interface Props {
  pageNumber: number;
  productType: string;
  pageSize?: number;
  keyword?: string;
  subjectIds?: string;
  gradeLevelIds?: string;
}

export const getProducts = async (params: Props) => {
  const { data: response } = await Axios.get('/products', {
    params: {
      pageNumber: params.pageNumber,
      pageSize: params.pageSize || 20,
      ...pickBy(params, identity)
    }
  });

  return response;
};
