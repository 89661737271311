export const downloadFile = ({
  url,
  fileName
}: {
  url: string;
  fileName: string;
}) => {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
};
