import { Col, Row, Tabs, message } from 'antd';
import { ProductFieldsValue, ProductForm } from 'feature/product/ProductForm';
import { RequestUploadUrl, UploadStatus } from 'hooks/useUploadImage';
import { useHistory, useLocation } from 'react-router-dom';

import Axios from 'axios';
import { ReactComponent as BreadcrumbsIcon } from 'assets/breadcrumbs.svg';
import { PageContainer } from 'feature/common/PageContainer';
import { ProductPageStateType } from 'feature/product/const';
import React from 'react';
import { useAddProduct } from 'hooks/product/useAddProduct';
import { useEditProduct } from 'hooks/product/useEditProduct';

const { TabPane } = Tabs;
export const AddProductPage = () => {
  const history = useHistory();
  const { state } = useLocation<ProductPageStateType>();
  const { execute: addProduct } = useAddProduct();
  const { execute: editProduct } = useEditProduct();

  const handleUploadImage = async (productId: string, file: any) => {
    // console.log('file', file);
    if (!file) return null;
    if (file.status === UploadStatus.EMPTY) return null;
    try {
      const requestCoverImageResponse: {
        data: RequestUploadUrl;
      } = await Axios.post('/assets', {
        refOwnerType: 'product-thumbnail',
        refOwnerId: productId,
        filename: file.name,
        contentType: file.type
      });
      await Axios({
        url: requestCoverImageResponse.data.data.upload.url,
        params: requestCoverImageResponse.data.data.upload.params,
        method: 'PUT',
        data: file,
        headers: { 'content-type': file.type }
      });
      return requestCoverImageResponse.data.data.url;
    } catch (error) {
      console.log(error);
    }
  };

  const onFinish = async (data: ProductFieldsValue) => {
    // console.log('onFinish', data);
    const payload = {
      productType: 'book',
      gradeLevelId: data.gradeLevelId,
      subjectGroupId: data.subjectGroupId,
      subjectId: data.subjectId,
      freeCourseId: data.freeCourseId,
      name: {
        th: data.nameTh,
        en: data.nameEn
      },
      barcode: data.barcode,
      coverImage: 'coverImage',
      basicSku: data.basicSku,
      premiumSku: data.premiumSku,
      description: {
        th: data.descriptionTh,
        en: data.descriptionEn
      },
      basicPrice: data.basicPrice ? +data.basicPrice : null,
      premiumPrice: data.premiumPrice ? +data.premiumPrice : null,
      aksornBookId: data.aksornBookId,
      bookType: data.bookType,
      authorName: {
        th: data.authorNameTh,
        en: data.authorNameEn
      },
      bookVolume: data.bookVolume && +data.bookVolume,
      bookUrl: data.bookUrl
    };
    try {
      const res = await addProduct(payload);
      const productId = res.data.data.productId;
      let urlCoverImage = null;
      if (data.coverImage) {
        urlCoverImage = await handleUploadImage(
          productId as string,
          data.coverImage?.file
        );
      }
      if (urlCoverImage) {
        await editProduct({ ...payload, coverImage: urlCoverImage }, productId);
      }
      history.replace('/product/manage-list/');
      message.success('เพิ่มสินค้าแล้ว');
    } catch (error) {
      message.error('เพิ่มสินค้าไม่สำเร็จ');
    }
  };
  return (
    <PageContainer className="p-8">
      <Row justify="space-between" align="middle" className="mb-4">
        <Col>
          <div className="flex items-center">
            <h1
              className="text-xl font-bold text-mono-gray-300 cursor-pointer"
              onClick={() => {
                history.push(`/product/manage-list${state?.queryString ?? ''}`);
              }}
            >
              จัดการสินค้า
            </h1>
            <BreadcrumbsIcon />
            <h1 className="text-xl font-bold">เพิ่มสินค้า</h1>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Tabs defaultActiveKey="1">
            <TabPane tab="รายละเอียด" key="1">
              <ProductForm
                onFinish={(data: any) => onFinish(data)}
                handleUploadImage={(courseId: string, file: any) =>
                  handleUploadImage(courseId, file)
                }
                mode="add"
              />
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    </PageContainer>
  );
};
