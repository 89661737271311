export enum BookType {
  ALL = 'all',
  EBOOK = 'ebook',
  QUIZ = 'quiz',
  DIGITAL = 'digital'
}

export interface ProductPageStateType {
  queryString: string;
}
