import { Button, Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';

import { ReactComponent as AddIcon } from 'assets/add.svg';
import Axios from 'axios';
import { BannerTable } from 'feature/banner/BannerTable';
import { PageContainer } from 'feature/common/PageContainer';
import { useActivateBanners } from 'hooks/banner/useActivateBanners';
import { useDeactivateBanners } from 'hooks/banner/useDeactivateBanners';
import { useEditBanner } from 'hooks/banner/useEditBanner';
import { useHistory } from 'react-router-dom';

export const BannerListPage = () => {
  const history = useHistory();

  const { execute: editBanner } = useEditBanner();
  const [editBannerLoading, setEditBannerLoading] = useState<boolean>(false);

  const {
    data: activateBannerListData,
    loading: getActivateBannerLoding,
    refetch: getActivateBannerList
  } = useActivateBanners();

  const [pageNumber, setPageNumber] = useState(1);
  const handleTableChange = (value: any) => {
    setPageNumber(value.current);
  };

  const {
    data: deactivatebannerListData,
    loading: getDeactivateBannerLoding,
    refetch: getDeactivateBannerList,
    pagination: deactivateBannerPagination
  } = useDeactivateBanners({ pageNumber: pageNumber });

  const handleDelete = async (bannerId: string) => {
    try {
      await Axios.delete('/banners/' + bannerId);
      const res = await Axios.get('/assets/', {
        params: { refOwnerId: bannerId }
      });
      // console.log('res', res);
      const currentAssetListData = res.data.data;
      if (!!currentAssetListData.length) {
        const assetId = currentAssetListData[0].assetId;
        await Axios.delete('/assets/' + assetId);
      }
      await getActivateBannerList();
      await getDeactivateBannerList();
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleEdit = async (payload: any, bannerId: string) => {
    try {
      setEditBannerLoading(true);
      await editBanner(payload, bannerId);
      await getActivateBannerList();
      await getDeactivateBannerList();
    } catch (error) {
      console.log('error', error);
    } finally {
      setEditBannerLoading(false);
    }
  };

  useEffect(() => {
    getActivateBannerList();
    getDeactivateBannerList();
  }, []);

  return (
    <PageContainer className="p-8">
      <Row justify="space-between" align="middle" className="mb-4">
        <Col>
          <h1 className="text-xl font-bold text-black">จัดการแบนเนอร์</h1>
        </Col>
      </Row>
      <Row justify="end" className="mb-6">
        <Col>
          <Button
            type="primary"
            block
            icon={<AddIcon className="mr-2" />}
            className="ml-2 mb-4 overflow-visible"
            onClick={() => history.push('/banner/add')}
          >
            เพิ่มแบนเนอร์
          </Button>
        </Col>
      </Row>
      <Row justify="space-between" align="middle" className="mb-4">
        <Col span={24}>
          <h1 className="text-xl font-bold">แบนเนอร์ที่กำลังใช้งาน</h1>
        </Col>
      </Row>
      <div className="mb-10">
        <BannerTable
          data={activateBannerListData}
          loading={getActivateBannerLoding || editBannerLoading}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
        />
      </div>
      <Row justify="space-between" align="middle" className="mb-4">
        <Col span={24}>
          <h1 className="text-xl font-bold">แบนเนอร์ที่ยังไม่ใช้งาน</h1>
        </Col>
      </Row>
      <BannerTable
        data={deactivatebannerListData}
        loading={getDeactivateBannerLoding || editBannerLoading}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
        pagination={deactivateBannerPagination}
        handleTableChange={handleTableChange}
      />
    </PageContainer>
  );
};
