/** @jsx jsx */
import { PersonalDetail } from './PersonalDetail';
import React, { useState, useEffect } from 'react';
import { SchoolRequest, SchoolRequestStatus } from 'feature/school-request/school-request.type';
import { Button, Form, Input, Spin, Col, Row, Modal, message, Select } from 'antd';
import { FormRule, getRule } from 'utils/form/getRule';
import { css, jsx } from '@emotion/react';
import { PrimaryButton } from 'feature/common/Button';
import { PlusOutlined } from '@ant-design/icons';
import { SchoolRequestRequestorDto } from '@aksorn/backoffice-sdk';
import { updateAdditionalRequestors } from 'api/school-request/updateAdditionalRequestors';
import { AdditionalRequestorsModal } from './AdditionalRequestorsModal';
import { ReactComponent as AddIcon } from 'assets/add.svg';
import { ReactComponent as DeleteIcon } from 'assets/delete.svg';

const { Option } = Select;

interface SchoolRequestContactTabProps {
  refetch: Function;
  schoolRequest: SchoolRequest;
  loading: boolean;
  schoolTeachers: any[];
}

export const SchoolRequestContactTab: React.FC<SchoolRequestContactTabProps> = ({
  refetch,
  schoolRequest: { requestor, superAdmins, contactPerson, schoolRequestId, additionalRequestors, status },
  loading,
  schoolTeachers
}) => {
  const currentAdditionalRequestors = additionalRequestors ?? [];
  const [form] = Form.useForm();

  const [totalNewAdditionalRequestors, setTotalNewAdditionalRequestors] = useState<number>(0);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [newAdditionalRequestors, setNewAdditionalRequestors] = useState<SchoolRequestRequestorDto[]>([]);
  const [deleteAdditionalRequestors, setDeleteAdditionalRequestors] = useState<SchoolRequestRequestorDto[]>([]);
  const [deleteAdditionalRequestorEmails, setDeleteAdditionalRequestorEmails] = useState<string[]>([]);
  const [isModalLoading, setIsModaLoading] = useState<boolean>(false);

  const isDelete = (email: string) => {
    return deleteAdditionalRequestorEmails.includes(email);
  }

  const handleClickDeleteButton = (additionalRequestor: SchoolRequestRequestorDto) => {
    if (isDelete(additionalRequestor.email)) {
      setDeleteAdditionalRequestors(deleteAdditionalRequestors.filter(
        (deleteAdditionalRequestor) => deleteAdditionalRequestor.email !== additionalRequestor.email
      ))
    } else {
      setDeleteAdditionalRequestors([...deleteAdditionalRequestors, additionalRequestor])
    }
  }

  useEffect(() => {
    setDeleteAdditionalRequestorEmails(deleteAdditionalRequestors.map((deleteAdditionalRequestor) => deleteAdditionalRequestor.email));
  }, [deleteAdditionalRequestors]);

  const onFormFinish = (fieldsValue: any) => {
    setIsModalVisible(true);
    const formatAdditionalRequestors: SchoolRequestRequestorDto[] = fieldsValue?.formAdditionalRequestors
      ?.map((formAdditionalRequestor: any) => ({
        firstName: formAdditionalRequestor.firstName,
        lastName: formAdditionalRequestor.lastName,
        phoneNumber: formAdditionalRequestor.phoneNumber,
        email: formAdditionalRequestor.email,
        position: formAdditionalRequestor.position
      })) ?? [];
    setNewAdditionalRequestors(formatAdditionalRequestors);
    // console.log('newAdditionalRequestors', formatAdditionalRequestors);
    // console.log('deleteAdditionalRequestors', deleteAdditionalRequestors);
  };

  const onClose = () => {
    setIsModalVisible(false);
    setNewAdditionalRequestors([]);
  }

  const onConfirm = async () => {
    const hide = message.loading('กำลังดำเนินการ...');
    var editType = 'แก้ไข'
    if (!!newAdditionalRequestors.length && !!deleteAdditionalRequestorEmails.length) {
      editType = 'เพิ่ม/ลบ'
    } else if (!!newAdditionalRequestors.length) {
      editType = 'เพิ่ม'
    } else if (!!deleteAdditionalRequestorEmails.length) {
      editType = 'ลบ'
    }

    try {
      setIsModaLoading(true);
      const deleteAdditionalRequestorEmails = deleteAdditionalRequestors.map((deleteAdditionalRequestor) => {
        return { email: deleteAdditionalRequestor.email }
      })
      await updateAdditionalRequestors(
        schoolRequestId,
        newAdditionalRequestors,
        deleteAdditionalRequestorEmails
      );
      message.success(editType + 'ผู้มีอำนาจกระทำการแทนโรงเรียนสำเร็จ');
      refetch();
      setIsModalVisible(false);
      form.setFieldsValue({ formAdditionalRequestors: [] });
      setTotalNewAdditionalRequestors(0);
      setDeleteAdditionalRequestors([]);
    } catch (error) {
      console.log('error', error);
      const e = error as any;
      const errorMessage = e.response.data.message;
      if (errorMessage === 'some requestors are using the same email') {
        message.error('ไม่สามารถ' + editType + 'ได้ เนื่องจากมีผู้มีอำนาจกระทำการแทนโรงเรียนบางท่าน ที่ใช้อีเมลซ้ำกัน');
      } else if (errorMessage === 'some additional requestors are not exist, not a teacher or not in the school') {
        message.error('ไม่สามารถ' + editType + 'ได้ เนื่องจากผู้มีอำนาจกระทำการแทนโรงเรียนบางท่าน ไม่ได้เป็นครูอยู่ในโรงเรียนที่ขอเปิดบัญชี');
      } else {
        message.error(editType + 'ผู้มีอำนาจกระทำการแทนโรงเรียนไม่สำเร็จ กรุณาลองใหม่อีกครั้ง');
      }
    } finally {
      setIsModaLoading(false);
      hide();
    }
  }

  return (
    <div className="mb-32">
      <Form
        form={form}
        onFinish={onFormFinish}
      >
        <Spin spinning={loading}>
          <div className="my-4 flex items-center text-base font-bold text-mono-gray-500">
            ข้อมูลผู้มีอำนาจกระทำการแทนโรงเรียน
          </div>
          <div className="w-full md:w-10/12 lg:w-8/12 xl:w-6/12 rounded-2xl px-5 py-2 mb-2 border-4 border-transparent">
            <div
              className="flex items-center text-base font-medium"
              style={{ height: '30px', color: '#BDBDBD' }}
            >
              ผู้มีอำนาจกระทำการแทนโรงเรียนคนที่ 1
            </div>
            <PersonalDetail
              firstName={requestor?.firstName}
              lastName={requestor?.lastName}
              email={requestor?.email}
              tel={requestor?.phoneNumber ?? '-'}
              position={requestor?.position}
            />
          </div>

          {currentAdditionalRequestors.map((additionalRequestor, index) => (
            <div
              key={index}
              className={'w-full md:w-10/12 lg:w-8/12 xl:w-6/12 rounded-2xl px-5 py-2 mb-2 border-4 ' + (isDelete(additionalRequestor?.email) ? 'border-danger' : 'border-transparent')}
            >
              <div className="flex items-center">
                {isDelete(additionalRequestor?.email) ? (
                  <DeleteIcon className="w-4 h-4 mr-2" />
                ) : (
                  <></>
                )}
                <div
                  className="flex items-center text-base font-medium"
                  style={{ height: '30px', color: isDelete(additionalRequestor?.email) ? '#FF7C7C' : '#BDBDBD' }}
                >
                  ผู้มีอำนาจกระทำการแทนโรงเรียนคนที่ {index + 2}
                </div>
                <Button
                  className="text-red ml-4 text-base font-medium"
                  style={{ height: '30px', fontFamily: 'Noto Sans, sans-serif' }}
                  type="text"
                  onClick={() => { handleClickDeleteButton(additionalRequestor) }}
                >
                  <span className="underline">
                    {deleteAdditionalRequestorEmails.includes(additionalRequestor?.email) ?
                      'ยกเลิกการลบ' : 'ลบ'
                    }
                  </span>
                </Button>
              </div>
              <PersonalDetail
                firstName={additionalRequestor?.firstName}
                lastName={additionalRequestor?.lastName}
                email={additionalRequestor?.email}
                tel={additionalRequestor?.phoneNumber ?? '-'}
                position={additionalRequestor?.position}
              />
            </div>
          ))}

          <Form.List name="formAdditionalRequestors">
            {(fields, operation) => (
              <>
                {fields.map((field, index) => {
                  const newAdditionalRequestorsData = form.getFieldValue('formAdditionalRequestors')
                  const thisNewAdditionalRequestor = newAdditionalRequestorsData[index]
                  return (
                    <div
                      key={field.key}
                      className="w-full md:w-10/12 lg:w-8/12 xl:w-6/12 rounded-2xl px-5 py-2 mb-2 border-4 border-green-500"
                    >
                      <Form.Item className="mb-0">
                        <div className="flex items-center">
                          <AddIcon
                            className="w-4 h-4 mr-2"
                            css={css`
                                path {
                                  stroke: #48bb78 !important;
                                }
                            `}
                          />
                          <div
                            className="flex items-center text-base font-medium text-green-500"
                            style={{ height: '30px', fontFamily: 'Noto Sans, sans-serif' }}
                          >
                            ผู้มีอำนาจกระทำการแทนโรงเรียนคนที่ {index + currentAdditionalRequestors.length + 2}
                          </div>
                          <Button
                            className="text-red ml-4 text-base font-medium"
                            style={{ height: '30px', fontFamily: 'Noto Sans, sans-serif' }}
                            type="text"
                            onClick={() => {
                              setTotalNewAdditionalRequestors(totalNewAdditionalRequestors - 1);
                              operation.remove(field.name);
                            }}
                          >
                            <span className="underline">ยกเลิกการเพิ่ม</span>
                          </Button>
                        </div>
                        <Row className="mt-4 text-mono-gray-400">
                          <Col span={24}>
                            <Row gutter={[8, 8]}>
                              <Col span={24} className="font-sarabun">
                                <Form.Item
                                  name={[field.name, 'schoolTeacherId']}
                                  className="w-full mb-0"
                                  rules={[getRule(FormRule.REQUIRE, 'กรุณเลือกครู')]}
                                >
                                  <Select
                                    allowClear
                                    placeholder="ค้นหาครูเพื่อเพิ่มเป็นผู้มีอำนาจกระทำการแทนโรงเรียน"
                                    onSelect={(schoolTeacherId: string) => {
                                      var updateAdditionalRequestors = form.getFieldValue('formAdditionalRequestors')
                                      var currentSchoolTeachers = schoolTeachers.find((schoolTeacher) => schoolTeacher.schoolTeacherId === schoolTeacherId)
                                      updateAdditionalRequestors[index].firstName = currentSchoolTeachers.firstName.th;
                                      updateAdditionalRequestors[index].lastName = currentSchoolTeachers.lastName.th;
                                      updateAdditionalRequestors[index].email = currentSchoolTeachers.email;
                                      updateAdditionalRequestors[index].phoneNumber = currentSchoolTeachers.mobileNo;
                                      form.setFieldsValue({ formAdditionalRequestors: updateAdditionalRequestors })
                                    }}
                                    onClear={() => {
                                      var updateAdditionalRequestors = form.getFieldValue('formAdditionalRequestors')
                                      updateAdditionalRequestors[index].firstName = null;
                                      updateAdditionalRequestors[index].lastName = null;
                                      updateAdditionalRequestors[index].email = null;
                                      updateAdditionalRequestors[index].phoneNumber = null;
                                      form.setFieldsValue({ formAdditionalRequestors: updateAdditionalRequestors })
                                    }}
                                    optionFilterProp="children"
                                    showSearch
                                    filterOption={(input, option) => {
                                      return (
                                        option?.title
                                          ?.toLowerCase()
                                          ?.indexOf(input?.toLowerCase()) >= 0
                                      );
                                    }}
                                  >
                                    {schoolTeachers.map((schoolTeacher) => (
                                      <Option
                                        key={schoolTeacher.schoolTeacherId}
                                        value={schoolTeacher.schoolTeacherId}
                                        title={`${schoolTeacher.title} ${schoolTeacher.firstName.th} ${schoolTeacher.lastName.th} (${schoolTeacher.email})`}
                                      >
                                        {schoolTeacher.title} {schoolTeacher.firstName.th} {schoolTeacher.lastName.th} ({schoolTeacher.email})
                                      </Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </Col>
                            </Row>

                            <Row gutter={[8, 8]}>
                              <Col span={9} className="pt-2 text-base font-medium" style={{ fontFamily: 'Noto Sans' }}>
                                ชื่อ
                              </Col>
                              <Col span={15} className="font-sarabun">
                                {thisNewAdditionalRequestor?.firstName ?? '-'}
                                {/* <Form.Item
                              name={[field.name, 'firstName']}
                              className="w-full mb-0"
                            // rules={[getRule(FormRule.REQUIRE, 'กรุณเลือกครูเพื่อกรอกชื่อ')]}
                            >
                              <Input disabled />
                            </Form.Item> */}
                              </Col>
                            </Row>
                            <Row gutter={[8, 8]}>
                              <Col span={9} className="pt-2 text-base font-medium" style={{ fontFamily: 'Noto Sans' }}>
                                สกุล
                              </Col>
                              <Col span={15} className="font-sarabun">
                                {thisNewAdditionalRequestor?.lastName ?? '-'}
                                {/* <Form.Item
                              name={[field.name, 'lastName']}
                              className="w-full mb-0"
                            // rules={[getRule(FormRule.REQUIRE, 'กรุณาเลือกครูเพื่อกรอกสกุล')]}
                            >
                              <Input disabled />
                            </Form.Item> */}
                              </Col>
                            </Row>
                            <Row gutter={[8, 8]}>
                              <Col span={9} className="pt-2 text-base font-medium" style={{ fontFamily: 'Noto Sans' }}>
                                อีเมล
                              </Col>
                              <Col span={15} className="font-sarabun">
                                {thisNewAdditionalRequestor?.email ?? '-'}
                                {/* <Form.Item
                              name={[field.name, 'email']}
                              className="w-full mb-0"
                            >
                              <Input disabled />
                            </Form.Item> */}
                              </Col>
                            </Row>
                            <Row gutter={[8, 8]}>
                              <Col span={9} className="pt-2 text-base font-medium" style={{ fontFamily: 'Noto Sans' }}>
                                เบอร์โทร
                              </Col>
                              <Col span={15} className="font-sarabun">
                                {thisNewAdditionalRequestor?.phoneNumber ?? '-'}
                                {/* <Form.Item
                              name={[field.name, 'phoneNumber']}
                              className="w-full mb-0"
                            >
                              <Input disabled />
                            </Form.Item> */}
                              </Col>
                            </Row>
                            <Row gutter={[8, 8]}>
                              <Col span={9} className="pt-2 text-base font-medium" style={{ fontFamily: 'Noto Sans' }}>
                                ตำแหน่ง
                              </Col>
                              <Col span={15} className="font-sarabun">
                                <Form.Item
                                  name={[field.name, 'position']}
                                  className="w-full mb-0"
                                  rules={[
                                    getRule(FormRule.REQUIRE, 'กรุณากรอกตำแหน่งในโรงเรียน')
                                  ]}
                                >
                                  <Input placeholder="กรุณากรอกตำแหน่งในโรงเรียน" />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Form.Item>
                    </div>
                  )
                })}

                {fields.length < 50 && (
                  <>
                    {(status === SchoolRequestStatus.PENDING || status === SchoolRequestStatus.ACCEPTED) ? (
                      <Button
                        onClick={() => {
                          setTotalNewAdditionalRequestors(totalNewAdditionalRequestors + 1);
                          operation.add()
                        }}
                        className="w-60 flex justify-center items-center rounded-xl pt-6 pb-6 m-6 hover:text-green-500 hover:border-green-500 focus:text-green-500 focus:border-green-500"
                      >
                        <PlusOutlined />
                        <span>เพิ่มผู้มีอำนาจกระทำการแทนโรงเรียน</span>
                      </Button>
                    ) : <></>}
                  </>
                )}
              </>
            )}
          </Form.List>


          <div className="my-4 flex items-center text-base font-bold text-mono-gray-500">
            ข้อมูลผู้ดูแลระบบ
          </div>
          {superAdmins?.map((admin, index) => (
            <div
              key={index}
              className="w-full md:w-10/12 lg:w-8/12 xl:w-6/12 rounded-2xl px-5 py-2 mb-2 border-4 border-transparent"
            >
              <div
                className="flex items-center text-base font-medium"
                style={{ height: '30px', color: '#BDBDBD' }}
              >{`ผู้ดูแลระบบคนที่ ${index + 1}`}</div>
              <PersonalDetail
                firstName={admin.firstName}
                lastName={admin.lastName}
                email={admin.email}
                tel={admin.phoneNumber ?? '-'}
              />
            </div>
          ))}

          <div className="my-4 flex items-center text-base font-bold text-mono-gray-500">
            ข้อมูลผู้ติดต่อ
          </div>
          <div className="w-full md:w-10/12 lg:w-8/12 xl:w-6/12 rounded-2xl px-5 py-2 mb-2 border-4 border-transparent">
            <PersonalDetail
              firstName={contactPerson?.firstName}
              lastName={contactPerson?.lastName}
              email={contactPerson?.email}
              tel={contactPerson?.phoneNumber ?? '-'}
            />
          </div>
        </Spin>
        <div
          className="flex justify-end items-center w-full z-10"
          css={css`
            position: fixed;
            bottom: 0;
            left: 0;
            background-color: #fcfcfd;
            padding: 2rem;
          `}
        >
          <PrimaryButton
            className="rounded-xl text-lg"
            disabled={!totalNewAdditionalRequestors && !deleteAdditionalRequestors.length}
          >
            บันทึก
          </PrimaryButton>
        </div>
      </Form>

      <AdditionalRequestorsModal
        visible={isModalVisible}
        onClose={onClose}
        onConfirm={onConfirm}
        loading={isModalLoading}
        newAdditionalRequestors={newAdditionalRequestors}
        deleteAdditionalRequestors={deleteAdditionalRequestors}
      />
    </div>
  );
};
