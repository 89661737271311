/** @jsx jsx */

import {
  CheckCircleFilled,
  CloseCircleFilled,
  ExclamationCircleFilled
} from '@ant-design/icons';
import {
  SchoolRequest,
  SchoolRequestStatus
} from 'feature/school-request/school-request.type';
import { css, jsx } from '@emotion/react';

import { Fragment } from 'react';
import moment from 'moment';

export const SchoolRequestStatusTag = ({
  schoolRequest
}: {
  schoolRequest: SchoolRequest;
}) => {
  switch (schoolRequest?.status) {
    case SchoolRequestStatus.CREATED:
      return (
        <Fragment>
          <div
            className="flex items-center text-lg font-semibold mr-6"
            css={css`
              color: #ffa940;
            `}
          >
            <ExclamationCircleFilled
              style={{ color: '#FFA940', marginRight: '10px' }}
            />
            รอเอกสาร
          </div>
          <div>
            คำขอถูกบันทึกเมือวันที่{' '}
            {moment(schoolRequest?.createdAt)
              ?.add(543, 'years')
              ?.format('DD MMM YY')}
          </div>
        </Fragment>
      );
    case SchoolRequestStatus.ACCEPTED:
      return (
        <Fragment>
          <div
            className="flex items-center text-lg font-semibold mr-6"
            css={css`
              color: #52c41a;
            `}
          >
            <CheckCircleFilled
              style={{ color: '#52C41A', marginRight: '10px' }}
            />
            อนุมัติแล้ว
          </div>
          <div>
            เมือวันที่{' '}
            {moment(schoolRequest?.acceptedAt)
              ?.add(543, 'years')
              ?.format('DD MMM YY')}
          </div>
        </Fragment>
      );
    case SchoolRequestStatus.REJECTED:
      return (
        <Fragment>
          <div
            className="flex items-center text-lg font-semibold mr-6"
            css={css`
              color: #f5222d;
            `}
          >
            <CloseCircleFilled
              style={{ color: '#F5222D', marginRight: '10px' }}
            />
            ไม่อนุมัติ
          </div>
          <div>
            เมือวันที่{' '}
            {moment(schoolRequest?.rejectedAt)
              ?.add(543, 'years')
              ?.format('DD MMM YY')}
          </div>
        </Fragment>
      );
    case SchoolRequestStatus.PENDING:
      return <Fragment></Fragment>;
    default:
      return <Fragment></Fragment>;
  }
};
