/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import ReactHtmlParser from 'react-html-parser';
import certBlank from 'assets/blank-cert.jpg';

interface CertificateRenderProps {
  imageUrl: string;
  templateString: any;
  signatures: Array<{
    signatureImageUrl: string;
    name: string;
    position: string;
    companyName: string;
  }>;
}

const Signature = ({
  signature
}: {
  signature: {
    signatureImageUrl: string;
    name: string;
    position: string;
    companyName: string;
  };
}) => {
  const { name, position, signatureImageUrl, companyName } = signature;
  return (
    <div className="flex flex-1 flex-col items-center signature">
      <img
        css={css`
          width: 6cm;
          height: 3cm;
          object-fit: contain;
        `}
        src={signatureImageUrl}
        alt=""
      />
      <span className="text-center">{name}</span>
      <span className="text-center">{position}</span>
      <span className="text-center">{companyName}</span>
    </div>
  );
};

export const CertificateRender = ({
  imageUrl = certBlank,
  templateString,
  signatures = []
}: CertificateRenderProps) => (
  <div
    css={css`
      width: 1123.2px;
      height: 793.92px;
      position: relative;
      font-size: 14px;
    `}
  >
    <img
      src={imageUrl}
      alt=""
      css={css`
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
      `}
    />
    <div
      css={css`
        overflow: hidden;
        padding-top: 5rem;
        width: 100%;
        height: 100%;
      `}
    >
      {ReactHtmlParser(templateString)}
    </div>
    <div
      css={css`
        position: absolute;
        bottom: 2.5rem;
        width: 100%;
        display: flex;
        .signature:first-child {
          margin-left: 1cm;
        }
        .signature:last-child {
          margin-right: 1cm;
        }
        .signature + .signature {
          margin-left: 1cm;
        }
      `}
    >
      <div className="flex font-sarabun min-w-full">
        {signatures.map((signature) => (
          <Signature signature={signature} />
        ))}
      </div>
    </div>
  </div>
);
