import { DatePicker, Form } from 'antd';
import { toEndOfDay, toStartOfDay } from 'utils/normalizeForm';

import React from 'react';
import moment from 'moment';

export const CourseSessionGenerateDateCertificate = ({
  session,
  setSession
}: any) => {
  return (
    <div>
      <div className="font-bold mb-4 mt-4">จัดการวันยืนยันใบประกาศ</div>
      <Form.Item
        label="วันที่เริ่มยืนยันใบประกาศได้"
        name="certificateGenerateStartDate"
        labelCol={{ span: 8 }}
      >
        <DatePicker
          defaultValue={
            session?.certificateGenerationStartDate
              ? moment(session?.certificateGenerationStartDate)
              : undefined
          }
          placeholder="เลือกวันที่"
          onChange={(date: any) => {
            setSession({
              ...session,
              certificateGenerationStartDate: date
                ? toStartOfDay(date).toISOString(true)
                : null,
              certificateGenerationEndDate: date
                ? toEndOfDay(date)
                    .set({ year: 9999, month: 11, date: 31 })
                    ?.toISOString(true)
                : null
            });
          }}
        />
      </Form.Item>
      {/* <Form.Item
        label="วันสิ้นสุดยืนยันใบประกาศได้"
        name="certificateGenerateEndDate"
        labelCol={{ span: 8 }}
        rules={[
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (
                (session?.certificateGenerationStartDate === null &&
                  session?.certificateGenerationStartDate === null) ||
                moment(session?.certificateGenerationEndDate)?.isAfter(
                  session?.certificateGenerationStartDate
                )
              ) {
                return Promise.resolve();
              }
              return Promise.reject('วันที่ผิดพลาด');
            }
          })
        ]}
      >
        <DatePicker
          defaultValue={
            session?.certificateGenerationEndDate
              ? moment(session?.certificateGenerationEndDate, 'YYYY-MM-DD')
              : undefined
          }
          onChange={(date: any, dateString: string) => {
            setSession({
              ...session,
              certificateGenerationEndDate: date?.toISOString(true)
            });
          }}
        />
      </Form.Item> */}
    </div>
  );
};
