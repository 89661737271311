import useAxios from 'axios-hooks';

export const useReorderCourses = () => {
  const [_response, execute] = useAxios(
    {
      url: '/course-sections/reorder',
      method: 'PUT'
    },
    { manual: true }
  );

  return {
    execute
  };
};
