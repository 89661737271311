import { Divider, Row } from 'antd';

import { CouponReport } from './coupon-report.type';
import { DateFormat } from 'feature/common/global.type';
import { DetailListItem } from 'feature/common/DetailListItem';
import React from 'react';
import moment from 'moment';

interface Props {
  report: CouponReport;
}

export const CouponReportDetailSection = (props: Props) => {
  const {
    report: {
      couponCode,
      name,
      numberOfSchools,
      numberOfSchoolsUsedCoupon,
      couponStartDate,
      couponExpiryDate,
      quotaLimit,
      quotaLeft
    }
  } = props;
  return (
    <div className="w-full text-sm">
      <Row>
        <h1 className="font-bold text-xl">
          Code <span className="text-blue">{couponCode}</span>
        </h1>
      </Row>
      <Divider />
      <h2 className="text-lg font-bold mb-5">รายละเอียดคูปอง</h2>
      <Row className="mt-2">
        <DetailListItem label="ชื่อคูปอง (TH)" value={name.th} />
        <DetailListItem label="จำนวนโรงเรียนทั้งหมด" value={numberOfSchools} />
      </Row>
      <Row className="mt-2">
        <DetailListItem label="ชื่อคูปอง (EN)" value={name.en} />
        <DetailListItem
          label="จำนวนโรงเรียนที่ใช้คูปอง"
          value={numberOfSchoolsUsedCoupon}
        />
      </Row>
      <Row className="mt-2">
        <DetailListItem
          label="วันเริ่มใช้งาน"
          value={moment(couponStartDate).format(DateFormat.DEFAULT)}
        />
        <DetailListItem label="โควต้าทั้งหมด" value={quotaLimit} />
      </Row>
      <Row className="mt-2">
        <DetailListItem
          label="วันหมดอายุ"
          value={moment(couponExpiryDate).format(DateFormat.DEFAULT)}
        />
        <DetailListItem label="โควต้าที่เหลือ" value={quotaLeft} />
      </Row>
    </div>
  );
};
