import { OrderGetResponseData } from '@aksorn/backoffice-sdk';
import useAxios from 'axios-hooks';

export const useOrders = () => {
  const [{ data: response, loading, error }, fire] = useAxios(
    {
      method: 'GET'
    },
    {
      manual: true
    }
  );

  const execute = (orderId: string) => {
    return fire({
      url: `/orders/${orderId}`
    });
  };

  return {
    data: response?.data as OrderGetResponseData,
    loading,
    error,
    execute
  };
};
