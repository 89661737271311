import { Province } from 'feature/coupon/province.type';
import useAxios from 'axios-hooks';

export const useProvinces = () => {
  const [{ data: response, loading, error }, refetch] = useAxios(
    '/provinces?pageNumber=1&pageSize=100'
  );

  return {
    data: (response?.data as Province[]) || [],
    loading,
    error,
    execute: refetch
  };
};
