import { OrderItemGetResponseData } from '@aksorn/backoffice-sdk';
import useAxios from 'axios-hooks';

export const useSchoolRequestOrders = () => {
  const [{ data: response, loading, error }, fire] = useAxios(
    {
      method: 'GET'
    },
    {
      manual: true
    }
  );

  const execute = (schoolId: string) => {
    return fire({
      url: `/school-requests/${schoolId}/orders`
    });
  };

  return {
    data: response?.data as OrderItemGetResponseData,
    loading,
    error,
    execute
  };
};
