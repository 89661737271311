import 'moment/locale/th';

import { Button, Col, DatePicker, Input, Pagination, Row } from 'antd';
import React, { useEffect, useState } from 'react';

import { CouponReportTable } from 'feature/coupon-report/CouponReportTable';
import { DateFormat } from 'feature/common/global.type';
import { PageContainer } from 'feature/common/PageContainer';
import locale from 'antd/es/date-picker/locale/th_TH';
import moment from 'moment';
import { useCouponReports } from 'hooks/coupon/useCouponReports';
import { useLocation } from 'react-router-dom';

const { RangePicker } = DatePicker;

export const CouponReportPage = () => {
  const location = useLocation();

  const [dateFilter, setDateFilter] = useState<[moment.Moment, moment.Moment]>(
    [] as any
  );
  const [searchFilter, setSearchFilter] = useState<string>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [keyword, setKeyword] = useState('');
  const { data: couponReports, loading, refetch, item } = useCouponReports({
    pageNumber: currentPage,
    fromDate: dateFilter && dateFilter[0]?.startOf('date').toISOString(),
    toDate: dateFilter && dateFilter[1]?.endOf('date').toISOString(),
    keyword
  });

  useEffect(() => {
    refetch();
  }, [refetch, location]);

  const handleSearch = (text: string = '') => {
    setKeyword(text.trim());
    setCurrentPage(1);
  };

  return (
    <PageContainer className="p-8">
      <Row justify="space-between" align="middle" className="mb-6">
        <Col>
          <h1 className="text-xl font-bold text-black">รายงานคูปอง</h1>
        </Col>
      </Row>
      <Row justify="space-between" align="middle" className="mb-4">
        <Col>
          <h1 className="text-xl font-bold">คูปองทั้งหมด</h1>
        </Col>
        <Col span={20}>
          <Row justify="end" gutter={8}>
            <Col span={5}>
              <RangePicker
                locale={locale}
                format={DateFormat.DEFAULT}
                onChange={(dates: any) => {
                  setCurrentPage(1);
                  setDateFilter(dates);
                }}
              />
            </Col>
            <Col span={10}>
              <Input
                placeholder="ค้นหาจากชื่อรหัสหรือชื่อคูปอง"
                onChange={(e: any) => {
                  setSearchFilter(e.target.value);
                }}
                onPressEnter={() => handleSearch(searchFilter)}
              />
            </Col>
            <Col span={3}>
              <Button
                type="primary"
                block
                onClick={() => handleSearch(searchFilter)}
              >
                ค้นหา
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <CouponReportTable couponReports={couponReports} loading={loading} />
      {item > 0 && (
        <Pagination
          current={currentPage}
          showSizeChanger={false}
          onChange={(page) => {
            setCurrentPage(page);
          }}
          total={item}
          className="flex justify-end mt-4"
        />
      )}
    </PageContainer>
  );
};
