export const calculateMilliseconds = (
  hours: number = 0,
  minutes: number = 0,
  seconds: number = 0
) => {
  return hours * 3600000 + minutes * 60000 + seconds * 1000;
};

export const millisecondsToHMS = (milliseconds: number) => {
  if (milliseconds === undefined || milliseconds === null)
    return {
      hours: undefined,
      minutes: undefined,
      seconds: undefined
    };
  return {
    hours: Math.floor(milliseconds / (1000 * 60 * 60)) % 24,
    minutes: Math.floor(milliseconds / (1000 * 60)) % 60,
    seconds: Math.floor(milliseconds / 1000) % 60
  };
};
