import { CourseSignature } from 'feature/course/signature/signature';
import useAxios from 'axios-hooks';

export const useCourseSignature = (courseId: string = '') => {
  const [{ data: response, loading, error }, refetch] = useAxios(
    '/course-signatures?pageNumber=1&pageSize=100&courseId=' + courseId
  );

  return {
    data: loading ? [] : (response?.data as CourseSignature[]) || [],
    loading,
    error,
    execute: refetch
  };
};
