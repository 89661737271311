/** @jsx jsx */
import {
  Alert,
  Button,
  Col,
  Form,
  Input,
  Row,
  Spin,
  Upload,
  message
} from 'antd';
import { CloudUploadOutlined, UserOutlined } from '@ant-design/icons';
import { FormRule, getRule } from 'feature/common/Form/utils';
import { HTMLtoState, parseStateToHTML } from 'feature/common/Wysiwyg/utils';
import { PrimaryButton, TextButton } from 'feature/common/Button';
import {
  RefOwnerType,
  RequestUploadUrl,
  UploadStatus,
  useUploadImage
} from 'hooks/useUploadImage';
import { css, jsx } from '@emotion/react';
import {
  mapLectureDTOToLecture,
  mapLectureToLectureDTO
} from 'feature/lecturer/utils';
import { useEffect, useRef, useState } from 'react';

import Avatar from 'antd/lib/avatar/avatar';
import Axios from 'axios';
import { EditorState } from 'draft-js';
import { Lecturer } from './lecturer';
import TextArea from 'antd/lib/input/TextArea';
import { WysiwygEditor } from 'feature/common/Wysiwyg/Editor';
import { useCreateLecturer } from 'hooks/lecturer/useCreateLecturer';
import { useHistory } from 'react-router-dom';
import { useLecturer } from 'hooks/lecturer/useLecturer';

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
};

type Props = {
  lecturerId?: string;
};

const uploadFile = async (
  file: any,
  lecturerId: string,
  refOwnerType: RefOwnerType
) => {
  if (!file.type) return file.url;
  const avatarUrlResponse: {
    data: RequestUploadUrl;
  } = await Axios.post('/assets', {
    refOwnerType,
    refOwnerId: lecturerId,
    filename: file.name,
    contentType: file.type
  });
  await Axios({
    url: avatarUrlResponse.data.data.upload.url,
    params: avatarUrlResponse.data.data.upload.params,
    method: 'PUT',
    data: file.file,
    headers: { 'content-type': file.type }
  });
  return avatarUrlResponse.data.data.url;
};

const updateLecturer = async (id: any, data: any) => {
  return await Axios.put('/lecturers/' + id, {
    ...data
  });
};

export const LecturerForm = (props: Props) => {
  const { lecturerId = null } = props;
  const {
    data,
    loading: lecturerLoading,
    execute: fetchLecturer
  } = useLecturer(lecturerId);
  const [form] = Form.useForm();
  const history = useHistory();
  const [creating, setCreating] = useState(false);
  const loading = creating || lecturerLoading;
  useEffect(() => {
    const fetch = async () => await fetchLecturer();
    fetch();
  }, [history]);
  const {
    fileList: cvFileList,
    status: cvStatus,
    beforeUpload: cvBeforeUpload,
    onRemove: cvRemove,
    setDefaultFile: setDefaultFileCv
  } = useUploadImage(true);
  const {
    fileList: avatarFileList,
    beforeUpload: avatarBeforeUpload,
    onRemove: avatarRemove,
    setDefaultFile: setDefaultFileAvatar
  } = useUploadImage(true);
  const { execute: createLecturer } = useCreateLecturer();
  const [editorProfileState, setEditorProfileState] = useState(
    EditorState.createEmpty()
  );

  const errorRef = useRef(null);
  const [isError, setIsError] = useState(false);
  const showError = () => {
    setIsError(true);
    if (errorRef) {
      const errorContent = errorRef?.current as any;
      errorContent.scrollIntoView();
    }
  };

  const onCreateLecturer = async (lecturer: Lecturer) => {
    try {
      setCreating(true);
      const createLecturerResponse = await createLecturer({
        data: lecturer
      });
      const createdLecturerId = createLecturerResponse.data?.data?.lecturerId;
      let avatarUrl = null;
      let cvUrl = null;
      if (avatarFileList[0]) {
        avatarUrl = await uploadFile(
          avatarFileList[0],
          createdLecturerId,
          RefOwnerType.LECTURER_AVATAR
        );
      }
      if (cvFileList[0]) {
        cvUrl = await uploadFile(
          cvFileList[0],
          createdLecturerId,
          RefOwnerType.LECTURER_CV
        );
      }
      if (cvUrl || avatarUrl)
        await updateLecturer(createdLecturerId, {
          ...lecturer,
          cvUrl,
          avatarUrl
        });
      history.push('/lecturer');
      message.success('สร้างวิทยากรสำเร็จ');
    } catch (error) {
      message.error('สร้างวิทยากรไม่สำเร็จ');
    } finally {
      setCreating(false);
    }
  };
  const onUpdateLecturer = async (lecturer: Lecturer) => {
    try {
      let avatarUrl = null;
      let cvUrl = null;
      if (avatarFileList[0] && lecturerId) {
        avatarUrl = await uploadFile(
          avatarFileList[0],
          lecturerId,
          RefOwnerType.LECTURER_AVATAR
        );
      }
      if (cvFileList[0] && lecturerId) {
        cvUrl = await uploadFile(
          cvFileList[0],
          lecturerId,
          RefOwnerType.LECTURER_CV
        );
      }
      setCreating(true);
      await updateLecturer(lecturerId, { ...lecturer, avatarUrl, cvUrl });
      history.push('/lecturer');
      message.success('แก้ไขวิทยากรสำเร็จ');
    } catch (error) {
      message.error('แก้ไขวิทยากรไม่สำเร็จ');

      setCreating(false);
    } finally {
      setCreating(false);
    }
  };

  const onFinish = async (value: any) => {
    const lecturerDTO = {
      ...value,
      profileTH: parseStateToHTML(editorProfileState),
      profileEN: parseStateToHTML(editorProfileState)
    };
    const lecturer = mapLectureDTOToLecture(lecturerDTO);
    if (lecturerId) {
      await onUpdateLecturer(lecturer);
    } else {
      await onCreateLecturer(lecturer);
    }
  };
  const onFinishFailed = () => {
    showError();
  };

  useEffect(() => {
    if (form && data) {
      const lecturerDTO = mapLectureToLectureDTO(data);
      form.setFieldsValue({
        ...lecturerDTO
      });
      const profile = EditorState.createWithContent(
        HTMLtoState(lecturerDTO.profileTH)
      );
      setEditorProfileState(profile);
      if (data.avatarUrl) setDefaultFileAvatar(data.avatarUrl as any);
      if (data.cvUrl) setDefaultFileCv(data.cvUrl as any);
    }
  }, [data, form]);

  return (
    <Spin spinning={loading}>
      <Form
        name="lecturer-detail"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        form={form}
        onFinishFailed={onFinishFailed}
        className="custom-form font-sa"
        colon={false}
        {...layout}
      >
        <div ref={errorRef}>
          {isError && (
            <Alert
              className="mb-2"
              message="คุณยังกรอกข้อมูลไม่ครบ กรุณาตรวจสอบอีกครั้งค่ะ"
              type="error"
              showIcon
            />
          )}
        </div>
        <Row className="mb-4">
          <h1 className="text-base font-semibold">ประวัติวิทยากร</h1>
        </Row>
        <Row>
          <Col span={12} className="pr-8">
            <Form.Item
              label="ชื่อ - นามสกุล (TH)"
              name="nameTH"
              required
              rules={[getRule(FormRule.REQUIRE)]}
            >
              <Input placeholder="ชื่อ - นามสกุลภาษาไทย" />
            </Form.Item>
            <Form.Item label="ชื่อ - นามสกุล (EN)" name="nameEN">
              <Input placeholder="ชื่อ - นามสกุลภาษาอังกฤษ" />
            </Form.Item>
            <Form.Item label="ตำแหน่งอย่างย่อ (TH)" name="positionTH">
              <TextArea placeholder="ครูชำนาญการ โรงเรียนหมีใหญ่" />
            </Form.Item>
            <Form.Item label="ตำแหน่งอย่างย่อ (EN)" name="positionEN">
              <TextArea placeholder="ครูชำนาญการ โรงเรียนหมีใหญ่" />
            </Form.Item>
            <Form.Item label="ประวัติอย่างย่อ (TH)" name="shortProfileTH">
              <TextArea rows={4} placeholder="ประวัติอย่างย่อภาษาไทย" />
            </Form.Item>
            <Form.Item label="ประวัติอย่างย่อ (EN)" name="shortProfileEN">
              <TextArea rows={4} placeholder="ประวัติอย่างย่อภาษาอังกฤษ" />
            </Form.Item>
            <Form.Item
              label="ประวัติเต็ม"
              className="w-full"
              wrapperCol={{ span: 24 }}
            >
              <WysiwygEditor
                editorState={editorProfileState}
                onEditorStateChange={setEditorProfileState}
              />
            </Form.Item>
            <Form.Item label="CV" name="CV" wrapperCol={{ span: 12 }}>
              <Upload
                onRemove={cvRemove}
                beforeUpload={cvBeforeUpload}
                fileList={cvFileList}
                listType="picture"
              >
                {cvStatus === UploadStatus.EMPTY && (
                  <Button>
                    <CloudUploadOutlined /> อัปโหลด CV
                  </Button>
                )}
              </Upload>
            </Form.Item>
          </Col>
          <Col span={12}>
            <div
              className="avatar"
              css={css`
                display: flex;
                align-items: center;
                flex-direction: column;
                width: 100%;
                .ant-avatar {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  > img {
                    object-fit: contain !important;
                  }
                }
              `}
            >
              <Avatar
                size={200}
                icon={<UserOutlined />}
                src={avatarFileList[0]?.url}
                className="mb-8 "
                style={{ border: '1px solid #EDEDED' }}
              />
              <Form.Item name="avatarImg" wrapperCol={{ span: 12 }}>
                <Upload
                  onRemove={avatarRemove}
                  beforeUpload={avatarBeforeUpload}
                  fileList={avatarFileList}
                  listType="picture"
                >
                  <Button>
                    <CloudUploadOutlined /> อัปโหลดรูป
                  </Button>
                </Upload>
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className="flex justify-end items-center">
              <TextButton
                className="mr-6"
                onClick={() => history.push('/lecturer')}
              >
                ยกเลิก
              </TextButton>
              <PrimaryButton htmlType="submit">บันทึก</PrimaryButton>
            </div>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};
