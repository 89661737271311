import { ContentState, convertToRaw } from 'draft-js';

import { EditorState } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

export const parseStateToHTML = (state: EditorState) =>
  draftToHtml(convertToRaw(state.getCurrentContent()));

export const HTMLtoState = (html: string = '') => {
  const contentBlock = htmlToDraft(html);
  const contentState = ContentState.createFromBlockArray(
    contentBlock.contentBlocks
  );
  return contentState;
};
