/** @jsx jsx */
import { Col, Row, message } from 'antd';
import { RequestUploadUrl, UploadStatus } from 'hooks/useUploadImage';
import { useHistory, useParams } from 'react-router-dom';

import Axios from 'axios';
import { BannerForm } from 'feature/banner/BannerForm';
import { ReactComponent as BreadcrumbsIcon } from 'assets/breadcrumbs.svg';
import { PageContainer } from 'feature/common/PageContainer';
import { jsx } from '@emotion/react';
import moment from 'moment';
import { useBanner } from 'hooks/banner/useBanner';
// import { useBanners } from 'hooks/banner/useBanners';
import { useEditBanner } from 'hooks/banner/useEditBanner';
import { useEffect } from 'react';

interface Params {
  bannerId: string;
}

export const EditBannerPage = () => {
  const history = useHistory();
  const params = useParams<Params>();

  const { data: bannerData, execute: getBanner } = useBanner();
  // const { refetch: getActivateBannerList } = useBanners({ isVisible: true });
  const { execute: editBanner } = useEditBanner();

  const handleUploadImage = async (bannerId: string, file: any) => {
    // console.log('file', file);
    if (!file) return null;
    if (file.status === UploadStatus.EMPTY) return null;
    try {
      const requestCoverImageResponse: {
        data: RequestUploadUrl;
      } = await Axios.post('/assets', {
        refOwnerType: 'banner-thumbnail',
        refOwnerId: bannerId,
        filename: file.name,
        contentType: file.type
      });
      await Axios({
        url: requestCoverImageResponse.data.data.upload.url,
        params: requestCoverImageResponse.data.data.upload.params,
        method: 'PUT',
        data: file,
        headers: { 'content-type': file.type }
      });
      return requestCoverImageResponse.data.data.url;
    } catch (error) {
      console.log(error);
    }
  };

  const onFinish = async (data: any) => {
    // console.log('onFinish', data);
    const payload: any = {
      bannerName: data.bannerName,
      imgUrl: data.bannerImage,
      startDate: data.dateRange[0].toISOString(),
      endDate: data.dateRange[1].toISOString(),
      isVisible: bannerData?.isVisible
    };
    try {
      /*
      if (data.isVisible) {
        const getRes = await getActivateBannerList();
        const activateBannerList = getRes.data.data;
        for (var activateBanner of activateBannerList) {
          if (activateBanner.bannerId !== params.bannerId) {
            var newPayload = {
              bannerName: activateBanner.bannerName,
              imgUrl: activateBanner.imgUrl,
              startDate: activateBanner.startDate,
              endDate: activateBanner.endDate,
              isVisible: false
            };
            await editBanner(newPayload, activateBanner.bannerId);
          }
        }
      }
      */
      let urlBannerImage = null;
      if (data?.bannerImage?.file) {
        urlBannerImage = await handleUploadImage(
          params.bannerId as string,
          data?.bannerImage?.file
        );

        payload.imgUrl = urlBannerImage;
      }
      await editBanner(payload, params.bannerId);
      message.success('แก้ไขแบนเนอร์สำเร็จ');
      history.replace('/banner');
    } catch (error) {
      message.error('แก้ไขแบนเนอร์ไม่สำเร็จ');
      console.log('error', error);
    }
  };

  useEffect(() => {
    getBanner(params.bannerId);
  }, []);

  return (
    <PageContainer className="p-8">
      <Row justify="space-between" align="middle" className="mb-8">
        <Col>
          <div className="flex items-center">
            <h1
              className="text-xl font-bold text-mono-gray-300 cursor-pointer"
              onClick={() => history.push('/banner')}
            >
              จัดการแบนเนอร์
            </h1>
            <BreadcrumbsIcon />
            <h1 className="text-xl font-bold">แก้ไขแบนเนอร์</h1>
          </div>
        </Col>
      </Row>
      <BannerForm
        valueBannerName={bannerData?.bannerName}
        valueBannerImage={bannerData?.imgUrl}
        valueDateRange={[
          moment(bannerData?.startDate),
          moment(bannerData?.endDate)
        ]}
        // valueIsVisible={bannerData?.isVisible}
        onFinish={(data: any) => onFinish(data)}
        handleUploadImage={(courseId: string, file: any) =>
          handleUploadImage(courseId, file)
        }
        mode="edit"
      />
    </PageContainer>
  );
};
