/** @jsx jsx */

import { Button, Col, Input, Radio, Row, Spin, Upload, message } from 'antd';
import {
  ProductPrivilegedType,
  RequestType,
  SchoolRequest,
  SchoolRequestStatus
} from 'feature/school-request/school-request.type';
import { css, jsx } from '@emotion/react';
import {
  getErrorMessage,
  useApproveSchoolRequest
} from 'hooks/school-request/useApproveSchoolRequest';
import { useEffect, useState } from 'react';

import { ReactComponent as Download } from 'assets/download-document.svg';
import { OrderOrderItemDto } from '@aksorn/backoffice-sdk';
import { PrimaryButton } from 'feature/common/Button';
import React from 'react';
import { RejectModal } from './RejectModal';
import { SchoolOrderTable } from '../SchoolOrderTable';
import { SchoolProductTable } from '../SchoolProductTable';
import { SchoolRequestStatusTag } from './SchoolRequestStatusTag';
import _ from 'lodash';
import { schoolEditOrdersState } from 'recoilModule/schoolRequest/atom';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

const { TextArea } = Input;

const productPrivilegedTypeOptions = [
  { label: 'ทั่วไป', value: ProductPrivilegedType.BASIC },
  { label: 'ทดลอง', value: ProductPrivilegedType.PREMIUM }
];

interface SchoolRequestDetailTab {
  schoolRequest: SchoolRequest;
  products: any[] | undefined;
  orders: OrderOrderItemDto[];
  loading: boolean;
}

export const SchoolRequestDetailTab: React.FC<SchoolRequestDetailTab> = ({
  schoolRequest,
  products,
  orders,
  loading
}) => {
  const schoolOrders = useRecoilValue(schoolEditOrdersState);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [privilegeTypeValue, setPrivilegeTypeValue] = useState(
    ProductPrivilegedType.BASIC
  );
  const [uploadFileList, setUploadFileList] = useState<any>([]);

  useEffect(() => {
    if (schoolRequest) {
      if (!_.isEmpty(schoolRequest.identityDocuments)) {
        setUploadFileList([
          ...uploadFileList,
          {
            uid: `${schoolRequest.schoolRequestId}-1`,
            name: `${schoolRequest.schoolRequestId}-ident`,
            status: 'done',
            url: schoolRequest.identityDocuments[0].split('?')[0]
          }
        ]);
      }
      if (!_.isEmpty(schoolRequest.signatureDocuments)) {
        setUploadFileList([
          ...uploadFileList,
          {
            uid: `${schoolRequest.schoolRequestId}-2`,
            name: `${schoolRequest.schoolRequestId}-sign`,
            status: 'done',
            url: schoolRequest.signatureDocuments[0].split('?')[0]
          }
        ]);
      }
      setPrivilegeTypeValue(schoolRequest.productPrivilegedType);
    }
  }, [schoolRequest]);

  const {
    execute: approveSchoolRequest,
    loading: approving
  } = useApproveSchoolRequest();
  const history = useHistory();

  const onApprove = async () => {
    try {
      const newSchoolOrders = { ...schoolOrders };
      newSchoolOrders.orderItems = schoolOrders.orderItems.filter(
        (order) => order.amount > 0
      );
      // no schoolLicense (all of cs is 0)
      // if (newSchoolOrders.orderItems.length === 0) {
      //   message.error('ไม่สามารถอนุมัติคำขอได้ กรุณาระบุจำนวนสื่อ', 5);
      // } else {
      //   await approveSchoolRequest(
      //     schoolRequest?.schoolRequestId,
      //     newSchoolOrders
      //   );
      //   message.success('อนุมัติคำขอเปิดบัญชีโรงเรียนสำเร็จ', 5);
      //   history.goBack();
      // }

      //pun Edit to approve if no orderItems
      await approveSchoolRequest(
        schoolRequest?.schoolRequestId,
        newSchoolOrders
      );
      message.success('อนุมัติคำขอเปิดบัญชีโรงเรียนสำเร็จ', 5);
      history.goBack();
    } catch (error) {
      console.error(error);
      const e = error as any;
      message.error(getErrorMessage(e?.response?.data?.message), 5);
    }
  };

  const download = (fileUrl: string) => {
    try {
      window.open(fileUrl, '_self');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div
      css={css`
        padding-bottom: 80px;
      `}
    >
      <Spin spinning={loading || approving}>
        {schoolRequest?.status !== SchoolRequestStatus.PENDING && (
          <div
            css={css`
              width: 100%;
              background-color: #f8f8f8;
              border-radius: 8px;
              display: flex;
              justify-content: start;
              align-items: center;
              padding: 17.5px;
              margin-bottom: 16px;
            `}
          >
            <SchoolRequestStatusTag schoolRequest={schoolRequest as any} />
          </div>
        )}
        <Row className="mt-2 mb-6">
          <Col span={12}>
            <Row gutter={[8, 8]}>
              <Col span={8}>หมายเลขคำขอ</Col>
              <Col span={12}>{schoolRequest?.schoolRequestId}</Col>
            </Row>
            <Row gutter={[8, 8]}>
              <Col span={8}>โรงเรียน</Col>
              <Col span={12}>{schoolRequest?.school?.schoolName?.th}</Col>
            </Row>
            <Row gutter={[8, 8]}>
              <Col span={8}>ที่อยู่</Col>
              <Col span={12}>{schoolRequest?.address}</Col>
            </Row>
            <Row gutter={[8, 8]}>
              <Col span={8}>จังหวัด</Col>
              <Col span={12}>
                {schoolRequest?.school?.province?.provinceName?.th}
              </Col>
            </Row>
            {schoolRequest?.status === SchoolRequestStatus.REJECTED && (
              <>
                <Row gutter={[8, 8]} className="mt-6">
                  <Col span={8} className="text-base font-bold">
                    เหตุผล
                  </Col>
                </Row>
                <Row gutter={[8, 8]}>
                  <Col span={24}>
                    <TextArea
                      value={schoolRequest?.rejectReason}
                      disabled={true}
                      autoSize={{ minRows: 5, maxRows: 5 }}
                      className="mt-2"
                    />
                  </Col>
                </Row>
              </>
            )}
          </Col>
          <Col span={12}>
            <Row gutter={[8, 8]}>
              <Col span={8}>ที่มา</Col>
              <Col span={12}>
                {schoolRequest?.requestType === RequestType.SALE
                  ? 'Private Link'
                  : 'Public Link'}
              </Col>
            </Row>
            {(schoolRequest?.status === SchoolRequestStatus.PENDING ||
              schoolRequest?.status === SchoolRequestStatus.REJECTED ||
              schoolRequest?.status === SchoolRequestStatus.ACCEPTED) && (
                <>
                  {/* pun comment to hide this feature 15/02/22
                <Row gutter={[8, 8]}>
                  <Col span={8}>ประเภท</Col>
                  <Col span={12}>
                    {schoolRequest?.status === SchoolRequestStatus.PENDING ? (
                      <Radio.Group
                        options={productPrivilegedTypeOptions}
                        optionType="button"
                        value={privilegeTypeValue}
                        defaultValue={ProductPrivilegedType.BASIC}
                        onChange={(e) => setPrivilegeTypeValue(e.target.value)}
                      />
                    ) : (
                      <>
                        {
                          productPrivilegedTypeOptions.find(
                            (opt) => opt.value === privilegeTypeValue
                          )?.label
                        }
                      </>
                    )}
                  </Col>
                </Row> */}
                  <Row gutter={[8, 8]}>
                    <Col span={8}>
                      {schoolRequest?.requestType === RequestType.SALE &&
                        (schoolRequest?.identityDocuments.length > 0 ||
                          schoolRequest?.signatureDocuments.length > 0)
                        ? `รูปภาพ`
                        : `ดาวน์โหลด`}
                    </Col>
                    <Col span={12}>
                      {schoolRequest?.requestType === RequestType.SALE &&
                        (schoolRequest?.identityDocuments.length > 0 ||
                          schoolRequest?.signatureDocuments.length > 0) ? (
                        <div className="flex justify-start items-center">
                          <Upload
                            listType="picture-card"
                            showUploadList={{
                              showRemoveIcon: false
                            }}
                            fileList={uploadFileList}
                          ></Upload>
                        </div>
                      ) : (
                        <div className="flex justify-start items-center">
                          <Button
                            onClick={() =>
                              download(schoolRequest?.requestDocuments[0])
                            }
                            disabled={_.isEmpty(schoolRequest?.requestDocuments)}
                            css={css`
                            margin-right: 8px;
                          `}
                          >
                            <Download />
                            เอกสารโรงเรียน
                          </Button>
                          {schoolRequest?.requestDocuments[0] !==
                            schoolRequest?.salesOrderDocuments[0] && (
                              <Button
                                onClick={() =>
                                  download(schoolRequest?.salesOrderDocuments[0])
                                }
                                disabled={_.isEmpty(
                                  schoolRequest?.salesOrderDocuments
                                )}
                              >
                                <Download />
                                เอกสารการสั่งซื้อ
                              </Button>
                            )}
                        </div>
                      )}
                    </Col>
                  </Row>
                </>
              )}
          </Col>
        </Row>
        {/* pun hide this feature 15/02/22
        {schoolRequest?.status === SchoolRequestStatus.PENDING && (
          <SchoolProductTable
            dataSource={products}
            privilege={privilegeTypeValue}
          />
        )}
        {schoolRequest?.status === SchoolRequestStatus.ACCEPTED && (
          <SchoolOrderTable dataSource={orders} />
        )} */}
      </Spin>
      {schoolRequest?.status === SchoolRequestStatus.PENDING && (
        <div
          className="flex justify-end items-center w-full z-10"
          css={css`
            position: fixed;
            bottom: 0;
            left: 0;
            background-color: #fcfcfd;
            padding: 2rem;
          `}
        >
          <Button
            type="text"
            className="mr-6 w-40 h-12 rounded-lg text-base outline-none"
            onClick={() => {
              history.push('/school-manage');
            }}
            disabled={approving}
          >
            ยกเลิก
          </Button>
          <Button
            className="w-40 h-12 rounded-lg text-base bg-red text-white mr-6 border-red"
            css={css`
              :disabled::before {
                position: absolute;
                top: -1px;
                right: -1px;
                bottom: -1px;
                left: -1px;
                z-index: 1;
                display: block;
                background: #fff;
                border-radius: inherit;
                opacity: 0.35;
                transition: opacity 0.2s;
                content: '';
                pointer-events: none;
              }
            `}
            disabled={approving}
            onClick={() => setModalVisible(true)}
          >
            ไม่อนุมัติ
          </Button>

          <PrimaryButton onClick={onApprove} loading={approving}>
            อนุมัติ
          </PrimaryButton>
        </div>
      )}
      <RejectModal
        visible={modalVisible}
        schoolRequestId={schoolRequest?.schoolRequestId}
        schoolName={schoolRequest?.school?.schoolName?.th}
        type={
          productPrivilegedTypeOptions.find(
            (privileged) =>
              privileged.value === schoolRequest?.productPrivilegedType
          )?.label
        }
        onCancle={() => setModalVisible(false)}
        setVisible={setModalVisible}
      />
    </div>
  );
};
