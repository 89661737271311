import React from 'react';
import { Table } from 'antd';
import moment from 'moment';

export const OverlapBannerTable = ({
  data = [],
  loading = false,
  isOverlapTable = false
}: {
  data: any[];
  loading: boolean;
  isOverlapTable?: boolean;
}) => {
  const columns = [
    {
      title: 'ชื่อแบนเนอร์',
      dataIndex: '',
      key: 'bannerName',
      render: (record: any) => {
        return isOverlapTable ? (
          <span className="flex">
            <a target="_blank" href={record?.imgUrl}>
              <img src={record?.imgUrl || ''} className="w-6 h-6 mr-2" />
            </a>
            <a target="_blank" href={record?.imgUrl}>
              {record?.bannerName || ''}
            </a>
          </span>
        ) : (
          <span className="flex">{record?.bannerName || ''}</span>
        );
      }
    },
    {
      title: 'วัน-เวลา เริ่มแสดงแบนเนอร์',
      dataIndex: 'startDate',
      key: 'startDate',
      render: (startDate: any) =>
        moment.utc(startDate).utcOffset(7).format('DD/MM/YYYY HH:mm')
    },
    {
      title: 'วัน-เวลา สิ้นสุดการแสดงแบนเนอร์',
      dataIndex: 'endDate',
      key: 'endDate',
      render: (endDate: any) =>
        moment.utc(endDate).utcOffset(7).format('DD/MM/YYYY HH:mm')
    }
  ];

  return (
    <React.Fragment>
      <Table
        loading={loading}
        dataSource={data}
        columns={columns}
        rowKey="bannerId"
        pagination={false}
        scroll={{ y: 114 }}
      />
    </React.Fragment>
  );
};
