import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch
} from 'react-router-dom';

import { AddBannerPage } from 'pages/banner/AddBannerPage';
import { AddProductPage } from 'pages/product/AddProductPage';
import { BannerListPage } from 'pages/banner/BannerListPage';
import CertificateRenderPage from 'pages/CertificateRenderPage';
import { CouponCreatePage } from 'pages/coupon/CouponCreatePage';
import { CouponEditPage } from 'pages/coupon/CouponEditPage';
import { CouponPage } from 'pages/coupon/CouponPage';
import { CouponReportDetail } from 'pages/coupon/CouponReportDetail';
import { CouponReportPage } from 'pages/coupon/CouponReportPage';
import { CouponReportSummaryPage } from 'pages/coupon/CouponReportSummaryPage';
import { CourseCreatePage } from 'pages/course/CourseCreatePage';
import { CourseEditPage } from 'pages/course/CourseEditPage';
import { CourseManagePage } from 'pages/course/CourseManagePage';
import { CourseReportDetailPage } from 'pages/course/CourseReportDetailPage';
import { CourseReportPage } from 'pages/course/CourseReportPage';
import { EditBannerPage } from 'pages/banner/EditBannerPage';
import { EditProductPage } from 'pages/product/EditProductPage';
import { LecturerCreatePage } from 'pages/lecturer/LecturerCreatePage';
import { LecturerEditPage } from 'pages/lecturer/LecturerEditPage';
import { LecturerPage } from 'pages/lecturer/LecturerPage';
import LoginPage from 'pages/LoginPage';
import { ProductListPage } from 'pages/product/ProductListPage';
import React from 'react';
import { RecoilRoot } from 'recoil';
import { SchoolManagePage } from 'pages/school-manage/SchoolManagePage';
import { SchoolManageRequestPage } from 'pages/school-manage/SchoolManageRequestPage';
import { Sidebar } from 'feature/common/Sidebar';
import { authState } from 'recoilModule/auth/atom';
import env from '@beam-australia/react-env';
import { useRecoilValue } from 'recoil';

const PrivateRoute = ({ children, withSidebar = true, ...rest }: any) => {
  const auth = useRecoilValue(authState);
  const userToken = localStorage.getItem('userToken');

  const isLogin =
    (auth.isLogin && Boolean(auth.userId)) ||
    userToken === 'backoffice@admin.com';

  return (
    <>
      {withSidebar && <Sidebar />}
      <div className="w-full min-h-screen ">
        <Route
          {...rest}
          render={({ location }: any) =>
            isLogin ? (
              children
            ) : (
              <Redirect
                to={{
                  pathname: '/login',
                  state: { from: location }
                }}
              />
            )
          }
        />
      </div>
    </>
  );
};
const PublicRoute = ({ children, withSidebar = true, ...rest }: any) => {
  return (
    <>
      {withSidebar && <Sidebar />}
      <div className="w-full min-h-screen ">
        <Route {...rest}>{children}</Route>
      </div>
    </>
  );
};

function App() {
  const basePath = env('BASE_PATH') || '/';

  return (
    <RecoilRoot>
      <Router basename={basePath}>
        <div className="min-h-screen w-full flex">
          <Switch>
            <PublicRoute exact path="/login">
              <LoginPage />
            </PublicRoute>
            <PrivateRoute exact path="/lecturer">
              <LecturerPage />
            </PrivateRoute>
            <PrivateRoute exact path="/lecturer/edit">
              <LecturerEditPage />
            </PrivateRoute>
            <PrivateRoute exact path="/lecturer/create">
              <LecturerCreatePage />
            </PrivateRoute>
            <PrivateRoute exact path="/course/manage">
              <CourseManagePage />
            </PrivateRoute>
            <PrivateRoute exact path="/course/report">
              <CourseReportPage />
            </PrivateRoute>
            <PrivateRoute exact path="/course/manage/create">
              <CourseCreatePage />
            </PrivateRoute>
            <PrivateRoute exact path="/course/manage/edit">
              <CourseEditPage />
            </PrivateRoute>
            <PrivateRoute exact path="/course/report/detail">
              <CourseReportDetailPage />
            </PrivateRoute>
            <PrivateRoute exact path="/coupon/manage">
              <CouponPage />
            </PrivateRoute>
            <PrivateRoute exact path="/coupon/report">
              <CouponReportPage />
            </PrivateRoute>
            <PrivateRoute exact path="/coupon/report/detail">
              <CouponReportDetail />
            </PrivateRoute>
            <PrivateRoute exact path="/coupon/manage/create">
              <CouponCreatePage />
            </PrivateRoute>
            <PrivateRoute exact path="/coupon/manage/edit">
              <CouponEditPage />
            </PrivateRoute>
            <PrivateRoute exact path="/product/manage-list">
              <ProductListPage />
            </PrivateRoute>
            <PrivateRoute exact path="/product/manage-list/add">
              <AddProductPage />
            </PrivateRoute>
            <PrivateRoute exact path="/product/manage-list/edit/:productId">
              <EditProductPage />
            </PrivateRoute>
            <PublicRoute exact path="/certificate" withSidebar={false}>
              <CertificateRenderPage />
            </PublicRoute>
            <PublicRoute exact path="/coupon-report" withSidebar={false}>
              <CouponReportSummaryPage />
            </PublicRoute>
            <PrivateRoute exact path="/">
              <Redirect to="/course/manage" />
            </PrivateRoute>
            <PrivateRoute exact path="/school-manage">
              <SchoolManagePage />
            </PrivateRoute>
            <PrivateRoute exact path="/school-manage/detail">
              <SchoolManageRequestPage />
            </PrivateRoute>
            <PrivateRoute exact path="/banner">
              <BannerListPage />
            </PrivateRoute>
            <PrivateRoute exact path="/banner/add">
              <AddBannerPage />
            </PrivateRoute>
            <PrivateRoute exact path="/banner/edit/:bannerId">
              <EditBannerPage />
            </PrivateRoute>
          </Switch>
        </div>
      </Router>
    </RecoilRoot>
  );
}

export default App;

// bump
