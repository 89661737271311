import { Col, Modal, Row, Switch, message } from 'antd';
import { PrimaryButton, TextButton } from 'feature/common/Button';
import React, { useEffect, useState } from 'react';

import { CourseLecturer } from 'feature/course/course.type';
import { ExclamationCircleOutlined } from '@ant-design/icons';

type Props = {
  visible: boolean;
  disableMainLecturer: boolean;
  selectedCourseLecturer: CourseLecturer;
  onEdit: (courseLecturerId: string, isMainLecturer: boolean) => void;
  onClose: () => void;
};

export const EditCourseLecturerModal = (props: Props) => {
  const {
    visible,
    onClose,
    onEdit,
    disableMainLecturer,
    selectedCourseLecturer
  } = props;
  const [creating, setCreating] = useState(false);

  const [isMainLecturer, setIsMainLecturer] = useState(false);
  useEffect(() => {
    if (selectedCourseLecturer) {
      setIsMainLecturer(selectedCourseLecturer.isMainLecturer);
    }
  }, [selectedCourseLecturer]);
  if (!selectedCourseLecturer) return null;
  const _disableMainLecturer =
    disableMainLecturer && !selectedCourseLecturer.isMainLecturer;
  return (
    <Modal
      visible={visible}
      title="แก้ไชวิทยากร"
      footer={null}
      width={640}
      onCancel={onClose}
    >
      <div className="flex  flex-col w-full font-sarabun">
        <Row>
          <Col span={6}>
            <div className="w-32">ชื่อวิทยากร</div>
          </Col>
          <Col span={18}>
            <b>{selectedCourseLecturer?.lecturer?.name?.th}</b>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col span={6}>
            <div className="w-32">วิทยากรหลัก</div>
          </Col>
          <Col span={18}>
            <Switch
              checked={isMainLecturer}
              disabled={_disableMainLecturer}
              onChange={setIsMainLecturer}
            />
            <span className="ml-2">เป็นวิทยากรหลัก</span>
          </Col>
        </Row>
        <Row className="mt-4 mb-32">
          <Col span={6}></Col>
          {_disableMainLecturer && (
            <Col span={18} className="flex items-center">
              <ExclamationCircleOutlined className="mr-4" />
              วิทยากรหลักมีได้คนเดียว คอร์สนี้มีวิทยากรหลักแล้ว
            </Col>
          )}
        </Row>
      </div>

      <Row>
        <Col span={24}>
          <div className="flex justify-end items-center">
            <TextButton className="mr-6" onClick={onClose}>
              ยกเลิก
            </TextButton>
            <PrimaryButton
              loading={creating}
              htmlType="submit"
              onClick={async () => {
                setCreating(true);
                try {
                  await onEdit(
                    selectedCourseLecturer.courseLecturerId,
                    isMainLecturer
                  );
                } catch (error) {
                  message.error('แก้ไขวิทยากรในคอร์สไม่สำเร็จ');
                } finally {
                  setCreating(false);
                }
              }}
            >
              บันทึก
            </PrimaryButton>
          </div>
        </Col>
      </Row>
    </Modal>
  );
};
