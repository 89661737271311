/** @jsx jsx */
import { ProductType, useProducts } from 'hooks/product/useProducts';
import {
  SchoolRequestStatus,
  SchoolRequest as SchoolRequestType
} from '../school-request.type';

import { ProductListDto } from '@aksorn/backoffice-sdk';
import { SchoolRequestContactTab } from './component/SchoolRequestDetail/SchoolRequestContactTab';
import { SchoolRequestDetailTab } from './component/SchoolRequestDetail/SchoolRequestDetailTab';
import { Tabs } from 'antd';
import { jsx } from '@emotion/react';
import { useEffect, useState } from 'react';
import { useOrders } from 'hooks/order/useOrders';
import { useSchoolRequest } from 'hooks/school-request/useSchoolRequest';
import { useSchoolRequestOrders } from 'hooks/school-request/useSchoolRequestOrders';
import { getSchoolTeachersBySchoolId } from 'api/school-teacher/getSchoolTeachers';

const { TabPane } = Tabs;

interface orderObject {
  [key: string]: {
    sku: string;
    amount: number;
  };
}

export const SchoolRequest = ({
  schoolRequestId
}: {
  schoolRequestId: string;
}) => {
  const [schoolTeachers, setSchoolTeachers] = useState<any[]>([]);

  const {
    data: schoolRequest,
    loading: fetchingRequest,
    execute: fetchRequest
  } = useSchoolRequest();
  const {
    data: products,
    loading: fetchingProducts,
    execute: fetchProducts
  } = useProducts();

  const {
    data: schoolOrders,
    loading: fetchingSchoolOrders,
    execute: fetchSchoolOrders
  } = useSchoolRequestOrders();

  const {
    data: orders,
    loading: fetchingOrders,
    execute: fetchOrder
  } = useOrders();

  useEffect(() => {
    handleFetchRequest();
  }, [schoolRequestId]);

  const handleFetchRequest = async () => {
    try {
      const response = await fetchRequest(schoolRequestId);
      const {
        data: schoolRequest
      }: { data: SchoolRequestType } = response?.data;

      const { data: schoolTeacherDataList } = await getSchoolTeachersBySchoolId(schoolRequest.school.schoolId);

      const additionalRequestors = schoolRequest?.additionalRequestors ?? [];
      const allRequestorEmails = additionalRequestors.map(
        (additionalRequestor) => additionalRequestor.email
      ).concat([schoolRequest.requestor.email]);

      const filterSchoolTeacherDataList = schoolTeacherDataList.filter(
        (schoolTeacher: any) => !(allRequestorEmails.includes(schoolTeacher.email))
      )

      setSchoolTeachers(filterSchoolTeacherDataList);

      if (schoolRequest.status === SchoolRequestStatus.PENDING) {
        fetchProducts(ProductType.BOOK, 1, 100);
        fetchSchoolOrders(schoolRequest.school.schoolId);
      } else if (schoolRequest.status === SchoolRequestStatus.ACCEPTED) {
        if (schoolRequest.order) {
          fetchOrder(schoolRequest.order.orderId);
        }
      }
    } catch (error) {
      console.log('error');
    }
  }

  const orderObj: orderObject = {};
  schoolOrders?.orderItems.forEach((order) => {
    orderObj[order.sku] = { ...order };
  });

  const schoolOrderProducts = products?.products.map(
    (product: ProductListDto) => {
      // set premiumSku if null
      if (!product.premiumSku) {
        product.premiumSku = product.basicSku;
      }

      const { basicSku, premiumSku } = product;
      if (orderObj[basicSku]) {
        return {
          ...product,
          erp: orderObj[basicSku].amount,
          cs: orderObj[basicSku].amount
        };
      } else if (orderObj[premiumSku]) {
        return {
          ...product,
          erp: orderObj[premiumSku].amount,
          cs: orderObj[premiumSku].amount
        };
      } else {
        return {
          ...product,
          erp: 0,
          cs: 0
        };
      }
    }
  );

  return (
    <Tabs>
      <TabPane tab="รายละเอียด" key="1">
        <SchoolRequestDetailTab
          schoolRequest={schoolRequest}
          products={schoolOrderProducts || []}
          orders={orders?.orderItems || []}
          loading={
            fetchingRequest ||
            fetchingProducts ||
            fetchingSchoolOrders ||
            fetchingOrders
          }
        />
      </TabPane>
      <TabPane tab="ผู้ติดต่อ" key="2">
        <SchoolRequestContactTab
          refetch={handleFetchRequest}
          schoolRequest={schoolRequest}
          loading={fetchingRequest}
          schoolTeachers={schoolTeachers}
        />
      </TabPane>
    </Tabs>
  );
};
