import useAxios from 'axios-hooks';

export const useCourseSection = (courseId: string) => {
  const [{ data, loading, error }, refetch] = useAxios(
    `/course-sections?courseId=${courseId}&pageNumber=1&pageSize=100`
  );

  return {
    data: data?.data || null,
    loading: loading,
    error,
    execute: refetch
  };
};
