import useAxios from 'axios-hooks';

type GradeLVsResponse = {
  gradeLevelId?: string;
  name?: {
    th?: string;
    en?: string;
  };
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  deletedBy?: string;
  deletedAt?: string;
};

export const useGradeLVs = () => {
  const [{ data, loading, error }, refetch] = useAxios(
    '/grade-levels?pageNumber=1&pageSize=100'
  );

  const res = loading ? [] : (data?.data as GradeLVsResponse[]) || [];
  const gradeLvsOption = (res as GradeLVsResponse[]).map((s) => ({
    value: s.gradeLevelId as string,
    label: `${s.name?.th}` as string
  }));
  return {
    data: gradeLvsOption,
    loading,
    error,
    execute: refetch
  };
};
