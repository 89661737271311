import { CourseSession } from 'feature/course/session/CourseSessionType';
import { School } from './school.type';

export interface CouponCourseSession {
  couponCourseSessionId: string;
  courseSession: CourseSession;
  createdBy?: string;
  createdAt: string;
  updatedBy?: string;
  updatedAt: string;
  deletedBy?: string;
  deletedAt?: string;
}

export enum CouponLevel {
  STUDENT = 'student',
  TEACHER = 'teacher'
}
export enum CouponStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive'
}
export interface Coupon {
  couponId: string;
  couponCode: string;
  name: {
    th?: string;
    en?: string;
  };
  couponLevel: CouponLevel;
  quotaLimit: number;
  quotaUsage: number;
  couponStartDate: string;
  couponExpiryDate: string;
  numberOfSessions: number;
  numberOfSchools: number;
  createdBy?: string;
  createdAt: string;
  updatedBy?: string;
  updatedAt: string;
  deletedBy?: string;
  deletedAt: string;
  status: CouponStatus;
}

export type CouponSchool = {
  couponSchoolId: string;
  school: School;
  createdBy: string;
  createdAt: Date;
  updatedBy: string;
  updatedAt: Date;
  deletedBy: string;
  deletedAt: Date;
};

export type CouponSchoolCreateBody = {
  couponId: string;
  schoolId: string;
};
export type CouponSchoolEmail = {
  couponSchoolEmailId: string;
  email: string;
};
