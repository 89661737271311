import { Button, Modal, Row } from 'antd';

import { CheckCircleTwoTone } from '@ant-design/icons';
import React from 'react';
import { useHistory } from 'react-router-dom';

export const CourseSuccessModal = (props: any) => {
  const { visible, courseId } = props;
  const history = useHistory();
  return (
    <Modal
      title={null}
      visible={visible}
      style={{ borderRadius: 16 }}
      width={800}
      closable={false}
      footer={null}
    >
      <div className="w-full flex flex-col  items-center">
        <CheckCircleTwoTone
          className="mt-12"
          twoToneColor="#52c41a"
          style={{ fontSize: 100 }}
        />
        <h1 className="text-3xl mt-6">สร้างคอร์สสำเร็จ</h1>
        <Row className="mt-8 mb-12 relative">
          <Button
            className="mr-2"
            onClick={() => history.push('/course/manage')}
          >
            กลับหน้าหลัก
          </Button>
          <Button
            type="primary"
            className="ml-2"
            onClick={() =>
              history.push(
                '/course/manage/edit?courseId=' + courseId + '&tab=2'
              )
            }
          >
            เพิ่มข้อมูลอื่นๆ
          </Button>
        </Row>
      </div>
    </Modal>
  );
};
