import { UserCourseSessionCertificateGetResponse } from '@aksorn/backoffice-sdk';
import useAxios from 'axios-hooks';

export const useGetUserCourseSessionCertificate = () => {
  const [
    { data: response, loading },
    fire
  ] = useAxios<UserCourseSessionCertificateGetResponse>({}, { manual: true });

  const execute = ({
    userCourseSessionId
  }: {
    userCourseSessionId: string;
  }) => {
    return fire({
      url: `user-course-session/${userCourseSessionId}/certificate`
    });
  };

  return {
    data: response?.data,
    loading,
    execute
  };
};
