import { identity, pickBy } from 'lodash';

import { CourseSessionReportCouponListResponse } from '@aksorn/backoffice-sdk';
import useAxios from 'axios-hooks';

type QueryCoupon = {
  courseSessionId: string;
  pageNumber?: number;
  pageSize?: number;
  fromDate?: string;
  toDate?: string;
  keyword?: string;
};

export const useCourseSessionReportCoupons = (params: QueryCoupon) => {
  const { courseSessionId } = params;
  const [
    { data: response, loading, error },
    refetch
  ] = useAxios<CourseSessionReportCouponListResponse>(
    {
      url: `/course-session-reports/${courseSessionId}/coupons`,
      params: {
        pageNumber: 1,
        pageSize: 10,
        ...pickBy(params, identity)
      }
    },
    { manual: true }
  );

  return {
    data: response?.data || [],
    loading: loading,
    error,
    refetch,
    item: response?.meta?.pagination?.total || 0
  };
};
