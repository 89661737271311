/** @jsx jsx */
import { Button, Form, Input, message } from 'antd';

import Axios from 'axios';
import { jsx } from '@emotion/react';
import queryString from 'query-string';
import { useForm } from 'antd/lib/form/Form';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';

// https://backoffice.aksorn-dev.mydevkit.me/coupon-report?couponCourseSessionReportId=1a19159f-5ca4-4c91-8b63-8ba300c5678b
export const CouponReportSummaryPage = () => {
  const location = useLocation();

  const { couponCourseSessionReportId } = queryString.parse(location.search);
  const [loading, setLoading] = useState(false);

  const [form] = useForm();

  const getReportData = async (password: string) => {
    try {
      setLoading(true);
      const pdfResponse = await Axios.get(
        '/course-report-automation/' + couponCourseSessionReportId,
        {
          headers: {
            'x-report-password': password
          }
        }
      );
      window.open(pdfResponse?.data?.data?.attachmentUrl);
    } catch (error) {
      message.error('Wrong password');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-full flex items-center justify-center flex-col min-h-screen">
      <div className="text-2xl mb-4">กรอก password 4 หลัก</div>
      <Form
        form={form}
        onFinish={async (value) => {
          await getReportData(value.password);
        }}
      >
        <Form.Item name="password">
          <Input.Password maxLength={4} onPressEnter={() => form.submit()} />
        </Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="mx-auto"
          loading={loading}
        >
          Enter
        </Button>
      </Form>
    </div>
  );
};
