/** @jsx jsx */
import { Avatar, Col, Pagination, Popconfirm, Row, Table } from 'antd';
import { DeleteTwoTone, FormOutlined, UserOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
// import _ from 'lodash';
import { css, jsx } from '@emotion/react';

import Axios from 'axios';
import { Lecturer } from './lecturer';
import { Localization } from 'feature/common/global.type';
import { useHistory } from 'react-router-dom';
import { useLecturers } from 'hooks/lecturer/useLecturers';

const renderLocalization = (localization: Localization) => (
  <span>{localization.th}</span>
);

export const LecturerTable = ({ keyword = '' }: { keyword?: string }) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { data, loading, execute, item } = useLecturers({
    pageNumber: currentPage,
    keyword
  });
  useEffect(() => {
    setCurrentPage(1);
  }, [keyword]);
  const history = useHistory();
  useEffect(() => {
    const refetch = async () => await execute();
    refetch();
  }, [history, execute]);
  const columns = [
    {
      title: '',
      dataIndex: 'avatarUrl',
      key: 'avatarUrl',
      render: (img: any) => (
        <div
          css={css`
            .ant-avatar {
              display: flex;
              justify-content: center;
              align-items: center;
              > img {
                object-fit: contain !important;
              }
            }
          `}
        >
          <Avatar icon={<UserOutlined />} src={img} />
        </div>
      ),
      width: 65
    },
    {
      title: 'ชื่อ',
      dataIndex: 'name',
      key: 'name',
      width: 300,
      render: renderLocalization
    },
    {
      title: 'ตำแหน่งวิทยากร',
      dataIndex: 'position',
      key: 'position',
      render: renderLocalization
    },

    {
      title: '',
      dataIndex: '',
      key: 'x',
      width: 100,
      render: (text: any, record: Lecturer) => {
        return (
          <Row gutter={4}>
            <Col>
              <a
                className="block px-2 text-center"
                onClick={() =>
                  history.push(
                    '/lecturer/edit?lecturerId=' + record?.lecturerId
                  )
                }
              >
                <FormOutlined style={{ color: '#1890FF' }} />
              </a>
            </Col>
            <Col>
              <Popconfirm
                title="วิทยากรท่านนี้จะถูกนำออกจากครอสและ รอบอบรมด้วย คุณแน่ใจใช่หรือไม่?"
                okText="ลบ"
                cancelText="ยกเลิก"
                placement="bottom"
                onConfirm={async () => {
                  try {
                    await Axios.delete('/lecturers/' + record?.lecturerId);
                    await execute();
                  } catch (error) {}
                }}
              >
                <DeleteTwoTone twoToneColor="#F03D3E" />
              </Popconfirm>
            </Col>
          </Row>
        );
      }
    }
  ];

  return (
    <React.Fragment>
      <Table
        loading={loading}
        dataSource={data}
        columns={columns}
        rowKey="id"
        pagination={false}
      />
      <Pagination
        current={currentPage}
        showSizeChanger={false}
        onChange={(page) => {
          setCurrentPage(page);
        }}
        total={item}
        className="flex justify-end mt-4"
      />
    </React.Fragment>
  );
};
