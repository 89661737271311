/** @jsx jsx */
import {
  Button,
  Col,
  Empty,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Table,
  Typography,
  message
} from 'antd';
import { Fragment, useEffect, useState } from 'react';
import { PrimaryButton, TextButton } from 'feature/common/Button';
import { css, jsx } from '@emotion/react';

import { CouponSchoolCreateBody } from './coupon.type';
import { Province } from './province.type';
import { School } from './school.type';
import { SchoolGroup } from './schoolGroup.type';
import { SearchOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { getSchools } from 'api/school/getSchools';
import { useCreateCouponSchool } from 'hooks/couponSchool/useCreateCouponSchool';
import { useProvinces } from 'hooks/province/useProvinces';
import { useSchoolGroups } from 'hooks/schoolGroup/useSchoolGroups';

const { Option } = Select;

type ManageCouponSchoolModalProps = {
  visible: boolean;
  couponId: string;
  selectedSchoolIds: string[];
  onClose: () => void;
  refetch: () => void;
};

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
};

const columns = [
  {
    title: 'รายขื่อโรงเรียน',
    dataIndex: ['schoolAliasName', 'th'],
    key: 'schoolName',
    width: 200
  },
  {
    title: 'สังกัดโรงเรียน',
    dataIndex: ['schoolGroup', 'schoolGroupName', 'th'],
    key: 'schoolGroupName',
    width: 200
  },
  {
    title: 'จังหวัด',
    dataIndex: ['province', 'provinceName', 'th'],
    key: 'province',
    width: 100
  }
];

export const ManageCouponSchoolModal = ({
  visible,
  couponId,
  selectedSchoolIds,
  onClose,
  refetch
}: ManageCouponSchoolModalProps) => {
  const { data: provinces } = useProvinces();
  const { data: schoolGroups } = useSchoolGroups();

  const { execute: createCouponSchool } = useCreateCouponSchool();

  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const [filteredSchools, setFilteredSchools] = useState<School[]>([]);
  const [fetching, setFetching] = useState<boolean>(false);

  const [form] = Form.useForm();

  useEffect(() => {
    setSelectedRowKeys(selectedSchoolIds);
  }, [selectedSchoolIds]);

  const handleSearchSchools = async (value: any) => {
    try {
      setFetching(true);
      const data = {
        keyword: value.schoolName,
        provinceId: value.provinceId,
        schoolGroupId: value.schoolGroupId
      };
      const searchSchools = await getSchools(data).catch((error) => {
        console.error(error);
        message.error('ดึงข้อมูลโรงเรียนไม่สำเร็จ');
      });
      setFilteredSchools(searchSchools);
    } catch (error) {
      console.error(error);
    } finally {
      setFetching(false);
    }
  };

  const handleAddCouponSchool = async () => {
    const loading = message.loading('กำลังดำเนินการ...', 0);
    try {
      const data = _.chain(selectedRowKeys)
        .difference(selectedSchoolIds)
        .map(
          (schoolId: string) =>
            ({
              couponId: couponId,
              schoolId: schoolId
            } as CouponSchoolCreateBody)
        )
        .value();
      await createCouponSchool({ data });

      await refetch();
      loading();
      message.success('บันทึกสำเร็จ');
    } catch (error) {
      loading();
      message.error('บันทึกไม่สำเร็จ');
      console.error(error);
    } finally {
      onClose();
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys: any) => {
      setSelectedRowKeys(selectedRowKeys);
    },
    getCheckboxProps: (record: School) => ({
      disabled: selectedSchoolIds.includes(record?.schoolId)
    })
  };

  return (
    <Modal
      title="เพิ่มโรงเรียน"
      width={1000}
      visible={visible}
      onCancel={onClose}
      afterClose={() => {
        setFilteredSchools([]);
        form.resetFields();
      }}
      centered
      bodyStyle={{ padding: '0' }}
      css={css`
        .ant-modal-body {
          min-height: 540px;
        }
        .ant-select-clear {
          top: 43% !important;
        }
      `}
      footer={
        <Row>
          <Col span={24}>
            <div className="flex justify-end items-center">
              <TextButton className="mr-6" onClick={onClose}>
                ยกเลิก
              </TextButton>
              <PrimaryButton onClick={handleAddCouponSchool}>
                บันทึก
              </PrimaryButton>
            </div>
          </Col>
        </Row>
      }
    >
      <Form form={form} onFinish={handleSearchSchools}>
        <Row className="p-6">
          <Col span={4} className="pr-2">
            <Form.Item name="provinceId">
              <Select allowClear className="w-full" placeholder="จังหวัด">
                {provinces?.map((province: Province) => (
                  <Option
                    key={province?.provinceId}
                    value={province?.provinceId}
                  >
                    {province?.provinceName?.th}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={10} className="pr-2">
            <Form.Item name="schoolGroupId">
              <Select
                allowClear
                className="w-full"
                placeholder="สังกัดโรงเรียน"
              >
                {schoolGroups?.map((schoolGroup: SchoolGroup) => (
                  <Option
                    key={schoolGroup?.schoolGroupId}
                    value={schoolGroup?.schoolGroupId}
                  >
                    {schoolGroup?.schoolGroupName?.th}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8} className="pr-2">
            <Form.Item name="schoolName">
              <Input className="w-full" placeholder="ชื่อโรงเรียน" />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Button
              loading={fetching}
              type="primary"
              className="w-full"
              htmlType="submit"
            >
              ค้นหา
            </Button>
          </Col>
        </Row>
      </Form>
      {filteredSchools?.length > 0 ? (
        <Fragment>
          <Row className="pb-4 px-6">
            <div
              className="text-sm"
              css={css`
                color: #7c7c7c;
              `}
            >
              จำนวน {filteredSchools.length} โรงเรียน
            </div>
          </Row>
          <Table
            loading={fetching}
            rowKey="schoolId"
            rowSelection={rowSelection}
            columns={columns}
            dataSource={filteredSchools}
            pagination={{ pageSize: 5 }}
            {...layout}
            css={css`
              .ant-table-cell {
                color: #6d6e71;
              }
              .ant-pagination {
                margin-right: 1rem;
              }
            `}
          />
        </Fragment>
      ) : (
        <Empty
          className="my-16"
          css={css`
            padding: 2em;
            display: flex;
            flex-direction: column;
            align-items: center;
          `}
          imageStyle={{ display: 'none' }}
          description={
            <Typography.Text className="flex flex-col">
              <SearchOutlined style={{ fontSize: '65px', color: '#495057' }} />
              <Typography.Text className="text-xl text-black my-4 font-sans">
                กรุณาค้นหาโรงเรียน
              </Typography.Text>
              <Typography.Text className="text-gray-900">
                กรุณาเลือกโรงเรียนที่สามารถใช้คูปองได้
              </Typography.Text>
            </Typography.Text>
          }
        />
      )}
    </Modal>
  );
};
