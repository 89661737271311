/** @jsx jsx */
import {
  Button,
  Col,
  ConfigProvider,
  DatePicker,
  Form,
  Input,
  Row,
  Upload
} from 'antd';
import { Global, css, jsx } from '@emotion/react';
import React, { useEffect, useState } from 'react';

import Axios from 'axios';
import { OverlapBannerModal } from './OverlapBannerModal';
import { UploadOutlined } from '@ant-design/icons';
import locale from 'antd/es/locale/th_TH';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useUploadImage } from 'hooks/useUploadImage';

const { RangePicker } = DatePicker;

export interface BannerFieldsValue {
  bannerName: string | null;
  bannerImage: any;
  dateRange: any;
  // isVisible: boolean;
}

export interface BannerFormProps {
  valueBannerName?: string | null;
  valueBannerImage?: string | null;
  valueDateRange?: any | null;
  // valueIsVisible?: boolean;
  onFinish: (data: any) => void;
  handleUploadImage: (courseId: any, file: any) => void;
  mode: 'add' | 'edit';
}

interface Params {
  bannerId: string;
}

export const BannerForm: React.FC<BannerFormProps> = (props) => {
  // const history = useHistory();
  const params = useParams<Params>();
  const [form] = Form.useForm<BannerFieldsValue>();
  const [fileList, setFileList] = useState<any>([]);
  const { beforeUpload: bannerImageBeforeUpload } = useUploadImage();
  // const [isActivate, setIsActivate] = useState<boolean>(!!props?.valueIsVisible);
  const [checkOverlapLoading, setCheckOverlapLoading] = useState<boolean>(
    false
  );
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [overlapBannerList, setOverlapBannerList] = useState<any>([]);
  const [currentBannerList, setCurrentBannerList] = useState<any>([]);
  const [isAllowSetFormValue, setIsAllowSetFormValue] = useState<boolean>(true);

  function dummyRequest({ onSuccess }: any) {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  }

  function handleChange(file: any) {
    const isJpgOrPng =
      file.file.type === 'image/jpeg' || file.file.type === 'image/png';

    if (file.fileList.length === 0 || !isJpgOrPng) {
      setFileList([]);
      form.setFieldsValue({ bannerImage: null });
    } else setFileList(file.fileList);
  }

  const removeFile = () => {
    form.setFieldsValue({ bannerImage: null });
  };

  const layoutSingleCol = {
    labelCol: {
      xs: { span: 12 },
      sm: { span: 12 },
      md: { span: 8 },
      lg: { span: 6 },
      xl: { span: 4 }
    },
    wrapperCol: {
      sm: { span: 8 }
    }
  };

  useEffect(() => {
    setFileList((prev: any) => {
      if (props?.valueBannerImage) {
        return [
          {
            name: 'image.jpg',
            url: props?.valueBannerImage
          }
        ];
      } else {
        return prev;
      }
    });
    // console.log('props', props);
  }, [props]);

  useEffect(() => {
    // console.log('props', props);
    if (!!props && isAllowSetFormValue) {
      // console.log('trigger');
      // setIsActivate(!!props?.valueIsVisible);
      form.setFieldsValue({
        bannerName: props?.valueBannerName,
        bannerImage: props?.valueBannerImage,
        dateRange: props?.valueDateRange
        // isVisible: props?.valueIsVisible
      });
    }
    // console.log('form', form.getFieldsValue());
  }, [props, isAllowSetFormValue]);

  const checkTimestampOverlap = async (data: any) => {
    setCheckOverlapLoading(true);
    const { bannerName, dateRange } = form.getFieldsValue();
    try {
      const res = await Axios.get('/banners/overlap-banner-list', {
        params: {
          startDate: dateRange[0].toISOString(),
          endDate: dateRange[1].toISOString()
        }
      });
      // console.log('res.data.data', res.data.data);
      var overlapBannerListData = res.data.data;
      if (props?.mode === 'edit') {
        overlapBannerListData = res.data.data.filter(
          (banner: any) => banner.bannerId !== params?.bannerId
        );
      }
      // console.log('overlapBannerListData', overlapBannerListData);
      setOverlapBannerList(overlapBannerListData);
      if (!!overlapBannerListData.length) {
        // console.log('overlap found!!');
        // console.log('overlapBannerList', overlapBannerListData);
        var tempList = [];
        const currentBannerData = {
          bannerName: bannerName,
          startDate: dateRange[0].toISOString(),
          endDate: dateRange[1].toISOString()
        };
        tempList.push(currentBannerData);
        setCurrentBannerList(tempList);
        setIsModalVisible(true);
      } else {
        // console.log('no overlap');
        setIsAllowSetFormValue(false);
        props?.onFinish(data);
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      setCheckOverlapLoading(false);
    }
  };

  return (
    <div>
      <Global
        styles={css`
          .ant-upload-list-picture .ant-upload-list-item {
            height: 32px;
            margin: 0;
          }
          .ant-upload-list-picture .ant-upload-list-item-thumbnail {
            height: 24px;
            width: 24px;
            pointer-events: none;
          }
          .ant-upload-list-picture .ant-upload-list-item-thumbnail img {
            height: 24px;
            width: 24px;
          }
        `}
      />
      <OverlapBannerModal
        isVisible={isModalVisible}
        setIsVisible={setIsModalVisible}
        mode={props?.mode}
        currentBannerList={currentBannerList}
        overlapBannerList={overlapBannerList}
        loading={checkOverlapLoading}
      />
      <Form
        onFinish={(data) => {
          // console.log('data', data);
          checkTimestampOverlap(data);
        }}
        form={form}
        initialValues={
          {
            bannerName: props?.valueBannerName,
            bannerImage: props?.valueBannerImage,
            dateRange: props?.valueDateRange
            // isVisible: props?.valueIsVisible
          } as BannerFieldsValue
        }
      >
        <Row>
          <Col span="24">
            <Form.Item
              {...layoutSingleCol}
              name="bannerName"
              label="ชื่อแบนเนอร์"
              colon={false}
              required={true}
              rules={[{ required: true, message: 'กรุณากรอก ชื่อแบนเนอร์' }]}
            >
              <Input placeholder="ชื่อแบนเนอร์" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span="24">
            <Form.Item
              {...layoutSingleCol}
              name="bannerImage"
              label="รูปภาพแบนเนอร์"
              colon={false}
              required={true}
              rules={[{ required: true, message: 'กรุณาเลือก รูปภาพแบนเนอร์' }]}
            >
              <Upload
                customRequest={dummyRequest}
                fileList={fileList}
                listType="picture"
                maxCount={1}
                onChange={handleChange}
                onRemove={removeFile}
                beforeUpload={bannerImageBeforeUpload}
                showUploadList={{ showPreviewIcon: false }}
              >
                {fileList.length > 0 ? null : (
                  <Button icon={<UploadOutlined />}>Upload</Button>
                )}
              </Upload>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span="24">
            <ConfigProvider locale={locale}>
              <Form.Item
                {...layoutSingleCol}
                name="dateRange"
                label="ช่วงเวลาที่แสดงแบนเนอร์"
                colon={false}
                required={true}
                rules={[
                  {
                    required: true,
                    message: 'กรุณาเลือก ช่วงเวลาที่แสดงแบนเนอร์'
                  }
                ]}
              >
                <RangePicker
                  ranges={{
                    ภายในวันนี้: [
                      moment().startOf('day'),
                      moment().add(1, 'days').startOf('day')
                    ],
                    ภายในสัปดาห์นี้: [
                      moment().startOf('week'),
                      moment().endOf('week').add(1, 'days').startOf('day')
                    ],
                    ภายในเดือนนี้: [
                      moment().startOf('month'),
                      moment().endOf('month').add(1, 'days').startOf('day')
                    ]
                  }}
                  showTime={{ format: 'HH:mm' }}
                  format="DD/MM/YYYY HH:mm"
                  onChange={(dates: any) => {
                    var newDates = dates;
                    if (dates) {
                      // console.log('dates', dates);
                      newDates[0] = dates[0]
                        .startOf('minute')
                        .add(1, 'milliseconds');
                      // console.log('newDates', newDates);
                      newDates[1] = dates[1].startOf('minute');
                    }
                    form.setFieldsValue({ dateRange: newDates });
                  }}
                  className="w-full"
                />
              </Form.Item>
            </ConfigProvider>
          </Col>
        </Row>
        {/* <Row>
          <Col span="24">
            <Form.Item
              {...layoutSingleCol}
              name="isVisible"
              label="การใช้งานแบนเนอร์"
              colon={false}
            >
              <Switch
                checked={isActivate}
                onClick={() => setIsActivate(!isActivate)}
                checkedChildren="เปิด"
                unCheckedChildren="ปิด"
                className={isActivate ? 'bg-green-600' : ''}
              />
            </Form.Item>
          </Col>
        </Row> */}

        <Row justify="end">
          <div className="flex justify-end">
            {/* <Button
              className="w-full md:w-32 h-12 border-none text-base"
              onClick={() => history.push('/product/manage-list')}
            >
              ยกเลิก
            </Button> */}
            <Button
              className="w-full md:w-32 h-12 text-white text-base rounded-lg"
              type="primary"
              htmlType="submit"
              loading={checkOverlapLoading}
              /*
              onClick={() => {
                checkTimestampOverlap();
              }}
              */
            >
              {props?.mode === 'add' ? 'เพิ่มแบนเนอร์' : 'บันทึก'}
            </Button>
          </div>
        </Row>
      </Form>
    </div>
  );
};
