import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  Switch,
  Table,
  message
} from 'antd';
import {
  CertificateCondition,
  CourseSession,
  CourseSessionLessonIdWithQuiz,
  Mode
} from 'feature/course/session/CourseSessionType';
import {
  CourseLecturer,
  CourseLesson,
  CourseLessonType,
  CourseSection,
  SessionCondition,
  SessionStatus,
  SessionType
} from 'feature/course/course.type';
import { FormRule, getRule } from 'feature/common/Form/utils';
import { Global, css } from '@emotion/react';
import { PrimaryButton, TextButton } from 'feature/common/Button';
import React, { useEffect, useState } from 'react';
import { toEndOfDay, toStartOfDay } from 'utils/normalizeForm';

import { CourseSessionCertificateCondition } from 'feature/course/session/CourseSessionCertificateCondition';
import { CourseSessionGenerateDateCertificate } from 'feature/course/session/CourseSessionGenerateDateCertificate';
import { CourseSessionLecturer } from './CourseSessionType';
import { CourseSessionSolutionReviewDate } from 'feature/course/session/CourseSessionSolutionReviewDate';
import { CourseSignatureDto } from '@aksorn/backoffice-sdk';
import { ReactComponent as DeleteIcon } from 'assets/delete.svg';
import { PlusOutlined } from '@ant-design/icons';
import _ from 'lodash';
import moment from 'moment';
import { useCourseLecturers } from 'hooks/courseLecturer/useCourseLecturer';
import { useCourseSection } from 'hooks/session/useCourseSection';
import { useCourseSignatures } from 'hooks/session/useCourseSignatures';
import { useCreateSession } from 'hooks/session/useCreateSession';
import { useLocation } from 'react-router-dom';

const { RangePicker } = DatePicker;

const { Option } = Select;

interface Props {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  courseId: string;
  refetch: () => void;
}

interface SelectItem {
  label?: string;
  value: string;
  key?: string;
}

export const CourseSessionAddSession = (props: Props) => {
  const { visible, setVisible, courseId, refetch } = props;
  const [form] = Form.useForm();

  const {
    data: dataOfLecturers,
    loading: useLecturersLoading
  } = useCourseLecturers(courseId);
  const {
    data: dataOfSections,
    loading: useSectionLoading,
    execute: fetchCourseSection
  } = useCourseSection(courseId);

  const {
    data: signatures,
    loading: useSignatureLoading,
    execute: fetchSignature
  } = useCourseSignatures(courseId);

  const [saving, setSaving] = useState<boolean>(false);

  const [learningStartDates, setLearningStartDates] = useState<string[]>(
    new Array(dataOfSections?.length)
  );

  const [
    importantExerciseIdInTables,
    setImportantExerciseIdInTables
  ] = useState<string[]>([]);

  const [
    lessonWithQuizScheduleOrQuizPercentages,
    setLessonWithQuizScheduleOrQuizPercentages
  ] = useState<CourseSessionLessonIdWithQuiz[]>([]);
  const [lecturerOptions, setLecturerOptions] = useState<SelectItem[]>([]);
  const [selectedLecturers, setSelectedLecturers] = useState<SelectItem[]>([]);
  const [
    selectedParticipatedSignatures,
    setSelectedParticipatedSignatures
  ] = useState<SelectItem[]>([]);
  const [selectedPassedSignatures, setSelectedPassedSignatures] = useState<
    SelectItem[]
  >([]);

  const initialSession = {
    course: {
      courseId: courseId
    },
    name: {
      th: '',
      en: ''
    },

    registrationStartDate: 'today',
    registrationEndDate: 'today',
    sessionStartDate: 'today',
    sessionEndDate: 'today',
    visibility: SessionType.SPECIAL,
    status: SessionStatus.ACTIVE,
    learningType: SessionCondition.PARALLEL,

    videoViewingPassPercentage: 80,
    courseSessionSections: [],
    courseSessionLessons: [],

    participatedCertificate: {
      enabled: false,
      videoViewingPassPercentage: 0,
      quizScorePassPercentage: 0,
      signatures: [] as any
    },
    passedCertificate: {
      enabled: false,
      videoViewingPassPercentage: 0,
      quizScorePassPercentage: 0,
      signatures: [] as any
    },
    seatLimit: 1
  };

  const location = useLocation();

  const [lessons, setLessons] = useState<CourseLesson[]>([]);
  const [sections, setSection] = useState<CourseSection[]>([]);
  const [session, setSession] = useState<CourseSession>(initialSession);

  const { execute: createSession } = useCreateSession();

  useEffect(() => {
    if (visible) refetchData();
  }, [visible]);

  useEffect(() => {
    const lessons = dataOfSections?.reduce(
      (total: CourseLesson[], section: CourseSection) => {
        if (section?.courseLessons?.length > 0) {
          total.push(...section?.courseLessons);
          return total;
        }
        return total;
      },
      []
    );

    setLessons(lessons);

    setSection(dataOfSections);
  }, [dataOfSections]);

  useEffect(() => {
    refetchData();
  }, [location.search]);

  useEffect(() => {
    const defaultLecturers = session?.courseSessionLecturers?.map(
      (lecturer: CourseSessionLecturer) => ({
        value: lecturer?.courseLecturer?.courseLecturerId,
        label: lecturer?.courseLecturer?.lecturer?.name?.th,
        key: lecturer?.courseLecturer?.courseLecturerId
      })
    ) as SelectItem[];
    setSelectedLecturers(defaultLecturers || []);

    if (session?.participatedCertificate?.signatures) {
      const defaultParticipatedSignature1 =
        session.participatedCertificate.signatures[0];

      const defaultParticipatedSignature2 =
        session.participatedCertificate.signatures[1];

      const defaultParticipatedSignature3 =
        session.participatedCertificate.signatures[2];

      const defaultParticipatedSignatures = [
        defaultParticipatedSignature1,
        defaultParticipatedSignature2,
        defaultParticipatedSignature3
      ];

      const convertedDefaultSignatures = defaultParticipatedSignatures
        .filter(
          (courseSignature): Partial<CourseSignatureDto> => courseSignature
        )
        .map((signature: Partial<CourseSignatureDto>) => ({
          value: signature?.courseSignatureId,
          label: signature?.name?.th,
          key: signature?.courseSignatureId
        })) as SelectItem[];

      setSelectedParticipatedSignatures(convertedDefaultSignatures);
    }

    if (session?.passedCertificate?.signatures) {
      const defaultPassedSignature1 = session.passedCertificate.signatures[0];

      const defaultPassedSignature2 = session.passedCertificate.signatures[1];

      const defaultPassedSignature3 = session.passedCertificate.signatures[2];

      const defaultPassedSignatures = [
        defaultPassedSignature1,
        defaultPassedSignature2,
        defaultPassedSignature3
      ];

      const convertedDefaultSignatures = defaultPassedSignatures
        .filter(
          (courseSignature): Partial<CourseSignatureDto> => courseSignature
        )
        .map((signature: Partial<CourseSignatureDto>) => ({
          value: signature?.courseSignatureId,
          label: signature?.name?.th,
          key: signature?.courseSignatureId
        })) as SelectItem[];
      setSelectedPassedSignatures(convertedDefaultSignatures);
    }
  }, [session]);

  useEffect(() => {
    form.setFieldsValue({
      instructors:
        selectedLecturers?.length > 0 ? selectedLecturers : [undefined],
      passedSignature1: selectedPassedSignatures[0],
      passedSignature2: selectedPassedSignatures[1],
      passedSignature3: selectedPassedSignatures[2],
      participatedSignature1: selectedParticipatedSignatures[0],
      participatedSignature2: selectedParticipatedSignatures[1],
      participatedSignature3: selectedParticipatedSignatures[2]
    });
  }, [
    session,
    selectedLecturers,
    form,
    selectedPassedSignatures,
    selectedParticipatedSignatures
  ]);

  useEffect(() => {
    const selectedLecturerIds = _.values(
      _.mapValues(selectedLecturers, (l: SelectItem) => l?.value)
    );
    const lecturerOptions = dataOfLecturers
      ?.filter((lecturer: CourseLecturer) => {
        return !selectedLecturerIds.includes(lecturer?.courseLecturerId as any);
      })
      .map((courseLecturer) => ({
        label: courseLecturer?.lecturer?.name?.th,
        value: courseLecturer?.courseLecturerId
      }));
    setLecturerOptions(lecturerOptions);
  }, [form, dataOfLecturers, selectedLecturers]);

  const refetchData = async () => {
    await fetchCourseSection();
    await fetchSignature();
  };

  const loading =
    useLecturersLoading || useSectionLoading || useSignatureLoading;

  const columns = [
    {
      title: 'บทเรียน',
      dataIndex: 'name',
      key: 'name',
      render: (name: any, record: CourseSection, index: number) => {
        return <div>บทที่ {index + 1}</div>;
      }
    },
    {
      title: 'วันเปิดบทเรียน',
      dataIndex: 'sectionOpeningType',
      key: 'sectionOpeningType',
      render: (text: any, record: CourseSection, index: number) => {
        return (
          <DatePicker
            onChange={(date: any) => {
              const newArrayOfLearningStartDate = new Array(
                ...learningStartDates
              );
              newArrayOfLearningStartDate[index] = toStartOfDay(
                date
              ).toISOString(true);
              setLearningStartDates(newArrayOfLearningStartDate);
            }}
          />
        );
      }
    },
    {
      title: 'วีดีโอที่สำคัญ',
      dataIndex: 'courseLessons',
      key: 'courseLessonsWithImportantVideo',
      render: (courseLessons: CourseLesson[]) => {
        const lessonWithImportantVideos = courseLessons?.filter(
          (lesson: CourseLesson) => {
            return (
              lesson?.lessonType === CourseLessonType.YOUTUBE &&
              lesson?.isImportant
            );
          }
        );

        return (
          <div>
            {lessonWithImportantVideos?.map(
              (lessonWithImportantVideo: CourseLesson) => (
                <div>{lessonWithImportantVideo?.name?.th}</div>
              )
            )}
          </div>
        );
      }
    },
    {
      title: 'แบบฝึกหัด',
      dataIndex: 'courseLessons',
      key: 'courseLessonsWithImportantExercise',

      render: (courseLessons: CourseLesson[]) => {
        const lessonWithImportantExercsise = courseLessons?.filter(
          (lesson: CourseLesson) => {
            return (
              lesson?.lessonType === CourseLessonType.QUIZ &&
              lesson?.isImportant
            );
          }
        );

        return lessonWithImportantExercsise?.map(
          (lessonWithImportantExercise: CourseLesson, index: number) => (
            <div className="flex justify-start mt-1">
              <Checkbox
                onChange={(e) => {
                  if (e.target.checked) {
                    setImportantExerciseIdInTables([
                      ...importantExerciseIdInTables,
                      lessonWithImportantExercise?.courseLessonId
                    ]);
                  } else {
                    setImportantExerciseIdInTables(
                      importantExerciseIdInTables.filter(
                        (id: string) =>
                          id !== lessonWithImportantExercise?.courseLessonId
                      )
                    );
                  }
                }}
              >
                แบบฝึกหัดที่ {index + 1} ได้คะแนนรวมมากกว่าที่กำหนด
              </Checkbox>
              <Input
                className="w-12 ml-2"
                type="number"
                onChange={(e) => {
                  if (
                    _.find(lessonWithQuizScheduleOrQuizPercentages, {
                      id: lessonWithImportantExercise?.courseLessonId
                    })
                  ) {
                    const newLessonWithQuizScheduleOrQuizPercentages = lessonWithQuizScheduleOrQuizPercentages?.map(
                      (elementWithId: CourseSessionLessonIdWithQuiz) => {
                        if (
                          elementWithId?.id ===
                          lessonWithImportantExercise?.courseLessonId
                        ) {
                          return {
                            ...elementWithId,
                            quizScorePassPercentage: parseInt(e.target.value)
                          };
                        }
                        return elementWithId;
                      }
                    );
                    setLessonWithQuizScheduleOrQuizPercentages(
                      newLessonWithQuizScheduleOrQuizPercentages
                    );
                  } else {
                    setLessonWithQuizScheduleOrQuizPercentages([
                      ...lessonWithQuizScheduleOrQuizPercentages,
                      {
                        id: lessonWithImportantExercise?.courseLessonId,
                        quizScorePassPercentage: parseInt(e.target.value)
                      }
                    ]);
                  }
                }}
                disabled={
                  !importantExerciseIdInTables.includes(
                    lessonWithImportantExercise?.courseLessonId
                  )
                }
              />
              <span className="ml-2">เปอร์เซ็นต์</span>
            </div>
          )
        );
      }
    }
  ];

  const renderSectionCondition = () => {
    return (
      <div>
        <div className="font-bold mb-4">เงื่อนไขการผ่านบทเรียน</div>
        <Row>
          <Col span={12} className="pr-16">
            <Form.Item
              label="เปอร์เซ็นต์การดูวีดีโอ"
              name="percentOfWatchingVideo"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 14 }}
            >
              <Input
                defaultValue={session?.videoViewingPassPercentage || undefined}
                onChange={(e) => {
                  setSession({
                    ...session,
                    videoViewingPassPercentage: parseInt(e.target.value)
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Table dataSource={sections} columns={columns} />
      </div>
    );
  };

  const onFinish = async (formValue: any) => {
    setSaving(true);

    const courseLecturers = session?.courseSessionLecturers?.map(
      (courseLecturer: CourseSessionLecturer, index: number) => ({
        ...courseLecturer,
        position: index + 1
      })
    );

    const courseSections = dataOfSections?.map(
      (section: CourseSection, index: number) => ({
        courseSection: { courseSectionId: section?.courseSectionId },
        learningStartDate: learningStartDates[index] || null
      })
    );
    const courseLessons = lessons?.map((lesson: CourseLesson) => {
      const lessonWithQuizSchedules = lessonWithQuizScheduleOrQuizPercentages?.filter(
        (elementWithId: CourseSessionLessonIdWithQuiz) =>
          elementWithId?.id === lesson?.courseLessonId
      );

      return {
        courseLesson: { courseLessonId: lesson?.courseLessonId },
        quizAnswerSheetSchedule:
          lessonWithQuizSchedules[0]?.quizAnswerSheetSchedule || null,
        quizScorePassPercentage:
          lessonWithQuizSchedules[0]?.quizScorePassPercentage || null
      };
    });

    let sessionData = {
      ...session,
      name: { th: formValue.nameTH, en: formValue?.nameEN || '' },
      seatLimit: Number(formValue?.seatLimit) || 1,
      registrationStartDate: toStartOfDay(
        formValue?.registrationDate[0]
      )?.toISOString(true),
      registrationEndDate: toEndOfDay(
        formValue?.registrationDate[1]
      )?.toISOString(true),
      sessionStartDate: toStartOfDay(formValue?.sessionDate[0])?.toISOString(
        true
      ),
      sessionEndDate: toEndOfDay(formValue?.sessionDate[1])?.toISOString(true),
      visibility: formValue?.type?.toLowerCase(),
      learningType: formValue?.condition?.toLowerCase(),
      courseSessionLecturers: courseLecturers || [],
      courseSessionLessons: courseLessons,
      courseSessionSections: courseSections
    };

    const loading = message.loading('กำลังดำเนินการ...', 0);
    try {
      await createSession({ data: sessionData });
      loading();
      message.success('บันทึกสำเร็จ');
      await refetch();
      setVisible(false);
    } catch (error) {
      loading();
      message.error('บันทึกไม่สำเร็จ');
      console.error(error);
    } finally {
      setSaving(false);
    }
  };

  if (loading) return <></>;

  return (
    <Modal
      title="เพิ่มรอบ"
      visible={visible}
      footer={null}
      maskClosable={false}
      className="w-11/12"
      onCancel={() => setVisible(false)}
    >
      <Spin spinning={loading || saving}>
        <Global
          styles={css`
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
            .ant-modal {
              top: 7rem;
            }
            .ant-modal-content {
              padding: 1rem;
            }
          `}
        />
        <Form
          form={form}
          initialValues={{
            instructors: [undefined],
            registrationDate: [moment(), moment()],
            sessionDate: [moment(), moment()],
            condition: session?.learningType,
            type: session?.visibility
          }}
          onFinish={onFinish}
        >
          <div className="font-bold mb-4">ข้อมูลพื้นฐาน</div>
          <Row>
            <Col span={12} className="pr-16">
              <Form.Item
                label="ชื่อรอบอบรม(TH)"
                name="nameTH"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 14 }}
                rules={[getRule(FormRule.REQUIRE, 'โปรดกรอกชื่อรอบอบรม')]}
              >
                <Input className="" placeholder="ชื่อรอบอบรมภาษาไทย" />
              </Form.Item>
              <Form.Item
                label="ชื่อรอบอบรม(EN)"
                name="nameEN"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 14 }}
              >
                <Input className="" placeholder="ชื่อรอบอบรมภาษาอังกฤษ" />
              </Form.Item>
              <Form.Item
                label="จำนวนผู้เข้าเรียนต่อรอบ"
                name="seatLimit"
                rules={[getRule(FormRule.REQUIRE)]}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 14 }}
              >
                <Input
                  min={1}
                  type="number"
                  placeholder="100"
                  addonAfter="คน"
                />
              </Form.Item>
              <Form.Item
                label="รอบลงทะเบียน"
                name="registrationDate"
                rules={[getRule(FormRule.REQUIRE, 'กรุณาระบุรอบลงทะเบียน')]}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 14 }}
                normalize={(value) => {
                  if (!value) return [];
                  return [value[0], value[1]];
                }}
              >
                <RangePicker />
              </Form.Item>
              <Form.Item
                label="ระยะเวลาอบรม"
                name="sessionDate"
                rules={[getRule(FormRule.REQUIRE, 'กรุณาระบุระยะเวลาอบรม')]}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 14 }}
                normalize={(value) => {
                  if (!value) return [];
                  return [value[0], value[1]];
                }}
              >
                <RangePicker />
              </Form.Item>
            </Col>
            <Col span={12} className="pr-14">
              <Form.Item
                label="ประเภทอบรม"
                name="type"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 14 }}
              >
                <Select
                  placeholder="Public/Special"
                  defaultValue={session?.visibility}
                >
                  <Select.Option value="public">Public</Select.Option>
                  <Select.Option value="special">Special</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="รูปแบบการดูเนื้อหา"
                name="condition"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 14 }}
              >
                <Select
                  placeholder="เรียงตามลำดับ/เลือกดูเนื้อหาได้ตามอิสระ"
                  onSelect={(value) => {
                    setSession({
                      ...session,
                      learningType: value as SessionCondition
                    });
                  }}
                  defaultValue={session?.learningType}
                >
                  <Select.Option value="sequential">
                    เรียงตามลำดับ
                  </Select.Option>
                  <Select.Option value="parallel">
                    เลือกดูเนื้อหาได้แบบอิสระ
                  </Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="สถานะ"
                name="status"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 14 }}
              >
                <Switch
                  defaultChecked={session?.status === 'active'}
                  onClick={(check: boolean) => {
                    let status;
                    if (check) {
                      status = 'active';
                    } else {
                      status = 'inactive';
                    }
                    setSession({ ...session, status: status });
                  }}
                />
                <span className="ml-2">
                  {session?.status === 'active' ? 'Active' : ''}
                </span>
              </Form.Item>
            </Col>
          </Row>
          <div className="font-bold mb-4">ข้อมูลวิทยากร</div>
          <Row>
            <Col span={12} className="pr-16">
              <Form.List name="instructors">
                {(fields, { add, remove }) => {
                  return (
                    <div>
                      {fields.map((field, index) => (
                        <Form.Item
                          {...field}
                          labelCol={{ span: 8 }}
                          wrapperCol={{ span: 14 }}
                          label={`วิทยากรคนที่ ${index + 1}`}
                          key={field.key}
                        >
                          <div className="flex justify-center">
                            <Form.Item
                              {...field}
                              label={`วิทยากรคนที่ ${index}`}
                              validateTrigger={['onChange', 'onBlur']}
                              rules={
                                index > 0
                                  ? [
                                      getRule(
                                        FormRule.REQUIRE,
                                        'โปรดเลือกวิทยากร'
                                      )
                                    ]
                                  : []
                              }
                              noStyle
                            >
                              <Select
                                showSearch
                                labelInValue
                                placeholder="เลือกวิทยากร"
                                style={{ width: '100%' }}
                                onSelect={(obj: any) => {
                                  const convertedObj = {
                                    courseLecturer: {
                                      courseLecturerId: obj.value,
                                      lecturer: {
                                        name: { th: obj.label, en: '' }
                                      }
                                    }
                                  };

                                  const currentLecturers =
                                    session?.courseSessionLecturers || [];
                                  if (index > currentLecturers.length - 1) {
                                    setSession({
                                      ...session,
                                      courseSessionLecturers: [
                                        ...currentLecturers,
                                        convertedObj
                                      ]
                                    });
                                  } else {
                                    currentLecturers[index] = convertedObj;
                                    setSession({
                                      ...session,
                                      courseSessionLecturers: currentLecturers
                                    });
                                  }
                                }}
                              >
                                {lecturerOptions.map((obj: any) => (
                                  <Option value={obj.value}>{obj.label}</Option>
                                ))}
                              </Select>
                            </Form.Item>
                            <div className="flex items-center anticon anticon-minus-circle dynamic-delete-button">
                              {fields.length > 1 ? (
                                <DeleteIcon
                                  className="dynamic-delete-button cursor-pointer"
                                  style={{ margin: '0 8px' }}
                                  onClick={() => {
                                    remove(field.name);
                                    const newLecturers = session?.courseSessionLecturers?.filter(
                                      (
                                        lecturer: Partial<CourseSessionLecturer>,
                                        index: number
                                      ) => index !== field.name
                                    );
                                    setSession({
                                      ...session,
                                      courseSessionLecturers: newLecturers
                                    });
                                  }}
                                />
                              ) : null}
                            </div>
                          </div>
                        </Form.Item>
                      ))}
                      <Row>
                        <Col span={8} />
                        <Col span={14}>
                          <Button
                            onClick={() => {
                              add();
                            }}
                          >
                            <PlusOutlined /> เพิ่มวิทยากร
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  );
                }}
              </Form.List>
            </Col>
          </Row>
          {session?.learningType === SessionCondition.SEQUENTIAL &&
            renderSectionCondition()}
          {
            <CourseSessionSolutionReviewDate
              sections={sections}
              lessonWithQuizScheduleOrQuizPercentages={
                lessonWithQuizScheduleOrQuizPercentages
              }
              setLessonWithQuizScheduleOrQuizPercentages={
                setLessonWithQuizScheduleOrQuizPercentages
              }
              mode={Mode.ADD}
            />
          }

          <div className="font-bold mb-4">จัดการใบประกาศสำหรับรอบอบรม</div>
          <Row gutter={[8, 8]}>
            <Col span={12}>
              <Switch
                size="small"
                onClick={(check: boolean) => {
                  setSession({
                    ...session,
                    participatedCertificate: {
                      ...session.participatedCertificate,
                      enabled: check
                    }
                  });
                }}
              />
              <span className="ml-4  text-mono-gray-400">
                ประกาศนียบัตรสำหรับผู้เข้าร่วมการอบรม
              </span>

              {session?.participatedCertificate?.enabled && (
                <CourseSessionCertificateCondition
                  prefix={CertificateCondition.PARTICIPATED}
                  type="participatedCertificate"
                  courseId={courseId}
                  session={session}
                  signatures={signatures}
                  signatureValidator={
                    session.participatedCertificate.signatures &&
                    session.participatedCertificate.signatures[0]
                  }
                  selectedSignatures={selectedParticipatedSignatures}
                  defaultVideoViewingPassPercentage={
                    session?.participatedCertificate
                      ?.videoViewingPassPercentage || null
                  }
                  defaultQuizScorePassPercentage={
                    session?.participatedCertificate?.quizScorePassPercentage ||
                    null
                  }
                  setVideoViewingPassPercentage={(e: any) =>
                    setSession({
                      ...session,
                      participatedCertificate: {
                        ...session.participatedCertificate,
                        videoViewingPassPercentage: parseInt(
                          e.target.value
                        ) as number
                      }
                    })
                  }
                  setQuizScorePassPercentage={(e: any) =>
                    setSession({
                      ...session,
                      participatedCertificate: {
                        ...session.participatedCertificate,
                        quizScorePassPercentage: parseInt(
                          e.target.value
                        ) as number
                      }
                    })
                  }
                  setCourseSignatureId={({ obj, index, isRemove }: any) => {
                    if (isRemove) {
                      const newSignatures = (session?.participatedCertificate
                        ?.signatures as Partial<CourseSignatureDto>[]).filter(
                        (signature: Partial<CourseSignatureDto>, i: number) =>
                          i !== index
                      );
                      setSession({
                        ...session,
                        participatedCertificate: {
                          ...session?.participatedCertificate,
                          signatures: newSignatures
                        }
                      });
                    } else {
                      const convertedObj = {
                        courseSignatureId: obj.value,
                        name: { th: obj.label, en: '' }
                      };

                      const currentSignatures = session?.participatedCertificate
                        ?.signatures as Partial<CourseSignatureDto>[];

                      if (index > currentSignatures.length - 1) {
                        setSession({
                          ...session,
                          participatedCertificate: {
                            ...session?.participatedCertificate,
                            signatures: [
                              ...(session?.participatedCertificate
                                ?.signatures as Partial<CourseSignatureDto>[]),
                              convertedObj
                            ] as Partial<CourseSignatureDto>[]
                          }
                        });
                      } else {
                        const newSignatures = session?.participatedCertificate
                          ?.signatures as Partial<CourseSignatureDto>[];
                        newSignatures[index] = convertedObj;
                        setSession({
                          ...session,
                          participatedCertificate: {
                            ...session?.participatedCertificate,
                            signatures: newSignatures
                          }
                        });
                      }
                    }
                  }}
                />
              )}
              <Col></Col>

              <Switch
                size="small"
                onClick={(check: boolean) => {
                  setSession({
                    ...session,
                    passedCertificate: {
                      ...session?.passedCertificate,
                      enabled: check
                    }
                  });
                }}
              />
              {session?.passedCertificate?.enabled && (
                <CourseSessionCertificateCondition
                  prefix={CertificateCondition.PASSED}
                  type="passedCertificate"
                  courseId={courseId}
                  session={session}
                  signatures={signatures}
                  signatureValidator={
                    session.passedCertificate.signatures &&
                    session.passedCertificate.signatures[0]
                  }
                  selectedSignatures={selectedPassedSignatures}
                  setVideoViewingPassPercentage={(e: any) =>
                    setSession({
                      ...session,
                      passedCertificate: {
                        ...session.passedCertificate,
                        videoViewingPassPercentage: parseInt(
                          e.target.value
                        ) as number
                      }
                    })
                  }
                  setQuizScorePassPercentage={(e: any) =>
                    setSession({
                      ...session,
                      passedCertificate: {
                        ...session.passedCertificate,
                        quizScorePassPercentage: parseInt(
                          e.target.value
                        ) as number
                      }
                    })
                  }
                  setCourseSignatureId={({ obj, index, isRemove }: any) => {
                    if (isRemove) {
                      const newSignatures = (session?.passedCertificate
                        ?.signatures as Partial<CourseSignatureDto>[]).filter(
                        (signature: Partial<CourseSignatureDto>, i: number) =>
                          i !== index
                      );
                      setSession({
                        ...session,
                        passedCertificate: {
                          ...session?.passedCertificate,
                          signatures: newSignatures
                        }
                      });
                    } else {
                      const convertedObj = {
                        courseSignatureId: obj.value,
                        name: { th: obj.label, en: '' }
                      };

                      const currentSignatures = session?.passedCertificate
                        ?.signatures as Partial<CourseSignatureDto>[];

                      if (index > currentSignatures.length - 1) {
                        setSession({
                          ...session,
                          passedCertificate: {
                            ...session?.passedCertificate,
                            signatures: [
                              ...(session?.passedCertificate
                                ?.signatures as Partial<CourseSignatureDto>[]),
                              convertedObj
                            ] as Partial<CourseSignatureDto>[]
                          }
                        });
                      } else {
                        const newSignatures = session?.passedCertificate
                          ?.signatures as Partial<CourseSignatureDto>[];
                        newSignatures[index] = convertedObj;
                        setSession({
                          ...session,
                          passedCertificate: {
                            ...session?.passedCertificate,
                            signatures: newSignatures
                          }
                        });
                      }
                    }
                  }}
                />
              )}
              <span className="ml-4 text-mono-gray-400">
                ประกาศนียบัตรสำหรับผู้ผ่านการอบรม
              </span>
              {(session?.participatedCertificate?.enabled ||
                session?.passedCertificate?.enabled) && (
                <CourseSessionGenerateDateCertificate
                  session={session}
                  setSession={setSession}
                />
              )}
            </Col>
          </Row>
          <div className="flex justify-end items-center">
            <TextButton
              onClick={() => {
                setVisible(false);
              }}
            >
              ยกเลิก
            </TextButton>
            <PrimaryButton htmlType="submit">บันทึก</PrimaryButton>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};
