import useAxios from 'axios-hooks';

export const useEditProduct = () => {
  const [{ data: response, loading, error }, fire] = useAxios(
    {
      method: 'PUT'
    },
    { manual: true }
  );
  const execute = (product: object, productId: string) => {
    return fire({
      url: `/products/${productId}`,
      data: product
    });
  };
  return {
    data: response?.data,
    loading,
    error,
    execute
  };
};
