/** @jsx jsx */
import { Button, Menu } from 'antd';
import { css, jsx } from '@emotion/react';
import { useHistory, useLocation } from 'react-router-dom';

const { SubMenu } = Menu;

const MenuItems = [
  // {
  //   title: 'หมวดหมู่',
  //   path: '/category'
  // },
  {
    title: 'คอร์ส',
    path: '/course',
    type: 'subMenu',
    subMenus: [
      { title: 'จัดการคอร์ส', path: '/course/manage' },
      { title: 'รายงานคอร์ส', path: '/course/report' }
    ]
  },
  {
    title: 'จัดการวิทยากร',
    path: '/lecturer'
  },
  {
    title: 'คูปอง',
    path: '/coupon',
    type: 'subMenu',
    subMenus: [
      { title: 'จัดการคูปอง', path: '/coupon/manage' },
      { title: 'รายงานคูปอง', path: '/coupon/report' }
    ]
  },
  {
    title: 'จัดการสินค้า',
    path: '/product',
    type: 'subMenu',
    subMenus: [
      { title: 'จัดการรายการสินค้า', path: '/product/manage-list' },
      { title: 'จัดการสื่อและรหัสสินค้า', path: '/product/manage' }
    ]
  },
  {
    title: 'จัดการบัญชีโรงเรียน',
    path: '/school-manage'
  },
  {
    title: 'จัดการแบนเนอร์',
    path: '/banner'
  }
];
export const Sidebar = () => {
  const location = useLocation();
  const history = useHistory();

  const flattenMenu: any[] = MenuItems.reduce((prev: any, curr) => {
    const sub = curr.subMenus || [];
    return [...prev, ...sub, curr];
  }, []);
  const currentMenu = flattenMenu.find((item: any) =>
    location.pathname.startsWith(item.path)
  );
  const currentSelected = currentMenu?.path;

  const defaultOpenKeys = MenuItems.filter(
    (menu) => menu.type === 'subMenu'
  ).map((menu) => menu.path);

  return (
    <div
      className="w-64 min-h-screen z-20"
      style={{ backgroundColor: '#F9FAFC' }}
    >
      <Button
        className="fixed"
        style={{ bottom: '2rem', left: '2rem' }}
        onClick={() => {
          localStorage.removeItem('userToken');
          history.push('/login');
        }}
      >
        Logout
      </Button>
      <div className="w-full flex justify-center items-center">
        <div className="w-12 h-12 rounded-full my-6 bg-gray-800 text-white text-sm flex justify-center items-center ">
          LOGO
        </div>
      </div>
      <Menu
        selectedKeys={[currentSelected]}
        defaultOpenKeys={defaultOpenKeys}
        mode="inline"
        className="overflow-y-auto overflow-x-hidden"
        css={css`
          height: calc(100vh - 184px);
        `}
      >
        {MenuItems.map((menu: any) => {
          if (menu.type === 'subMenu')
            return (
              <SubMenu key={menu.path} title={menu.title} icon={menu?.icon}>
                {menu.subMenus.map((menu: any) => (
                  <Menu.Item
                    key={menu.path}
                    icon={menu?.icon}
                    onClick={() => history.push(menu.path)}
                  >
                    {menu.title}
                  </Menu.Item>
                ))}
              </SubMenu>
            );
          return (
            <Menu.Item
              key={menu.path}
              icon={menu.icon}
              onClick={() => history.push(menu.path)}
            >
              {menu.title}
            </Menu.Item>
          );
        })}
        {/* <SubMenu key="sub1" title="Navigation One">
          <Menu.Item key="5">Option 5</Menu.Item>
          <Menu.Item key="6">Option 6</Menu.Item>
          <Menu.Item key="7">Option 7</Menu.Item>
          <Menu.Item key="8">Option 8</Menu.Item>
        </SubMenu>
        <SubMenu key="sub2" title="Navigation Two">
          <Menu.Item key="9">Option 9</Menu.Item>
          <Menu.Item key="10">Option 10</Menu.Item>
          <SubMenu key="sub3" title="Submenu">
            <Menu.Item key="11">Option 11</Menu.Item>
            <Menu.Item key="12">Option 12</Menu.Item>
          </SubMenu>
        </SubMenu> */}
      </Menu>
    </div>
  );
};
