/** @jsx jsx */
import { Button, Empty, Table, message } from 'antd';
import { COURSE_LEVEL_TEXT, SESSION_TYPE_TEXT } from 'feature/course/const';
import { Coupon, CouponCourseSession } from './coupon.type';
import { css, jsx } from '@emotion/react';

import { AddCouponReceiveCourseModal } from 'feature/coupon/AddCouponReceiveCourseModal';
import { AlertModal } from 'feature/common/AlertModal';
import Axios from 'axios';
import { CourseLevel } from 'feature/course/course.type';
import { ReactComponent as DeleteIcon } from 'assets/delete.svg';
import { PlusOutlined } from '@ant-design/icons';
import { compact } from 'lodash';
import moment from 'moment';
import { useCouponCourseSessions } from 'hooks/coupon/useCouponCourseSessions';
import { useState } from 'react';

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
};
const PAGE_SIZE = 100;
interface Props {
  coupon: Coupon;
}
export const CouponReceiveCourseForm = ({ coupon }: Props) => {
  const [pageNumber, setPageNumber] = useState(1);
  const {
    data: couponCourseSessions = [],
    meta,
    refetch,
    loading
  } = useCouponCourseSessions({
    couponId: coupon?.couponId,
    pageNumber,
    pageSize: PAGE_SIZE
  });
  const [
    deletingCourseSession,
    setDeletingCourseSession
  ] = useState<CouponCourseSession | null>(null);
  const [
    couponReceiveCourseModalVisible,
    setCouponReceiveCourseModalVisible
  ] = useState(false);
  const onDelete = async () => {
    if (!deletingCourseSession) return;
    const couponCourseSessionId = deletingCourseSession.couponCourseSessionId;
    try {
      await Axios.delete(`coupon-course-sessions/${couponCourseSessionId}`);
      setDeletingCourseSession(null);
      message.success('ลบคอร์สสำเร็จ');
      refetch();
    } catch {
      message.error('ลบคอร์สไม่สำเร็จ');
    }
  };
  const columns = [
    {
      title: 'คอร์ส',
      key: 'courseName',
      width: 250,
      render: (record: CouponCourseSession) =>
        record.courseSession?.course?.name?.th
    },
    {
      title: 'รอบอบรม',
      key: 'sessionName',
      render: (record: CouponCourseSession) => record.courseSession?.name?.th
    },
    {
      title: 'ระดับคอร์ส',
      key: 'courseLevel',
      render: (record: CouponCourseSession) =>
        record.courseSession?.courseLevel &&
        COURSE_LEVEL_TEXT[record.courseSession?.courseLevel]
    },
    {
      title: 'ประเภทอบรม',
      key: 'sessionVisibility',
      render: (record: CouponCourseSession) =>
        record.courseSession?.visibility &&
        SESSION_TYPE_TEXT[record.courseSession?.visibility]
    },
    {
      title: 'วันที่อบรม',
      dataIndex: 'sessionStartDate',
      key: 'sessionStartDate',
      render: (text: string, record: any) => {
        const sessionStartDate = record?.courseSession?.sessionStartDate
          ? moment(record?.courseSession?.sessionStartDate).format('D MMM')
          : 'ไม่ทราบวันที่';
        const sessionEndDate = record?.courseSession?.sessionEndDate
          ? moment(record?.courseSession?.sessionEndDate)
              .add(543, 'year')
              .format('D MMM YY')
          : 'ไม่ทราบวันที่';
        return <span>{`${sessionStartDate} - ${sessionEndDate}`}</span>;
      }
    },
    {
      title: 'ที่นั่งทั้งหมด',
      key: 'seatLimit',

      render: (record: CouponCourseSession) =>
        record?.courseSession?.seatLimit !== undefined
          ? record.courseSession.seatLimit
          : '-'
    },
    {
      title: 'ที่นั่งเหลือ',
      key: 'remainingSeat',

      render: (record: CouponCourseSession) => {
        if (
          record.courseSession &&
          record.courseSession.seatLimit !== undefined &&
          record.courseSession.seatUsage !== undefined
        ) {
          return (
            record.courseSession.seatLimit - record.courseSession.seatUsage
          );
        } else {
          return '-';
        }
      }
    },
    {
      title: '',
      key: 'action',
      align: 'right',
      render: (record: CouponCourseSession) => (
        <div className="flex justify-end">
          <div>
            <DeleteIcon
              style={{ color: '#F03D3E' }}
              className="cursor-pointer"
              onClick={() => setDeletingCourseSession(record)}
            />
          </div>
        </div>
      )
    }
  ];
  const createCouponCourseSession = async (courseSessionId: string) => {
    try {
      await Axios.post(
        '/coupon-course-sessions',
        JSON.stringify({
          couponId: coupon.couponId,
          courseSessionId
        }),
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      refetch();
      setCouponReceiveCourseModalVisible(false);
      message.success('เพิ่มคอร์สสำเร็จ');
    } catch (e) {
      message.error('ทำรายการไม่สำเร็จ');
      throw e;
    }
  };

  return (
    <div>
      <div className="flex justify-between font-bold text-base text-mono-gray-400 mb-6">
        รายการคอร์ส
        {couponCourseSessions?.length > 0 && (
          <Button
            className="flex items-center"
            onClick={() => setCouponReceiveCourseModalVisible(true)}
          >
            <PlusOutlined className="relative w-4 h-4 mr-2" />
            เพิ่มคอร์ส
          </Button>
        )}
      </div>
      <Table
        loading={loading}
        dataSource={couponCourseSessions}
        columns={columns as any}
        rowKey="couponCourseSessionId"
        pagination={{
          onChange: (page) => setPageNumber(page),
          ...meta?.pagination
        }}
        locale={{
          emptyText: (
            <Empty
              css={css`
                padding: 2em;
                display: flex;
                flex-direction: column;
                align-items: center;
              `}
              imageStyle={{ display: 'none' }}
              description={
                <div className="flex flex-col">
                  <div className="text-2xl font-sans text-black">
                    ยังไม่มีคอร์สเรียนในคูปองนี้
                  </div>
                  <div className="text-gray-900">
                    กรุณาเพิ่มรอบอบรมที่ผู้ใช้งานจะได้รับเมื่อใช้คูปองนี้
                  </div>
                </div>
              }
            >
              <Button
                className="flex items-center"
                onClick={() => setCouponReceiveCourseModalVisible(true)}
              >
                <PlusOutlined className="relative w-4 h-4 mr-2" />
                เพิ่มคอร์ส
              </Button>
            </Empty>
          )
        }}
        {...layout}
      />
      <AddCouponReceiveCourseModal
        visible={couponReceiveCourseModalVisible}
        onClose={() => setCouponReceiveCourseModalVisible(false)}
        onSubmit={(courseSessionId) => {
          return createCouponCourseSession(courseSessionId);
        }}
        courseLevel={(coupon?.couponLevel as unknown) as CourseLevel}
        disabledCourseIds={
          // TODO: ใช้ couponCourseSessions ที่ query pageSize=100 อยู่ ไม่ได้ใช้ทั้งหมด
          compact(
            couponCourseSessions.map(
              (courseSession: CouponCourseSession) =>
                courseSession?.courseSession.course?.courseId
            )
          ) as string[]
        }
      />
      <AlertModal
        visible={!!deletingCourseSession}
        onOk={onDelete}
        onCancel={() => {
          setDeletingCourseSession(null);
        }}
      />
    </div>
  );
};
