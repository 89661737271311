import { Link, useHistory } from 'react-router-dom';

import { Coupon } from './coupon.type';
import { DateFormat } from 'feature/common/global.type';
import { ReactComponent as FormEditIcon } from 'assets/form-edit.svg';
import React from 'react';
import { Table } from 'antd';
import moment from 'moment';

interface Props {
  coupons: Coupon[];
  loading?: boolean;
}
export const CouponTable = ({ coupons = [], loading = false }: Props) => {
  const history = useHistory();
  const columns = [
    {
      title: 'รหัสคูปอง',
      key: 'couponCode',
      width: 100,
      render: (record: Coupon) => (
        <Link
          to={`/coupon/manage/edit?couponId=${record.couponId}`}
          className="text-blue font-medium"
        >
          {record.couponCode}
        </Link>
      )
    },
    {
      title: 'ชื่อคูปอง',
      dataIndex: 'name',
      key: 'name',
      render: (name: Coupon['name']) => <div>{name.th}</div>
    },
    {
      title: 'จำนวนรอบอบรม',
      dataIndex: 'numberOfSessions',
      key: 'numberOfSessions',
      width: 130
    },
    {
      title: 'จำนวนโรงเรียน',
      dataIndex: 'numberOfSchools',
      key: 'numberOfSchools',
      width: 150
    },
    {
      title: 'โควต้าที่ใช้',
      dataIndex: 'quotaUsage',
      key: 'quotaUsage',
      width: 100
    },
    {
      title: 'โควต้าทั้งหมด',
      dataIndex: 'quotaLimit',
      key: 'quotaLimit',
      width: 150
    },
    {
      title: 'เริ่มใช้งาน',
      dataIndex: 'couponStartDate',
      key: 'couponStartDate',
      width: 100,
      render: (timestamp: string) =>
        timestamp && moment(timestamp).format(DateFormat.DEFAULT)
    },
    {
      title: 'หมดอายุ',
      dataIndex: 'couponExpiryDate',
      key: 'couponExpiryDate',
      width: 100,
      render: (timestamp: string) =>
        timestamp && moment(timestamp).format(DateFormat.DEFAULT)
    },
    {
      title: 'สถานะ',
      dataIndex: 'status',
      key: 'status',
      width: 100
    },
    {
      title: 'แก้ไขล่าสุด',
      key: 'updatedAt',
      render(record: Coupon) {
        return (
          <div className="flex flex-col">
            <div>{record.updatedBy}</div>
            <div className="text-xs text-gray-500">
              {moment(record.updatedAt).fromNow()}
            </div>
          </div>
        );
      }
    },
    {
      title: '',
      key: 'action',
      align: 'right',
      render: (coupon: Coupon) => (
        <div className="flex justify-end">
          <div
            className="cursor-pointer"
            onClick={() => {
              history.push(`/coupon/manage/edit?couponId=${coupon.couponId}`);
            }}
          >
            <FormEditIcon style={{ color: '#1890FF' }} />
          </div>
        </div>
      )
    }
  ];
  return (
    <>
      <Table
        loading={loading}
        pagination={false}
        dataSource={coupons}
        columns={columns as any}
        rowKey="couponId"
      />
    </>
  );
};
