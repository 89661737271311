import { identity, omit, pickBy } from 'lodash';

import { CourseReportUserSort } from 'feature/course-report/course-report.type';
import { CourseSessionReportUserListResponse } from '@aksorn/backoffice-sdk';
import useAxios from 'axios-hooks';

type QueryCourseSessionReportUsers = {
  courseSessionId: string;
  schoolId?: string;
  couponCode?: string;
  pageNumber?: number;
  sortBy?: CourseReportUserSort;
};

export const useCourseSessionReportUsers = (
  params: QueryCourseSessionReportUsers
) => {
  const { courseSessionId = '' } = params;
  const [
    { data: response, loading, error },
    refetch
  ] = useAxios<CourseSessionReportUserListResponse>(
    {
      url: `/course-session-reports/${courseSessionId}/users`,
      params: {
        pageNumber: 1,
        pageSize: 10,
        ...omit(pickBy(params, identity), 'courseSessionId')
      }
    },
    { manual: true }
  );

  return {
    data: response?.data || [],
    loading: loading,
    error,
    refetch,
    item: response?.meta?.pagination?.total || 0
  };
};
