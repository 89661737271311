/** @jsx jsx */
import { Col, Row, Tabs } from 'antd';

import { PageContainer } from 'feature/common/PageContainer';
import { SchoolRequestList } from 'feature/school-request/request-list/SchoolRequestList';
import { jsx } from '@emotion/react';

const { TabPane } = Tabs;
export const SchoolManagePage = () => {
  return (
    <PageContainer className="p-8">
      <Row justify="space-between" align="middle" className="mb-8">
        <Col>
          <div className="flex items-center">
            <h1 className="text-xl font-bold ">จัดการบัญชีโรงเรียน</h1>
          </div>
        </Col>
      </Row>
      <Tabs>
        <TabPane tab="รายการคำขออนุมัติ" key="1">
          <SchoolRequestList />
        </TabPane>
        <TabPane tab="รายการบัญชีโรงเรียน" key="2"></TabPane>
        <TabPane tab="เปิดบัญชีโรงเรียนด้วยตัวเอง" key="3"></TabPane>
      </Tabs>
    </PageContainer>
  );
};
