import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'antd/dist/antd.css';
import './style/style.css';
import './style/App.css';
import './style/tailwind.css';
import 'moment/locale/th';

import * as serviceWorker from './serviceWorker';

import App from './App';
import Axios from 'axios';
import React from 'react';
import ReactDOM from 'react-dom';
import { configure } from 'axios-hooks';
import env from '@beam-australia/react-env';
import moment from 'moment';

moment.locale('th');

const baseUrl =
  env('API_URL') || 'https://backoffice.aksorn-dev.mydevkit.me/api';
const apiKey = env('API_KEY') || 'rsBc9vJi9Tq1yilyLjpdHXKKLRtmmXwl';

Axios.defaults.baseURL = baseUrl;
Axios.defaults.headers.common['X-API-KEY'] = apiKey;
const axios = Axios.create({
  baseURL: baseUrl,
  headers: { 'X-API-KEY': apiKey }
});
// const cache = new LRU({ max: 10 });

configure({ cache: false, axios });

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
