import { Button, Col, Input, Pagination, Row, Select } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { COURSE_LEVEL_TEXT } from 'feature/course/const';
import { CourseLevel } from 'feature/course/course.type';
import { CourseManageTable } from 'feature/course/CourseManageTable';
import { PageContainer } from 'feature/common/PageContainer';
import { PlusOutlined } from '@ant-design/icons';
import { useCourses } from 'hooks/course/useCourses';
import { useTeacherAcademyCourseTypes } from 'hooks/course/useTeacherAcademyCourseTypes';

const { Option } = Select;

export const CourseManagePage = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const history = useHistory();
  const [searchFilter, setSearchFilter] = useState<string>();
  const [keyword, setKeyword] = useState('');
  const [courseLevel, setCourseLevel] = useState<CourseLevel | undefined>();
  const [
    teacherAcademyCourseType,
    setTeacherAcademyCourseType
  ] = useState<string>();

  const handleSearch = (text: string = '') => {
    setKeyword(text.trim());
    setCurrentPage(1);
  };

  const handleSelectType = useCallback((value: string) => {
    setTeacherAcademyCourseType(value);
  }, []);

  const handleSelectCourseLevel = useCallback((value: CourseLevel) => {
    if (value === CourseLevel.STUDENT) {
      setTeacherAcademyCourseType(undefined);
    }
    setCourseLevel(value);
  }, []);

  const location = useLocation();
  const { data: coursesData, loading, refetch, item } = useCourses({
    pageNumber: currentPage,
    keyword,
    courseLevel,
    teacherAcademyCourseType
  });
  const {
    data: teacherAcademyCourseTypeOptions,
    loading: teacherAcademyCourseTypeLoading
  } = useTeacherAcademyCourseTypes();

  useEffect(() => {
    refetch();
  }, [refetch, location]);

  return (
    <PageContainer className="p-8 course-list">
      <Row justify="space-between" align="middle" className="mb-6">
        <Col span={4}>
          <h1 className="text-xl font-bold text-black">จัดการคอร์ส</h1>
        </Col>
      </Row>
      <Row justify="space-between" align="middle" className="mb-4">
        <Col span={12}>
          <h1 className="text-xl font-bold">คอร์สทั้งหมด</h1>
        </Col>
        <Col span={12}>
          <Row justify="end" gutter={16}>
            <Col span={5}>
              <Select
                placeholder="เลือกระดับคอร์ส"
                onChange={handleSelectCourseLevel}
                onClear={() => setCourseLevel(undefined)}
                value={courseLevel}
                allowClear
                className="min-w-full"
              >
                <Option key={CourseLevel.STUDENT} value={CourseLevel.STUDENT}>
                  {COURSE_LEVEL_TEXT[CourseLevel.STUDENT]}
                </Option>
                <Option key={CourseLevel.TEACHER} value={CourseLevel.TEACHER}>
                  {COURSE_LEVEL_TEXT[CourseLevel.TEACHER]}
                </Option>
              </Select>
            </Col>
            <Col span={5}>
              <Select
                placeholder="เลือกประเภทคอร์ส"
                onChange={handleSelectType}
                onClear={() => setTeacherAcademyCourseType(undefined)}
                value={teacherAcademyCourseType}
                allowClear
                disabled={courseLevel === CourseLevel.STUDENT}
                className="min-w-full"
              >
                {teacherAcademyCourseTypeOptions?.map((s) => (
                  <Option key={s?.value} value={s?.value}>
                    {s.label}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col span={8}>
              <Input
                placeholder="ค้นหาจากชื่อคอร์ส"
                onChange={(e: any) => {
                  setSearchFilter(e.target.value);
                }}
                onPressEnter={() => handleSearch(searchFilter)}
              />
            </Col>
            <Col span={3}>
              <Button
                type="primary"
                block
                onClick={() => handleSearch(searchFilter)}
              >
                ค้นหา
              </Button>
            </Col>
            <Col span={3}>
              <Button
                className="flex items-center"
                onClick={() => {
                  history.push('/course/manage/create');
                }}
              >
                <PlusOutlined /> เพิ่มคอร์ส
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <CourseManageTable
        courses={coursesData}
        loading={loading || teacherAcademyCourseTypeLoading}
        refetch={refetch}
      />
      <Pagination
        showSizeChanger={false}
        current={currentPage}
        onChange={(page) => {
          setCurrentPage(page);
        }}
        total={item}
        className="flex justify-end mt-4"
      />
    </PageContainer>
  );
};
