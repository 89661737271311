/** @jsx jsx */
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  message
} from 'antd';
import { css, jsx } from '@emotion/react';
import { useEffect, useState } from 'react';

import { CourseSession } from './CourseSessionType';
import { CourseSignatureDto } from '@aksorn/backoffice-sdk';
import { EyeOutlined } from '@ant-design/icons';
import _ from 'lodash';
import mockCertificate from 'assets/mockCertificate.png';
import moment from 'moment';
import { useCourse } from 'hooks/course/useCourse';

const { Option } = Select;

interface SelectItem {
  label?: string;
  value: string;
  key?: string;
}

const formatDateTh = (date?: string) =>
  moment(date).add(543, 'year').format('D MMMM พ.ศ. YYYY');

const formatDateEn = (date?: string) =>
  moment(date).locale('en').format('dddd, MMMM D, YYYY');

const createCertificatePreview = (
  course: any,
  session: CourseSession,
  signatures: CourseSignatureDto[],
  type: 'passedCertificate' | 'participatedCertificate'
) => {
  return {
    courseNameTh: course?.name?.th,
    courseNameEn: course?.name?.en,
    sessionNameTh: session?.name?.th,
    sessionNameEn: session?.name?.en,
    candidateNameTh: 'นายตัวอย่าง เรียนดี',
    candidateNameEn: 'Example Intelligent',
    passedDateTh: formatDateTh(),
    passedDateEn: formatDateEn(),
    sessionStartDateTh: formatDateTh(session.sessionStartDate),
    sessionStartDateEn: formatDateEn(session.sessionStartDate),
    sessionEndDateTh: formatDateTh(session.sessionEndDate),
    sessionEndDateEn: formatDateEn(session.sessionEndDate),
    lang: 'th',
    certificateImageUrl: course[type]?.imageUrl?.th,
    certificateTemplate: course[type]?.template?.th,
    signatures: signatures.reduce(
      (result, signature) => {
        return result.concat({
          signatureImageUrl: signature.signatureImageUrl.th,
          name: signature.name.th,
          position: signature.position.th,
          companyName: signature.companyName.th
        });
      },
      [] as {
        signatureImageUrl: string;
        name: string;
        position: string;
        companyName: string;
      }[]
    )
  };
};

interface Props {
  prefix: string;
  signatures?: CourseSignatureDto[];
  signatureValidator?: Partial<CourseSignatureDto>;
  defaultVideoViewingPassPercentage?: number | null;
  defaultQuizScorePassPercentage?: number | null;
  setVideoViewingPassPercentage: (eventFromInput: any) => void;
  setQuizScorePassPercentage: (eventFromInput: any) => void;
  setCourseSignatureId: (obj: {
    obj?: SelectItem;
    index: number;
    isRemove?: boolean;
  }) => void;
  selectedSignatures?: SelectItem[];
  certificate?: any;
  courseId?: string;
  session: CourseSession;
  type: 'passedCertificate' | 'participatedCertificate';
}

export const CourseSessionCertificateCondition = (props: Props) => {
  const {
    prefix,
    signatures,
    signatureValidator,
    defaultVideoViewingPassPercentage,
    defaultQuizScorePassPercentage,
    setVideoViewingPassPercentage,
    setQuizScorePassPercentage,
    setCourseSignatureId,
    selectedSignatures,
    courseId,
    session,
    type
  } = props;

  const { execute } = useCourse(courseId, true);
  const [isExceedWatchingVideo, setIsExceedWatchingVideo] = useState(
    defaultVideoViewingPassPercentage
      ? defaultVideoViewingPassPercentage > 0
      : false
  );
  const [isExceedScore, setIsExceedScore] = useState(
    defaultQuizScorePassPercentage ? defaultQuizScorePassPercentage > 0 : false
  );
  const [
    certificatePreviewModalVisible,
    setCertificatePreviewModalVisible
  ] = useState(false);
  const [signatureOptions, setSignatureOptions] = useState<SelectItem[]>([]);

  useEffect(() => {
    const selectedSignatureIds = _.values(
      _.mapValues(selectedSignatures, (s: SelectItem) => s?.value)
    );
    const signatureOptions = signatures
      ?.filter((signature) => {
        return !selectedSignatureIds.includes(
          signature?.courseSignatureId as any
        );
      })
      .map((courseSignature) => ({
        label: courseSignature?.name?.th,
        value: courseSignature?.courseSignatureId
      })) as SelectItem[];
    setSignatureOptions(signatureOptions);
  }, [signatures, selectedSignatures]);

  return (
    <div className="ml-12">
      <div className="text-sm mb-4 mt-4 text-mono-gray-300">
        เงื่อนไขการได้รับใบประกาศ
      </div>
      <Row>
        <Col span={12}>
          <Checkbox
            checked={isExceedWatchingVideo}
            onChange={(e) => {
              setIsExceedWatchingVideo(e.target.checked as boolean);
            }}
          >
            ดูวีดีโอมากกว่าที่กำหนด
          </Checkbox>
        </Col>
        <Col span={2}>
          <Form.Item name={prefix + 'percentOfWatchingVideo'}>
            <Input
              type="number"
              defaultValue={`${defaultVideoViewingPassPercentage}`}
              disabled={!isExceedWatchingVideo}
              onChange={(e) => {
                setVideoViewingPassPercentage(e);
              }}
            />
          </Form.Item>
        </Col>
        <Col span={4} className="ml-2">
          เปอร์เซ็นต์
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Checkbox
            checked={isExceedScore}
            onChange={(e) => {
              setIsExceedScore(e.target.checked);
            }}
          >
            ได้คะแนนมากกว่าที่กำหนด
          </Checkbox>
        </Col>
        <Col span={2}>
          <Form.Item name={prefix + 'percentOfScore'}>
            <Input
              type="number"
              disabled={!isExceedScore}
              defaultValue={`${defaultQuizScorePassPercentage}`}
              onChange={(e) => {
                setQuizScorePassPercentage(e);
              }}
            />
          </Form.Item>
        </Col>
        <Col span={4} className="ml-2">
          เปอร์เซ็นต์
        </Col>
      </Row>
      <div className="text-sm mb-4 mt-4 text-mono-gray-300">
        จัดการลายเซ็นสำหรับใบประกาศ
        <Row>
          <Col span={18}>
            {[1, 2, 3].map((a, index: number) => (
              <Form.Item
                name={`${prefix}Signature${index + 1}`}
                labelCol={{ span: 6 }}
                label={`ลายเซ็นที่ ${index + 1}`}
                required={index === 0}
                rules={
                  index === 0
                    ? [
                        () => ({
                          validator(rule, value) {
                            if (signatureValidator || value) {
                              return Promise.resolve();
                            }
                            return Promise.reject('กรุณาเลือกลายเซ็น');
                          }
                        })
                      ]
                    : []
                }
              >
                <Select
                  allowClear
                  showSearch
                  labelInValue
                  placeholder="เลือกลายเซ็น"
                  onSelect={(value: any) => {
                    setCourseSignatureId({ obj: value, index });
                  }}
                  onClear={() => {
                    setCourseSignatureId({ index, isRemove: true });
                  }}
                  css={css`
                    .anticon-close-circle {
                      position: relative !important;
                      bottom: 3px !important;
                    }
                  `}
                >
                  {signatureOptions.map((obj: SelectItem) => (
                    <Option value={obj.value}>{obj.label}</Option>
                  ))}
                </Select>
              </Form.Item>
            ))}
            <Button
              onClick={async () => {
                try {
                  const course = await execute();
                  const selectedSignatureIds = _.values(
                    _.mapValues(selectedSignatures, (s: SelectItem) => s?.value)
                  );
                  const certificatePreview = createCertificatePreview(
                    course.data.data,
                    session,
                    selectedSignatureIds.map((sId) =>
                      signatures?.find((s: any) => s.courseSignatureId === sId)
                    ) as CourseSignatureDto[],
                    type
                  );
                  localStorage.setItem(
                    'certificate_data',
                    JSON.stringify(certificatePreview)
                  );
                  window.open('/certificate');
                } catch (error) {
                  message.error('preview certificate error');
                }
              }}
            >
              <EyeOutlined /> ดูตัวอย่างใบประกาศ
            </Button>
            {certificatePreviewModalVisible && (
              <Modal
                visible={certificatePreviewModalVisible}
                onCancel={() => {
                  setCertificatePreviewModalVisible(false);
                }}
                title="ตัวอย่าง"
                footer={null}
              >
                <img src={mockCertificate} alt="mock-certificate" />
              </Modal>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};
