import React from 'react';
import { SchoolRequestApproveBody } from '@aksorn/backoffice-sdk';
import useAxios from 'axios-hooks';

export const getErrorMessage = (text: string) => {
  switch (text) {
    case 'some user is not in this school':
      return (
        <div className="text-left">
          ไม่สามารถอนุมัติคำขอได้ เนื่องจากอีเมลของผู้ติดต่ออยู่ในโรงเรียนอื่น
          <br /> กรุณากด “ไม่อนุมัติ” คำขอนี้และติดต่อโรงเรียน
          เพื่อให้โรงเรียนส่งคำขอเข้ามาอีกครั้ง
        </div>
      );
    case 'user is not in the school':
      return (
        <div className="text-left">
          ไม่สามารถอนุมัติคำขอได้ เนื่องจากอีเมลของผู้ติดต่ออยู่ในโรงเรียนอื่น
          <br /> กรุณากด “ไม่อนุมัติ” คำขอนี้และติดต่อโรงเรียน
          เพื่อให้โรงเรียนส่งคำขอเข้ามาอีกครั้ง
        </div>
      );
    default:
      return 'อนุมัติคำขอเปิดบัญชีโรงเรียนไม่สำเร็จ';
  }
};

export const useApproveSchoolRequest = () => {
  const [{ data: response, loading, error }, fire] = useAxios(
    {
      method: 'POST'
    },
    { manual: true }
  );

  const execute = (schoolRequestId: string, data: SchoolRequestApproveBody) => {
    return fire({
      url: `/school-requests/${schoolRequestId}/approve`,
      data
    });
  };

  return {
    data: response?.data,
    loading,
    error,
    execute
  };
};
