import { Fragment, useState } from 'react';
/** @jsx jsx */
import { Pagination, Table, Typography } from 'antd';

import { jsx } from '@emotion/react';
import { useCouponReportSchools } from 'hooks/coupon/useCouponReportSchools';

interface Props {
  couponId: string;
}

export const CouponReportDetailSchoolSection = ({ couponId }: Props) => {
  const [currentPage, setCurrentPage] = useState<number>(1);

  const { data: couponSchools, loading, item } = useCouponReportSchools({
    couponId,
    pageNumber: currentPage
  });

  const columns = [
    {
      title: 'ลำดับที่',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 100
    },
    {
      title: 'รายชื่อโรงเรียนที่เข้าร่วม',
      dataIndex: ['schoolAliasName', 'th'],
      key: 'schoolNameTH',
      width: 300,
      render: (text: string) => (
        <Typography.Text>{text || 'ไม่ระบุโรงเรียน'}</Typography.Text>
      )
    },
    {
      title: 'จำนวนผู้เข้ามาใช้งาน',
      dataIndex: 'numberOfSchoolUsage',
      align: 'center',
      key: 'numberOfSchoolUsage',
      width: 140
    },
    {
      title: 'จำนวนครูที่เข้ามาใช้งาน',
      dataIndex: 'numberOfTeacherUsage',
      align: 'center',
      key: 'numberOfTeacherUsage',
      width: 140
    },
    {
      title: 'จำนวนนักเรียนที่เข้ามาใช้งาน',
      dataIndex: 'numberOfStudentUsage',
      align: 'center',
      key: 'numberOfStudentUsage',
      width: 140
    }
  ];
  return (
    <Fragment>
      <h1 className="text-xl font-bold mb-2">รายละเอียดโรงเรียนที่เข้าร่วม</h1>
      <Table
        loading={loading}
        pagination={false}
        dataSource={couponSchools as any}
        columns={columns as any}
        rowKey="schoolId"
      />
      {item > 0 && (
        <Pagination
          current={currentPage}
          showSizeChanger={false}
          onChange={(page) => {
            setCurrentPage(page);
          }}
          total={item}
          className="flex justify-end mt-4"
        />
      )}
    </Fragment>
  );
};
