import { Coupon } from 'feature/coupon/coupon.type';
import useAxios from 'axios-hooks';

export const useCoupon = (couponId: string) => {
  const [{ data, loading, error }, refetch] = useAxios(
    couponId && `/coupons/${couponId}`
  );

  return {
    data: data && (data?.data as Coupon),
    loading: loading,
    error,
    refetch
  };
};
