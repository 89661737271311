/** @jsx jsx */
import { Col, Pagination, Row, Select, Spin, Table, Typography } from 'antd';
import { Fragment, useEffect, useState } from 'react';
import { css, jsx } from '@emotion/react';

import Axios from 'axios';
import { CouponReportUser } from './coupon-report.type';
import { DateFormat } from 'feature/common/global.type';
import { School } from 'feature/coupon/school.type';
import { USER_TYPE } from '../common/user';
import { UserType } from 'feature/common/user.type';
import { debounce } from 'lodash';
import moment from 'moment';
import { useCouponReportUsers } from 'hooks/coupon/useCouponReportUsers';

const { Option } = Select;

interface Props {
  couponId: string;
}

const fetchSchools = debounce(
  async ({
    keyword = '',
    pageNumber = 1,
    isAppendSchools,
    filteredSchools = [],
    setFilteredSchools
  }) => {
    const {
      data: { data: schools }
    } = await Axios.get(`/schools`, {
      params: {
        keyword,
        pageNumber,
        pageSize: 20
      }
    });
    let newSchools = schools;
    if (isAppendSchools) {
      newSchools = [...filteredSchools, ...schools];
    }
    setFilteredSchools(newSchools);
  },
  100,
  { leading: false, trailing: true }
);

export const CouponReportDetailUserSection = ({ couponId }: Props) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [schoolIdFilter, setSchoolIdFilter] = useState<string>();
  const [userTypeFilter, setUserTypeFilter] = useState<UserType>();
  const [isLoadingSchools, setIsLoadingSchools] = useState(false);
  const [isAppendSchools, setIsAppendSchools] = useState(false);
  const [schoolSearchText, setSchoolSearchText] = useState<string>('');
  const [schoolPageNumber, setSchoolPageNumber] = useState<number>(1);
  const [filteredSchools, setFilteredSchools] = useState<School[]>([]);
  const [schoolListOptions, setSchoolListOptions] = useState<any>([]);

  const { data: couponUsers, loading, item } = useCouponReportUsers({
    couponId,
    pageNumber: currentPage,
    schoolId: schoolIdFilter,
    userType: userTypeFilter
  });

  const columns = [
    {
      title: 'ลำดับที่',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 100
    },
    {
      title: 'รายชื่อผู้ใช้คูปอง',
      key: 'displayNameTH',
      width: 120,
      render: (dto: CouponReportUser) => `${dto.title}${dto.displayName.th}`
    },
    {
      title: 'ชื่อโรงเรียน',
      dataIndex: ['school', 'schoolAliasName', 'th'],
      key: 'schoolNameTH',
      width: 250,
      render: (text: string) => (
        <Typography.Text>{text || 'ไม่ระบุโรงเรียน'}</Typography.Text>
      )
    },
    {
      title: 'ระดับผู้ใช้',
      dataIndex: 'userType',
      key: 'userType',
      width: 30
    },
    {
      title: 'วันที่ใช้',
      dataIndex: 'couponUsedDate',
      key: 'couponUsedDate',
      width: 50,
      render: (timestamp: string) =>
        timestamp && moment(timestamp).format(DateFormat.DEFAULT)
    }
  ];

  useEffect(() => {
    const schoolListOptions = filteredSchools?.map((school: School) => ({
      key: school?.schoolId,
      label: school?.schoolAliasName?.th,
      value: school?.schoolId
    })) as any;
    setSchoolListOptions(schoolListOptions);
  }, [filteredSchools]);

  useEffect(() => {
    getSchools({
      schoolSearchText,
      pageNumber: schoolPageNumber,
      isAppendSchools,
      filteredSchools,
      setFilteredSchools
    });
  }, [schoolSearchText, schoolPageNumber]);

  useEffect(() => {
    setIsLoadingSchools(false);
  }, [schoolListOptions]);

  const getSchools = async ({
    schoolSearchText,
    pageNumber,
    isAppendSchools,
    filteredSchools,
    setFilteredSchools
  }: {
    schoolSearchText: string;
    pageNumber: number;
    isAppendSchools: boolean;
    filteredSchools: School[];
    setFilteredSchools: Function;
  }) => {
    try {
      setIsLoadingSchools(true);
      await fetchSchools({
        keyword: schoolSearchText,
        pageNumber,
        isAppendSchools,
        filteredSchools,
        setFilteredSchools
      });
    } catch (error) {
      setIsLoadingSchools(false);
      console.error(error);
    }
  };

  const handleLoadMoreSchools = async (event: any) => {
    const target = event.target;
    if (target.scrollTop + target.offsetHeight > 0.99 * target.scrollHeight) {
      target.scrollTo(0, target.scrollHeight);
      setIsAppendSchools(true);
      setSchoolPageNumber(schoolPageNumber + 1);
    }
  };

  const handleResetLoadMoreState = (value: string) => {
    setSchoolPageNumber(1);
    setSchoolSearchText(value);
    setIsAppendSchools(false);
  };

  return (
    <Fragment>
      <Row justify="space-between" align="middle" className="mb-4">
        <Col>
          <h1 className="text-xl font-bold mb-2">รายละเอียดผู้ใช้คูปอง</h1>
        </Col>
        <Col span={20}>
          <Row justify="end" gutter={8}>
            <Col span={12}>
              <Select
                showSearch
                className="w-full"
                placeholder="โรงเรียน"
                allowClear
                optionFilterProp="children"
                value={schoolIdFilter as any}
                onSearch={handleResetLoadMoreState}
                notFoundContent={(() => {
                  if (isLoadingSchools)
                    return (
                      <div className="flex justify-center items-center">
                        <Spin size="small" />
                      </div>
                    );
                  return (
                    <div className="ant-select-item-empty">
                      <div className="ant-empty ant-empty-normal ant-empty-small">
                        <div className="ant-empty-description">
                          ไม่พบโรงเรียนที่ค้นหา
                        </div>
                      </div>
                    </div>
                  );
                })()}
                filterOption={(input, option) => {
                  return (option?.label as string)?.includes(input);
                }}
                onPopupScroll={handleLoadMoreSchools}
                onSelect={(value) => {
                  setSchoolIdFilter(value as string);
                  handleResetLoadMoreState('');
                }}
                onClear={() => {
                  setSchoolIdFilter(undefined);
                  handleResetLoadMoreState('');
                }}
                options={schoolListOptions}
                css={css`
                  .anticon-close-circle {
                    position: relative;
                    bottom: 2px;
                  }
                `}
              />
            </Col>
            <Col span={5}>
              <Select
                className="w-full"
                placeholder="ระดับผู้ใช้"
                allowClear
                onChange={(value: any) => {
                  setUserTypeFilter(value);
                }}
                css={css`
                  .anticon-close-circle {
                    position: relative;
                    bottom: 2px;
                  }
                `}
              >
                <Option value={UserType.STUDENT}>
                  {USER_TYPE[UserType.STUDENT]}
                </Option>
                <Option value={UserType.TEACHER}>
                  {USER_TYPE[UserType.TEACHER]}
                </Option>
              </Select>
            </Col>
          </Row>
        </Col>
      </Row>
      <Table
        loading={loading}
        pagination={false}
        dataSource={couponUsers as any}
        columns={columns as any}
        rowKey={(record) => record.userId + '-' + record.courseSessionId}
      />
      {item > 0 && (
        <Pagination
          current={currentPage}
          showSizeChanger={false}
          onChange={(page) => {
            setCurrentPage(page);
          }}
          total={item}
          className="flex justify-end mt-4"
        />
      )}
    </Fragment>
  );
};
