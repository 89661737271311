/** @jsx jsx */

import Axios, { AxiosError } from 'axios';
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Switch,
  Upload,
  message
} from 'antd';
import {
  CourseLesson,
  CourseLessonCreateDto,
  CourseLessonType
} from 'feature/course/course.type';
import { FormRule, getRule } from 'feature/common/Form/utils';
import { Fragment, useEffect, useState } from 'react';
import { HTMLtoState, parseStateToHTML } from 'feature/common/Wysiwyg/utils';
import { PrimaryButton, TextButton } from 'feature/common/Button';
import {
  RefOwnerType,
  RequestUploadUrl,
  UploadStatus,
  useUploadImage
} from 'hooks/useUploadImage';
import {
  calculateMilliseconds,
  millisecondsToHMS
} from 'feature/common/utils/calculateMilliseconds';
import { css, jsx } from '@emotion/react';

import { CloudUploadOutlined } from '@ant-design/icons';
import { EditorState } from 'draft-js';
import { ReactComponent as FlagIcon } from 'assets/flag.svg';
import { WysiwygEditor } from 'feature/common/Wysiwyg/Editor';
import _ from 'lodash';
import { useCreateCourseLesson } from 'hooks/course/useCreateCourseLesson';

const { Option } = Select;

type ManageLessonModalProps = {
  isEdit: boolean;
  visible: boolean;
  lesson: CourseLesson | undefined;
  courseSectionId: string;
  setSelectedLesson: (lesson: CourseLesson) => void;
  setUpdatedLessonId: (lessonId: string) => void;
  refetch: () => {};
  onClose: () => void;
};

export enum QuizLessonTypeOptions {
  DEFAULT = 'default',
  PRETEST = 'preTest',
  POSTTEST = 'postTest'
}

export const ManageLessonModal = ({
  isEdit,
  visible,
  lesson,
  courseSectionId,
  setSelectedLesson,
  setUpdatedLessonId,
  refetch,
  onClose
}: ManageLessonModalProps) => {
  const { fileList, beforeUpload, onRemove, setDefaultFile } = useUploadImage(
    true
  );
  const { execute: createCourseLesson } = useCreateCourseLesson();

  const [lessonType, setLessonType] = useState<CourseLessonType>();
  const [isPreview, setIsPreview] = useState(false);
  const [videoDurationHours, setVideoDurationHours] = useState<
    number | undefined
  >();
  const [videoDurationMinutes, setVideoDurationMinutes] = useState<
    number | undefined
  >();
  const [videoDurationSeconds, setVideoDurationSeconds] = useState<
    number | undefined
  >();
  const [previewVideoDurationHours, setPreviewVideoDurationHours] = useState<
    number | undefined
  >();
  const [
    previewVideoDurationMinutes,
    setPreviewVideoDurationMinutes
  ] = useState<number | undefined>();
  const [
    previewVideoDurationSeconds,
    setPreviewVideoDurationSeconds
  ] = useState<number | undefined>();
  const [quizDurationHours, setQuizDurationHours] = useState<
    number | undefined
  >();
  const [quizDurationMinutes, setQuizDurationMinutes] = useState<
    number | undefined
  >();
  const [quizDurationSeconds, setQuizDurationSeconds] = useState<
    number | undefined
  >();

  const [fileDurationHours, setFileDurationHours] = useState<
    number | undefined
  >();
  const [fileDurationMinutes, setFileDurationMinutes] = useState<
    number | undefined
  >();
  const [fileDurationSeconds, setFileDurationSeconds] = useState<
    number | undefined
  >();

  const [editorDesTHState, setEditorDesTHState] = useState(
    EditorState.createEmpty()
  );

  const [editorDesENState, setEditorDesENState] = useState(
    EditorState.createEmpty()
  );

  const [isImportant, setIsImportant] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    if (isEdit && lesson) {
      setLessonType(lesson.lessonType);

      const desTH = EditorState.createWithContent(
        HTMLtoState(lesson?.description.th || '')
      );
      setEditorDesTHState(desTH);

      const desEN = EditorState.createWithContent(
        HTMLtoState(lesson?.description.en || '')
      );
      setEditorDesENState(desEN);

      form.setFieldsValue({
        ...lesson,
        nameTH: lesson?.name?.th,
        nameEN: lesson?.name?.en,
        //descriptionTH: lesson?.description.th,
        //descriptionEN: lesson?.description.en,
        quizLessonType: lesson?.quizLessonType ?? QuizLessonTypeOptions.DEFAULT
      });
      const {
        hours: videoHours,
        minutes: videoMinutes,
        seconds: videoSeconds
      } = millisecondsToHMS(lesson?.videoDuration);
      setVideoDurationHours(videoHours);
      setVideoDurationMinutes(videoMinutes);
      setVideoDurationSeconds(videoSeconds);
      setIsImportant(lesson?.isImportant);
      setIsPreview(lesson?.isPreview);
      const {
        hours: previewVideoHours,
        minutes: previewVideoMinutes,
        seconds: previewVideoSeconds
      } = millisecondsToHMS(lesson?.previewVideoDuration);
      setPreviewVideoDurationHours(previewVideoHours);
      setPreviewVideoDurationMinutes(previewVideoMinutes);
      setPreviewVideoDurationSeconds(previewVideoSeconds);
      setIsImportant(lesson?.isImportant);
      setIsPreview(lesson?.isPreview);
      const {
        hours: quizHours,
        minutes: quizMinutes,
        seconds: quizSeconds
      } = millisecondsToHMS(lesson?.quizDuration);
      setQuizDurationHours(quizHours);
      setQuizDurationMinutes(quizMinutes);
      setQuizDurationSeconds(quizSeconds);
      setIsImportant(lesson?.isImportant);
      setIsPreview(lesson?.isPreview);
      if (lesson.fileUrl) {
        setDefaultFile(lesson.fileUrl);
      }
      const {
        hours: fileHours,
        minutes: fileMinutes,
        seconds: fileSeconds
      } = millisecondsToHMS(lesson?.fileDuration);
      setFileDurationHours(fileHours);
      setFileDurationMinutes(fileMinutes);
      setFileDurationSeconds(fileSeconds);
      setIsImportant(lesson?.isImportant);
    }
  }, [visible, lesson, form, isEdit]);

  useEffect(() => {
    validateVideoDuration();
  }, [videoDurationHours, videoDurationMinutes, videoDurationSeconds]);

  useEffect(() => {
    validatePreviewVideoDuration();
  }, [
    previewVideoDurationHours,
    previewVideoDurationMinutes,
    previewVideoDurationSeconds
  ]);

  useEffect(() => {
    validateQuizDuration();
  }, [quizDurationHours, quizDurationMinutes, quizDurationSeconds]);

  useEffect(() => {
    validateFileDuration();
  }, [fileDurationHours, fileDurationMinutes, fileDurationSeconds]);

  const validateVideoDuration = () => {
    if (
      videoDurationHours !== undefined &&
      videoDurationMinutes !== undefined &&
      videoDurationSeconds !== undefined
    )
      form.setFieldsValue({
        videoDuration: true
      });
    else
      form.setFieldsValue({
        videoDuration: undefined
      });
    form.validateFields(['videoDuration']);
  };

  const validatePreviewVideoDuration = () => {
    if (
      previewVideoDurationHours !== undefined &&
      previewVideoDurationMinutes !== undefined &&
      previewVideoDurationSeconds !== undefined
    )
      form.setFieldsValue({
        previewVideoDuration: true
      });
    else
      form.setFieldsValue({
        previewVideoDuration: undefined
      });
    form.validateFields(['previewVideoDuration']);
  };

  const validateQuizDuration = () => {
    if (
      quizDurationHours !== undefined &&
      quizDurationMinutes !== undefined &&
      quizDurationSeconds !== undefined
    )
      form.setFieldsValue({
        quizDuration: true
      });
    else
      form.setFieldsValue({
        quizDuration: undefined
      });
    form.validateFields(['quizDuration']);
  };

  const validateFileDuration = () => {
    if (
      fileDurationHours !== undefined &&
      fileDurationMinutes !== undefined &&
      fileDurationSeconds !== undefined
    )
      form.setFieldsValue({
        fileDuration: true
      });
    else
      form.setFieldsValue({
        fileDuration: undefined
      });
    form.validateFields(['fileDuration']);
  };

  const handleUploadImage = async (courseLessonId: string, file: any) => {
    if (file.status === UploadStatus.EMPTY) return null;
    try {
      const requestThumbnailUrlResponse: {
        data: RequestUploadUrl;
      } = await Axios.post('/assets', {
        refOwnerType: RefOwnerType.LESSON_FILE,
        refOwnerId: courseLessonId,
        filename: file.name,
        contentType: file.type
      });
      await Axios({
        url: requestThumbnailUrlResponse.data.data.upload.url,
        params: requestThumbnailUrlResponse.data.data.upload.params,
        method: 'PUT',
        data: file.file,
        headers: { 'content-type': file.type }
      });
      return requestThumbnailUrlResponse.data.data.url;
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async () => {
    await form.validateFields();

    const values = form.getFieldsValue();

    const {
      nameTH,
      nameEN,
      // descriptionTH,
      // descriptionEN,
      lessonType,
      videoUrl,
      previewVideoUrl,
      quizRefCode,
      quizQuestionAmount,
      quizMaxScore,
      quizLessonType
    } = values;

    const loading = message.loading('กำลังดำเนินการ...');

    try {
      let data: CourseLessonCreateDto = {
        courseSection: {
          courseSectionId: courseSectionId
        },
        name: { th: nameTH, en: nameEN },
        description: {
          th: parseStateToHTML(editorDesTHState) || '',
          en: parseStateToHTML(editorDesENState) || ''
        },
        lessonType: lessonType as CourseLessonType,
        isImportant: isImportant
      };

      switch (lessonType) {
        case CourseLessonType.BYTEARK:
        case CourseLessonType.YOUTUBE:
          data = {
            videoUrl: videoUrl,
            videoDuration: calculateMilliseconds(
              videoDurationHours,
              videoDurationMinutes,
              videoDurationSeconds
            ),
            isPreview: isPreview,
            previewVideoUrl: isPreview ? previewVideoUrl : undefined,
            previewVideoDuration: isPreview
              ? calculateMilliseconds(
                  previewVideoDurationHours,
                  previewVideoDurationMinutes,
                  previewVideoDurationSeconds
                )
              : 0,
            ...data
          };
          break;
        case CourseLessonType.QUIZ:
          data = {
            quizRefCode: quizRefCode,
            quizQuestionAmount: +quizQuestionAmount,
            quizMaxScore: +quizMaxScore,
            quizDuration: calculateMilliseconds(
              quizDurationHours,
              quizDurationMinutes,
              quizDurationSeconds
            ),
            quizLessonType:
              quizLessonType === QuizLessonTypeOptions.DEFAULT
                ? null
                : quizLessonType,
            ...data
          };
          break;
      }

      if (isEdit) {
        if (lessonType === CourseLessonType.FILE) {
          let fileUrl: any = '';
          if (!fileList[0].file) {
            fileUrl = fileList[0].url;
          } else {
            fileUrl = await handleUploadImage(
              lesson?.courseLessonId || '',
              fileList[0]
            );
          }
          data = {
            ...data,
            fileUrl: fileUrl || '',
            fileDuration: calculateMilliseconds(
              fileDurationHours,
              fileDurationMinutes,
              fileDurationSeconds
            )
          };
        }
        await Axios.put(`/course-lessons/${lesson?.courseLessonId}`, {
          ...data
        });
        setUpdatedLessonId(lesson?.courseLessonId as string);
      } else {
        const {
          data: { data: createdCourseLesson }
        } = await createCourseLesson({ data });

        if (lessonType === CourseLessonType.FILE) {
          const fileUrl = await handleUploadImage(
            createdCourseLesson?.courseLessonId,
            fileList[0]
          );

          await Axios.put(
            `/course-lessons/${createdCourseLesson?.courseLessonId}`,
            {
              lessonType: lessonType,
              fileUrl,
              fileDuration: calculateMilliseconds(
                fileDurationHours,
                fileDurationMinutes,
                fileDurationSeconds
              )
            }
          );
        }
        setSelectedLesson(createdCourseLesson);
        setUpdatedLessonId(createdCourseLesson?.courseLessonId);
      }

      await refetch();
      loading();
      message.success(`${isEdit ? 'แก้ไข' : 'เพิ่ม'}เนื้อหาสำเร็จ`);
      onClose();
    } catch (error) {
      loading();
      //message.error(`${isEdit ? 'แก้ไข' : 'เพิ่ม'}เนื้อหาไม่สำเร็จ`);
      if (Axios.isAxiosError(error)) {
        const { response } = error as AxiosError;
        if (response) {
          const { message: errMessage } = response.data;
          message.error(
            `${isEdit ? 'แก้ไข' : 'เพิ่ม'}เนื้อหาไม่สำเร็จ: ${errMessage}`
          );
        }
      }
      console.error(error);
    }
  };

  const renderAdditionalForm = () => {
    switch (lessonType) {
      case CourseLessonType.BYTEARK:
      case CourseLessonType.YOUTUBE:
        return (
          <Fragment>
            <Form.Item
              label="ลิงก์วิดีโอ"
              name="videoUrl"
              validateTrigger="onChange"
              rules={[
                getRule(FormRule.REQUIRE, 'กรุณากรอกลิงก์วีดีโอ'),
                getRule(FormRule.IS_URL, 'กรุณากรอกลิงก์ Url ให้ถูกต้อง')
              ]}
            >
              <Input placeholder="" />
            </Form.Item>
            <Form.Item
              label="เวลา"
              name="videoDuration"
              rules={[getRule(FormRule.REQUIRE, 'กรุณากรอกเวลา')]}
              css={css`
                .ant-form-item-control-input-content {
                  display: flex;
                  align-items: center;
                }
              `}
            >
              <Input
                type="number"
                min={0}
                max={10}
                addonAfter="ชั่วโมง"
                value={videoDurationHours}
                placeholder="ชั่วโมง"
                onChange={(event: any) => {
                  const value = _.isEmpty(event.target.value)
                    ? undefined
                    : event.target.value;
                  if (value > 10) setVideoDurationHours(10);
                  else setVideoDurationHours(value);
                  validateVideoDuration();
                }}
                className="mr-2"
              />
              <Input
                type="number"
                min={0}
                max={59}
                addonAfter="นาที"
                value={videoDurationMinutes}
                placeholder="นาที"
                onChange={(event: any) => {
                  const value = _.isEmpty(event.target.value)
                    ? undefined
                    : event.target.value;
                  if (value > 59) setVideoDurationMinutes(59);
                  else setVideoDurationMinutes(value);
                  validateVideoDuration();
                }}
                className="mr-2"
              />
              <Input
                type="number"
                min={0}
                max={59}
                addonAfter="วินาที"
                value={videoDurationSeconds}
                placeholder="วินาที"
                onChange={(event: any) => {
                  const value = _.isEmpty(event.target.value)
                    ? undefined
                    : event.target.value;
                  if (value > 59) setVideoDurationSeconds(59);
                  else setVideoDurationSeconds(value);
                  validateVideoDuration();
                }}
                className="mr-2"
              />
              {/* <Button onClick={getVideoDuration}>ดึงจากวีดีโอ</Button> */}
            </Form.Item>
            <Form.Item label="Preview" name="isPreview">
              <Switch
                defaultChecked={isPreview}
                checkedChildren="เปิด"
                unCheckedChildren="ปิด"
                onChange={(checked: boolean) => {
                  setIsPreview(checked);
                }}
              />
            </Form.Item>
            {isPreview && (
              <Fragment>
                <Form.Item
                  label="ลิงก์วิดีโอตัวอย่าง"
                  name="previewVideoUrl"
                  rules={[
                    getRule(FormRule.REQUIRE, 'กรุณากรอกลิงก์วีดีโอตัวอย่าง'),
                    getRule(FormRule.IS_URL, 'กรุณากรอกลิงก์ Url ให้ถูกต้อง')
                  ]}
                >
                  <Input placeholder="" />
                </Form.Item>
                <Form.Item
                  label="เวลาวิดีโอตัวอย่าง"
                  name="previewVideoDuration"
                  rules={[getRule(FormRule.REQUIRE, 'กรุณากรอกเวลา')]}
                  css={css`
                    .ant-form-item-control-input-content {
                      display: flex;
                      align-items: center;
                    }
                  `}
                >
                  <Input
                    type="number"
                    min={0}
                    max={10}
                    addonAfter="ชั่วโมง"
                    value={previewVideoDurationHours}
                    placeholder="ชั่วโมง"
                    onChange={(event: any) => {
                      const value = _.isEmpty(event.target.value)
                        ? undefined
                        : event.target.value;
                      if (value > 10) setPreviewVideoDurationHours(10);
                      else setPreviewVideoDurationHours(value);
                      validatePreviewVideoDuration();
                    }}
                    className="mr-2"
                  />
                  <Input
                    type="number"
                    min={0}
                    max={59}
                    addonAfter="นาที"
                    value={previewVideoDurationMinutes}
                    placeholder="นาที"
                    onChange={(event: any) => {
                      const value = _.isEmpty(event.target.value)
                        ? undefined
                        : event.target.value;
                      if (value > 59) setPreviewVideoDurationMinutes(59);
                      else setPreviewVideoDurationMinutes(value);
                      validatePreviewVideoDuration();
                    }}
                    className="mr-2"
                  />
                  <Input
                    type="number"
                    min={0}
                    max={59}
                    addonAfter="วินาที"
                    value={previewVideoDurationSeconds}
                    placeholder="วินาที"
                    onChange={(event: any) => {
                      const value = _.isEmpty(event.target.value)
                        ? undefined
                        : event.target.value;
                      if (value > 59) setPreviewVideoDurationSeconds(59);
                      else setPreviewVideoDurationSeconds(value);
                      validatePreviewVideoDuration();
                    }}
                    className="mr-2"
                  />
                  {/* <Button onClick={getPreviewVideoDuration}>
                    ดึงจากวีดีโอ
                  </Button> */}
                </Form.Item>
              </Fragment>
            )}
            <Form.Item label="สำคัญ" name="isImportant">
              <div className="flex items-center">
                <Checkbox
                  className="mr-3"
                  onChange={(event: any) => {
                    setIsImportant(event.target.checked);
                  }}
                  checked={isImportant}
                />
                <FlagIcon
                  css={css`
                    color: #f2994a;
                    margin-right: 0.5rem;
                  `}
                />
                <span className="text-mono-gray-400">Important</span>
              </div>
            </Form.Item>
          </Fragment>
        );
      case CourseLessonType.FILE:
        return (
          <Fragment>
            <Form.Item
              label="เวลาที่ใช้"
              name="fileDuration"
              rules={[getRule(FormRule.REQUIRE, 'กรุณากรอกเวลาที่ใช้')]}
              css={css`
                .ant-form-item-control-input-content {
                  display: flex;
                  align-items: center;
                }
              `}
            >
              <Input
                type="number"
                min={0}
                max={10}
                addonAfter="ชั่วโมง"
                value={fileDurationHours}
                placeholder="ชั่วโมง"
                onChange={(event: any) => {
                  const value = _.isEmpty(event.target.value)
                    ? undefined
                    : event.target.value;
                  if (value > 10) setFileDurationHours(10);
                  else setFileDurationHours(value);
                  validateFileDuration();
                }}
                className="mr-2"
              />
              <Input
                type="number"
                min={0}
                max={59}
                addonAfter="นาที"
                value={fileDurationMinutes}
                placeholder="นาที"
                onChange={(event: any) => {
                  const value = _.isEmpty(event.target.value)
                    ? undefined
                    : event.target.value;
                  if (value > 59) setFileDurationMinutes(59);
                  else setFileDurationMinutes(value);
                  validateFileDuration();
                }}
                className="mr-2"
              />
              <Input
                type="number"
                min={0}
                max={59}
                addonAfter="วินาที"
                value={fileDurationSeconds}
                placeholder="วินาที"
                onChange={async (event: any) => {
                  const value = _.isEmpty(event.target.value)
                    ? undefined
                    : event.target.value;
                  if (value > 59) setFileDurationSeconds(59);
                  else setFileDurationSeconds(value);
                  validateFileDuration();
                }}
                className="mr-2"
              />
            </Form.Item>
            <Form.Item label="สำคัญ" name="isImportant">
              <div className="flex items-center">
                <Checkbox
                  className="mr-3"
                  onChange={(event: any) => {
                    setIsImportant(event.target.checked);
                  }}
                  checked={isImportant}
                />
                <FlagIcon
                  css={css`
                    color: #f2994a;
                    margin-right: 0.5rem;
                  `}
                />
                <span className="text-mono-gray-400">Important</span>
              </div>
            </Form.Item>
            <Form.Item
              label="อัปโหลด"
              name="fileUrl"
              rules={[
                getRule(FormRule.REQUIRE, ' '),
                getRule(FormRule.UPLOAD_REQUIRE, 'กรุณาอัปโหลดไฟล์')
              ]}
            >
              <Upload
                onRemove={onRemove}
                beforeUpload={beforeUpload}
                fileList={fileList}
                listType="picture"
              >
                <Button className="flex" disabled={fileList.length > 0}>
                  <CloudUploadOutlined />
                  อัปโหลดไฟล์
                </Button>
              </Upload>
            </Form.Item>
          </Fragment>
        );
      case CourseLessonType.QUIZ:
        return (
          <Fragment>
            <Form.Item
              label="ประเภทแบบฝึกหัด"
              name="quizLessonType"
              validateTrigger="onChange"
              rules={[getRule(FormRule.REQUIRE, 'กรุณาเลือกประเภทแบบฝึกหัด')]}
              initialValue={QuizLessonTypeOptions.DEFAULT}
            >
              <Select
                placeholder="เลือกประเภทเนื้อหา"
                allowClear
                defaultValue={QuizLessonTypeOptions.DEFAULT}
              >
                <Option value={QuizLessonTypeOptions.DEFAULT}>
                  แบบฝึกหัดทั่วไป
                </Option>
                <Option value={QuizLessonTypeOptions.PRETEST}>
                  แบบฝึกหัดก่อนเรียน
                </Option>
                <Option value={QuizLessonTypeOptions.POSTTEST}>
                  แบบฝึกหัดหลังเรียน
                </Option>
              </Select>
            </Form.Item>

            <Form.Item
              label="refCode แบบฝึกหัด"
              name="quizRefCode"
              validateTrigger="onChange"
              rules={[getRule(FormRule.REQUIRE, 'กรุณากรอก refcode แบบฝึกหัด')]}
            >
              <Input placeholder="" />
            </Form.Item>
            <Form.Item
              label="จำนวนข้อ"
              name="quizQuestionAmount"
              rules={[getRule(FormRule.REQUIRE, 'กรุณากรอกจำนวนข้อ')]}
            >
              <Input
                type="number"
                min={0}
                max={1000}
                onChange={(event: any) => {
                  const value = _.isEmpty(event.target.value)
                    ? undefined
                    : event.target.value;
                  if (value > 1000)
                    form.setFieldsValue({ quizQuestionAmount: 1000 });
                  else form.setFieldsValue({ quizQuestionAmount: value });
                }}
                placeholder=""
              />
            </Form.Item>

            <Form.Item
              label="คะแนนเต็ม"
              name="quizMaxScore"
              rules={[getRule(FormRule.REQUIRE, 'กรุณากรอกคะแนนเต็ม')]}
            >
              <Input
                type="number"
                min={0}
                max={1000}
                onChange={(event: any) => {
                  const value = _.isEmpty(event.target.value)
                    ? undefined
                    : event.target.value;
                  if (value > 1000) form.setFieldsValue({ quizMaxScore: 1000 });
                  else form.setFieldsValue({ quizMaxScore: value });
                }}
                placeholder=""
              />
            </Form.Item>

            <Form.Item
              label="เวลาที่ใช้"
              name="quizDuration"
              rules={[getRule(FormRule.REQUIRE, 'กรุณากรอกเวลาที่ใช้')]}
              css={css`
                .ant-form-item-control-input-content {
                  display: flex;
                  align-items: center;
                }
              `}
            >
              <Input
                type="number"
                min={0}
                max={10}
                addonAfter="ชั่วโมง"
                value={quizDurationHours}
                placeholder="ชั่วโมง"
                onChange={(event: any) => {
                  const value = _.isEmpty(event.target.value)
                    ? undefined
                    : event.target.value;
                  if (value > 10) setQuizDurationHours(10);
                  else setQuizDurationHours(value);
                  validateQuizDuration();
                }}
                className="mr-2"
              />
              <Input
                type="number"
                min={0}
                max={59}
                addonAfter="นาที"
                value={quizDurationMinutes}
                placeholder="นาที"
                onChange={(event: any) => {
                  const value = _.isEmpty(event.target.value)
                    ? undefined
                    : event.target.value;
                  if (value > 59) setQuizDurationMinutes(59);
                  else setQuizDurationMinutes(value);
                  validateQuizDuration();
                }}
                className="mr-2"
              />
              <Input
                type="number"
                min={0}
                max={59}
                addonAfter="วินาที"
                value={quizDurationSeconds}
                placeholder="วินาที"
                onChange={async (event: any) => {
                  const value = _.isEmpty(event.target.value)
                    ? undefined
                    : event.target.value;
                  if (value > 59) setQuizDurationSeconds(59);
                  else setQuizDurationSeconds(value);
                  validateQuizDuration();
                }}
                className="mr-2"
              />
            </Form.Item>
            <Form.Item label="สำคัญ" name="isImportant">
              <div className="flex items-center">
                <Checkbox
                  className="mr-3"
                  onChange={(event: any) => {
                    setIsImportant(event.target.checked);
                  }}
                  checked={isImportant}
                />
                <FlagIcon
                  css={css`
                    color: #f2994a;
                    margin-right: 0.5rem;
                  `}
                />
                <span className="text-mono-gray-400">Important</span>
              </div>
            </Form.Item>
          </Fragment>
        );
    }
  };

  return (
    <Modal
      title={`${isEdit ? 'แก้ไข' : 'เพิ่ม'}เนื้อหา`}
      visible={visible}
      maskClosable={false}
      onCancel={onClose}
      afterClose={() => {
        form.resetFields();
        form.setFieldsValue({ lessonType: null });
        setIsPreview(false);
        setIsImportant(false);
        setLessonType(undefined);
        setVideoDurationHours(undefined);
        setVideoDurationMinutes(undefined);
        setVideoDurationSeconds(undefined);
        setPreviewVideoDurationHours(undefined);
        setPreviewVideoDurationMinutes(undefined);
        setPreviewVideoDurationSeconds(undefined);
        setQuizDurationHours(undefined);
        setQuizDurationMinutes(undefined);
        setQuizDurationSeconds(undefined);
        setFileDurationHours(undefined);
        setFileDurationMinutes(undefined);
        setFileDurationSeconds(undefined);
        setEditorDesTHState(EditorState.createEmpty());
        setEditorDesENState(EditorState.createEmpty());
        onRemove();
      }}
      footer={
        <Row>
          <Col span={24}>
            <div className="flex justify-end items-center">
              <TextButton className="mr-6" onClick={onClose}>
                ยกเลิก
              </TextButton>
              <PrimaryButton
                onClick={() => {
                  handleSubmit();
                }}
              >
                บันทึก
              </PrimaryButton>
            </div>
          </Col>
        </Row>
      }
      css={css`
        width: 640px !important;
        .ant-modal-title {
          font-weight: bold;
        }
        .ant-modal-body {
          min-height: 520px;
        }
      `}
    >
      <Form
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        colon={false}
      >
        <Form.Item
          label="ชื่อเนื้อหา (TH)"
          name="nameTH"
          rules={[getRule(FormRule.REQUIRE, 'กรุณากรอกชื่อเนื้อหาภาษาไทย')]}
        >
          <Input placeholder="ชื่อเนื้อหาภาษาไทย..." />
        </Form.Item>
        <Form.Item label="ชื่อเนื้อหา (EN)" name="nameEN">
          <Input placeholder="ชื่อเนื้อหาภาษาอังกฤษ..." />
        </Form.Item>
        <Form.Item
          label="ประเภทเนื้อหา"
          name="lessonType"
          rules={[getRule(FormRule.REQUIRE, 'กรุณากรอกเลือกประเภทเนื้อหา')]}
        >
          <Select
            disabled={isEdit && !!lessonType}
            placeholder="เลือกประเภทเนื้อหา"
            onChange={(value: CourseLessonType) => {
              setLessonType(value);
            }}
            defaultValue={lessonType}
          >
            <Option value={CourseLessonType.YOUTUBE}>Youtube</Option>
            <Option value={CourseLessonType.FILE}>ไฟล์</Option>
            <Option value={CourseLessonType.QUIZ}>แบบฝึกหัด</Option>
            <Option value={CourseLessonType.BYTEARK}>ByteArk</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="รายละเอียด (TH)"
          // name="descriptionTH"
          name=""
          className="flex flex-col"
          wrapperCol={{ span: 24 }}
          css={css`
            .text-warning-shift {
              font-size: 14px !important;
            }
          `}
        >
          <WysiwygEditor
            editorState={editorDesTHState}
            onEditorStateChange={(state) => {
              form.setFieldsValue({
                descriptionTH: parseStateToHTML(state)
              });
              setEditorDesTHState(state);
            }}
          />
        </Form.Item>
        <Form.Item
          label="รายละเอียด (EN)"
          // name="descriptionEN"
          name=""
          className="flex flex-col"
          wrapperCol={{ span: 24 }}
          css={css`
            .text-warning-shift {
              font-size: 14px !important;
            }
          `}
        >
          <WysiwygEditor
            editorState={editorDesENState}
            onEditorStateChange={(state) => {
              form.setFieldsValue({
                descriptionEN: parseStateToHTML(state)
              });
              setEditorDesENState(state);
            }}
          />
        </Form.Item>
        {renderAdditionalForm()}
      </Form>
    </Modal>
  );
};
