import Axios from 'axios';

interface Props {
  keyword?: string;
  provinceId?: string;
  schoolGroupId?: string;
}

export const getSchools = async ({
  keyword = '',
  provinceId = '',
  schoolGroupId = ''
}: Props = {}) => {
  const {
    data: { data: schools }
  } = await Axios.get(
    `/schools?pageNumber=1&pageSize=100&keyword=${keyword}&provinceId=${provinceId}&schoolGroupId=${schoolGroupId}`,
    { timeout: 3000 }
  );

  return schools || [];
};
