import { CertificateStatus, CourseReportUserSort } from './course-report.type';

export const ENABLE_CERTIFICATE_TYPE_TEXT = {
  [CertificateStatus.DEFAULT]: 'ตามเกณฑ์',
  [CertificateStatus.QUALIFY]: 'อนุมัติ'
};

export const COURSE_REPORT_USER_SORT_TEXT = {
  [CourseReportUserSort.USER]: 'ผู้เรียน',
  [CourseReportUserSort.SCHOOL]: 'โรงเรียน'
};
