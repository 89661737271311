import { Col, Row } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';

import { LecturerForm } from 'feature/lecturer/LecturerForm';
import { PageContainer } from 'feature/common/PageContainer';
import React from 'react';
import { RightOutlined } from '@ant-design/icons';
import queryString from 'query-string';

export const LecturerEditPage = () => {
  const history = useHistory();
  const location = useLocation();
  const qs = queryString.parse(location.search);
  return (
    <PageContainer className="p-8">
      <Row justify="space-between" align="middle" className="mb-8">
        <Col>
          <div className="flex items-center">
            <h1
              className="text-xl font-bold text-mono-gray-300 cursor-pointer"
              onClick={() => history.push('/lecturer')}
            >
              จัดการวิทยากร
            </h1>
            <RightOutlined
              className="mx-2 text-mono-gray-300"
              style={{ fontSize: 12 }}
            />
            <h1 className="text-xl font-bold ">แก้ไขวิทยากร</h1>
          </div>
        </Col>
      </Row>
      <LecturerForm lecturerId={qs.lecturerId as string} />
    </PageContainer>
  );
};
