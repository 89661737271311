import { identity, pickBy } from 'lodash';

import { Banner } from 'feature/banner/banner.type';
import queryString from 'query-string';
import useAxios from 'axios-hooks';

type QueryBanners = {
  keyword: string;
  isVisible: boolean | string;
};

export const useActivateBanners = (params?: Partial<QueryBanners>) => {
  const [{ data, loading, error }, refetch] = useAxios({
    url: `/banners/activate-banner-list?${
      !!params && params?.isVisible !== undefined && !params?.isVisible
        ? 'isVisible=false&'
        : ''
    }${queryString.stringify({
      pageNumber: 1,
      pageSize: 10,
      ...pickBy(params, identity)
    })}`,
    method: 'GET'
  });

  return {
    data: data && (data?.data as Banner[]),
    loading: loading,
    error,
    refetch,
    item: data && data?.meta?.pagination?.total
  };
};
