import { Col, Row, Tabs, message } from 'antd';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Axios from 'axios';
import { CouponDetailForm } from 'feature/coupon/CouponDetailForm';
import { CouponReceiveCourseForm } from 'feature/coupon/CouponReceiveCourseForm';
import { CouponSchoolTab } from 'feature/coupon/CouponSchoolTab';
import { CouponStatus } from 'feature/coupon/coupon.type';
import { PageContainer } from 'feature/common/PageContainer';
import { RightOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';
import queryString from 'query-string';
import { useCoupon } from 'hooks/coupon/useCoupon';

const { TabPane } = Tabs;

export const CouponEditPage = () => {
  const history = useHistory();

  const location = useLocation();
  const { couponId, tab } = queryString.parse(location.search);
  const { data: coupon, loading, refetch } = useCoupon(
    (couponId as string) || ''
  );

  useEffect(() => {
    refetch();
  }, [refetch, location]);
  const handleSubmit = async (values: any) => {
    try {
      await Axios.put(`/coupons/${couponId}`, {
        name: {
          th: values['name.th'],
          en: values['name.en']
        },
        quotaLimit: +values?.quotaLimit,
        couponStartDate: values?.duration[0],
        couponExpiryDate: values?.duration[1],
        status: values?.status ? CouponStatus.ACTIVE : CouponStatus.INACTIVE
      });
      message.success('อัปเดตคูปองสำเร็จ');
    } catch {
      message.error('ทำรายการไม่สำเร็จ');
    }
  };
  return (
    <PageContainer className="p-8">
      <Row justify="space-between" align="middle" className="mb-4">
        <Col>
          <div className="flex items-center">
            <h1
              className="text-xl font-bold text-mono-gray-300 cursor-pointer"
              onClick={() => history.push('/coupon/manage')}
            >
              จัดการคูปอง
            </h1>
            <RightOutlined className="mx-2 text-mono-gray-300 text-xs" />
            <h1 className="text-xl font-bold ">แก้ไขคูปอง</h1>
          </div>
        </Col>
      </Row>
      <Tabs defaultActiveKey={(tab as string) || '1'}>
        <TabPane
          tab="รายละเอียด"
          key="1"
          css={css`
            height: calc(100vh - 180px);
            overflow: auto;
          `}
          style={{ height: 'calc(100vh - 180px)', overflow: 'auto' }}
        >
          <CouponDetailForm
            coupon={coupon}
            loading={loading}
            handleSubmit={handleSubmit}
            mode="edit"
          />
        </TabPane>
        <TabPane tab="คอร์สที่จะได้รับ" key="2">
          <CouponReceiveCourseForm coupon={coupon} />
        </TabPane>
        <TabPane tab="โรงเรียน" key="3">
          <CouponSchoolTab couponId={couponId as string | undefined} />
        </TabPane>
      </Tabs>
    </PageContainer>
  );
};
