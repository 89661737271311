import { identity, pickBy } from 'lodash';

import { Coupon } from 'feature/coupon/coupon.type';
import queryString from 'query-string';
import useAxios from 'axios-hooks';

type QueryCoupon = {
  pageNumber: number;
  pageSize: number;
  fromDate: string;
  toDate: string;
  keyword: string;
};

export const useCoupons = (params: Partial<QueryCoupon>) => {
  const [{ data, loading, error }, refetch] = useAxios({
    url: `/coupons?${queryString.stringify({
      pageNumber: 1,
      pageSize: 10,
      ...pickBy(params, identity)
    })}`,
    method: 'GET'
  });

  return {
    data: data && (data?.data as Coupon[]),
    loading: loading,
    error,
    refetch,
    item: data && data?.meta?.pagination?.total
  };
};
