import { Col, Row, Tabs, message } from 'antd';
import { ProductFieldsValue, ProductForm } from 'feature/product/ProductForm';
import React, { useEffect } from 'react';
import { RequestUploadUrl, UploadStatus } from 'hooks/useUploadImage';
import { identity, pickBy } from 'lodash';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { ReactComponent as BreadcrumbsIcon } from 'assets/breadcrumbs.svg';
import { PageContainer } from 'feature/common/PageContainer';
import { ProductPageStateType } from 'feature/product/const';
import axios from 'axios';
import { useEditProduct } from 'hooks/product/useEditProduct';
import { useProduct } from 'hooks/product/useProduct';

const { TabPane } = Tabs;
interface Params {
  productId: string;
}

export const EditProductPage = () => {
  const { data: productData, execute: product } = useProduct();
  const { execute: editProduct } = useEditProduct();
  const params = useParams<Params>();
  const history = useHistory();
  const { state } = useLocation<ProductPageStateType>();

  const handleUploadImage = async (courseId: string, file: any) => {
    if (!file) return null;
    if (file.status === UploadStatus.EMPTY) return null;
    try {
      const requestCoverImageResponse: {
        data: RequestUploadUrl;
      } = await axios.post('/assets', {
        refOwnerType: 'course-thumbnail',
        refOwnerId: courseId,
        filename: file.name,
        contentType: file.type
      });
      await axios({
        url: requestCoverImageResponse.data.data.upload.url,
        params: requestCoverImageResponse.data.data.upload.params,
        method: 'PUT',
        data: file,
        headers: { 'content-type': file.type }
      });
      return requestCoverImageResponse.data.data.url;
    } catch (error) {
      console.log(error);
    }
  };

  const onFinish = async (data: ProductFieldsValue) => {
    // console.log('onFinish', data);
    var payload = pickBy(
      {
        productType: 'book',
        gradeLevelId: data.gradeLevelId,
        subjectGroupId: data.subjectGroupId,
        subjectId: data.subjectId,
        freeCourseId: data.freeCourseId,
        name: {
          th: data.nameTh,
          en: data.nameEn
        },
        barcode: data.barcode,
        coverImage: data.coverImage,
        basicSku: data.basicSku,
        premiumSku: data.premiumSku,
        description: {
          th: data.descriptionTh,
          en: data.descriptionEn
        },
        basicPrice: data.basicPrice ? +data.basicPrice : null,
        premiumPrice: data.premiumPrice ? +data.premiumPrice : null,
        aksornBookId: data.aksornBookId,
        bookType: data.bookType,
        authorName: {
          th: data.authorNameTh,
          en: data.authorNameEn
        },
        bookVolume: data.bookVolume && +data.bookVolume,
        bookUrl: data.bookUrl
      },
      identity
    );
    if (data.freeCourseId === '') {
      payload['freeCourseId'] = null;
    }
    // console.log('payload', payload);
    try {
      let urlCoverImage = null;
      if (data?.coverImage?.file) {
        urlCoverImage = await handleUploadImage(
          params.productId as string,
          data?.coverImage?.file
        );

        payload.coverImage = urlCoverImage;
      }
      await editProduct(payload, params.productId);
      history.replace('/product/manage-list/');
      message.success('บันทึกสำเร็จ');
    } catch (error) {
      console.log('error', error);
      message.error(`บันทึกไม่สำเร็จ`);
    }
  };

  useEffect(() => {
    product(params.productId);
  }, []);

  return (
    <PageContainer className="p-8">
      <Row justify="space-between" align="middle" className="mb-4">
        <Col>
          <div className="flex items-center">
            <h1
              className="text-xl font-bold text-mono-gray-300 cursor-pointer"
              onClick={() => {
                history.push(`/product/manage-list${state?.queryString ?? ''}`);
              }}
            >
              จัดการสินค้า
            </h1>
            <BreadcrumbsIcon />
            <h1 className="text-xl font-bold">แก้ไขสินค้า</h1>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Tabs defaultActiveKey={'1'}>
            <TabPane tab="รายละเอียด" key="1">
              <ProductForm
                valueAksornBookId={productData?.product?.book?.aksornBookId}
                valueBasicSku={productData?.product?.basicSku}
                valuePremiumSku={productData?.product?.premiumSku}
                valueFreeCourseId={productData?.product?.freeCourseId}
                valueNameTh={productData?.product?.name.th}
                valueNameEn={productData?.product?.name.en}
                valueBarcode={productData?.product?.barcode}
                valueBookType={productData?.product?.book?.bookType}
                valueGradeLevelId={
                  productData?.product?.gradeLevel?.gradeLevelId
                }
                valueBookVolume={productData?.product?.book?.bookVolume}
                valueSubjectGroupId={
                  productData?.product?.subjectGroup.subjectGroupId
                }
                valueSubjectId={productData?.product?.subject?.subjectId}
                valueBookUrl={productData?.product?.book?.bookUrl}
                valueAuthorNameTh={productData?.product?.book?.authorName?.th}
                valueAuthorNameEn={productData?.product?.book?.authorName?.en}
                valueDescriptionTh={productData?.product?.description?.th}
                valueDescriptionEn={productData?.product?.description?.en}
                valueCoverImage={productData?.product?.coverImage}
                valueBasicPrice={productData?.product?.basicPrice}
                valuePremiumPrice={productData?.product?.premiumPrice}
                onFinish={(data: any) => onFinish(data)}
                handleUploadImage={(courseId: string, file: any) =>
                  handleUploadImage(courseId, file)
                }
                mode="edit"
              />
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    </PageContainer>
  );
};
