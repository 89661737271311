import 'moment/locale/th';

import { Button, Col, DatePicker, Input, Pagination, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Coupon } from 'feature/coupon/coupon.type';
import { CouponTable } from 'feature/coupon/CouponTable';
import { DateFormat } from 'feature/common/global.type';
import { PageContainer } from 'feature/common/PageContainer';
import { PlusOutlined } from '@ant-design/icons';
import _ from 'lodash';
import locale from 'antd/es/date-picker/locale/th_TH';
import moment from 'moment';
import { useCoupons } from 'hooks/coupon/useCoupons';

const { RangePicker } = DatePicker;

export const CouponPage = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const history = useHistory();
  const location = useLocation();
  const [coupons, setCoupons] = useState<Coupon[]>([]);
  const [dateFilter, setDateFilter] = useState<[moment.Moment, moment.Moment]>(
    [] as any
  );
  const [searchFilter, setSearchFilter] = useState<string>();
  const [keyword, setKeyword] = useState('');

  const { data: couponsData = [], loading, refetch, item } = useCoupons({
    pageNumber: currentPage,
    fromDate: dateFilter && dateFilter[0]?.toISOString(),
    toDate: dateFilter && dateFilter[1]?.toISOString(),
    keyword
  });

  useEffect(() => {
    refetch();
  }, [refetch, location]);

  useEffect(() => {
    setCoupons(couponsData);
  }, [dateFilter, couponsData]);
  const filterDate = (dates: any) => {
    setCurrentPage(1);
    setDateFilter(dates);
  };
  const handleSearch = (text: string = '') => {
    setKeyword(text.trim());
    setCurrentPage(1);
  };
  return (
    <PageContainer className="p-8">
      <Row justify="space-between" align="middle" className="mb-6">
        <Col>
          <h1 className="text-xl font-bold text-black">จัดการคูปอง</h1>
        </Col>
      </Row>
      <Row align="middle" className="mb-4 text-mo">
        <Col span={4}>
          <h1 className="text-xl font-bold">คูปองทั้งหมด</h1>
        </Col>
        <Col span={20}>
          <Row justify={'end'}>
            <Col span={8} className="pr-4">
              <RangePicker
                className="w-full"
                locale={locale}
                format={DateFormat.DEFAULT}
                onChange={filterDate}
              />
            </Col>
            <Col span={4} className="mr-4">
              <Input
                placeholder="ค้นหาจากชื่อรหัสหรือชื่อคูปอง"
                onChange={(e: any) => {
                  setSearchFilter(e.target.value);
                }}
                onPressEnter={() => handleSearch(searchFilter)}
              />
            </Col>
            <Col span={2}>
              <Button
                type="primary"
                block
                onClick={() => handleSearch(searchFilter)}
              >
                ค้นหา
              </Button>
            </Col>
            <Col span={2}>
              <Button
                className="flex items-center ml-2"
                onClick={() => {
                  history.push('/coupon/manage/create');
                }}
              >
                <PlusOutlined /> เพิ่มคูปอง
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <CouponTable coupons={(coupons as Coupon[]) || []} loading={loading} />
      {item > 0 && (
        <Pagination
          current={currentPage}
          showSizeChanger={false}
          onChange={(page) => {
            setCurrentPage(page);
          }}
          total={item}
          className="flex justify-end mt-4"
        />
      )}
    </PageContainer>
  );
};
