import { CourseSection } from 'feature/course/course.type';
import useAxios from 'axios-hooks';

export const useCourseSections = (courseId: string) => {
  const [{ data: response, loading, error }, refetch] = useAxios(
    `/course-sections?courseId=${courseId}&pageNumber=1&pageSize=100`
  );

  return {
    data: loading ? [] : (response?.data as CourseSection[]) || [],
    loading,
    error,
    execute: refetch
  };
};
