export const getBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export enum FormRule {
  REQUIRE = 'require',
  UPLOAD_REQUIRE = 'upload-require',
  IS_URL = 'is-url'
}
const DEFAULT_ERROR_MSG = 'กรุณากรอกข้อมูล';

export const getRule = (
  type: FormRule,
  msg: String = DEFAULT_ERROR_MSG
): any => {
  if (type === FormRule.REQUIRE) return { required: true, message: msg };
  if (type === FormRule.UPLOAD_REQUIRE)
    // @ts-ignore
    return () => ({
      validator(rule: any, value: any) {
        if (value && typeof value === 'string') {
          return Promise.resolve();
        } else if (value && value.fileList?.length > 0) {
          return Promise.resolve();
        } else {
          return Promise.reject(msg);
        }
      }
    });
  if (type === FormRule.IS_URL)
    return {
      pattern: new RegExp(
        '^(https?:\\/\\/)?' + // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
          '(\\#[-a-z\\d_]*)?$',
        'i'
      ),
      message: msg
    };
};
