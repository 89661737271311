import { Col, DatePicker, Form, Input, Radio, Row, Spin, Switch } from 'antd';
import { Coupon, CouponLevel, CouponStatus } from './coupon.type';
import { FormRule, getRule } from 'feature/common/Form/utils';
import { PrimaryButton, TextButton } from 'feature/common/Button';
import { toEndOfDay, toStartOfDay } from 'utils/normalizeForm';

import { LoadingOutlined } from '@ant-design/icons';
import QRCode from 'qrcode.react';
import React from 'react';
import env from '@beam-australia/react-env';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

const { RangePicker } = DatePicker;

const layout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 17 }
};

type Props = {
  coupon?: Coupon;
  loading?: boolean;
  handleSubmit: (values: any) => Promise<any>;
  mode: 'edit' | 'create';
};

export const CouponDetailForm = ({
  coupon,
  handleSubmit,
  loading = false,
  mode
}: Props) => {
  const courseOnlineURL =
    env('COURSE_ONLINE_URL') || 'https://course-online.aksorn-dev.mydevkit.me';
  const teacherAcademyOnlineURL =
    env('TEACHER_ACADEMY_URL') ||
    'https://docker-testing-env.playtorium.co.th/aksorn-teacher-academy-web';
  const qrCodeURL = `${
    coupon?.couponLevel === CouponLevel.STUDENT
      ? courseOnlineURL
      : teacherAcademyOnlineURL
  }/coupon/${coupon?.couponCode}`;
  const history = useHistory();
  const initialValues = coupon
    ? {
        'name.th': coupon?.name?.th,
        'name.en': coupon?.name?.en,
        quotaLimit: coupon?.quotaLimit,
        duration: [
          coupon?.couponStartDate && moment(coupon?.couponStartDate),
          coupon?.couponExpiryDate && moment(coupon?.couponExpiryDate)
        ],
        couponLevel: coupon?.couponLevel,
        status: coupon?.status === CouponStatus.ACTIVE
      }
    : {};
  return (
    <Spin
      delay={700}
      spinning={loading}
      size="large"
      indicator={<LoadingOutlined style={{ fontSize: 50 }} spin />}
    >
      {!loading && (
        <Form
          name="coupon-detail"
          className="custom-form font-sa"
          colon={false}
          initialValues={initialValues}
          onFinish={handleSubmit}
          {...layout}
        >
          <Row className="pt-4">
            <Col span={12} className="pr-8">
              {coupon?.couponCode && (
                <Form.Item label="Code" name="code" className="font-semibold">
                  <div style={{ color: '#1890FF' }}>{coupon?.couponCode}</div>
                </Form.Item>
              )}
              <Form.Item
                label="ชื่อคูปอง (TH)"
                name="name.th"
                rules={[getRule(FormRule.REQUIRE)]}
              >
                <Input placeholder="ชื่อคูปองภาษาไทย" />
              </Form.Item>
              <Form.Item label="ชื่อคูปอง (EN)" name="name.en">
                <Input placeholder="ชื่อคูปองภาษาอังกฤษ" />
              </Form.Item>
              {mode === 'edit' && (
                <Form.Item label="จำนวนผู้ใช้คูปองในรอบนี้">
                  {coupon?.quotaUsage} คน
                </Form.Item>
              )}
              <Form.Item
                label="จำนวนโควต้า"
                name="quotaLimit"
                rules={[getRule(FormRule.REQUIRE)]}
              >
                <Input
                  type="number"
                  placeholder="12XX"
                  min={coupon?.quotaUsage || 0}
                />
              </Form.Item>
              <Form.Item
                label="ระยะเวลาการใช้งาน"
                name="duration"
                rules={[getRule(FormRule.REQUIRE)]}
                normalize={(value) => {
                  return [toStartOfDay(value[0]), toEndOfDay(value[1])];
                }}
              >
                <RangePicker />
              </Form.Item>
              <Form.Item
                label="ระดับคูปอง"
                name="couponLevel"
                rules={[getRule(FormRule.REQUIRE)]}
              >
                <Radio.Group disabled={mode === 'edit'}>
                  <Radio.Button value={CouponLevel.STUDENT}>
                    นักเรียน
                  </Radio.Button>
                  <Radio.Button value={CouponLevel.TEACHER}>
                    คุณครู
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
              <Form.Item label="สถานะ" name="status" valuePropName="checked">
                <Switch />
              </Form.Item>
            </Col>
            {coupon && (
              <Col span={12}>
                <div className="flex w-full">
                  <span className="font-semibold mr-2 w-2/12 flex-grow-0">
                    QR Code
                  </span>
                  <div className="flex flex-col items-center flex-grow">
                    <QRCode value={qrCodeURL} size={256} />
                    <span className="mt-2 tracking-widest text-2xl">
                      {coupon?.couponCode}
                    </span>
                  </div>
                </div>
              </Col>
            )}
          </Row>
          <Row>
            <Col span={24}>
              <div className="flex justify-end items-center">
                <TextButton
                  className="mr-6"
                  onClick={() => history.push('/coupon/manage')}
                >
                  ยกเลิก
                </TextButton>
                <PrimaryButton htmlType="submit">บันทึก</PrimaryButton>
              </div>
            </Col>
          </Row>
        </Form>
      )}
    </Spin>
  );
};
