/** @jsx jsx */
import { Button, Col, Row, Table, message } from 'antd';
import {
  CheckCircleOutlined,
  DeleteTwoTone,
  FormOutlined,
  PlusOutlined
} from '@ant-design/icons';
import { css, jsx } from '@emotion/react';
import { useEffect, useState } from 'react';

import AddCourseLecturerModal from './AddCourseLecturerModal';
import { AlertModal } from 'feature/common/AlertModal';
import Axios from 'axios';
import { CourseLecturer } from '../course.type';
import { EditCourseLecturerModal } from './EditCourseLecturerModal';
import { StyledAvatar } from 'feature/common/StyledAvatar';
import { useCourseLecturers } from 'hooks/courseLecturer/useCourseLecturer';
import { useHistory } from 'react-router-dom';

type Props = {
  courseId?: string;
};

export const CourseLecturerForm = (props: Props) => {
  const { courseId } = props;
  const [deletingItemId, setDeletingItemId] = useState<any>(null);

  const { data, execute: refetchCourseLecturers } = useCourseLecturers(
    courseId
  );
  const history = useHistory();
  useEffect(() => {
    const refetch = async () => await refetchCourseLecturers();
    refetch();
  }, [history, refetchCourseLecturers]);

  const [addInstructorModalVisible, setAddInstructorModalVisible] = useState(
    false
  );
  const [editInstructorModalVisible, setEditInstructorModalVisible] = useState(
    false
  );
  const [
    selectedCourseLecturer,
    setSelectedCourseLecturer
  ] = useState<CourseLecturer | null>(null);

  const columns = [
    {
      title: '',
      dataIndex: 'img',
      key: 'img',
      width: 60,
      render: (img: any, record: CourseLecturer) => (
        <StyledAvatar src={record.lecturer.avatarUrl} />
      )
    },
    {
      title: 'ชื่อวิทยากร',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      render: (idx: string, record: CourseLecturer) => (
        <span>{record.lecturer.name?.th}</span>
      )
    },
    {
      title: 'ตำแหน่ง',
      dataIndex: 'position',
      key: 'position',
      render: (idx: string, record: CourseLecturer) => (
        <span>{record.lecturer.position?.th}</span>
      )
    },
    {
      title: 'วิทยากรหลัก',
      dataIndex: 'mainInstructor',
      key: 'mainInstructor',
      align: 'center',
      render: (idx: any, record: CourseLecturer) => {
        if (record.isMainLecturer) {
          return <CheckCircleOutlined style={{ color: '#27AE60' }} />;
        }
      }
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      align: 'left',
      width: 100,
      render: (idx: any, record: CourseLecturer) => (
        <Row gutter={4}>
          <Col>
            <a className="block px-2 text-center">
              <FormOutlined
                style={{ color: '#1890FF' }}
                onClick={() => {
                  setSelectedCourseLecturer(record);
                  setEditInstructorModalVisible(true);
                }}
              />
            </a>
          </Col>
          <Col>
            <DeleteTwoTone
              twoToneColor="#F03D3E"
              onClick={() => {
                setDeletingItemId(record?.courseLecturerId);
              }}
            />
          </Col>
        </Row>
      )
    }
  ];
  const handleOnAdd = async (lecturerId: string, isMainLecturer: boolean) => {
    await Axios.post('/course-lecturers', {
      course: {
        courseId
      },
      lecturer: {
        lecturerId
      },
      isMainLecturer
    });
    setAddInstructorModalVisible(false);
    await refetchCourseLecturers();
    message.success('สร้างวิทยากรในคอร์สสำเร็จ');
  };
  const handleOnEdit = async (
    courseLecturerId: string,
    isMainLecturer: boolean
  ) => {
    await Axios.put('course-lecturers/' + courseLecturerId, {
      isMainLecturer
    });
    setEditInstructorModalVisible(false);
    await refetchCourseLecturers();
    message.success('แก้ไขวิทยากรในคอร์สสำเร็จ');
  };
  const disableMainLecturer = data.reduce(
    (p, c) => p || c.isMainLecturer,
    false
  );
  const courseLecturers = [
    ...data.filter((d) => d.isMainLecturer),
    ...data.filter((d) => !d.isMainLecturer)
  ];
  return (
    <div>
      <div className="flex justify-between font-bold text-base text-mono-gray-400 mb-6">
        รายชื่อวิทยากร
        <Button
          className="flex items-center"
          onClick={() => setAddInstructorModalVisible(true)}
        >
          <PlusOutlined
            className="relative w-4 h-4"
            css={css`
              margin-right: 0.5rem;
            `}
          />
          เพิ่มวิทยากร
        </Button>
      </div>
      <Table dataSource={courseLecturers} columns={columns as any} />
      <AddCourseLecturerModal
        visible={addInstructorModalVisible}
        onClose={() => setAddInstructorModalVisible(false)}
        onAdd={handleOnAdd}
        disableMainLecturer={disableMainLecturer}
        courseLecturers={data}
      />
      <EditCourseLecturerModal
        visible={editInstructorModalVisible}
        onClose={() => {
          setSelectedCourseLecturer(null);
          setEditInstructorModalVisible(false);
        }}
        onEdit={handleOnEdit}
        selectedCourseLecturer={selectedCourseLecturer as any}
        disableMainLecturer={disableMainLecturer}
      />
      <AlertModal
        subTitle="ข้อมูลวิทยากร มีการเชื่อมกับข้อมูลคอร์สและรอบอบรม"
        visible={!!deletingItemId}
        onOk={async () => {
          try {
            await Axios.delete('/course-lecturers/' + deletingItemId);
            setDeletingItemId(null);
            await refetchCourseLecturers();
          } catch (error) {}
        }}
        onCancel={() => {
          setDeletingItemId(null);
        }}
      />
    </div>
  );
};
