/** @jsx jsx */
import { Button, Pagination, Table } from 'antd';
import { Fragment, useState } from 'react';

import { CouponReportCourseSession } from './coupon-report.type';
import { CouponReportResendEmailDrawer } from './CouponReportResendEmailDrawer';
import { DateFormat } from '../common/global.type';
import { jsx } from '@emotion/react';
import moment from 'moment';
import { useCouponReportCourseSessions } from '../../hooks/coupon/useCouponReportCourseSessions';

interface Props {
  couponId: string;
}

export const CouponReportDetailCourseSessionSection = ({ couponId }: Props) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [couponCourseSession, setCouponCourseSession] = useState<any>({});
  const [currentPage, setCurrentPage] = useState<number>(1);

  const {
    data: couponReportCourseSessions,
    loading,
    item
  } = useCouponReportCourseSessions({ couponId, pageNumber: currentPage });

  const columns = [
    {
      title: 'ลำดับที่',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 100
    },
    {
      title: 'คอร์สที่ผู้ใช้ได้รับ',
      dataIndex: ['course', 'name', 'th'],
      key: 'courseNameTH',
      width: 300
    },
    {
      title: 'รอบอบรม',
      dataIndex: ['courseSession', 'name', 'th'],
      key: 'courseSessionNameTH',
      width: 140
    },
    {
      title: 'วันเริ่มต้นการอบรม',
      dataIndex: ['courseSession', 'sessionStartDate'],
      key: 'courseSessionStartDate',
      width: 140,
      render: (timestamp: string) =>
        timestamp && moment(timestamp).format(DateFormat.DEFAULT)
    },
    {
      title: 'วันสิ้นสุดการอบรม',
      dataIndex: ['courseSession', 'sessionEndDate'],
      key: 'courseSessionEndDate',
      width: 140,
      render: (timestamp: string) =>
        timestamp && moment(timestamp).format(DateFormat.DEFAULT)
    },
    {
      title: '',
      dataIndex: '',
      key: 'couponId',
      width: 50,
      align: 'center',
      render: (record: CouponReportCourseSession) => {
        return (
          <Button
            className="w-12 rounded-lg text-xs"
            type="primary"
            onClick={() => {
              setVisible(true);
              setCouponCourseSession({
                courseId: record?.course?.courseId,
                courseName: record?.course?.name?.th,
                courseSessionId: record?.courseSession?.courseSessionId,
                courseSessionName: record?.courseSession?.name?.th,
                couponCourseSessionId: record?.couponCourseSessionId
              });
            }}
          >
            Resend
          </Button>
        );
      }
    }
  ];

  return (
    <Fragment>
      <h1 className="text-xl font-bold mb-2">รายละเอียดคอร์สที่ผู้ใช้ได้รับ</h1>
      <Table
        loading={loading}
        pagination={false}
        dataSource={couponReportCourseSessions as any}
        columns={columns as any}
        rowKey="couponCourseSessionId"
      />
      {item > 0 && (
        <Pagination
          current={currentPage}
          showSizeChanger={false}
          onChange={(page) => {
            setCurrentPage(page);
          }}
          total={item}
          className="flex justify-end mt-4"
        />
      )}
      <CouponReportResendEmailDrawer
        visible={visible}
        setVisible={setVisible}
        couponId={couponId}
        couponCourseSession={couponCourseSession}
      />
    </Fragment>
  );
};
