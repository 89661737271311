/** @jsx jsx */

import { Button, Col, Form, Input, Row, Select, TreeSelect } from 'antd';
import { css, jsx } from '@emotion/react';
import { useEffect, useState } from 'react';

import { ReactComponent as AddIcon } from 'assets/add.svg';
import { BookType } from 'feature/product/const';
import { PageContainer } from 'feature/common/PageContainer';
import { ProductTableManage } from 'feature/product/ProductTableManage';
import { ProductType } from 'feature/product/product';
import { SearchOutlined } from '@ant-design/icons';
import { getGradeLevels } from 'api/gradeLevel/getGradeLevels';
import { getProducts } from 'api/product/getProducts';
import { getSubjects } from 'api/subject/getSubjects';
import { useHistory } from 'react-router-dom';

const { SHOW_PARENT } = TreeSelect;
const { Option } = Select;

export const ProductListPage = () => {
  const history = useHistory();

  var query = new URLSearchParams(document.location.search);
  const convertAnyToInt = (value: any, defaultValue: number) => {
    const num = parseInt(value, 10);
    return isNaN(num) ? defaultValue : num;
  };
  const [productIdSearchText, setProductIdSearchText] = useState<string>(
    query.get('productId') ?? ''
  );
  const [productId, setProductId] = useState<string>(
    query.get('productId') ?? ''
  );
  const [keywordSearchText, setKeywordSearchText] = useState<string>(
    query.get('keyword') ?? ''
  );
  const [keyword, setKeyword] = useState<string>(query.get('keyword') ?? '');
  const [subjectIds, setSubjectIds] = useState<string[]>(
    query.get('subjectIds')?.split(',') ?? ['all']
  );
  const [gradeLevelIds, setGradeLevelIds] = useState<string[]>(
    query.get('gradeLevelIds')?.split(',') ?? ['all']
  );
  const [bookType, setBookType] = useState<BookType>(
    query.get('bookType') ? (query.get('bookType') as BookType) : BookType.ALL
  );
  const [pageSize, setPageSize] = useState<number>(
    convertAnyToInt(query.get('pageSize'), 20)
  );

  const [productType, _setProductType] = useState<ProductType>(
    ProductType.BOOK
  );
  const [tableLoading, setTableLoading] = useState<boolean>(true);
  const [productsListData, setProductsListData] = useState<any>({});

  const [filterLoading, setFilterLoading] = useState<boolean>(true);
  const [gradeLevels, setGradeLevels] = useState<any[]>([]);
  const [subjects, setSubjects] = useState<any[]>([]);

  const [isAlreadyInit, setIsAlreadyInit] = useState<boolean>(false);

  const mergeStringInArray = (someArray: string[]) => {
    let arrayString = '';
    for (let i = 0; i < someArray.length; i++) {
      if (i > 0) {
        arrayString = arrayString.concat(',');
      }
      arrayString = arrayString.concat(someArray[i]);
    }
    return arrayString;
  };

  const getQuery = (pageNumber: number) => {
    var queryObject: any = {
      productType,
      pageNumber,
      pageSize
    };

    if (productId !== '') {
      queryObject['productId'] = productId;
    }
    if (keyword !== '') {
      queryObject['keyword'] = keyword;
    }
    if (!!subjectIds.length && subjectIds[0] !== 'all') {
      queryObject['subjectIds'] = mergeStringInArray(subjectIds);
    }
    if (!!gradeLevelIds.length && gradeLevelIds[0] !== 'all') {
      queryObject['gradeLevelIds'] = mergeStringInArray(gradeLevelIds);
    }
    if (bookType !== BookType.ALL) {
      queryObject['bookType'] = bookType;
    }

    return queryObject;
  };

  const setQueryBeforeFetch = (pageNumber: number) => {
    var queryObject: any = getQuery(pageNumber);
    const queryString = '?' + new URLSearchParams(queryObject).toString();
    history.push({
      pathname: '/product/manage-list',
      search: queryString
    });
    fetchProductList(pageNumber);
  };

  const fetchProductList = async (pageNumber: number) => {
    try {
      setTableLoading(true);
      var params: any = getQuery(pageNumber);

      const { data: getProductListData } = await getProducts(params);
      // console.log('getProductListData', getProductListData);
      setProductsListData(getProductListData);
    } catch (error) {
      console.log('error', error);
    } finally {
      setTableLoading(false);
    }
  };

  const fetchFilterData = async () => {
    try {
      setFilterLoading(true);
      const { data: subjectListData } = await getSubjects();
      const { data: gradeLevelListData } = await getGradeLevels();
      setSubjects(subjectListData);
      setGradeLevels(gradeLevelListData);
    } catch (error) {
      console.log('error', error);
    } finally {
      setFilterLoading(false);
    }
  };

  const gradeLevelsTreeData = [
    {
      title: 'ทั้งหมด',
      value: 'all',
      key: 'all',
      children: gradeLevels?.map(({ name, gradeLevelId }) => ({
        title: name.th,
        value: gradeLevelId,
        key: gradeLevelId
      }))
    }
  ];
  const subjectsTreeData = [
    {
      title: 'ทั้งหมด',
      value: 'all',
      key: 'all',
      children: subjects?.map(({ name, subjectId }) => ({
        title: name.th,
        value: subjectId,
        key: subjectId
      }))
    }
  ];

  useEffect(() => {
    fetchFilterData();
  }, []);

  useEffect(() => {
    if (!isAlreadyInit) {
      fetchProductList(convertAnyToInt(query.get('pageNumber'), 1));
    } else {
      setQueryBeforeFetch(1);
    }
    setIsAlreadyInit(true);
  }, [productId, keyword, subjectIds, gradeLevelIds, bookType, pageSize]);

  return (
    <PageContainer
      className="p-8"
      css={css`
        width: calc(100vw - 224px) !important;
      `}
    >
      <Row justify="space-between" align="middle" className="mb-4">
        <Col>
          <div className="flex items-center">
            <h1 className="text-xl font-bold ">จัดการสินค้า</h1>
          </div>
        </Col>
      </Row>
      <div
        className="grid grid-cols-1 md:grid-cols-12 xl:grid-cols-9 gap-4 xl:gap-2 xxl:gap-4 xxl:flex justify-end items-end mb-4"
        css={css`
          .ant-select-selector {
            overflow-x: auto;
            overflow-y: hidden;
          }
          .ant-select-selection-overflow {
            flex-wrap: nowrap;
          }
        `}
      >
        <div className="col-span-1 md:col-span-4 lg:col-span-3 xl:col-span-2">
          <Form.Item
            label="รหัสสินค้า"
            css={css`
              .ant-form-item-label label {
                height: 20px;
              }
            `}
            className="flex flex-col m-0 w-full xxl:w-40 "
          >
            <Input
              placeholder="ค้นหาจากรหัสสินค้า"
              value={productIdSearchText}
              onChange={(e: any) => {
                setProductIdSearchText(e.target.value);
              }}
              onPressEnter={() => setProductId(productIdSearchText)}
              disabled={tableLoading}
              suffix={
                <SearchOutlined
                  onClick={() => {
                    setProductId(productIdSearchText);
                  }}
                />
              }
            />
          </Form.Item>
        </div>
        <div className="col-span-1 md:col-span-8 lg:col-span-5 xl:col-span-2">
          <Form.Item
            label="ชื่อสินค้า"
            css={css`
              .ant-form-item-label label {
                height: 20px;
              }
            `}
            className="flex flex-col m-0 w-full xxl:w-56"
          >
            <Input
              placeholder="ค้นหาจากชื่อสินค้า"
              value={keywordSearchText}
              onChange={(e: any) => {
                setKeywordSearchText(e.target.value);
              }}
              onPressEnter={() => setKeyword(keywordSearchText)}
              disabled={tableLoading}
              suffix={
                <SearchOutlined
                  onClick={() => {
                    setKeyword(keywordSearchText);
                  }}
                />
              }
            />
          </Form.Item>
        </div>
        <div className="hidden lg:block xl:hidden lg:col-span-4"></div>
        <div className="col-span-1 md:col-span-6 lg:col-span-4 xl:col-span-2">
          <Form.Item
            label="สาระวิชา"
            css={css`
              .ant-form-item-label label {
                height: 20px;
              }
            `}
            className="flex flex-col m-0 w-full xxl:w-56"
          >
            <TreeSelect
              placeholder="สาระวิชา"
              value={subjectIds}
              onChange={(value) => {
                setSubjectIds(value);
              }}
              disabled={filterLoading || tableLoading}
              treeData={subjectsTreeData}
              showCheckedStrategy={SHOW_PARENT}
              maxTagCount={2}
              allowClear
              treeCheckable
              treeDefaultExpandAll
              css={css`
                .ant-select-selector {
                  min-width: 176px;
                }
              `}
            >
              {subjects?.map(({ subjectId, name }) => (
                <Option key={subjectId} value={subjectId}>
                  {name?.th}
                </Option>
              ))}
            </TreeSelect>
          </Form.Item>
        </div>
        <div className="col-span-1 md:col-span-6 lg:col-span-4 xl:col-span-2">
          <Form.Item
            label="ระดับชั้น"
            css={css`
              .ant-form-item-label label {
                height: 20px;
              }
            `}
            className="flex flex-col m-0 w-full xxl:w-56"
          >
            <TreeSelect
              placeholder="ระดับชั้น"
              value={gradeLevelIds}
              onChange={(value) => {
                setGradeLevelIds(value);
              }}
              disabled={filterLoading || tableLoading}
              treeData={gradeLevelsTreeData}
              showCheckedStrategy={SHOW_PARENT}
              maxTagCount={2}
              allowClear
              treeCheckable
              treeDefaultExpandAll
              css={css`
                .ant-select-selector {
                  min-width: 176px;
                }
              `}
            >
              {gradeLevels?.map(({ gradeLevelId, name }) => (
                <Option value={gradeLevelId} key={gradeLevelId}>
                  {name?.th}
                </Option>
              ))}
            </TreeSelect>
          </Form.Item>
        </div>
        <div className="col-span-1 md:col-span-4 lg:col-span-2 xl:col-span-1">
          <Form.Item
            label="ประเภท"
            css={css`
              .ant-form-item-label label {
                height: 20px;
              }
            `}
            className="flex flex-col m-0 w-full xxl:w-32"
          >
            <Select
              value={bookType}
              onSelect={(value) => {
                setBookType(value);
              }}
              disabled={filterLoading || tableLoading}
            >
              <Option key={BookType.ALL} value={BookType.ALL}>
                ทั้งหมด
              </Option>
              <Option key={BookType.EBOOK} value={BookType.EBOOK}>
                แบบเรียน
              </Option>
              <Option key={BookType.QUIZ} value={BookType.QUIZ}>
                แบบฝึกหัด
              </Option>
            </Select>
          </Form.Item>
        </div>
        <div className="sm:hidden md:block lg:hidden xl:block xxl:hidden md:col-span-4 xl:col-span-8" />
        <div className="col-span-1 md:col-span-4 lg:col-span-2 xl:col-span-1">
          <Button
            type="primary"
            block
            icon={<AddIcon className="mr-2" />}
            onClick={() =>
              history.push({
                pathname: '/product/manage-list/add',
                state: {
                  queryString: document.location.search
                }
              })
            }
            className="w-full xxl:w-auto"
          >
            เพิ่มสินค้า
          </Button>
        </div>
      </div>
      <ProductTableManage
        loading={tableLoading}
        productsListData={productsListData}
        setQueryBeforeFetch={setQueryBeforeFetch}
        setPageSize={setPageSize}
      />
    </PageContainer>
  );
};
