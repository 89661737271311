import {
  Course,
  CourseCreateDTO,
  CourseDetailFormData,
  CourseLevel,
  CourseStatus,
  CourseVisibility,
  TeacherAcademyCourseType
} from 'feature/course/course.type';
import { LANG, Localization } from 'feature/common/global.type';

import _ from 'lodash';

export const mapCourseToFormData = (course: Course): CourseDetailFormData => ({
  nameTH: course.name?.th,
  nameEN: course.name?.en,
  slug: course.slug,
  visibility: course.visibility,
  status: course.status,
  courseLevel: course.courseLevel,
  descriptionTH: course.description?.th,
  descriptionEN: course.description?.en,
  benefitTH: course.benefit?.th,
  benefitEN: course.benefit?.en,
  fullPrice: course.fullPrice,
  salePrice: course.salePrice,
  rating: course.rating,
  isPopular: course.isPopular,
  isRecommend: course.isRecommend,
  isNew: course.isNew,
  isNewEndAt: course.isNewEndAt,
  language: course.language,
  previewVideoUrl: course.previewVideoUrl,
  thumbnailUrlTH: course.thumbnailUrl?.th,
  thumbnailUrlEN: course.thumbnailUrl?.en,
  subCategories: course.subCategories?.map((sc) => sc.subCategoryId) as any,
  gradeLevels: course.gradeLevels?.map((g) => g.gradeLevelId) as any,
  courseCollections: course.courseCollections?.map(
    (g) => g.courseCollectionId
  ) as any,
  subjectGroups: course.subjectGroups?.map((s) => s.subjectGroupId) as any,
  duration: course?.duration,
  teacherAcademyCourseType:
    course.teacherAcademyCourseType?.teacherAcademyCourseTypeId
});

export const mapFormDataToCourse = (
  data: CourseDetailFormData
): CourseCreateDTO => {
  return _.omitBy(
    {
      name: {
        th: data.nameTH,
        en: data.nameEN
      },
      slug: data.slug,
      visibility: data.visibility as CourseVisibility,
      status: data.status as CourseStatus,
      courseLevel: data.courseLevel as CourseLevel,
      description: {
        en: data.descriptionEN,
        th: data.descriptionTH
      },
      benefit: {
        en: data.benefitEN,
        th: data.benefitTH
      },
      duration: Number(data?.duration) as number,
      fullPrice: Number(data.fullPrice) as number,
      salePrice: Number(data.salePrice) as number,
      language: data.language as LANG,
      previewVideoUrl: data.previewVideoUrl ? data.previewVideoUrl : null,
      thumbnailUrl: {
        en: data.thumbnailUrlEN,
        th: data.thumbnailUrlTH
      },
      subCategories: data.subCategories?.map((sc) => ({
        subCategoryId: sc
      })) as any,
      gradeLevels: data.gradeLevels?.map((g) => ({
        gradeLevelId: g
      })) as any,
      courseCollections: data.courseCollections?.map((g) => ({
        courseCollectionId: g
      })) as any,
      subjectGroups: data.subjectGroups?.map((s) => ({
        subjectGroupId: s
      })) as any,
      rating: data.rating,
      isPopular: data.isPopular,
      isRecommend: data.isRecommend,
      isNew: data.isNew,
      isNewEndAt: data.isNewEndAt,
      teacherAcademyCourse: data.teacherAcademyCourseType
    },
    (i) => {
      return (
        !_.isArray(i) &&
        !_.isDate(i) &&
        _.isObject(i) &&
        !(i as Localization).th &&
        !(i as Localization).en &&
        !(i as TeacherAcademyCourseType)
      );
    }
  );
};
