import { Lecturer } from 'feature/lecturer/lecturer';
import useAxios from 'axios-hooks';

export const useLecturer = (lecturerId: string | null = null) => {
  const [{ data, loading, error }, refetch] = useAxios(
    '/lecturers/' + lecturerId
  );

  return {
    data: loading || !lecturerId ? null : (data?.data as Lecturer),
    loading: lecturerId ? loading : false,
    error,
    execute: refetch
  };
};
