/** @jsx jsx */
import { Button, Col, Form, Input, Pagination, Row, Select } from 'antd';
import { CourseLevel, CourseStatus } from 'feature/course/course.type';
import { css, jsx } from '@emotion/react';
import { useEffect, useState } from 'react';

import { COURSE_STATUS_TEXT } from '../../feature/course/const';
import { CourseReportTable } from 'feature/course-report/CourseReportTable';
import { PageContainer } from 'feature/common/PageContainer';
import _ from 'lodash';
import { useCategories } from 'hooks/course/useCategories';
import { useCourses } from 'hooks/course/useCourses';
import { useGradeLVs } from 'hooks/course/useGradeLVs';
import { useLocation } from 'react-router-dom';
import { useSubjectGroups } from 'hooks/course/useSubjectGroups';

type FormItem = {
  colSpan: number;
  name: string;
  placeholder: string;
  list?: { label: string; value: string }[];
  onSelect?: Function;
  onClear?: Function;
};

const { Option } = Select;

const FormSelect = ({
  colSpan,
  list,
  placeholder,
  name,
  onSelect,
  onClear
}: FormItem) => {
  return (
    <Col span={colSpan}>
      <Form.Item name={name}>
        <Select
          allowClear
          placeholder={placeholder}
          onSelect={onSelect as any}
          onClear={onClear as any}
          css={css`
            .anticon-close-circle {
              position: relative;
              bottom: 3px;
            }
          `}
        >
          {_.map(list, ({ value, label }) => (
            <Option value={value}>{label}</Option>
          ))}
        </Select>
      </Form.Item>
    </Col>
  );
};

const FormInput = ({ colSpan, placeholder, name }: FormItem) => {
  return (
    <Col span={colSpan}>
      <Form.Item name={name}>
        <Input placeholder={placeholder} />
      </Form.Item>
    </Col>
  );
};

export const CourseReportPage = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [courseLevelFilter, setCourseLevelFilter] = useState<CourseLevel>();
  const [gradeLevelFilter, setGradeLevelFilter] = useState();
  const [subjectGroupFilter, setSubjectGroupFilter] = useState();
  const [categoryFilter, setCategoryFilter] = useState();
  const [courseStatusFilter, setCourseStatusFilter] = useState<CourseStatus>();
  const [courseSearchText, setCourseSearchText] = useState<string>();

  const { data: subjectGroups } = useSubjectGroups();
  const { data: gradeLevels } = useGradeLVs();
  const { data: categories } = useCategories();
  const { data: coursesData, loading, refetch, item } = useCourses({
    keyword: courseSearchText,
    subCategory: categoryFilter,
    subjectGroup: subjectGroupFilter,
    gradeLevel: gradeLevelFilter,
    courseLevel: courseLevelFilter,
    status: courseStatusFilter,
    pageNumber: currentPage
  });

  const location = useLocation();

  const [form] = Form.useForm();

  useEffect(() => {
    refetch();
  }, [refetch, location]);

  return (
    <PageContainer className="p-8 course-list">
      <Row justify="space-between" align="middle" className="mb-6">
        <h1 className="text-xl font-bold text-black">รายงานคอร์ส</h1>
      </Row>
      <Row>
        <Col span={24}>
          <Form
            form={form}
            name="horizontal_login"
            layout="inline"
            onFinish={(value: any) => {
              setCourseSearchText(value.keyword);
            }}
            className="w-full"
          >
            <FormSelect
              colSpan={3}
              name="courseLevel"
              placeholder="ระดับคอร์ส"
              list={[
                { label: 'นักเรียน', value: CourseLevel.STUDENT },
                { label: 'ครู', value: CourseLevel.TEACHER }
              ]}
              onSelect={(value: any) => setCourseLevelFilter(value)}
              onClear={() => setCourseLevelFilter(undefined)}
            />
            <FormSelect
              colSpan={3}
              name="gradeLevel"
              placeholder="ระดับชั้น"
              list={gradeLevels}
              onSelect={(value: any) => setGradeLevelFilter(value)}
              onClear={() => setGradeLevelFilter(undefined)}
            />
            <FormSelect
              colSpan={4}
              name="subjectGroup"
              placeholder="วิชา"
              list={subjectGroups}
              onSelect={(value: any) => setSubjectGroupFilter(value)}
              onClear={() => setSubjectGroupFilter(undefined)}
            />
            <FormSelect
              colSpan={5}
              name="category"
              placeholder="หมวดหมู่"
              list={categories}
              onSelect={(value: any) => setCategoryFilter(value)}
              onClear={() => setCategoryFilter(undefined)}
            />
            <FormSelect
              colSpan={3}
              name="courseStatus"
              placeholder="สถานะ"
              list={[
                {
                  label: COURSE_STATUS_TEXT[CourseStatus.ACTIVE],
                  value: CourseStatus.ACTIVE
                },
                {
                  label: COURSE_STATUS_TEXT[CourseStatus.INACTIVE],
                  value: CourseStatus.INACTIVE
                }
              ]}
              onSelect={(value: any) => setCourseStatusFilter(value)}
              onClear={() => setCourseStatusFilter(undefined)}
            />
            <FormInput
              colSpan={4}
              name="keyword"
              placeholder="ค้นหาจากชื่อคอร์ส"
            />
            <Col span={2}>
              <Button className="w-full" type="primary" htmlType="submit">
                ค้นหา
              </Button>
            </Col>
          </Form>
        </Col>
      </Row>
      <Row
        justify="space-between"
        align="middle"
        className="mb-4 text-mo"
      ></Row>
      <CourseReportTable
        courses={coursesData}
        loading={loading}
        refetch={refetch}
      />
      <Pagination
        showSizeChanger={false}
        current={currentPage}
        onChange={(page) => {
          setCurrentPage(page);
        }}
        total={item}
        className="flex justify-end mt-4"
      />
    </PageContainer>
  );
};
