/** @jsx jsx */
import { Button, Col, Input, Row, message } from 'antd';
import { css, jsx } from '@emotion/react';

import Modal from 'antd/lib/modal/Modal';
import { useHistory } from 'react-router-dom';
import { useRejectSchoolRequest } from 'hooks/school-request/useRejectSchoolRequest';
import { useState } from 'react';

const { TextArea } = Input;

interface RejectModalProps {
  schoolRequestId: string;
  schoolName?: string;
  type?: string;
  visible: boolean;
  onCancle?: () => void;
  setVisible: (visible: false) => void;
}

export const RejectModal: React.FC<RejectModalProps> = ({
  schoolRequestId,
  schoolName,
  type,
  visible,
  onCancle,
  setVisible
}) => {
  const [reason, setReason] = useState('');
  const history = useHistory();
  const {
    execute: rejectSchoolRequest,
    loading: rejecting
  } = useRejectSchoolRequest();

  const onReject = async () => {
    try {
      await rejectSchoolRequest(schoolRequestId, reason);
      message.success('ไม่อนุมัติคำขอเปิดบัญชีโรงเรียนแล้ว', 2);
      setVisible(false);
      history.goBack();
    } catch (error) {
      console.error(error);
      message.error('ไม่อนุมัติคำขอเปิดบัญชีโรงเรียนไม่สำเร็จ', 2);
    }
  };

  return (
    <Modal
      title="ไม่อนุมัติคำร้องขอเปิดบัญชีโรงเรียน"
      centered
      visible={visible}
      onCancel={onCancle}
      width={'40%'}
      css={css`
        .ant-modal-content {
          button {
            &:focus {
              outline: none;
            }
          }
        }

        .ant-modal-header {
          border-radius: 15px 15px 0 0 !important;
        }

        .ant-modal-footer {
          border-top: none;
          background-color: #fcfcfd;
          border-radius: 0 0 15px 15px;
        }

        .ant-modal-close-x {
          padding: 0;
          padding-left: 0;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      `}
      footer={
        <Row>
          <Col span={24}>
            <div className="flex justify-end items-center">
              <Button
                type="text"
                className="h-12 rounded-lg text-base outline-none"
                css={css`
                  width: 7rem;
                `}
                onClick={onCancle}
                disabled={rejecting}
              >
                ยกเลิก
              </Button>
              <Button
                className="h-12 rounded-lg text-base bg-red text-white border-red ml-5"
                css={css`
                  width: 7rem;
                `}
                onClick={onReject}
                loading={rejecting}
              >
                ไม่อนุมัติ
              </Button>
            </div>
          </Col>
        </Row>
      }
    >
      <Row>
        <Col span={24}>
          <Row gutter={[8, 8]}>
            <Col span={8}>โรงเรียน</Col>
            <Col
              span={16}
              className="text-sm font-normal font-sarabun"
              css={css`
                color: #1890ff;
              `}
            >
              {schoolName}
            </Col>
          </Row>
          <Row gutter={[8, 8]} className="mt-2">
            <Col span={8}>ประเภท</Col>
            <Col span={16}>{type}</Col>
          </Row>
          <Row>
            <Col span={24} className="mt-2">
              เหตุผล
            </Col>
          </Row>
        </Col>
      </Row>
      <TextArea
        disabled={rejecting}
        placeholder="กรุณากรอกเหตุผล"
        autoSize={{ minRows: 5, maxRows: 5 }}
        className="mt-4"
        onChange={({ target: { value } }) => setReason(value)}
      />
    </Modal>
  );
};
