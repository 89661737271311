import { CourseReportAutomationSchoolEmail } from 'feature/coupon-report/coupon-report.type';
import useAxios from 'axios-hooks';

export const useCourseReportAutomationSchoolEmails = (
  couponCourseSessionId: string
) => {
  const [{ data, loading, error }, refetch] = useAxios(
    `/course-report-automation/school-emails?couponCourseSessionId=${couponCourseSessionId}`
  );

  return {
    data: data && (data?.data as CourseReportAutomationSchoolEmail[]),
    loading: loading,
    error,
    refetch
  };
};
