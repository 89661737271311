/** @jsx jsx */
import { Button, Empty, Table, Typography, message } from 'antd';
import { DeleteOutlined, MailOutlined, PlusOutlined } from '@ant-design/icons';
import { css, jsx } from '@emotion/react';
import { useEffect, useState } from 'react';

import { AlertModal } from 'feature/common/AlertModal';
import { CouponSchool } from './coupon.type';
import { EditSchoolEmailModal } from './EditSchoolEmailModal';
import { ManageCouponSchoolModal } from './ManageCouponSchoolModal';
import { useCouponSchools } from 'hooks/couponSchool/useCouponSchools';
import { useDeleteCouponSchool } from 'hooks/couponSchool/useDeleteCouponSchool';

interface Props {
  couponId?: string;
}

export const CouponSchoolTab = ({ couponId = '' }: Props) => {
  const {
    data: couponSchools,
    loading,
    execute: refetch,
    error
  } = useCouponSchools(couponId);

  const [convertedCouponSchools, setConvertedCouponSchools] = useState<
    (CouponSchool | { index: number })[]
  >([]);
  const [couponSchoolModalVisible, setCouponSchoolModalVisible] = useState(
    false
  );
  const [
    visibleDeletingCouponSchool,
    setVisibleDeletingCouponSchool
  ] = useState<boolean>(false);
  const [deletedCouponSchoolId, setDeletedCouponSchoolId] = useState<string>();
  const [
    isEditEmailModalVisible,
    setIsEditEmailModalVisible
  ] = useState<boolean>(false);
  const [editCouponSchoolId, setEditCouponSchoolId] = useState<string>();

  const { execute: deleteCouponSchool } = useDeleteCouponSchool(
    deletedCouponSchoolId
  );

  useEffect(() => {
    if (error) {
      console.error(error);
      message.error('ดึงข้อมูลโรงเรียนไม่สำเร็จ');
    }
  }, [error]);
  useEffect(() => {
    if (!loading && !error) {
      const newCouponSchools = couponSchools.map(
        (couponSchool: CouponSchool, index: number) => ({
          ...couponSchool,
          index: index + 1
        })
      );
      setConvertedCouponSchools(newCouponSchools);
    }
  }, [couponSchools, loading, error]);

  const columns = [
    { title: 'ลำดับ', dataIndex: 'index', key: 'index' },
    {
      title: 'รายชื่อโรงเรียน',
      dataIndex: ['school', 'schoolAliasName', 'th'],
      key: 'schoolName'
    },
    {
      title: 'สังกัดโรงเรียน',
      dataIndex: ['school', 'schoolGroup', 'schoolGroupName', 'th'],
      key: 'schoolGroupName'
    },
    {
      title: 'จังหวัด',
      dataIndex: ['school', 'province', 'provinceName', 'th'],
      key: 'provinceName'
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      render: (text: any, record: CouponSchool) => (
        <div className="flex justify-center items-center">
          <MailOutlined
            style={{ color: '#1890FF' }}
            className="cursor-pointer mr-2"
            onClick={() => {
              setEditCouponSchoolId(record?.couponSchoolId);
              setIsEditEmailModalVisible(true);
            }}
          />

          <DeleteOutlined
            className="cursor-pointer"
            style={{ color: '#F03D3E' }}
            onClick={() => {
              setDeletedCouponSchoolId(record.couponSchoolId);
              setVisibleDeletingCouponSchool(true);
            }}
          />
        </div>
      )
    }
  ];

  const handleDeleteCouponSchool = async () => {
    const loading = message.loading('กำลังดำเนินการ...', 0);
    try {
      await deleteCouponSchool();
      await refetch();
      loading();
      message.success('ลบโรงเรียนสำเร็จ');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <div className="flex justify-between font-bold text-base text-mono-gray-400 mb-6">
        โรงเรียนที่สามารถใชัคูปองได้
        {convertedCouponSchools.length > 0 && (
          <Button
            className="flex items-center"
            onClick={() => {
              setCouponSchoolModalVisible(true);
            }}
          >
            <PlusOutlined className="relative w-4 h-4 mr-2" />
            จัดการโรงเรียน
          </Button>
        )}
      </div>
      <Table
        loading={loading}
        dataSource={convertedCouponSchools}
        columns={columns as any}
        rowKey="couponSchoolId"
        locale={{
          emptyText: (
            <Empty
              css={css`
                padding: 2em;
                display: flex;
                flex-direction: column;
                align-items: center;
              `}
              imageStyle={{ display: 'none' }}
              description={
                <Typography.Text className="flex flex-col">
                  <Typography.Text className="text-lg text-black">
                    ยังไม่มีโรงเรียน
                  </Typography.Text>
                  <Typography.Text className="text-gray-900">
                    หากท่านไม่เลือกโรงเรียน ทุกโรงเรียนจะสามารถใช้คูปองนี้ได้
                  </Typography.Text>
                </Typography.Text>
              }
            >
              <Button
                className="flex items-center"
                onClick={() => setCouponSchoolModalVisible(true)}
              >
                <PlusOutlined className="relative w-4 h-4 mr-2" />
                เพิ่มโรงเรียน
              </Button>
            </Empty>
          )
        }}
      />
      <ManageCouponSchoolModal
        visible={couponSchoolModalVisible}
        couponId={couponId}
        selectedSchoolIds={couponSchools.map(
          (couponSchool: CouponSchool) => couponSchool?.school?.schoolId
        )}
        onClose={() => {
          setCouponSchoolModalVisible(false);
        }}
        refetch={refetch}
      />
      <AlertModal
        visible={visibleDeletingCouponSchool}
        onOk={() => {
          setVisibleDeletingCouponSchool(false);
          handleDeleteCouponSchool();
        }}
        onCancel={() => {
          setVisibleDeletingCouponSchool(false);
        }}
      />
      <EditSchoolEmailModal
        schoolCouponId={editCouponSchoolId}
        visible={isEditEmailModalVisible}
        setIsVisible={setIsEditEmailModalVisible}
      />
    </div>
  );
};
