import { Button, Col, Input, Row } from 'antd';
import React, { useState } from 'react';

import { LecturerTable } from 'feature/lecturer/LecturerTable';
import { PageContainer } from 'feature/common/PageContainer';
import { PlusOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

export const LecturerPage = () => {
  const history = useHistory();
  const [searchFilter, setSearchFilter] = useState<string>();
  const [keyword, setKeyword] = useState('');

  const handleSearch = (text: string = '') => {
    setKeyword(text.trim());
  };
  return (
    <PageContainer className="p-8">
      <Row justify="space-between" align="middle" className="mb-6">
        <Col>
          <h1 className="text-xl font-bold text-black">จัดการวิทยากร</h1>
        </Col>
      </Row>
      <Row justify="space-between" align="middle" className="mb-4">
        <Col span={12}>
          <h1 className="text-xl font-bold">รายชื่อวิทยากร</h1>
        </Col>
        <Col span={12}>
          <Row justify="end">
            <Col span={10} className="mr-2">
              <Input
                placeholder="ค้นหาจากชื่อวิทยากร"
                onChange={(e: any) => {
                  setSearchFilter(e.target.value);
                }}
                onPressEnter={() => handleSearch(searchFilter)}
              />
            </Col>
            <Col span={3} className="mr-2">
              <Button
                type="primary"
                block
                onClick={() => handleSearch(searchFilter)}
              >
                ค้นหา
              </Button>
            </Col>
            <Col>
              <Button
                className="flex items-center "
                onClick={() => {
                  history.push('/lecturer/create');
                }}
              >
                <PlusOutlined /> เพิ่มวิทยากร
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <LecturerTable keyword={keyword} />
    </PageContainer>
  );
};
