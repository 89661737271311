import { identity, pickBy } from 'lodash';

import { CouponReport } from 'feature/coupon-report/coupon-report.type';
import queryString from 'query-string';
import useAxios from 'axios-hooks';

type QueryCouponReport = {
  pageNumber: number;
  pageSize: number;
  fromDate: string;
  toDate: string;
  keyword: string;
};
export const useCouponReports = (params: Partial<QueryCouponReport>) => {
  const [{ data, loading, error }, refetch] = useAxios({
    url: `/coupon-reports?${queryString.stringify({
      pageNumber: 1,
      pageSize: 10,
      ...pickBy(params, identity)
    })}`,
    method: 'GET'
  });

  return {
    data: data && (data?.data as CouponReport[]),
    loading: loading,
    error,
    refetch,
    item: data && data?.meta?.pagination?.total
  };
};
