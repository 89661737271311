import { SchoolRequest } from 'feature/school-request/school-request.type';
import useAxios from 'axios-hooks';

export const useSchoolRequest = () => {
  const [{ data: response, loading, error }, fire] = useAxios(
    {
      method: 'GET'
    },
    { manual: true }
  );

  const execute = (schoolRequestId: string) => {
    return fire({
      url: `/school-requests/${schoolRequestId}`
    });
  };

  return {
    data: response?.data as SchoolRequest,
    loading,
    error,
    execute
  };
};
