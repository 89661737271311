/** @jsx jsx */
import { COURSE_LEVEL_TEXT, COURSE_STATUS_TEXT } from '../course/const';
import {
  Course,
  CourseLevel,
  CourseStatus,
  FK
} from 'feature/course/course.type';
import { Table, Typography } from 'antd';
import { css, jsx } from '@emotion/react';

import _ from 'lodash';
import { useHistory } from 'react-router-dom';

interface Props {
  courses?: Course[];
  loading: boolean;
  refetch: Function;
}

export const CourseReportTable = ({ courses = [], loading }: Props) => {
  const history = useHistory();

  const renderContentFK = (content: FK[]) => (
    <span>{content && content.map((c) => c?.name?.th).join(' ')}</span>
  );
  const columns = [
    {
      title: 'คอร์ส',
      dataIndex: ['name', 'th'],
      key: 'courseNameTH',
      width: 400
    },
    {
      title: 'ระดับคอร์ส',
      dataIndex: 'courseLevel',
      key: 'courseLevel',
      width: 150,
      render: (courseLevel: CourseLevel) => {
        if (!courseLevel) return <Typography.Text>-</Typography.Text>;
        return (
          <Typography.Text>{COURSE_LEVEL_TEXT[courseLevel]}</Typography.Text>
        );
      }
    },
    {
      title: 'ระดับชั้น',
      dataIndex: 'gradeLevels',
      key: 'gradeLevels',
      render: renderContentFK,
      width: 150
    },
    {
      title: 'วิชา',
      dataIndex: 'subjectGroups',
      key: 'subjectGroups',
      render: renderContentFK,
      width: 400
    },
    {
      title: 'หมวดหมู่',
      dataIndex: 'subCategories',
      key: 'subCategories',
      render: renderContentFK,
      width: 200
    },
    {
      title: 'สถานะ',
      dataIndex: 'status',
      key: 'status',
      width: 150,
      render: (status: CourseStatus) => {
        if (!status) return <Typography.Text>-</Typography.Text>;
        return <Typography.Text>{COURSE_STATUS_TEXT[status]}</Typography.Text>;
      }
    }
  ];

  return (
    <Table
      loading={loading}
      pagination={false}
      dataSource={courses}
      columns={columns}
      onRow={(record: any) => {
        return {
          onClick: () => {
            history.push('/course/report/detail?courseId=' + record?.courseId);
          }
        };
      }}
      css={css`
        .ant-table-row {
          cursor: pointer;
        }
      `}
    />
  );
};
