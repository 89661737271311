import { SchoolRequestRequestorDto } from '@aksorn/backoffice-sdk';
import Axios from 'axios';

export const updateAdditionalRequestors = async (
  schoolRequestId: string,
  newData: SchoolRequestRequestorDto[],
  deleteData: { email: string }[]
) => {
  const { data: response } = await Axios.put(
    `/school-requests/${schoolRequestId}/additional-requestors`,
    {
      dataListForCreateAdditionalRequestors: newData,
      dataListForDeleteAdditionalRequestors: deleteData
    }
  );

  return response;
};
  