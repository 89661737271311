import { CourseSessionReportGetResponse } from '@aksorn/backoffice-sdk';
import useAxios from 'axios-hooks';

export const useCourseSessionReports = (courseSessionId: string) => {
  const [
    { data: response, loading, error },
    refetch
  ] = useAxios<CourseSessionReportGetResponse>(
    `/course-session-reports/${courseSessionId}`,
    { manual: true }
  );

  return {
    data: response?.data,
    loading,
    error,
    refetch
  };
};
