import useAxios from 'axios-hooks';

export const useSubjectGroups = () => {
  const [{ data: response, loading, error }, fire] = useAxios(
    {
      method: 'GET'
    },
    { manual: true }
  );
  const execute = (pageNumber: number = 1, pageSize: number = 100) => {
    return fire({
      url: `/subject-groups?pageNumber=${pageNumber}&pageSize=${pageSize}`
    });
  };
  return {
    data: response?.data,
    loading,
    error,
    execute
  };
};
