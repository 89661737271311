import { Lecturer, LecturerDTO } from './lecturer';

import _ from 'lodash';

export const mapLectureToLectureDTO = (
  lecture: Partial<Lecturer>
): LecturerDTO => {
  return {
    nameEN: lecture.name?.en,
    nameTH: lecture?.name?.th,
    avatarUrl: lecture?.avatarUrl,
    cvUrl: lecture?.cvUrl,
    positionEN: lecture?.position?.en,
    positionTH: lecture?.position?.th,
    profileEN: lecture?.profile?.en,
    profileTH: lecture?.profile?.th,
    shortProfileEN: lecture?.shortProfile?.en,
    shortProfileTH: lecture?.shortProfile?.th,
    title: lecture?.title
  };
};

export const mapLectureDTOToLecture = (data: LecturerDTO): Lecturer => {
  return _.omitBy(
    {
      name: {
        th: data?.nameTH,
        en: data?.nameEN
      },
      position: {
        th: data?.positionTH,
        en: data?.positionEN
      },
      profile: {
        th: data?.profileTH,
        en: data?.profileEN
      },
      shortProfile: {
        th: data?.shortProfileTH,
        en: data?.shortProfileEN
      },
      avatarUrl: data.avatarUrl,
      cvUrl: data?.cvUrl,
      title: data?.title
    },
    (i) => {
      return _.isObject(i) && !i.th;
    }
  );
};
