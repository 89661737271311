/** @jsx jsx */
import { Button, Modal, Row } from 'antd';
import { css, jsx } from '@emotion/react';

import { ExclamationCircleTwoTone } from '@ant-design/icons';
import { MEDIA } from 'utils/responsiveSize';
import { OverlapBannerTable } from './OverlapBannerTable';

export interface OverlapBannerModalProps {
  isVisible: boolean;
  setIsVisible: Function;
  mode: string;
  currentBannerList: any[];
  overlapBannerList: any[];
  loading: boolean;
}

export const OverlapBannerModal = ({
  isVisible,
  setIsVisible,
  mode,
  currentBannerList,
  overlapBannerList,
  loading
}: OverlapBannerModalProps) => {
  return (
    <Modal
      title={null}
      visible={isVisible}
      style={{ borderRadius: 16 }}
      width={900}
      closable={false}
      footer={null}
      centered
      css={css`
        padding: 0 !important;
        width: 95% !important;
        ${MEDIA.MIN.sm} {
          width: 90% !important;
        }
        ${MEDIA.MIN.md} {
          width: 85% !important;
        }
        ${MEDIA.MIN.lg} {
          width: 70% !important;
        }
        ${MEDIA.MIN.xl} {
          width: 60% !important;
        }
        .ant-modal-content {
          height: 100% !important;
        }
        .ant-modal-body {
          width: 100% !important;
          padding: 16px;
          height: 96vh;
          @media (min-width: 576px) {
            padding: 24px;
            height: 95vh;
          }
          ${MEDIA.MIN.sm} {
            height: 90vh;
          }
          ${MEDIA.MIN.md} {
            height: 90vh;
          }
          @media (max-width: 320px) {
            padding: 12px;
          }
        }
      `}
    >
      <div className="overflow-y-auto h-full">
        <div className="w-full flex flex-col items-center">
          <ExclamationCircleTwoTone
            className="mt-8 mb-6"
            twoToneColor="#FAAD14"
            style={{ fontSize: 100 }}
          />
          <h1 className="text-3xl mb-4 text-center">
            ไม่สามารถ{mode === 'add' ? 'เพิ่ม' : 'แก้ไข'}แบนเนอร์ได้
          </h1>
          <h2 className="text-base text-center mb-8 font-sarabun text-gray-900">
            เนื่องจากช่วงเวลาการแสดงแบนเนอร์ที่กำลังจะ
            {mode === 'add' ? 'เพิ่ม' : 'แก้ไข'}{' '}
            ทับซ้อนกับช่วงเวลาของแบนเนอร์อื่นๆ
            <br />
            กรุณาเลือกช่วงเวลาการแสดงแบนเนอร์กำลังจะ
            {mode === 'add' ? 'เพิ่ม' : 'แก้ไข'} ใหม่อีกครั้ง
          </h2>
          <h1 className="text-xl font-bold mb-4">
            แบนเนอร์ที่กำลังจะ{mode === 'add' ? 'เพิ่ม' : 'แก้ไข'}
          </h1>
          <div className="mb-8">
            <OverlapBannerTable data={currentBannerList} loading={loading} />
          </div>
          <h1 className="text-xl font-bold mb-4">
            แบนเนอร์ที่มีช่วงเวลาทับซ้อน
          </h1>
          <div className="mb-8">
            <OverlapBannerTable
              data={overlapBannerList}
              loading={loading}
              isOverlapTable={true}
            />
          </div>
          <Row className="mb-8 relative">
            <Button
              type="primary"
              className="ml-2 border-red bg-red w-32"
              onClick={() => {
                setIsVisible(false);
              }}
            >
              ตกลง
            </Button>
          </Row>
        </div>
      </div>
    </Modal>
  );
};
