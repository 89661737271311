import _ from 'lodash';
import useAxios from 'axios-hooks';

type TeacherAcademyCourseTypeResponse = {
  teacherAcademyCourseTypeId?: string;
  nameTh: string;
  nameEn: string;
};

type TeacherAcademyCourseTypeOptions = {
  value: string;
  label: string;
};

export const useTeacherAcademyCourseTypes = () => {
  const [{ data, loading, error }, refetch] = useAxios(
    '/teacher-academy-course-type?pageNumber=1&pageSize=100'
  );

  const res = loading
    ? []
    : (data?.data as TeacherAcademyCourseTypeResponse[]) || [];
  const teacherAcademyCourseTypeOptions = _.flatten(
    (res as TeacherAcademyCourseTypeResponse[]).map((obj) => {
      return {
        value: obj.teacherAcademyCourseTypeId as string,
        label: `${obj.nameTh}` as string
      };
    })
  );

  return {
    data: teacherAcademyCourseTypeOptions as TeacherAcademyCourseTypeOptions[],
    loading,
    error,
    execute: refetch
  };
};
