/** @jsx jsx */
import {
  Button,
  Col,
  DatePicker,
  Input,
  Pagination,
  Row,
  Table,
  Typography
} from 'antd';
import { Global, css, jsx } from '@emotion/react';
import { useEffect, useState } from 'react';

import { CourseSessionReportCouponDto } from '@aksorn/backoffice-sdk';
import { DateFormat } from 'feature/common/global.type';
import locale from 'antd/es/date-picker/locale/th_TH';
import moment from 'moment';
import { useCourseSessionReportCoupons } from 'hooks/course-session-report/useCourseSessionReportCoupons';

const { RangePicker } = DatePicker;

interface Props {
  courseSessionId: string;
}

export const CourseReportDetailCouponList = ({ courseSessionId }: Props) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchFilter, setSearchFilter] = useState<string>();
  const [dateFilter, setDateFilter] = useState<[moment.Moment, moment.Moment]>(
    [] as any
  );
  const [keyword, setKeyword] = useState('');

  const {
    data: coupons = [],
    loading,
    item,
    refetch
  } = useCourseSessionReportCoupons({
    courseSessionId,
    keyword,
    pageNumber: currentPage,
    fromDate: dateFilter && dateFilter[0]?.toISOString(),
    toDate: dateFilter && dateFilter[1]?.toISOString()
  });

  useEffect(() => {
    if (courseSessionId) {
      refetch();
    }
  }, [courseSessionId, keyword, currentPage, dateFilter]);

  const handleSearch = (text: string = '') => {
    setKeyword(text.trim());
    setCurrentPage(1);
  };

  const filterDate = (dates: any) => {
    setCurrentPage(1);
    setDateFilter(dates);
  };

  const columns = [
    {
      title: 'รหัสคูปอง',
      dataIndex: ['coupon', 'couponCode'],
      key: 'couponCode'
    },
    {
      title: 'ชื่อคูปอง',
      dataIndex: ['coupon', 'name', 'th'],
      key: 'couponNameTH'
    },
    {
      title: 'โควต้าทั้งหมด',
      dataIndex: ['coupon', 'quotaLimit'],
      align: 'center',
      key: 'quotaLimit'
    },
    {
      title: 'โควต้าที่เหลือ',
      dataIndex: ['coupon', 'quotaUsage'],
      align: 'center',
      key: 'quotaUsage',
      render: (quotaUsage: number, record: CourseSessionReportCouponDto) => (
        <Typography.Text>
          {record?.coupon?.quotaLimit - quotaUsage}
        </Typography.Text>
      )
    },
    {
      title: 'วันที่เริ่มใช้',
      dataIndex: ['coupon', 'couponStartDate'],
      align: 'center',
      key: 'couponStartDate',
      render: (date: Date) => (
        <Typography.Text>
          {moment(date).format(DateFormat.DEFAULT)}
        </Typography.Text>
      )
    },
    {
      title: 'วันที่หมดอายุ',
      dataIndex: ['coupon', 'couponExpiryDate'],
      align: 'center',
      key: 'couponExpiryDate',
      render: (date: Date) => (
        <Typography.Text>
          {moment(date).format(DateFormat.DEFAULT)}
        </Typography.Text>
      )
    }
  ];

  return (
    <div>
      <Global
        styles={css`
          .ant-table-row.ant-table-row-level-1 > td > span {
            margin-right: 16px;
          }
          .ant-table-row.ant-table-row-level-1 {
            background-color: #f8f9fa;
          }
        `}
      />
      <div className="flex justify-between bg-mono-gray-50 p-2 mb-4">
        <Row className="w-full" gutter={[8, 0]}>
          <Col span={11}>
            <Input
              placeholder="คูปอง"
              className="w-full"
              onChange={(e: any) => {
                setSearchFilter(e.target.value);
              }}
              onPressEnter={() => handleSearch(searchFilter)}
              css={css`
                width: 200px;
              `}
            />
          </Col>
          <Col span={10}>
            <RangePicker
              className="w-full"
              locale={locale}
              format={DateFormat.DEFAULT}
              onChange={filterDate}
            />
          </Col>
          <Col span={3}>
            <Button
              className="w-full"
              type="primary"
              onClick={() => handleSearch(searchFilter)}
            >
              ค้นหา
            </Button>
          </Col>
        </Row>
      </div>
      <Table
        dataSource={courseSessionId ? (coupons as any) : []}
        columns={columns as any}
        pagination={false}
        loading={loading}
      />
      {item > 0 && (
        <Pagination
          current={currentPage}
          showSizeChanger={false}
          onChange={(page) => {
            setCurrentPage(page);
          }}
          total={item}
          className="flex justify-end mt-4"
        />
      )}
    </div>
  );
};
