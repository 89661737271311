import { Button, Col, Row, Spin, Tabs, message } from 'antd';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { CouponReportDetailCourseSessionSection } from 'feature/coupon-report/CouponReportDetailCourseSessionSection';
import { CouponReportDetailSchoolSection } from 'feature/coupon-report/CouponReportDetailSchoolSection';
import { CouponReportDetailSection } from 'feature/coupon-report/CouponReportDetailSection';
import { CouponReportDetailUserSection } from 'feature/coupon-report/CouponReportDetailUserSection';
import { PageContainer } from 'feature/common/PageContainer';
import { RightOutlined } from '@ant-design/icons';
import { UploadOutlined } from '@ant-design/icons';
import queryString from 'query-string';
import { useCouponReport } from 'hooks/coupon/useCouponReport';
import { useCouponReportExportExcel } from 'hooks/coupon/useCouponReportExportExcel';

const { TabPane } = Tabs;

export const CouponReportDetail = () => {
  const history = useHistory();

  const location = useLocation();
  const { couponId, tab } = queryString.parse(location.search);
  const { data: coupon, loading, refetch } = useCouponReport(
    (couponId as string) || ''
  );

  const {
    loading: exportExcelLoading,
    execute: exportExcelExecute
  } = useCouponReportExportExcel();

  useEffect(() => {
    refetch();
  }, [refetch, location]);

  const exportExcel = async () => {
    try {
      await exportExcelExecute(couponId as string);
    } catch (error) {
      console.error(error);
      openMessageError();
    }
  };

  const openMessageError = () => {
    message.error('ไม่สามารถดาวน์โหลดได้ในขณะนี้ กรุณาลองใหม่อีกครั้ง');
  };

  if (loading) return <div></div>;
  return (
    <Spin spinning={exportExcelLoading}>
      <PageContainer className="p-8">
        <Row justify="space-between" align="middle" className="mb-4">
          <Col>
            <div className="flex items-center">
              <h1
                className="text-xl font-bold text-mono-gray-300 cursor-pointer"
                onClick={() => history.push('/coupon/manage')}
              >
                รายงานคูปอง
              </h1>
              <RightOutlined className="mx-2 text-mono-gray-300 text-xs" />
              <h1 className="text-xl font-bold ">รายละเอียดรายงานคูปอง</h1>
            </div>
          </Col>
        </Row>
        <Row justify="space-between" align="middle" className="mb-8">
          <CouponReportDetailSection report={coupon} />
        </Row>
        <Row justify="end">
          <Button className="flex items-center" onClick={exportExcel}>
            <UploadOutlined /> Export Excel
          </Button>
        </Row>
        <Tabs defaultActiveKey={(tab as string) || '1'} type="card">
          <TabPane tab="สรุปโรงเรียนที่เข้าร่วม" key="1">
            <CouponReportDetailSchoolSection couponId={couponId as string} />
          </TabPane>
          <TabPane tab="สรุปผู้ใช้คูปอง" key="2">
            <CouponReportDetailUserSection couponId={couponId as string} />
          </TabPane>
          <TabPane tab="สรุปคอร์สที่ผู้ใช้ได้รับ" key="3">
            <CouponReportDetailCourseSessionSection
              couponId={couponId as string}
            />
          </TabPane>
        </Tabs>
      </PageContainer>
    </Spin>
  );
};
