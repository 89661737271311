import { SchoolGroup } from 'feature/coupon/schoolGroup.type';
import useAxios from 'axios-hooks';

export const useSchoolGroups = () => {
  const [{ data: response, loading, error }, refetch] = useAxios(
    '/school-groups?pageNumber=1&pageSize=100'
  );

  return {
    data: (response?.data as SchoolGroup[]) || [],
    loading,
    error,
    execute: refetch
  };
};
