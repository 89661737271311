/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Col, Form, Input, Row, Select, Upload } from 'antd';
import { Global, css } from '@emotion/react';
import React, { useEffect, useState } from 'react';

import { UploadOutlined } from '@ant-design/icons';
import { getGradeLevels } from 'api/gradeLevel/getGradeLevels';
import { useHistory } from 'react-router-dom';
import { useSubjectGroups } from 'hooks/product/useSubjectGroups';
import { useSubjects } from 'hooks/product/useSubjects';
import { useUploadImage } from 'hooks/useUploadImage';

export interface ProductFieldsValue {
  aksornBookId: string | null;
  basicSku: string | null;
  premiumSku: string | null;
  freeCourseId: string | null;
  nameTh: string | null;
  nameEn: string | null;
  barcode: string | null;
  bookType: string | null;
  gradeLevelId: string | null;
  bookVolume: string | null;
  subjectGroupId: string | null;
  subjectId: string | null;
  bookUrl: string | null;
  authorNameTh: string | null;
  authorNameEn: string | null;
  descriptionTh: string | null;
  descriptionEn: string | null;
  coverImage: any;
  basicPrice: string | null;
  premiumPrice: string | null;
}

export interface ProductFormProps {
  valueAksornBookId?: string | null;
  valueBasicSku?: string | null;
  valuePremiumSku?: string | null;
  valueFreeCourseId?: string | null;
  valueNameTh?: string | null;
  valueNameEn?: string | null;
  valueBarcode?: string | null;
  valueBookType?: string | null;
  valueGradeLevelId?: string | null;
  valueBookVolume?: string | null;
  valueSubjectGroupId?: string | null;
  valueSubjectId?: string | null;
  valueBookUrl?: string | null;
  valueAuthorNameTh?: string | null;
  valueAuthorNameEn?: string | null;
  valueDescriptionTh?: string | null;
  valueDescriptionEn?: string | null;
  valueCoverImage?: string | null;
  valueBasicPrice?: string | null;
  valuePremiumPrice?: string | null;
  onFinish: (data: any) => void;
  handleUploadImage: (courseId: any, file: any) => void;
  mode: 'add' | 'edit';
}
export const ProductForm: React.FC<ProductFormProps> = (props) => {
  const history = useHistory();
  const [form] = Form.useForm<ProductFieldsValue>();
  const [fileList, setFileList] = useState<any>([]);

  const [gradeLevelsData, setGradeLevelsData] = useState<any[]>([]);

  const {
    data: subjectGroupsData,
    execute: subjectGroups
  } = useSubjectGroups();
  const { data: subjectsData, execute: subjects } = useSubjects();
  const { beforeUpload: coverImageBeforeUpload } = useUploadImage();

  const [subjectsState, setSubjectsState] = useState([]);

  function dummyRequest({ onSuccess }: any) {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  }

  function handleChange(file: any) {
    const isJpgOrPng =
      file.file.type === 'image/jpeg' || file.file.type === 'image/png';

    if (file.fileList.length === 0 || !isJpgOrPng) {
      setFileList([]);
      form.setFieldsValue({ coverImage: null });
    } else setFileList(file.fileList);
  }

  const removeFile = () => {
    form.setFieldsValue({ coverImage: null });
  };

  const layoutSingleCol = {
    labelCol: {
      sm: { span: 3 }
    },
    wrapperCol: {
      sm: { span: 8 }
    }
  };
  const layoutDoubleCol = {
    labelCol: {
      sm: { span: 6 }
    },
    wrapperCol: {
      sm: { span: 16 }
    }
  };

  const selectSubject = (subjectSelect: any) => {
    const afterMap = subjectsData?.filter((subject: any) => {
      return subject.subjectGroupId === subjectSelect;
    });
    form.setFieldsValue({ subjectId: null });
    setSubjectsState(afterMap);
  };

  const fetchGradeLevelsData = async () => {
    try {
      const { data: gradeLevelListData } = await getGradeLevels();
      setGradeLevelsData(gradeLevelListData);
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    fetchGradeLevelsData();
    subjectGroups();
    subjects();
  }, []);

  useEffect(() => {
    setSubjectsState(subjectsData);
    setFileList((prev: any) => {
      if (props.valueCoverImage) {
        return [
          {
            name: 'image.jpg',
            url: props.valueCoverImage
          }
        ];
      } else {
        return prev;
      }
    });
  }, [props]);

  useEffect(() => {
    form.setFieldsValue({
      aksornBookId: props.valueAksornBookId,
      basicSku: props.valueBasicSku,
      premiumSku: props.valuePremiumSku,
      freeCourseId: props.valueFreeCourseId,
      nameTh: props.valueNameTh,
      nameEn: props.valueNameEn,
      barcode: props.valueBarcode,
      bookType: props.valueBookType,
      gradeLevelId: props.valueGradeLevelId,
      bookVolume: props.valueBookVolume,
      subjectGroupId: props.valueSubjectGroupId,
      subjectId: props.valueSubjectId,
      bookUrl: props.valueBookUrl,
      authorNameTh: props.valueAuthorNameTh,
      authorNameEn: props.valueAuthorNameEn,
      descriptionTh: props.valueDescriptionTh,
      descriptionEn: props.valueDescriptionEn,
      coverImage: props.valueCoverImage,
      basicPrice: props.valueBasicPrice,
      premiumPrice: props.valuePremiumPrice
    });
  }, [props]);

  useEffect(() => {
    const afterMap = subjectsData?.filter((subject: any) => {
      return subject.subjectGroupId === props.valueSubjectGroupId;
    });
    setSubjectsState(afterMap);
    form.setFieldsValue({ subjectId: props.valueSubjectId });
  }, [props.valueSubjectGroupId, subjectsData]);
  return (
    <div>
      <Global
        styles={css`
          .ant-upload-list-picture .ant-upload-list-item {
            height: 32px;
            margin: 0;
          }
          .ant-upload-list-picture .ant-upload-list-item-thumbnail {
            height: 24px;
            width: 24px;
            pointer-events: none;
          }
          .ant-upload-list-picture .ant-upload-list-item-thumbnail img {
            height: 24px;
            width: 24px;
          }
        `}
      />
      <Form
        onFinish={(data) => props.onFinish(data)}
        form={form}
        initialValues={
          {
            aksornBookId: props.valueAksornBookId,
            basicSku: props.valueBasicSku,
            premiumSku: props.valuePremiumSku,
            freeCourseId: props.valueFreeCourseId,
            nameTh: props.valueNameTh,
            nameEn: props.valueNameEn,
            barcode: props.valueBarcode,
            bookType: props.valueBookType,
            gradeLevelId: props.valueGradeLevelId,
            bookVolume: props.valueBookVolume,
            subjectGroupId: props.valueSubjectGroupId,
            subjectId: props.valueSubjectId,
            bookUrl: props.valueBookUrl,
            authorNameTh: props.valueAuthorNameTh,
            authorNameEn: props.valueAuthorNameEn,
            descriptionTh: props.valueDescriptionTh,
            descriptionEn: props.valueDescriptionEn,
            coverImage: props.valueCoverImage,
            basicPrice: props.valueBasicPrice,
            premiumPrice: props.valuePremiumPrice
          } as ProductFieldsValue
        }
      >
        <Row>
          <div className="font-bold mt-6 mb-5 text-base">รหัสสินค้า</div>
        </Row>
        <Row>
          <Col span="24">
            <Form.Item
              {...layoutSingleCol}
              name="aksornBookId"
              label="รหัส E-Book"
              colon={false}
              required={false}
            >
              <Input placeholder="รหัส E-Book" />
            </Form.Item>
          </Col>
          <Col span="24">
            <Form.Item
              {...layoutSingleCol}
              name="basicSku"
              label="รหัส Basic"
              colon={false}
              required={true}
              rules={[{ required: true, message: 'กรุณากรอก รหัส Basic' }]}
            >
              <Input placeholder="รหัส Basic" />
            </Form.Item>
          </Col>
          <Col span="24">
            <Form.Item
              {...layoutSingleCol}
              name="premiumSku"
              label="รหัส Premium"
              colon={false}
              required={false}
            >
              <Input placeholder="รหัส Premium" />
            </Form.Item>
          </Col>
          <Col span="24">
            <Form.Item
              {...layoutSingleCol}
              name="freeCourseId"
              label="รหัสคลิปอักษรเรียนสรุป"
              colon={false}
              required={false}
            >
              <Input placeholder="รหัสคลิปอักษรเรียนสรุป" />
            </Form.Item>
          </Col>
        </Row>

        {/* Double Column */}
        <Row>
          <div className="font-bold mt-6 mb-5 text-base">ข้อมูลพื้นฐาน</div>
        </Row>
        <Row>
          <Col span="12">
            <Form.Item
              {...layoutDoubleCol}
              name="nameTh"
              label="ชื่อสินค้า (TH)"
              colon={false}
              required={true}
              rules={[{ required: true, message: 'กรุณากรอก ชื่อสินค้า (TH)' }]}
            >
              <Input placeholder="ชื่อสินค้าภาษาไทย" />
            </Form.Item>
          </Col>

          <Col span="12">
            <Form.Item
              {...layoutDoubleCol}
              name="bookUrl"
              label="URL LINK (E-Book)"
              colon={false}
              required={false}
            >
              <Input placeholder="URL LINK (E-Book)" />
            </Form.Item>
          </Col>

          <Col span="12">
            <Form.Item
              {...layoutDoubleCol}
              name="nameEn"
              label="ชื่อสินค้า (EN)"
              colon={false}
              required={false}
            >
              <Input placeholder="ชื่อสินค้าภาษาอังกฤษ" />
            </Form.Item>
          </Col>

          <Col span="12">
            <Form.Item
              {...layoutDoubleCol}
              name="authorNameTh"
              label="ชื่อผู้แต่ง (TH)"
              colon={false}
              required={true}
              rules={[
                { required: true, message: 'กรุณากรอก ชื่อผู้แต่ง (TH)' }
              ]}
            >
              <Input placeholder="ชื่อผู้แต่งภาษาไทย" />
            </Form.Item>
          </Col>

          <Col span="12">
            <Form.Item
              {...layoutDoubleCol}
              name="barcode"
              label="รหัสบาร์โค้ด"
              colon={false}
              required={true}
              rules={[{ required: true, message: 'กรุณากรอก รหัสบาร์โค้ด' }]}
            >
              <Input placeholder="รหัสบาร์โค้ด" />
            </Form.Item>
          </Col>

          <Col span="12">
            <Form.Item
              {...layoutDoubleCol}
              name="authorNameEn"
              label="ชื่อผู้แต่ง (EN)"
              colon={false}
              required={false}
            >
              <Input placeholder="กรอกชื่อผู้แต่งภาษาอังกฤษ" />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span="12">
            <Col span="24">
              <Form.Item
                {...layoutDoubleCol}
                name="bookType"
                label="ประเภทสินค้า"
                colon={false}
                required={true}
                rules={[{ required: true, message: 'กรุณากรอก ประเภทสินค้า' }]}
              >
                <Select className="w-full" placeholder="เลือกประเภทสินค้า">
                  <Select.Option key={1} value={'ebook'}>
                    แบบเรียน
                  </Select.Option>
                  <Select.Option key={2} value={'quiz'}>
                    แบบฝึกหัด
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span="24">
              <Form.Item
                {...layoutDoubleCol}
                name="gradeLevelId"
                label="ระดับชั้น"
                colon={false}
                required={true}
                rules={[{ required: true, message: 'กรุณากรอก ระดับชั้น' }]}
              >
                <Select className="w-full" placeholder="เลือกระดับชั้น">
                  {gradeLevelsData?.map((grade: any) => {
                    return (
                      <Select.Option
                        key={grade.gradeLevelId}
                        value={grade.gradeLevelId}
                      >
                        {grade.name.th}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span="24">
              <Form.Item
                {...layoutDoubleCol}
                name="bookVolume"
                label="เล่มที่"
                colon={false}
                required={true}
                rules={[
                  { required: true, message: 'กรุณากรอก เล่มที่' },
                  {
                    pattern: new RegExp(/^[1-9][0-9]*$/),
                    message: 'กรุณากรอก เล่มที่ ให้ถูกต้อง'
                  }
                ]}
              >
                <Input placeholder="เล่มที่" />
              </Form.Item>
            </Col>

            <Col span="24">
              <Form.Item
                {...layoutDoubleCol}
                name="subjectGroupId"
                label="สาระวิชา"
                colon={false}
                required={true}
                rules={[{ required: true, message: 'กรุณากรอก สาระวิชา' }]}
              >
                <Select
                  className="w-full"
                  placeholder="เลือกสาระวิชา"
                  optionFilterProp="children"
                  showSearch
                  onSelect={(subjectSelect) => selectSubject(subjectSelect)}
                >
                  {subjectGroupsData?.map((subjectGroup: any) => {
                    return (
                      <Select.Option
                        key={subjectGroup.subjectGroupId}
                        value={subjectGroup.subjectGroupId}
                      >
                        {subjectGroup.name.th}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>

            {/* TextArea */}
            <Col span="24">
              <Form.Item
                {...layoutDoubleCol}
                name="subjectId"
                label="วิชา"
                colon={false}
                required={true}
                rules={[{ required: true, message: 'กรุณากรอก วิชา' }]}
              >
                <Select
                  className="w-full"
                  placeholder="เลือกวิชา"
                  optionFilterProp="children"
                  showSearch
                  disabled={subjectsState?.length === 0}
                >
                  {subjectsState?.map((subject: any) => {
                    return (
                      <Select.Option
                        key={subject.subjectId}
                        value={subject.subjectId}
                      >
                        {subject.name.th}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Col>

          <Col span="12">
            <Col span="24">
              <Form.Item
                {...layoutDoubleCol}
                name="descriptionTh"
                label="รายละเอียดสินค้า (TH)"
                colon={false}
                required={true}
                rules={[
                  {
                    required: true,
                    message: 'กรุณากรอก รายละเอียดสินค้า (TH)'
                  }
                ]}
              >
                <Input.TextArea
                  className=""
                  rows={2}
                  placeholder="รายละเอียดสินค้าภาษาไทย"
                />
              </Form.Item>
            </Col>
            <Col span="24">
              <Form.Item
                {...layoutDoubleCol}
                name="descriptionEn"
                label="รายละเอียดสินค้า (EN)"
                colon={false}
                required={false}
              >
                <Input.TextArea
                  className=""
                  rows={2}
                  placeholder="รายละเอียดสินค้าภาษาอังกฤษ"
                />
              </Form.Item>
            </Col>
          </Col>
        </Row>

        <Row>
          <div className="font-bold mt-6 mb-5 text-base">รูปภาพ</div>
        </Row>
        <Row>
          <Col span="24">
            <Form.Item
              {...layoutSingleCol}
              name="coverImage"
              label="รูปภาพปกสินค้า"
              colon={false}
              required={true}
              rules={[{ required: true, message: 'กรุณาเลือก รูปภาพปกสินค้า' }]}
            >
              <Upload
                customRequest={dummyRequest}
                fileList={fileList}
                listType="picture"
                maxCount={1}
                onChange={handleChange}
                onRemove={removeFile}
                beforeUpload={coverImageBeforeUpload}
                showUploadList={{ showPreviewIcon: false }}
              >
                {fileList.length > 0 ? null : (
                  <Button icon={<UploadOutlined />}>Upload</Button>
                )}
              </Upload>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <div className="font-bold mt-6 mb-5 text-base">การจำหน่าย</div>
        </Row>
        <Row>
          <Col span="24">
            <Form.Item
              {...layoutSingleCol}
              name="basicPrice"
              label="ราคา Basic"
              colon={false}
              required={false}
              rules={[
                {
                  pattern: new RegExp(/^[1-9][0-9]*$/),
                  message: 'กรุณากรอก ราคา Basic ให้ถูกต้อง'
                }
              ]}
            >
              <Input placeholder="ราคาสินค้า Basic" />
            </Form.Item>
          </Col>
          <Col span="24">
            <Form.Item
              {...layoutSingleCol}
              name="premiumPrice"
              label="ราคา Premium"
              colon={false}
              required={false}
              rules={[
                {
                  pattern: new RegExp(/^[1-9][0-9]*$/),
                  message: 'กรุณากรอก ราคา Premium ให้ถูกต้อง'
                }
              ]}
            >
              <Input placeholder="ราคาสินค้า Premium" />
            </Form.Item>
          </Col>
        </Row>

        {/* End double column */}

        <Row justify="end">
          <div className="flex justify-end">
            <Button
              className="w-full md:w-32 h-12 border-none text-base"
              onClick={() => history.push('/product/manage-list')}
            >
              ยกเลิก
            </Button>
            <Button
              className="w-full md:w-32 h-12 text-white text-base rounded-lg"
              type="primary"
              htmlType="submit"
            >
              {props.mode === 'add' ? 'เพิ่มสินค้า' : 'บันทึก'}
            </Button>
          </div>
        </Row>
      </Form>
    </div>
  );
};
