import { Col, DatePicker, Form, Radio, Row } from 'antd';
import {
  CourseLesson,
  CourseLessonType,
  CourseSection
} from 'feature/course/course.type';
import {
  CourseSessionLessonIdWithQuiz,
  Mode
} from 'feature/course/session/CourseSessionType';
import React, { useState } from 'react';

import _ from 'lodash';
import moment from 'moment';
import { toStartOfDay } from 'utils/normalizeForm';

enum AnswerSchedule {
  IMMEDIATELY = 'immediately',
  SETTIME = 'setTime'
}
interface Props {
  sections: CourseSection[];
  lessonWithQuizScheduleOrQuizPercentages: CourseSessionLessonIdWithQuiz[];
  setLessonWithQuizScheduleOrQuizPercentages: (
    lessonWithQuizScheduleOrQuizPercentages: CourseSessionLessonIdWithQuiz[]
  ) => void;
  mode: Mode;
}
interface SolutionProps {
  lessonWithQuizScheduleOrQuizPercentages: CourseSessionLessonIdWithQuiz[];
  setLessonWithQuizScheduleOrQuizPercentages: (
    lessonWithQuizScheduleOrQuizPercentages: CourseSessionLessonIdWithQuiz[]
  ) => void;
  mode: Mode;
  lessonElement: CourseLesson;
}

export const CourseSessionSolutionReviewDate = (props: Props) => {
  const {
    sections,
    lessonWithQuizScheduleOrQuizPercentages,
    setLessonWithQuizScheduleOrQuizPercentages,
    mode
  } = props;
  const sectionWithQuizs = sections?.reduce(
    (total: CourseSection[], section: CourseSection) => {
      const lessons = section?.courseLessons?.filter(
        (courseLessons: CourseLesson) =>
          courseLessons.lessonType === CourseLessonType.QUIZ
      );

      if (!lessons?.length) {
        return total;
      } else {
        total.push({ ...section, courseLessons: lessons });
        return total;
      }
    },
    [] as CourseSection[]
  );

  return (
    <>
      <div className="font-bold mb-4">กำหนดเฉลย</div>
      <Row>
        <Col span={24}>
          {sectionWithQuizs?.map((section) => {
            return (
              <div key={section.courseSectionId}>
                <div className="text-mono-gray-300">{section?.name?.th}</div>
                {section?.courseLessons?.map((lessonElement: CourseLesson) => {
                  return (
                    <SolutionReviewDate
                      key={lessonElement.courseLessonId}
                      lessonElement={lessonElement}
                      lessonWithQuizScheduleOrQuizPercentages={
                        lessonWithQuizScheduleOrQuizPercentages
                      }
                      setLessonWithQuizScheduleOrQuizPercentages={
                        setLessonWithQuizScheduleOrQuizPercentages
                      }
                      mode={mode}
                    />
                  );
                })}
              </div>
            );
          })}
        </Col>
      </Row>
    </>
  );
};

const SolutionReviewDate = (props: SolutionProps) => {
  const {
    lessonElement,
    lessonWithQuizScheduleOrQuizPercentages,
    setLessonWithQuizScheduleOrQuizPercentages,
    mode
  } = props;
  const indexOfChangedElement = lessonWithQuizScheduleOrQuizPercentages?.findIndex(
    (courseLesson: CourseSessionLessonIdWithQuiz) => {
      return courseLesson?.id === lessonElement?.courseLessonId;
    }
  );

  const [isDatePickerVisible, setIsDatePickerVisible] = useState(
    lessonWithQuizScheduleOrQuizPercentages[indexOfChangedElement]
      ?.quizAnswerSheetSchedule
      ? true
      : false
  );

  const handleSelectSeeAnswerSheetDate = (date: any) => {
    switch (mode) {
      case Mode.Edit:
        const newLessonWithQuizScheduleOrQuizPercentages = lessonWithQuizScheduleOrQuizPercentages;
        newLessonWithQuizScheduleOrQuizPercentages[
          indexOfChangedElement
        ].quizAnswerSheetSchedule = date;
        setLessonWithQuizScheduleOrQuizPercentages(
          newLessonWithQuizScheduleOrQuizPercentages
        );

        break;
      case Mode.ADD:
        if (
          _.find(lessonWithQuizScheduleOrQuizPercentages, {
            id: lessonElement?.courseLessonId
          })
        ) {
          const newLessonWithQuizScheduleOrQuizPercentages = lessonWithQuizScheduleOrQuizPercentages?.map(
            (elementWithId: CourseSessionLessonIdWithQuiz) => {
              if (elementWithId?.id === lessonElement?.courseLessonId) {
                return {
                  ...elementWithId,
                  quizAnswerSheetSchedule: date
                };
              }
              return elementWithId;
            }
          );
          setLessonWithQuizScheduleOrQuizPercentages(
            newLessonWithQuizScheduleOrQuizPercentages
          );
        } else {
          setLessonWithQuizScheduleOrQuizPercentages([
            ...lessonWithQuizScheduleOrQuizPercentages,
            {
              id: lessonElement?.courseLessonId,
              quizAnswerSheetSchedule: date
            }
          ]);
        }
        break;
    }
  };

  return (
    <Row>
      <Col span={8}>
        <Form.Item
          label={lessonElement.name.th}
          key={lessonElement.courseLessonId}
          name={lessonElement.courseLessonId}
          labelCol={{ span: 11 }}
          wrapperCol={{ span: 13 }}
        >
          <Radio.Group
            key={lessonElement.courseLessonId}
            defaultValue={
              lessonWithQuizScheduleOrQuizPercentages[indexOfChangedElement]
                ?.quizAnswerSheetSchedule
                ? AnswerSchedule.SETTIME
                : AnswerSchedule.IMMEDIATELY
            }
            name={lessonElement?.courseLessonId}
            onChange={(e) => {
              if (e.target.value === AnswerSchedule.SETTIME) {
                setIsDatePickerVisible(true);
              } else {
                setIsDatePickerVisible(false);
                handleSelectSeeAnswerSheetDate(null);
              }
            }}
          >
            <Radio.Button value={AnswerSchedule.IMMEDIATELY}>
              ทันที
            </Radio.Button>
            <Radio.Button value={AnswerSchedule.SETTIME}>
              พร้อมกัน(ตั้งเวลา)
            </Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Col>

      {isDatePickerVisible && (
        <Col span={12}>
          <Form.Item
            label="เฉลยวันที่"
            name={`quizAnswerSheetSchedule${lessonElement?.name?.th}`}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
          >
            <DatePicker
              defaultValue={
                lessonWithQuizScheduleOrQuizPercentages[indexOfChangedElement]
                  ?.quizAnswerSheetSchedule
                  ? moment(
                      lessonWithQuizScheduleOrQuizPercentages[
                        indexOfChangedElement
                      ]?.quizAnswerSheetSchedule
                    )
                  : undefined
              }
              onChange={(date: any) => {
                handleSelectSeeAnswerSheetDate(
                  toStartOfDay(date).toISOString(true)
                );
              }}
            />
          </Form.Item>
        </Col>
      )}
    </Row>
  );
};
