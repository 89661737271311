import { Button } from 'antd';
import React from 'react';

export const PrimaryButton = ({ children, className, ...restProps }: any) => (
  <Button
    htmlType="submit"
    className={`w-40 h-12 rounded-lg text-base ${className}`}
    type="primary"
    {...restProps}
  >
    {children}
  </Button>
);

export const TextButton = ({ children, className, ...restProps }: any) => (
  <button
    type="button"
    className={`w-40 h-12 rounded-lg text-base outline-none ${className}`}
    {...restProps}
  >
    {children}
  </button>
);
