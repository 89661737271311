import { Button, Popconfirm, Spin, Table, message } from 'antd';
import React, { useState } from 'react';
import {
  SessionCondition,
  SessionStatus,
  SessionType
} from 'feature/course/course.type';

import Axios from 'axios';
import { CourseSessionAddSession } from 'feature/course/session/CourseSessionAddSession';
import { CourseSessionEditSession } from 'feature/course/session/CourseSessionEditSession';
import { ReactComponent as DeleteIcon } from 'assets/delete.svg';
import { ReactComponent as FormEditIcon } from 'assets/form-edit.svg';
import { PlusOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { css } from '@emotion/react';
import moment from 'moment';
import { useSessions } from 'hooks/session/useSessions';

interface Props {
  courseId: string;
}

export const CourseSessionForm = (props: Props) => {
  const { courseId } = props;

  const {
    data: dataOfSessions,
    loading: loadingOfUseSession,
    execute: refetch
  } = useSessions(courseId);

  const [addSessionModalVisible, setAddSessionModalVisible] = useState<boolean>(
    false
  );
  const [
    editSessionModalVisible,
    setEditSessionModalVisible
  ] = useState<boolean>(false);
  const [courseSessionId, setCourseSessionId] = useState<string>('');
  const [deleting, setDeleting] = useState(false);

  const columns = [
    {
      title: 'รอบลงทะเบียน',
      dataIndex: 'sessionStartDate',
      key: 'sessionStartDate',
      render: (text: string, record: any) => {
        const registrationStartDate = moment(
          record?.registrationStartDate
        ).format('D MMM');
        const registrationEndDate = moment(record?.registrationEndDate).format(
          'D MMM YY'
        );
        return (
          <span>{`${registrationStartDate} - ${registrationEndDate}`}</span>
        );
      }
    },
    {
      title: 'ชื่อรอบอบรม',
      dataIndex: 'name',
      key: 'name',
      render: (name: any) => <span>{name?.th}</span>
    },
    {
      title: 'แสดงบนเว็บ',
      dataIndex: 'status',
      key: 'status',

      render: (status: SessionStatus) => {
        switch (status) {
          case SessionStatus.ACTIVE:
            return <div className="text-blue">แสดง</div>;
          case SessionStatus.INACTIVE:
            return <div className="text-mono-gray-300">ไม่แสดง</div>;
        }
      }
    },
    {
      title: 'ระยะเวลาอบรม',
      dataIndex: 'sessionStartDate',
      key: 'sessionStartDate',
      render: (text: string, record: any) => {
        const sessionStartDate = moment(record?.sessionStartDate).format(
          'D MMM'
        );
        const sessionEndDate = moment(record?.sessionEndDate).format(
          'D MMM YY'
        );
        return <span>{`${sessionStartDate} - ${sessionEndDate}`}</span>;
      }
    },
    {
      title: 'ประเภทอบรม',
      dataIndex: 'visibility',
      key: 'visibility',
      render: (text: SessionType) => {
        switch (text) {
          case SessionType.PUBLIC:
            return <span>Public</span>;
          case SessionType.SPECIAL:
            return <span>Special</span>;
        }
      }
    },
    {
      title: 'ลำดับการดู',
      dataIndex: 'learningType',
      key: 'learningType',
      render: (text: SessionCondition) => {
        switch (text) {
          case SessionCondition.SEQUENTIAL:
            return <span>Sequential</span>;
          case SessionCondition.PARALLEL:
            return <span>Parallel</span>;
        }
      }
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      align: 'right',
      render: (text: any, record: any) => (
        <div className="flex justify-end">
          <div>
            <FormEditIcon
              className="cursor-pointer"
              onClick={() => {
                setCourseSessionId(record.courseSessionId);
                setEditSessionModalVisible(true);
              }}
              style={{ color: '#1890FF' }}
            />
          </div>
          <div className="ml-4">
            <Popconfirm
              title="ต้องการลบเนื้อหานี้?"
              okText="ลบ"
              cancelText="ยกเลิก"
              placement="bottom"
              onConfirm={() => {
                handleDeleteCourseSession(record?.courseSessionId);
              }}
            >
              <DeleteIcon
                className="cursor-pointer"
                css={css`
                  color: #f03d3e;
                `}
              />
            </Popconfirm>
          </div>
        </div>
      )
    }
  ];

  const handleDeleteCourseSession = async (courseSessionId: string) => {
    setDeleting(true);
    const loading = message.loading('กำลังดำเนินการ...', 0);
    try {
      await Axios.delete('/course-sessions/' + courseSessionId);
      loading();
      message.success('ลบเนื้อหาสำเร็จ');
      await refetch();
    } catch (error) {
      loading();

      message.error('ลบเนื้อหาไม่สำเร็จ');
    } finally {
      setDeleting(false);
    }
  };

  return (
    <Spin spinning={loadingOfUseSession || deleting}>
      <div className="flex justify-between font-bold text-base text-mono-gray-400 mb-6">
        รอบทั้งหมด
        <Button
          className="flex items-center"
          onClick={() => setAddSessionModalVisible(true)}
        >
          <PlusOutlined /> เพิ่มรอบ
        </Button>
      </div>
      <Table dataSource={dataOfSessions} columns={columns as any}></Table>
      {addSessionModalVisible && (
        <CourseSessionAddSession
          visible={addSessionModalVisible}
          setVisible={setAddSessionModalVisible}
          courseId={courseId}
          refetch={refetch}
        />
      )}
      {editSessionModalVisible && (
        <CourseSessionEditSession
          visible={editSessionModalVisible}
          setVisible={setEditSessionModalVisible}
          courseId={courseId}
          courseSessionId={courseSessionId}
          refetch={refetch}
        />
      )}
    </Spin>
  );
};
