import useAxios from 'axios-hooks';

type CourseCollectionResponse = {
  courseCollectionId?: string;
  name?: {
    th?: string;
    en?: string;
  };
  thumbnailUrl?: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  deletedBy?: string;
  deletedAt?: string;
};

export const useCourseCollections = () => {
  const [{ data, loading, error }, refetch] = useAxios(
    '/course-collections?pageNumber=1&pageSize=100'
  );

  const res = loading ? [] : (data?.data as CourseCollectionResponse[]) || [];
  const courseCollectionOptions = (res as CourseCollectionResponse[]).map(
    (s) => ({
      value: s.courseCollectionId as string,
      label: `${s.name?.th}` as string
    })
  );
  return {
    data: courseCollectionOptions,
    loading,
    error,
    execute: refetch
  };
};
