export const size = {
  iPhone: '375px',
  iPhonePlus: '425px',
  iPhoneHorizontal: '640px',
  iPad: '768px',
  iPadPro: '1024px',
  iPadHorizontal: '1280px',
  labtop: '1440px'
};
export const MEDIA = {
  MIN: {
    xs: '@media (min-width: 475px)',
    sm: '@media (min-width: 640px)',
    md: '@media (min-width: 768px)',
    lg: '@media (min-width: 1024px)',
    xl: '@media (min-width: 1280px)',
    '2xl': '@media (min-width: 1440px)'
  },
  MAX: {
    xs: '@media (max-width: 475px)',
    sm: '@media (max-width: 640px)',
    md: '@media (max-width: 768px)',
    lg: '@media (max-width: 1024px)',
    xl: '@media (max-width: 1280px)',
    '2xl': '@media (max-width: 1440px)'
  }
};
