import { identity, pickBy } from 'lodash';

import { CouponReportUser } from '../../feature/coupon-report/coupon-report.type';
import { UserType } from '../../feature/common/user.type';
import useAxios from 'axios-hooks';

type CouponReportUserRowItem = CouponReportUser & {
  index: number;
};

type QueryCouponReportUsers = {
  couponId: string;
  pageNumber?: number;
  pageSize?: number;
  schoolId?: string;
  userType?: UserType;
};

export const useCouponReportUsers = (params: QueryCouponReportUsers) => {
  const { couponId = '' } = params;
  const [{ data, loading, error }, refetch] = useAxios({
    url: `/coupon-reports/${couponId}/users`,
    params: {
      pageNumber: 1,
      pageSize: 10,
      ...pickBy(params, identity)
    }
  });

  return {
    data: data?.data.map((d: CouponReportUser, idx: number) => ({
      ...d,
      index: ((params.pageNumber || 1) - 1) * 10 + idx + 1
    })) as CouponReportUserRowItem[],
    loading: loading,
    error,
    refetch,
    item: data && data?.meta?.pagination?.total
  };
};
